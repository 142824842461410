/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export const format = (text: string | null, params: string | string[]) => {
  if (text && params) {
    if (Array.isArray(params)) {
      for (let i = 0; i < params.length; i++) {
        text = text.replace(new RegExp("\\{" + i + "\\}", "gi"), params[i]);
      }
    } else {
      text = text.replace(new RegExp("\\{0\\}", "gi"), params);
    }
  }

  return text;
};

export const normalizeName = (name: string | null) => {
  if (name === null || "" === name) {
    return "noName";
  } else {
    return name.replace(/\s+|\.|@/g, "-");
  }
};

export const normalizeFilter = (filter: string) => {
  const softHyphen = "\u00AD";
  const whiteSpace = /\s+/g;
  return filter.toLocaleLowerCase().replace(whiteSpace, " ").replace(softHyphen, "");
};

