/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { DHLDecisionDialogStore } from "../../../stores/DHLDecisionDialogStore";
import "./DHLDecisionDialog.scss";
import { DHLButton } from "../../atoms/DHLButton/DHLButton";
import { ErrorResponse } from "../../../stores/BaseDataStore";
import { DHLMessages } from "../../molecules/DHLMessages/DHLMessages";
import { DHLFormGroup } from "../../atoms/DHLFormGroup/DHLFormGroup";
import { createMessageWithSpecialTags } from "../../../utils/messagesUtils";
import { DHLModal } from "../../molecules/DHLModal/DHLModal";
import { runInAction } from "mobx";

export type DHLDecisionDialogProps = {
  /** Store */
  store: DHLDecisionDialogStore;

  /** Name (used for localization keys) */
  name: string;

  /** Action to take on confirming decision */
  onConfirm: () => Promise<void>;
}

export const DHLDecisionDialog = observer(({
                                          store,
                                          name,
                                          onConfirm
                                        }: DHLDecisionDialogProps) => {
  const {messageDataStore} = store;
  const {localizationPack} = store.resourceDataStore;

  if (!store.showDialog) {
    return null;
  }

  const actionBar = <>
    <DHLButton
        type="default"
        label={localizationPack.getButton(`${name}.no`)}
        name={name + "-modalNo"}
        onClick={() => {
          messageDataStore.clearTemporaryMessages();
          runInAction(() => store.showDialog = false);
        }}
        disabled={store.runningConfirm}
    />
    <DHLButton
        type="primary"
        label={localizationPack.getButton(`${name}.yes`)}
        name={name + "-modalYes"}
        onClick={() => {
          messageDataStore.clearTemporaryMessages();
          store.runningConfirm = true;
          onConfirm()
              .then(() => runInAction(() => store.showDialog = false), (error) => {
                const errorResponse = error as ErrorResponse;
                errorResponse.errorMessages?.forEach(errorMsg => messageDataStore.addErrorMessage(
                    localizationPack.getMessage(errorMsg.messageKey, errorMsg.params ?? [])
                ));
              })
              .finally(() => runInAction(() => store.runningConfirm = false));
        }}
        loadingState={store.runningConfirm}
        loadingTooltip={localizationPack.getTooltip(`${name}.runningConfirm`)}
    />
  </>;

  const content = <>
    <DHLMessages name={name + "-message"} msgStore={messageDataStore} />
    <DHLFormGroup>
      {createMessageWithSpecialTags(localizationPack.getMsg(`${name}.message`))}
    </DHLFormGroup>
  </>;

  return <DHLModal
      className={"dhlDecisionDialog"}
      name={name}
      show={store.showDialog}
      title={localizationPack.getLabel(`${name}.title`)}
      content={content}
      actionbar={actionBar}
  />;
});
