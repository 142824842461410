import { observer } from "mobx-react-lite";
import { formatNumber } from "../../../utils/formatter";
import { useLocaleManager } from "../../../locale/useLocaleManager";

export type DHLTableColNumberProps = {
  /** Anzuzeigender Wert. */
  value?: any;

  /** Mindestanzahl Nachkommastellen. */
  minFractionDigits?: number;

  /** Maximalanzahl Nachkommastellen. */
  maxFractionDigits?: number;

  /** Tausendertrennzeichen verwenden? */
  groupingEnabled?: boolean;

  /** Formatierer für Spalten. */
  formatter?: any;
};

/** Ausgabe einer Tabellenzelle - numerischer Wert. */
export const DHLTableColNumber = observer(({
                                             value,
                                             minFractionDigits = 0,
                                             maxFractionDigits = 0,
                                             groupingEnabled = true,
                                             formatter
                                           }: DHLTableColNumberProps
) => {
  const localeManager = useLocaleManager();
  let output = value;

  if (output) {
    if (formatter) {
      output = formatter.format(value);
    } else {
      output = formatNumber(localeManager, value, minFractionDigits, maxFractionDigits, groupingEnabled);
    }
  }

  return <>{output}</>;
});
