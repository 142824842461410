/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { UIDConsumer } from "react-uid";
import { DHLFieldNote, DHLIcon, DHLLabel, DHLTooltip, FormField, logger } from "../../..";
import { LocalizationPackType } from "../../../stores/LocalizationPack";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import {
  calcSelectOptionData,
  calculateDisabled,
  calculateError,
  calculateErrorMarkerOnly,
  calculateLabel,
  calculateName,
  calculateOnChange,
  calculateRequired,
  calculateValidationRule,
  calculateValue
} from "../../../utils/calcPropertiesValue";
import classNames from "classnames";
import "./DHLSelectOne.scss";
import { IconType } from "../../atoms/DHLIcon/DHLIcon";
import { KeyValueType } from "../../../types/DataTypes";
import { KeyResourceDataType } from "../../../types/ResourceDataStoreTypes";
import * as Popper from "popper.js";
import { useState } from "react";

export type DHLSelectOneOptionType = {
  key: string;
  value?: string;
  resourceDataKey?: string;
  disabled: boolean;
}
export type DHLSelectOneProps = {
  /** Name of the input field. Should match the name of the property for the input value to use a single change handler.
   *  Also used to generate the Test-ID. */
  name?: string;

  /** Label Classic (!) */
  label?: string;

  /** CSS classes for the label. */
  labelClassName?: string;

  /** CSS classes for the input field. */
  className?: string;

  /** Label with mandatory field marking. */
  required?: boolean;

  /** Enforce that there is no empty Option even if the field is not required. */
  forceNoEmptyOption?: boolean;

  /** Position cursor in input field? */
  autoFocus?: boolean;

  /** Auto-complete allowed? */
  autoCompleteOff?: boolean;

  /** Edit mode of the form (true = new creation, false = editing). The mandatory field status of the input field may depend on the edit mode. */
  createMode?: boolean;

  /** Disable? */
  disabled?: boolean;

  /** Tooltip, if disabled. */
  disabledTooltip?: string;

  /** Show Element? */
  render?: boolean;

  /** Function for onChange calls. */
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;

  /** Placeholder text for empty input fields // Label Compact */
  placeholder?: string;

  /** Input value (optional to allow Storybook etc. input). */
  value?: any;

  /** Validation rule with configuration data for the input field. */
  validationRule?: ValidationRuleType;

  /** Available options to select. If KeyValueResourceDataType is given, a localizationPack is required. */
  data: (KeyValueType | KeyResourceDataType | DHLSelectOneOptionType)[];

  /** Error text(s), if one or more errors have occurred. */
  error?: string | string[] | null;

  /** Only mark the input field or also show error texts? */
  errorMarkerOnly?: boolean;

  /** Name des Icons . */
  icon?: IconType;

  /** Defines a form field with name, label, value, validation rule and error text. Single parameters have priority. */
  formField?: FormField<any>;

  /** Tooltip. */
  tooltip?: string;

  /**Tooltip alignment */
  tooltipPlacement?: Popper.Placement;

  /** Is used to get selection List Labels if KeyValueResourceDataType is given as data */
  localizationPack?: LocalizationPackType;

};

/** Auswahlliste für Einfachauswahlen. */
export const DHLSelectOne = observer(({
                                        name,
                                        label,
                                        labelClassName,
                                        className,
                                        data,
                                        required,
                                        forceNoEmptyOption = false,
                                        autoFocus,
                                        autoCompleteOff,
                                        createMode,
                                        disabled,
                                        disabledTooltip,
                                        render = true,
                                        value,
                                        placeholder,
                                        error,
                                        errorMarkerOnly,
                                        validationRule,
                                        onChange,
                                        icon,
                                        formField,
                                        tooltip,
                                        tooltipPlacement = "bottom",
                                        localizationPack
                                      }: DHLSelectOneProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  if (!render) {
    return null;
  }

  const toggle = () => setTooltipOpen(!tooltipOpen);

  let usedTooltip: string | undefined = tooltip;

  if (disabled && disabledTooltip) {
    usedTooltip = disabledTooltip;
  }
  const calcName = calculateName(name, formField);
  const calcData = calcSelectOptionData(data);

  if (!calcName) {
    logger.error("Components needs an explicit name or formField parameter");
    return null;
  }

  const calcLabel = calculateLabel(label, formField);
  const calcValidationRule = calculateValidationRule(validationRule, formField);
  const calcError = calculateError(error, formField);
  const calcValue = calculateValue(value, formField);
  const calcOnChange = calculateOnChange(onChange, formField);
  const calcRequired = calculateRequired(required, calcValidationRule, createMode);
  const calcErrorMarkerOnly = calculateErrorMarkerOnly(errorMarkerOnly, calcValidationRule);
  const calcDisabled = calculateDisabled(disabled, calcValidationRule, createMode);
  const functionIcon: IconType = "arrow-down";
  const calcFunctionsIcon = calcDisabled ? null : <div
      className={classNames("input-function", {
        "not-interactive": true,
      }, functionIcon)}
  >
    <DHLIcon name={calcName + "-function"} icon={functionIcon} />
  </div>;

  const calcPlaceholderActive = calcValue !== null && calcValue !== undefined && calcValue !== "";

  // minor Bug/wontfix? Änderung von placeholder-Prob aktualisiert nicht zuverlässig Ansicht.
  // (im Storybook+Firefox; Storybook+Chrome ok)
  const calcPlaceholder = placeholder === undefined ? null :
      <option key="_placeholder"
              value={""}
              className={"placeholder"}
          // Don't disable and hide Placeholder if optional select
              disabled={calcRequired || forceNoEmptyOption ? true : undefined}
              hidden={calcRequired || forceNoEmptyOption ? true : undefined}
          // selected={calcValue === "" ? true : undefined}
      >
        {/* placeholder; empty for now, placeholder text via Label Compact*/}
      </option>;

  function calcOptionLabel(option: KeyValueType | KeyResourceDataType | DHLSelectOneOptionType) {
    if ((option as KeyResourceDataType).resourceDataKey) {
      if (localizationPack) {
        return localizationPack.getSelectionListOptionLabel((option as KeyResourceDataType).resourceDataKey);
      } else {
        return (option as KeyResourceDataType).resourceDataKey;
      }
    }

    return (option as KeyValueType).value;
  }

  const output = (
      <UIDConsumer>
        {(uid, itemUid) => (
            <>
              {calcLabel &&
                  <DHLLabel name={calcName + "-label"} htmlFor={uid} label={calcLabel} required={calcRequired}
                            className={labelClassName} />}
              <div className={classNames("dhlInputSelectContainer-inner")}>
                {icon &&
                    <div className={classNames("select-one-icon-container", disabled ? "disabled" : null)}>
                      <DHLIcon name={calcName + "-selectOne-icon"} icon={icon} />
                    </div>}
                <select
                    id={uid}
                    data-testid={calcName}
                    value={calcValue}
                    onChange={calcOnChange}
                    className={classNames(
                        "dhlInputSelect", icon ? "withIcon" : null,
                        (calcError !== null && calcError !== undefined && calcError.length > 0) ? "error" : null
                    )}
                    autoComplete={autoCompleteOff ? "off" : ""}
                    autoFocus={autoFocus}
                    disabled={calcDisabled}
                >
                  {calcPlaceholder}
                  {calcData.map((option, index) => (
                      <option key={itemUid(option, index)} value={option.key} data-testid={calcName + "-option-" + option.key}
                              defaultValue={value === option.key ? value : undefined} disabled={option.disabled}>
                        {calcOptionLabel(option)}
                      </option>
                  ))}
                </select>
                {calcPlaceholder && <DHLLabel name={calcName + "-placeholder"} htmlFor={uid} label={placeholder} required={calcRequired}
                                              className={classNames("labelCompact", labelClassName, {labelActive: calcPlaceholderActive}, icon
                                                  ? "withIcon" : null, {hasError: calcError})} />}
                {calcFunctionsIcon}
              </div>
              {usedTooltip && <div className={classNames("input-tooltip")}>
                <DHLIcon name={calcName + "-tooltip"} icon={"alert-info"} />
                <DHLTooltip testid={calcName + "-tooltip"} placement={tooltipPlacement} tooltipOpen={tooltipOpen} target={calcName + "-tooltip"}
                            toggle={toggle}>
                  {usedTooltip}
                </DHLTooltip>
              </div>}
              {!calcErrorMarkerOnly && calcError && <DHLFieldNote name={calcName + "-error"} type={"error"} notes={calcError} />}
            </>
        )}
      </UIDConsumer>
  );

  return <div className={classNames("dhlInputSelectContainer", className)}>{output}</div>;
});
