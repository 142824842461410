/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import "./DHLAnimatedChevron.scss";
import classNames from "classnames";
import { DHLButton } from "../DHLButton/DHLButton";

export type DHLAnimatedChevronProps = {
  active: boolean,
  onClick: () => void,
}

export const DHLAnimatedChevron = ({active, onClick}: DHLAnimatedChevronProps) => {
  return <DHLButton
      onClick={onClick}
      icon={"arrow-down"}
      iconPosition={"icon"}
      className={classNames(active && "active", "dhlAnimatedChevron")}
  />;
};