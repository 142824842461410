/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { CSSProperties } from "react";
import { useUID, useUIDSeed } from "react-uid";
import { DHLLabel } from "../../atoms/DHLLabel/DHLLabel";
import "./ShuttleInput.scss"
import { KeyValueType } from "../../../types/DataTypes";

type ShuttleInputProps = {
  name?: string,
  className?: string;
  /** Labeltext */
  label?: string;

  /** has error? */
  error?: boolean;
  /** input disabled? */
  disabled?: boolean;

  /** Element ausgeben? */
  render?: boolean;
  /** optional: fixed display height in count of entries */
  fixedHeight?: number;

  /** Funktion für onChange-Aufrufe. */
  onChange: (newValues: string[]) => void;

  /** selected elements */
  value: string[];

  /** displayed options */
  data: KeyValueType[];
}
export const ShuttleInput = (props: ShuttleInputProps) => {
  const {name, className, label, error, disabled, data, value, onChange, fixedHeight} = props;
  const uid = useUID();
  const optionsKey = useUIDSeed();

  let inlineStyle: CSSProperties | undefined = undefined;
  if (fixedHeight !== undefined && fixedHeight >= 0) {
    inlineStyle = {
      height: ((30 * fixedHeight) + 2 * (10 + 1)) + "px", // option-height + padding + border
      minHeight: "unset",
      maxHeight: "unset",
    };
  }

  return <div className={classNames("dhlShuttle-input", className)}>
    <DHLLabel name={name + "-label"} label={label} htmlFor={uid} />
    <ul id={uid} className={classNames("dhlShuttle-input-inner", {error, disabled})} style={inlineStyle}>
      {data.map((option, index) => {
        const toggleHandler = () => {
          if (disabled) {
            return;
          }
          const wasSelected = value?.includes(option.key);
          if (wasSelected) {
            onChange(value.filter((v) => v !== option.key));
          } else {
            const newValues = value.slice();
            newValues.push(option.key);
            onChange(newValues);
          }
        };
        // normal tabable if first element, all others only focusable (like GKP)
        const tabIndex = index === 0 ? 0 : -1;
        return (
            <li key={optionsKey(option.key)}
                className={classNames("option", {interactable: !disabled, selected: value.includes(option.key)})}
                tabIndex={tabIndex}
                onClick={e => {
                  e.currentTarget.focus();
                  e.preventDefault();
                  toggleHandler();
                }}
                onKeyDown={e => {
                  if (e.key === " ") {
                    e.preventDefault();
                    toggleHandler();
                    return;
                  } else if (e.key === "ArrowUp" && index !== 0) {
                    e.preventDefault();
                    const element = e.currentTarget.previousElementSibling;
                    if (element !== null) {
                      (element as HTMLElement).focus();
                    }
                  } else if (e.key === "ArrowDown" && index + 1 !== data.length) {
                    e.preventDefault();
                    const element = e.currentTarget.nextElementSibling;
                    if (element !== null) {
                      (element as HTMLElement).focus();
                    }
                  }
                }}
            >
              {option.value}
            </li>
        );
      })}
    </ul>
  </div>;
};