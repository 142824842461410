/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { reaction } from "mobx";
import { useEffect, useState } from "react";
import { LocalizationManager } from "../stores/LocalizationManager";
import { LocalizationPack } from "../stores/LocalizationPack";

export const useLocalizations = (localizationManager: LocalizationManager, module: string) => {
  const [localizations, setLocalizations] = useState<LocalizationPack>(new LocalizationPack(module, localizationManager.localizations));

  useEffect(() => {
    localizationManager.loadLocalizations(module);
    return reaction(
        () => localizationManager.localizations,
        () => setLocalizations(new LocalizationPack(module, localizationManager.localizations))
    );
  }, [module]);

  return localizations;
};