/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Dispatch, SetStateAction } from "react";

export type DHLTableCellDetailViewProps = {
  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type?: typeof detailViewType;

  /** CSS-Klassen. */
  className?: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: (value: boolean, cellId: string, idProperyName: string, colPropertyName: string) => void;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Funktion zum Rendern des Zelleninhalts. */
  customRenderer: (value: any, row: Object, isOpen: boolean, setOpen: Dispatch<SetStateAction<boolean>>) => any;

  /** Kind-Komponenten. */
  children?: never;
};

/** Tabellenspaltendefinition - freie Ausgabe. */
export const DHLTableCellDetailView = (props: DHLTableCellDetailViewProps) => {
  return null;
};
export const detailViewType = "detailView";
DHLTableCellDetailView.defaultProps = {
  type: detailViewType,

  sortable: false
};
