/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export type DHLTableCellDetailViewOpenerProps = {
  /** Name des anzuzeigenden Properties. */
  propertiesName: string;
  /** Ausgabetyp. */
  type?: typeof detailViewTypeOpener;

  /** Kind-Komponenten. */
  children?: never;
};

/** Tabellenspaltendefinition - freie Ausgabe. */
export const DHLTableCellDetailViewOpener = (props: DHLTableCellDetailViewOpenerProps) => {
  return null;
};
export const detailViewTypeOpener = "detailViewOpener";
DHLTableCellDetailViewOpener.defaultProps = {
  type: detailViewTypeOpener,
  propertiesName: "__never",
  disableMinWidth: true,
  sortable: false
};
