import {observer} from "mobx-react-lite";
import { logger } from "../../../utils/logger";
import {DHLTextInput} from "../../molecules/DHLTextInput/DHLTextInput";

export type DHLTableColTextProps = {
  /** Anzuzeigender Wert. */
  value?: any;

  /** ID der Zelle. */
  cellId: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: (event: React.ChangeEvent, cellId: string, idPropertyName: string, colPropertyName: string) => void;

  /** Max. Anzahl erlaubter Zeichen. */
  maxLength?: number;

  /** Name des Properties mit der ID des Datensatzes. */
  idPropertyName?: string;

  /** Name des Properties dessen Daten in dieser Spalte angezeigt werden. */
  colPropertyName?: string;
};

/** Ausgabe einer Tabebellenzelle - textueller Wert mit optionaler Eingabemöglichkeit. */
export const DHLTableColText = observer(({value, cellId, onChange, maxLength, idPropertyName, colPropertyName}: DHLTableColTextProps) => {
  let output = value;

  if (onChange && !(idPropertyName && colPropertyName)) {
    logger.error("Missing parameters, need names of id and col properties when using onChange");
  }

  if (output !== undefined && onChange && idPropertyName && colPropertyName) {
    output = (
        <DHLTextInput
            name={cellId}
            value={value}
            errorMarkerOnly
            onChange={e => {
              onChange(e, cellId, idPropertyName, colPropertyName);
            }}
            maxLength={maxLength}
        />
    );
  }

  return <>{output}</>;
});
