/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { RefObject } from "react";

export function isExpectedOrigin(event: MessageEvent, expectedUrl: string, ourWindowLocation: Location){
    const expectedIframeOrigin = new URL(expectedUrl, ourWindowLocation.toString()).origin;
    return event.origin === expectedIframeOrigin;
}

export function isExpectedSource(event: MessageEvent, expectedSource: HTMLIFrameElement | null){
    return event.source === undefined  // undefined source always is valid
        || event.source === expectedSource?.contentWindow;
}

export function shouldEventBeRejected(
    event: MessageEvent, 
    expectedUrl: string,
    ourWindow: Window,
    expectedIFrameRef: RefObject<HTMLIFrameElement>
){
    return !isExpectedSource(event, expectedIFrameRef.current) //event is not from this iframe
        || !isExpectedOrigin(event, expectedUrl, ourWindow.location); //event is not from origin we rendered as iframe src
}

export function sendAnswer(
    eventToAnswer: MessageEvent | undefined, // todo: restrict to undefined/null as deprecation
    frameToSendTo: RefObject<HTMLIFrameElement>,
    response: unknown,
    iframeOrigin: string
){
    const target: Window | null | undefined = frameToSendTo.current?.contentWindow;

    target?.postMessage(response, iframeOrigin);
}