import {DateFormatType, TimeFormatType} from "../../../types/DataTypes";

export type DHLTableCellDateTimeProps = {
  /** Spaltentitel. */
  title: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type?: "dateTime" | "date" | "time";

  /** CSS-Klassen. */
  className?: string;

  /** Formatierung für Datumsanteil. */
  dateFormat?: DateFormatType;

  /** Formatierung für Uhrzeitanteil. */
  timeFormat?: TimeFormatType;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Kind-Komponenten. */
  children?: never;
};

/** Tabellenspaltendefinition - Datum/Zeit. */
export const DHLTableCellDateTime = (props: DHLTableCellDateTimeProps) => {
  return null;
};

DHLTableCellDateTime.defaultProps = {
  type: "dateTime",

  dateFormat: "DD.MM.YYYY",

  timeFormat: "HH:mm:ss",

  sortable: false,
};
