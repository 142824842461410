/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { ProgressBarBorder } from "./ProgressBarBorder";
import { ProgressBarIndicator } from "./ProgressBarIndicator";
import { ProgressBarLabel } from "./ProgressBarLabel";

const MINIMUM_STEPS_FOR_LARGE_LAYOUT = 5;

export type DHLProgressBarStepType = {
  /** Label for the step */
  label: string;
};

export type DHLProgressBarProps = {
  /** Array defining the steps */
  steps: DHLProgressBarStepType[];
  /** The index of the currently active step */
  activeIndex: number
}

export type ProgressBarState = "done" | "active" | "default";

export const DHLProgressBar = ({steps, activeIndex}: DHLProgressBarProps) => {
  if (steps.length === 0) {
    return null;
  }

  const getStateByIndex = (index: number): ProgressBarState => {
    if (index < activeIndex) {
      return "done";
    }
    if (index === activeIndex) {
      return "active";
    }
    return "default";
  };

  const createBorderElement = (predecessorIndex: number): JSX.Element => {
    const borderState: ProgressBarState = predecessorIndex < activeIndex ? "done" : "default";
    return <ProgressBarBorder
        key={`progressBarBorder-${predecessorIndex}`}
        borderState={borderState} />;
  };

  const createIndicatorElement = (index: number): JSX.Element =>
      <ProgressBarIndicator
          key={`progressBarIndicator-${index}`}
          showBorderStart={index > 0}
          showBorderEnd={index < steps.length - 1}
          index={index + 1}
          indicatorState={getStateByIndex(index)} />;

  const createLabelElement = (index: number): JSX.Element =>
      <ProgressBarLabel
          key={`progressBarLabel-${index}`}
          labelText={steps[index].label}
          labelState={getStateByIndex(index)} />;

  const indicatorElements: JSX.Element[] = [];
  const labelElements: JSX.Element[] = [];
  if (steps.length === 1) {
    const index = 0;
    indicatorElements.push(createIndicatorElement(index));
    labelElements.push(createLabelElement(index));
  } else {
    for (let i = 0; i < steps.length - 1; i++) {
      indicatorElements.push(createIndicatorElement(i));
      indicatorElements.push(createBorderElement(i));
      labelElements.push(createLabelElement(i));
    }
    const lastIndex = steps.length - 1;
    indicatorElements.push(createIndicatorElement(lastIndex));
    labelElements.push(createLabelElement(lastIndex));
  }

  let classes: string;
  const progressBarClassName = "dhlProgressBar";
  if (steps.length < MINIMUM_STEPS_FOR_LARGE_LAYOUT) {
    classes = classNames(progressBarClassName, "col-8", "offset-2");
  } else {
    classes = classNames(progressBarClassName, "col-12");
  }

  return (
      <div
          data-testid={"dhlProgressBar"}
          className={classes}>
        <div className={"dhlProgressBar-indicatorRow"}>
          {indicatorElements}
        </div>
        <div className={"dhlProgressBar-labelRow"}>
          {labelElements}
        </div>
      </div>
  );

};