import {useState} from "react";
import {Button, Tooltip} from "reactstrap";

export type DHLButtonLinkProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;

  /** Buttontext. */
  label?: string;

  /** Funktion für onClick-Aufrufe. */
  onClick: React.MouseEventHandler<HTMLElement>;

  /** CSS-Klassen. */
  className?: string;

  /** Deaktiviert? */
  disabled?: boolean;

  /** Element ausgeben? */
  render?: boolean;

  /** Name des Images für aktive Button. */
  imageSrc?: string;

  /** Name des Images für deaktivierte Button. Wenn kein alternatives Image angegeben wird, wird dass Standard-Image verwendet. */
  disabledImageSrc?: string;

  /** Tooltip. */
  tooltip?: string;

  /** Tooltip, wenn Button disabled. */
  disabledTooltip?: string;

  /** Image-Breite. */
  imageWidth?: number;

  /** Image-Höhe. */
  imageHeight?: number;

  /** Image-Alttext. */
  imageAlt?: string;
};

/** Button Link. Erlaubt die Ausgabe eines Text- oder Image-Links unter Verendung eines Buttons. */
export const DHLButtonLink = ({
                                name,
                                label,
                                disabled,
                                render = true,
                                onClick,
                                tooltip,
                                disabledTooltip,
                                imageSrc,
                                disabledImageSrc,
                                imageWidth,
                                imageHeight,
                                imageAlt,
                                className
                              }: DHLButtonLinkProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  if (!render) {
    return null;
  }

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const usedTooltip = (!disabled ? tooltip : (disabledTooltip ? disabledTooltip : tooltip));

  return (
      <div id={"container-" + name}>
        <Button data-testid={name} onClick={onClick} color="link" disabled={disabled} className={className}>
          {label}
          {imageSrc && !label && (
              <img
                  data-testid={name + "-img"}
                  src={disabled && disabledImageSrc ? disabledImageSrc : imageSrc}
                  width={imageWidth ? imageWidth : 10}
                  height={imageHeight ? imageHeight : 10}
                  alt={imageAlt}
              />
          )}
        </Button>
        {usedTooltip && <Tooltip placement="right" isOpen={tooltipOpen} target={"container-" + name} toggle={toggle}>
          {usedTooltip}
        </Tooltip>}
      </div>
  );
};
