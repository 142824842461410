/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, makeObservable, observable, ObservableSet, reaction, values } from "mobx";
import { normalizeFilter } from "../utils/stringUtils";
import { ResourceDataStore } from "./ResourceDataStore";
import { filterTextsSymbol } from "./TableDataStore";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { IObservableSetInitialValues } from "mobx/dist/internal";
import { createContext } from "react";

export const fullTextFilterPredicate = <T extends { [filterTextsSymbol]?: string[] }>
(values: Set<string>) => (data: T) => [...values].every(filterValue =>
    data[filterTextsSymbol]?.some(fullText => normalizeFilter(fullText).includes(normalizeFilter(filterValue)))
);

export type FilterData<T> = {
  values: ObservableSet<string>;
  predicate: (values: Set<string>) => (data: T) => boolean;
  localize: (value: string) => string;
}

export class DataFilterStore<T> {

  public readonly filterData = observable.map<string, FilterData<T>>();

  constructor(
      readonly resourceDataStore: ResourceDataStore,
      authenticationManager: AuthenticationManager
  ) {
    makeObservable(this, {
      filterData: observable,
      reset: action,
      predicate: computed,
      register: action,
      setFilterValues: action,
      addFilterValues: action,
      removeFilterValues: action,
      clearFilterValues: action
    });
    reaction(
        () => authenticationManager.authenticatedSubject,
        () => this.reset()
    );
  }

  public reset() {
    this.clearFilterValues();
  }

  public get predicate(): (data: T) => boolean {
    const predicates = [...this.filterData.entries()]
        .map(([_, filterData]) => {
          // we need to access values in the set to make mobx react to changes
          return filterData.predicate(new Set(values(filterData.values)));
        });
    // noinspection UnnecessaryLocalVariableJS TypeScript erroneously infers predicate as (data: T) => (data: T) => boolean if returned directly
    const predicate = ((data: T) => predicates
        .reduce(
            (result, predicate) => result && predicate(data),
            true
        ));
    return predicate;
  }

  private initFilterData(
      values: IObservableSetInitialValues<string>,
      predicate: (values: Set<string>) => (data: T) => boolean,
      localize: (value: string) => string
  ) {
    return observable(
        {values: observable.set(values), predicate, localize},
        {values: observable, predicate: observable, localize: observable}
    );
  }

  public register(
      owner: string,
      predicate: (values: Set<string>) => (data: T) => boolean,
      localize: (value: string) => string = value => value
  ) {
    const filterData = this.filterData.get(owner);
    if (filterData === undefined) {
      this.filterData.set(owner, this.initFilterData(new Set(), predicate, localize));
    } else {
      filterData.predicate = predicate;
      filterData.localize = localize;
    }
  }

  public setFilterValues(owner: string, values: string[]) {
    this.filterData.get(owner)?.values?.clear();
    this.addFilterValues(owner, ...values);
  }

  public addFilterValues(owner: string, ...values: string[]) {
    const filterData = this.filterData.get(owner);
    if (filterData === undefined || filterData.predicate === undefined) {
      this.filterData.set(owner, this.initFilterData(values, () => () => true, value => value));
    } else {
      values.forEach(value => filterData.values.add(value));
    }
  }

  public removeFilterValues(owner: string, ...values: string[]) {
    const filterData = this.filterData.get(owner);
    values.forEach(value => filterData?.values.delete(value));
  }

  public clearFilterValues(owner?: string) {
    if (owner !== undefined) {
      this.filterData.get(owner)?.values?.clear();
    } else {
      this.filterData.forEach(filterData => filterData.values.clear());
    }
  }
}

export const DataFilterStoreContext = createContext<DataFilterStore<any> | undefined>(undefined);