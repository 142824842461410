import moment from "moment";
import { uid } from "react-uid";

export type WeekdayHeaderProps = {
    /** Name, wird für die Generierung der Test-ID verwendet. */
    name: string;
    /** Wochennummern anzeigen */
    showWeekNumbers: boolean
};

/** Stellt die Überschriften mit den Wochentagen im Kalender-Header dar.*/
export const WeekdayHeader = ({name, showWeekNumbers}: WeekdayHeaderProps) => {
    const days: number[] = [0, 1, 2, 3, 4, 5, 6];

    return (
        <tr data-testid={name}>
            {showWeekNumbers && <th>{/* Kalenderwochenspalte */}</th>}
            {days.map(day => (
                <th key={uid(day)} className="dow">
                    {moment()
                        .weekday(day)
                        .format("dd")}
                </th>
            ))}
        </tr>
    );
};
