/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {CurrencyType, DateFormatType, TimeFormatType} from "../../../types/DataTypes";
import { logger } from "../../../utils/logger";

export type DHLTableCellCommonProps = {
  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Kind-Komponenten. */
  children?: never;
}

export type TableCellType = "text" | "boolean" | "number" | "currency" | "dateTime" | "date" | "time" | "imageLink" | "buttonLink" | "link" |
    "resourceKey" | "custom";

export type DHLTableCellProps = DHLTableCellCommonProps & {
  /** Spaltentitel. */
  title: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type?: TableCellType;

  /** CSS-Klassen. */
  className?: string;

  /** Mindestanzahl Nachkommastellen. */
  minFractionDigits?: number;

  /** Maximalanzahl Nachkommastellen. */
  maxFractionDigits?: number;

  /** Tausendertrennzeichen verwenden? */
  groupingEnabled?: boolean;

  /** Währungskennzeichen. */
  currencySymbol?: CurrencyType;

  /** Formatierung für Datumsanteil. */
  dateFormat?: DateFormatType;

  /** Formatierung für Uhrzeitanteil. */
  timeFormat?: TimeFormatType;

  /** Funktion wird bei Klick auf ein Icon aufgerufen. */
  onClick?: React.MouseEventHandler<HTMLElement>;

  /** Funktion für onChange-Aufrufe, führt zur Anzeige von Eingabefeldern. */
  onChange?: (event: React.ChangeEvent, cellId: string, idProperyName: string, colPropertyName: string) => void;

  /** Max. Anzahl erlaubter Zeichen, wenn ein Eingabefeld angezeigt werden soll. */
  maxLength?: number;

  /** Dateiname des Icons. */
  filename?: string;

  /** Tooltip für Links. */
  tooltip?: string;

  /** Tooltip für deaktivierte Links, Buttons. */
  disabledTooltip?: string;

  /** Breite des Icons. */
  width?: number;

  /** Höhe des Icons. */
  height?: number;

  /** Funktion zur Auflösung von Resource-Keys. */
  resolve?: (msg: string) => string;

  /** Funktion zum Rendern des Zelleninhalts. */
  customRenderer?: (value: any, row: any, cellId: string) => any;
};

/** Tabellenspaltendefinition - Allgemein. */
export const DHLTableCell = (props: DHLTableCellProps) => {
  logger.log("DHLTableCell", props);

  return null;
};

DHLTableCell.defaultProps = {
  type: "text",

  minFractionDigits: 0,

  maxFractionDigits: 0,

  groupingEnabled: true,

  currencySymbol: "EUR",

  dateFormat: "DD.MM.YYYY",

  timeFormat: "HH:mm:ss",

  width: 10,

  height: 10,

  sortable: false
};
