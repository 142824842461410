/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { DHLButton, DHLContextmenu, } from "../../..";
import { normalizeName } from "../../../utils/stringUtils";
import { DHLTableColIconButtonProps } from "../DHLTableColIconButton/DHLTableColIconButton";
import React, { useEffect, useRef } from "react";
import "./DHLTableColIconButtonContextMenu.scss";

export type DHLTableColIconButtonContextMenuProps = DHLTableColIconButtonProps & {

  /** Context menu to open on an icon click. */
  contextMenuContent: React.ReactNode;

  /** Context menu should be open */
  contextMenuOpen: boolean;

  /** Invoked when a click event occurs outside the component */
  onClickOutside: (value: any) => void

  /** Placement of opened context menu. */
  placement: "left" | "right";

};

export const DHLTableColIconButtonContextMenu = observer(({
                                                            name,
                                                            iconName,
                                                            dynamicIconNameFunction,
                                                            value,
                                                            tooltip,
                                                            dynamicTooltipFunction,
                                                            disabledTooltip,
                                                            disabled,
                                                            contextMenuContent,
                                                            contextMenuOpen,
                                                            onClick,
                                                            onClickOutside,
                                                            placement
                                                          }: DHLTableColIconButtonContextMenuProps) => {
  const clickOutsideRefButton = useRef<HTMLDivElement | null>(null);
  const clickOutsideRefContextMenu = useRef<HTMLDivElement | null>(null);
  const onIconButtonClick = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    onClick(evt, value);
  };
  const normalizedName = normalizeName(name);
  const calcIconName = dynamicIconNameFunction ? dynamicIconNameFunction(value) : iconName;
  const calcTooltip = dynamicTooltipFunction ? dynamicTooltipFunction(value) : tooltip;

  useEffect(() => {
    const onClickOutsideCallback = (e: MouseEvent) => {
      if (clickOutsideRefButton.current
        && !clickOutsideRefButton.current.contains(e.target as Node)
        && clickOutsideRefContextMenu.current
        && !clickOutsideRefContextMenu.current.contains(e.target as Node)
      ) {
        onClickOutside(value);
      }
    };
    document.addEventListener("mousedown", onClickOutsideCallback);
    return () => document.removeEventListener("mousedown", onClickOutsideCallback);
  });

  return (
    <>
      <div id={name} ref={clickOutsideRefButton}>
        <DHLButton
          name={normalizedName}
          iconPosition={"icon"}
          icon={calcIconName}
          onClick={onIconButtonClick}
          tooltip={contextMenuOpen ? undefined : calcTooltip}
          disabledTooltip={disabledTooltip}
          disabled={disabled} />
      </div>
      <div className={"contextMenuContainer"} ref={clickOutsideRefContextMenu}>
        <div className={`contextMenu contextMenu-${placement}`}>
          <DHLContextmenu visible={contextMenuOpen}>
            {contextMenuContent}
          </DHLContextmenu>
        </div>
      </div>
    </>
  );

});
