/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useUID } from "react-uid";
import { DHLButton, DHLLabel, DHLTextInput, FormField } from "../../../index";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import { DHLFieldNote } from "../../atoms/DHLFieldNote/DHLFieldNote";
import "./DHLHalfShuttle.scss";
import { ShuttleInput } from "./ShuttleInput";

export type DHLHalfShuttleProps = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen einzigen Change-Handler verwenden zu
   * können. Wir dauch für die Generierung der Test-ID ververwendet. */
  name: string;

  /** Labeltext für das gesamte Shuttle. */
  label?: string;

  /** FormField für ausgewählte Einträge */
  formField: FormField<string[]>

  /** FormField für Eingabefeld Einträge */
  inputFormField: FormField<string>;

  /** Label des Titels des Eingabefelds für neue Einträge. */
  labelInputTitle?: string;

  /** Labeltext für die erzeugte Auswahlliste.  */
  labelCreatedList?: string;

  /** CSS-Klassen für das gesamte Element */
  className?: string;

  /** Label mit Pflichtfeldmarkierung versehen. */
  required?: boolean;

  /** Bearbeitungsmodus des Formulars (true = Neuanalage, false = Bearbeitung). Der Pflichtfeldstatus des Eingabefeldes kann vom Bearbeitungsmodus
   * abhängen.  */
  createMode?: boolean;

  /** Deaktivieren? */
  disabled?: boolean;

  /** Add one button deaktivieren? */
  addOneDisabled?: boolean;

  /** Remove one button deaktivieren? */
  removeSelectedDisabled?: boolean;

  /** Tooltip für deaktivierten add one button */
  addOneDisabledTooltip?: string;

  /** Tooltip für deaktivierten add one button falls die Eingabe bereits existiert */
  inputAlreadyExistsAddOneDisabledTooltip?: string;

  /** Tooltip für remove one button */
  removeSelectedTooltip?: string;

  /** Tooltip für deaktivierten remove one button */
  removeSelectedDisabledTooltip?: string;

  /** Listener für das (De)-Selektieren einer gewählten Option */
  onSelectedValuesFromListChanged?: (items: string[]) => void;

  /** Validiert, ob die aktuelle Eingabe zur Liste hinzugefügt werden darf. */
  validateAllowAddingInputToList?: (input: string) => boolean;

  /** Element ausgeben? */
  render?: boolean;

  /** optional: fixed display height in count of entries.
   * For non-broken design minimum 6 required (minor blemishes), 7 recommended
   */
  fixedHeight?: number;

  /** Funktion für onChange-Aufrufe. Die nach der Änderung selektierten Elemente werden als Set übergeben.*/
  onChange?: (selection: Set<string>) => void;

  /** Validierungsregel mit Konfiurationsdaten für das Eingabefeld. */
  validationRule?: ValidationRuleType;

  /** Fehlertext(e), wenn ein oder mehrere Fehler aufgetreten sind. */
  error?: string | string[] | null;

  /** Nur das Eingabefeld markieren oder auch Fehlertexte anzeigen? */
  errorMarkerOnly?: boolean;
};

/** Shuttle für die Auswahl mehrerer Werte. */
export const DHLHalfShuttle = observer(({
                                          name,
                                          label,
                                          formField,
                                          inputFormField,
                                          labelInputTitle,
                                          labelCreatedList,
                                          className,
                                          required,
                                          createMode,
                                          disabled,
                                          addOneDisabled,
                                          removeSelectedDisabled,
                                          addOneDisabledTooltip,
                                          inputAlreadyExistsAddOneDisabledTooltip,
                                          removeSelectedTooltip,
                                          removeSelectedDisabledTooltip,
                                          onSelectedValuesFromListChanged,
                                          validateAllowAddingInputToList,
                                          render = true,
                                          error,
                                          errorMarkerOnly,
                                          validationRule,
                                          fixedHeight,
                                          onChange
                                        }: DHLHalfShuttleProps) => {

  const initialSelected: Set<string> = new Set();
  const [selectedValuesFromList, setSelectedValuesFromList] = useState(initialSelected);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    inputFormField.updateValue(inputValue);
  };

  const onChangeSelected = (newValues: string[]): void => {
    if (onSelectedValuesFromListChanged) {
      const items = formField.value.filter(data => newValues.includes(data));
      onSelectedValuesFromListChanged(items);
    }
    setSelectedValuesFromList(new Set(newValues));
  };

  const resetSelections = (): void => {
    setSelectedValuesFromList(new Set());
    inputFormField.reset();
  };

  const addInput = (): void => {
    const inputValue = inputFormField.value;
    const allowAdd: boolean = validateAllowAddingInputToList ? validateAllowAddingInputToList(inputValue) : true;
    if (allowAdd) {
      const currentlySelectedItems: string[] = formField.value;
      currentlySelectedItems.push(inputValue);
      formField.updateValue(currentlySelectedItems);
      resetSelections();
      if (onChange) {
        onChange(new Set(currentlySelectedItems));
      }
    }
  };

  const removeSelected = (): void => {
    const currentCreatedList = formField.value.filter(entry => !selectedValuesFromList.has(entry));
    formField.updateValue(currentCreatedList);
    resetSelections();
    if (onChange) {
      onChange(new Set(currentCreatedList));
    }
  };

  let calcRequired = required;
  let calcErrorMarkerOnly = errorMarkerOnly;
  let calcDisabled = disabled;

  if (calcRequired === undefined && validationRule !== undefined) {
    if (!createMode && validationRule.createModeOnly) {
      calcRequired = false;
    } else {
      calcRequired = validationRule.required;
    }
  }

  if (calcErrorMarkerOnly === undefined && validationRule !== undefined) {
    calcErrorMarkerOnly = validationRule.errorMarkerOnly;
  }

  if (disabled === undefined && createMode !== undefined && validationRule !== undefined && validationRule.createModeOnly) {
    calcDisabled = true;
  }
  const calcAddOneDisabled = calcDisabled || addOneDisabled || !inputFormField.value;
  const calcRemoveSelectedDisabled = calcDisabled || removeSelectedDisabled || selectedValuesFromList.size === 0;
  const calcAddOneDisabledTooltip = calcAddOneDisabled ? addOneDisabledTooltip : inputAlreadyExistsAddOneDisabledTooltip;
  const inputAlreadyExistsDisabled = formField.value.find(item => item === inputFormField.value) !== undefined;

  if (!render) {
    return null;
  }
  const cssPrefix = "dhlHalfShuttle";
  const inputUid = useUID();
  const halfShuttleInputsUid = useUID();
  const output = (
    <div data-testid={name} className={classNames(cssPrefix, className)}>
      {label &&
          <div className={"dhlHalfShuttle-inputs-label-container"}>
            <DHLLabel name={name + "dhlHalfShuttle-inputs-label"} label={label} htmlFor={halfShuttleInputsUid} />
            {calcRequired && <span className="dhl-required"> *</span>}
          </div>
      }
      <div id={halfShuttleInputsUid} className={cssPrefix + "-inputs"}>
        <div className={"dhlHalfShuttle-input-container"}>
          <DHLLabel name={name + "dhlHalfShuttle-input-label"} label={labelInputTitle} htmlFor={inputUid} />
          <div id={inputUid} className={"dhlHalfShuttle-input-inner-container"}>
            <DHLTextInput formField={inputFormField} disabled={disabled} onChange={onChangeInput} />
          </div>
        </div>
        <div className={cssPrefix + "-inputs-control"}>
          <div className="control-top">
            <DHLButton
              name={name + "addSelected"}
              disabled={calcAddOneDisabled || inputAlreadyExistsDisabled}
              disabledTooltip={calcAddOneDisabledTooltip}
              iconPosition={"icon"}
              icon={"link-arrow-next"}
              onClick={addInput}
            />
          </div>
          <div className="control-bottom">
            <DHLButton
              name={name + "removeSelected"}
              disabled={calcRemoveSelectedDisabled}
              disabledTooltip={removeSelectedDisabledTooltip}
              tooltip={removeSelectedTooltip}
              iconPosition={"icon"}
              icon={"delete"}
              onClick={removeSelected}
            />
          </div>
        </div>
        <ShuttleInput
          label={labelCreatedList}
          className={cssPrefix + "-inputs-createdList"}
          name={name + "-createdList"}
          value={[...selectedValuesFromList]}
          onChange={onChangeSelected}
          disabled={calcDisabled}
          data={formField.value.map(item => ({key: item, value: item}))}
          fixedHeight={fixedHeight}
        />
      </div>
      {!calcErrorMarkerOnly && error && <DHLFieldNote classname={"default"} name={name + "-error"} type={"error"} notes={error} />}
    </div>
  );

  return <>{output}</>;
});