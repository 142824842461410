/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, flow, makeObservable, observable, reaction } from "mobx";
import { LanguageType } from "../types/DataTypes";
import { ResourceDataClient } from "../clients/ResourceDataClient";
import { AuthenticationManagerImpl2 } from "@gkuis/gkp-authentication";

export class LocalizationManager {

  private readonly localizationMaps = {
    "de": observable.map<string, string>(),
    "en": observable.map<string, string>()
  };

  private readonly loadingModules = {
    "de": new Set<string>(),
    "en": new Set<string>()
  };
  private readonly loadedModules = {
    "de": new Set<string>(),
    "en": new Set<string>()
  };

  constructor(private readonly resourceDataClient: ResourceDataClient) {
    makeObservable<LocalizationManager, "localizationMaps">(this, {
      localizationMaps: observable,
      localizations: computed,
      loadManyLocalizations: action,
      loadLocalizations: action
    });
    // TODO Temporary solution. Define an AuthManager property instead.
    const authManager = resourceDataClient.fetchAdapter.authenticationManager ?? new AuthenticationManagerImpl2("url", "realm", "clientId");
    reaction(
        () => authManager.language,
        () => {
          const modulesToLoad = new Set([
            ...this.loadedModules["de"],
            ...this.loadedModules["en"],
            ...this.loadingModules["de"],
            ...this.loadingModules["en"]
          ]);
          this.loadManyLocalizations(modulesToLoad);
        },
        {fireImmediately: true});
  }

  get localizations(): Map<string, string> {
    return this.localizationMaps[this.getLanguage()];
  }

  loadManyLocalizations = flow(function* loadLocalizations(this: LocalizationManager, modules: Set<string>) {
    yield Promise.all([...modules].map(m => this.loadLocalizations(m)));
  });

  loadLocalizations = flow(function* loadLocalizations(this: LocalizationManager, module: string) {
    // store language in variable to prevent race conditions (language updated while this method is running)
    const language = this.getLanguage();

    if (this.loadingModules[language].has(module) || this.loadedModules[language].has(module)) {
      return;
    }

    const localizations: { key: string, value: string }[] = yield this.resourceDataClient.fetchLocalizations(language, module);
    this.localizationMaps[language].merge(localizations.map<[string, string]>(entry => [entry.key, entry.value]));

    this.loadedModules[language].add(module);
    this.loadingModules[language].delete(module);
  });

  private getLanguage(): LanguageType {
    // TODO Temporary solution. Define and use own authManager property instead
    const authManager = this.resourceDataClient.fetchAdapter.authenticationManager ?? new AuthenticationManagerImpl2("url", "realm", "clientId");
    return authManager.language;
  }

}


