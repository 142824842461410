/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { Fragment, ReactElement, useEffect, useMemo, useState } from "react";
import { uid } from "react-uid";
import { DHLTableCol, extractObjectData, logger } from "../../..";
import "./DHLTableRow.scss";
import { observer } from "mobx-react-lite";
import { detailViewType } from "../../atoms/DHLTableCell/DHLTableCellDetailView";
import { Collapse } from "reactstrap";

export type DHLTableRowProps<T> = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Tabellen Datenspeicher. */
  data: T;

  /** Tabellenspalten. */
  colItems: ReactElement[] | ReactElement;

  /** Called when a table row is selected, the data of the current row is passed to the function. */
  selected?: (event: React.MouseEvent, data: any) => void;

  /** Formatter für numerische Werte und Datumswerte. */
  formatter: Map<string, any>;

  /** ID der Datenzeile */
  rowId: any;

  /** Name des Properties, das die ID enthält. */
  idPropertiesName: string;

  /** Defines, whether the row (given as a parameter) is highlighted */
  highlightRow?: (data: Object) => boolean;

  /** Defines, whether the row (given as a parameter) is disabled */
  disabledRow?: (data: Object) => boolean;

  /** Defines, whether the row (given as a parameter) has a detail view */
  hasDetailViewFunc?: (data: T) => boolean;
};

/** Ausgabe einer Daten-Tabellenzeile. */
export const DHLTableRow = observer(<T extends unknown>(
    {
      name,
      colItems,
      data,
      selected,
      formatter,
      rowId,
      idPropertiesName,
      highlightRow,
      disabledRow,
      hasDetailViewFunc,
    }: DHLTableRowProps<T>
) => {
  logger.log("DHLTableRow", rowId);
  // highlightRow!(data)
  const rowDisabled = disabledRow !== undefined && disabledRow(data as any); // to do: introduce type t param to function
  const rowHighlighted = highlightRow !== undefined && highlightRow(data as any);
  const rowSelected = selected !== undefined && !rowDisabled;

  const rowClassForHighlighting = rowHighlighted ? "dhlTable-row-highlight" : "";
  const rowClassForSelecting = rowSelected ? "dhlTable-row-selectable" : "";
  const rowClassForDisabling = rowDisabled ? "dhlTable-row-disabled" : "";
  const rowClass = !rowHighlighted && !rowDisabled ? "dhlTable-row dhlTable-row-default" : "dhlTable-row";

  const detailViewCol = useMemo(() => Array.isArray(colItems) && colItems.find(s => s.props.type === detailViewType), [colItems]);
  const [detailViewOpen, setDetailViewOpen] = useState(false);
  const hasDetailView = !!(detailViewCol && hasDetailViewFunc && hasDetailViewFunc(data));
  useEffect(() => {
    setDetailViewOpen(false);
  }, [detailViewCol, data]);


  if (!Array.isArray(colItems)) {
    const {isDisabled, propertiesName} = colItems.props;
    const disabledPropertiesName = extractDisabledPropertiesName(colItems);
    return (
        <tr
            key={uid(rowId)}
            data-testid={name}
            className={classNames(rowClass, rowClassForHighlighting, rowClassForSelecting, rowClassForDisabling)}>
          <DHLTableCol
              key={uid(1)}
              value={extractObjectData(data, propertiesName)}
              selected={(event: React.MouseEvent) => {
                if (!rowDisabled && selected) {
                  selected(event, data);
                }
              }}
              formatter={formatter.get(propertiesName)}
              cellDefinition={colItems}
              cellId={rowId + "-1"}
              idPropertiesName={idPropertiesName}
              row={data}
              disabled={(isDisabled && isDisabled(data)) || extractObjectData(data, disabledPropertiesName)}
              detailViewControl={{hasDetailView, isDetailViewOpen: detailViewOpen, setDetailViewOpen}}
          />
        </tr>
    );
  }

  const colCount = colItems.length - (hasDetailView ? 1 : 0);

  return (
      <Fragment key={uid(rowId)}>
        <tr
            data-testid={name}
            className={classNames(rowClass, rowClassForDisabling, rowClassForHighlighting, rowClassForSelecting, {
              hasDetailView,
              "hasDetailView-open": detailViewOpen
            })}
        >
          {colItems.filter(s => s.props.type !== detailViewType).map((colItem, col) => {
            const {isDisabled, propertiesName} = colItem.props;
            const disabledPropertiesName = extractDisabledPropertiesName(colItem);
            const id: string = rowId + "-" + col;
            const output: ReactElement = (
                <DHLTableCol
                    key={uid(id)}
                    value={extractObjectData(data, propertiesName)}
                    selected={(event: React.MouseEvent) => {
                      if (!rowDisabled && selected) {
                        selected(event, data);
                      }
                    }}
                    formatter={formatter.get(propertiesName)}
                    cellDefinition={colItem}
                    cellId={id}
                    row={data}
                    idPropertiesName={idPropertiesName}
                    disabled={(isDisabled && isDisabled(data)) || extractObjectData(data, disabledPropertiesName)}
                    detailViewControl={{hasDetailView, isDetailViewOpen: detailViewOpen, setDetailViewOpen}}
                />
            );

            return output;
          })}
        </tr>
        {hasDetailView && <tr className={classNames("dhlTable-row-detailView", {"dhlTable-row-detailView-open": detailViewOpen})}>
          <td colSpan={colCount}>
            <Collapse isOpen={detailViewOpen} timeout={250 /* dhltransition-default */}>
              <div className={"detailView-collapse-inner"}>
                {detailViewCol.props.customRenderer(extractObjectData(data, detailViewCol.props.propertiesName), data, detailViewOpen,
                    setDetailViewOpen)}
              </div>
            </Collapse>
          </td>
        </tr>}
      </Fragment>
  );
});

function extractDisabledPropertiesName(colItems: React.ReactElement<any,
    string |
    ((props: any) => React.ReactElement<any, string | any | (new (props: any) => React.Component<any, any, any>)> | null) |
    (new (props: any) => React.Component<any, any, any>)>) {
  return colItems.props.disabledPropertiesName
      ? colItems.props.disabledPropertiesName
      : " ";
}
