/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { logger } from "../logger";

/**
 * Kapselt den Get Aufruf von "fetch" für einen Download.
 *
 * @param path die URL die aufgerufen werden soll
 * @param successCallback callback that will be called in case of successful fetch
 * @param errorCallback callback that will be called in case of errors
 * @param finalCallback callback
 */
export const fetchFileGet = async (path: string, successCallback?: (filename: string) => any, errorCallback?: Function, finalCallback?: Function) => {
  logger.log("fetchFileGet - fetching...", path);
  const fetchUrl = path;

  try {
    const response = await fetch(fetchUrl, {
      headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache,no-store,must-revalidate",
        Expires: "0"
      },
      credentials: "include",
      referrerPolicy: "same-origin",
      method: "GET"
    });

    if (200 <= response.status && response.status <= 299) {
      let contentDisposition: string | null = response.headers.get("Content-Disposition");
      if (contentDisposition === undefined) {
        contentDisposition = response.headers.get("content-disposition");
      }
      
      let contentType: string | null = response.headers.get("Content-Type");
      if (contentType === undefined) {
        contentType = response.headers.get("content-type");
      }

      let filename: string = "default.txt";

      logger.log("Downloaded file:", contentDisposition, contentType, response.headers);

      if (contentDisposition !== null) {
        filename = contentDisposition.split("filename=")[1];

        if (filename.startsWith("\"")) {
          filename = filename.substring(1);
        }

        if (filename.endsWith("\"")) {
          filename = filename.substring(0, filename.length - 1);
        }
      }

      let newBlob;

      if (contentType != null) {
        newBlob = new Blob([await response.blob()], {type: contentType});
      } else {
        newBlob = new Blob([await response.blob()]);
      }

      const url = window.URL.createObjectURL(newBlob);

      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();

      setTimeout(() => {
        // Für FF muss gewartet werden
        window.URL.revokeObjectURL(url);
      }, 100);

      if (successCallback !== undefined) {
        successCallback(filename);
      }
    } else if (errorCallback !== undefined) {
      errorCallback({
        body: response.body,
        status: response.status,
        headers: response.headers
      });
    }

    if (finalCallback) {
      finalCallback();
    }
  } catch (error) {
    logger.error("Beim fetchFileGet von " + path + " ist ein Fehler aufgetreten:", error);

    const errorCallbackResult =
        errorCallback !== undefined &&
        errorCallback({
          status: 500,
          body: {
            errorMessages: [
              {
                messageKey: "framework.error.fetchFileGet"
              }
            ]
          }
        });

    if (finalCallback !== undefined) {
      finalCallback();
    }

    return errorCallbackResult;
  }
};
