/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export type FeatureToggles = { [ft: string]: boolean }

let featureToggles: FeatureToggles = {};

export function setFeatureToggles(newFeatureToggles: FeatureToggles) {
  featureToggles = newFeatureToggles;
}

export function useFeatureToggles<RequestedFts extends string[]>(...requestedFts: RequestedFts): { [ft in RequestedFts[number]]: boolean } {
  return Object.fromEntries(
      // featureToggles["featureA"] returns undefined iff featureA is not set
      requestedFts.map(requestedFt => [requestedFt, featureToggles[requestedFt] || false])
  ) as { [key in RequestedFts[number]]: boolean }; //because Object.fromEntries always returns { [k: string]: T }
}
