/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";


/**
 * Workaround für https://github.com/mobxjs/mobx-react-lite/issues/243.
 * Verwendung analog zu observer(...).
 */
export function genericObserver<
    T extends React.FunctionComponent<P>,
    P extends object = T extends React.FunctionComponent<infer P> ? P : unknown
    >(fn: T): T {
  return observer<P>(fn) as T;
}