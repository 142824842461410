/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ReactNode } from "react";
import classNames from "classnames";
import "./DHLHeadline.scss";

export type DHLHeadlineProbs = {
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  /**
   * Design, wenn von semantischem abweicht.
   */
  visualType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  /** nur simulierte Headline, keine semantische/html */
  fakeHeadline?: boolean;
  textAlignment?: "left" | "center" | "right";
  color?: "gray" | "red" | "yellow" | "white";
  children: ReactNode;
  tooltip?: ReactNode;
  className?: string;
}
export const DHLHeadline = ({type, visualType, fakeHeadline = false, children, textAlignment = "left", color = "gray", tooltip, className}
                                : DHLHeadlineProbs) => {
  let HeadlineType: keyof JSX.IntrinsicElements = type;
  if (!visualType) {
    visualType = type; // default value funktionierte nicht gut oben in der Destruktion
  }
  if (fakeHeadline) {
    HeadlineType = "div";
  }
  const headlineContent = !tooltip ? children :
      <div className={"headlineWithInfoIcon-container"}>
        {children}
        {tooltip}
      </div>;


  return <HeadlineType className={classNames("dhlHeadline", visualType, textAlignment, color, className)}>{headlineContent}</HeadlineType>;
};