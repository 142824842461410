/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { DateRangePickerDataStore } from "../../..";
import "./DHLDateRangePicker.scss";
import { FooterPanel } from "./FooterPanel";
import { PickerPanel } from "./PickerPanel";
import moment from "moment";

export type DHLDateRangePickerProps = {
  name: string
  dataStore: DateRangePickerDataStore,
  onSubmit: React.MouseEventHandler<any>,
  onCancel: React.MouseEventHandler<any>,
  /** Used by DHLDateRangeInput only, Setting this to true for a standalone DHLDateRangePicker is NOT recommended */
  keyboardOnlyMode?: boolean
}

const defaultYearsFromNow: number = 10;

function initEarliestAndLatestAllowedDatesIfNotGiven(dataStore: DateRangePickerDataStore) {
  if (dataStore.earliestAllowedDate == null) {
    dataStore.setEarliestAllowedDate(moment().subtract(defaultYearsFromNow, "years"));
  }
  if (dataStore.latestAllowedDate == null) {
    dataStore.setLatestAllowedDate(moment().add(defaultYearsFromNow, "years"));
  }
}

export const DHLDateRangePicker = observer(({
                                              name,
                                              dataStore,
                                              onSubmit,
                                              onCancel,
                                              keyboardOnlyMode
                                            }: DHLDateRangePickerProps) => {
  initEarliestAndLatestAllowedDatesIfNotGiven(dataStore);

  const leftPickerPanel = (
      <PickerPanel
          name={name + "-leftPicker"}
          dataStore={dataStore}
          side={"left"} />
  );
  const rightPickerPanel = (
      <PickerPanel
          name={name + "-rightPicker"}
          dataStore={dataStore}
          side={"right"} />
  );
  const footerPanel = (
      <FooterPanel
          name={name + "-footer"}
          onSubmit={onSubmit}
          onCancel={onCancel}
          dataStore={dataStore}
          keyboardOnlyMode={keyboardOnlyMode}
      />
  );

  let openToLeftClass = null;
  if (dataStore.openRangePickerToLeft) {
    openToLeftClass = keyboardOnlyMode ? "open-to-left-keyboardOnly" : "open-to-left";
  }

  return (
      <div data-testid={"date-range-picker-" + name}
           className={classNames(
               "dhlDateRangePicker",
               "dhlDateRangePicker-expanded",
               dataStore.showWeekNumbers ? "show-kw" : null,
               openToLeftClass,
               keyboardOnlyMode ? "dhlDateRangePicker-expanded-keyboardOnly" : null
           )}>
        {!keyboardOnlyMode && leftPickerPanel}
        {!keyboardOnlyMode && rightPickerPanel}
        {footerPanel}
      </div>
  );
});