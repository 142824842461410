/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { FetchAdapter } from "../utils/fetch/FetchAdapter";
import { logger } from "../utils/logger"
import { ValidationRuleType } from "../types/ValidationRuleTypes";

const LOG_MODULE = "ValidationRuleDataClient";

export class ValidationRuleDataClient{
  constructor(
    private readonly fetchAdapter: FetchAdapter, 
    private readonly baseUrl: string){ }

  async load(module: string): Promise<ValidationRuleType[]>{
    logger.log(LOG_MODULE, "loading validation rules for "+ module);

    const response = await this.fetchAdapter.get(`${this.baseUrl}/validations/rules?module=${module}`);

    if (response.ok || response.status === 304){ // 304 = not modified
      const responseBody = (await response.json()) as ValidationRuleType[];
      return responseBody?? [];
    }else{
      logger.warn(LOG_MODULE, "loading validation rules failed");
      return Promise.reject();
    }
  }

}