import classNames from "classnames";
import { ReactElement, ReactNode } from "react";

export type DHLTabProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Text für Navigations-Item - wird über props ausgelesen und in der Navigation ausgegeben. */
  label?: string | React.ReactNode;

  /** icon for navigationTab - will be rendered in the tabs but not the container */
  icon?: ReactElement,

  /** Eindeutige ID des Tabs innerhalb des Containers, wird über props in der Navigation ausgelesen. */
  tabId: string;

  /** Aktives Tab? */
  active?: boolean;

  /** Deaktiviert? Wird über props in der Navigation ausgelesen */
  disabled?: boolean;

  /** Tooltiptext. */
  tooltip?: string | React.ReactNode;

  /** Kind-Komponenten. */
  children: ReactNode;
};

/** Tab. */
export const DHLTab = ({name, label, tabId, active, disabled, tooltip, children}: DHLTabProps) => {
  if (!active) {
    return null;
  }

  return (
      <div data-testid={name} className={classNames("tab-pane container", {active: active})}>
        {children}
      </div>
  );
};
