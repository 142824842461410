/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, makeObservable, observable } from "mobx";
import { ResourceDataStore } from "./ResourceDataStore";
import { KeyValueType } from "../types/DataTypes";
import autoBind from "auto-bind";
import { TFunction } from "i18next";
import { isTFunc } from "../utils/i18next";

/** Saves the settings of a pager. */
export class PaginationDataStore {

  /** Number of the current page. */
  currentPage: number = 1;
  /** Number of lines per page. */
  currentPageSize: number = 10;
  /** Total number of entries. */
  noOfEntries: number = 0;
  /** max. Number of direct page links displayed. */
  maxPageLinks: number = 5;
  /** Custom page sizes to override default values of pageSizes */
  customPageSizes?: KeyValueType[] = undefined;

  get allText(): string {
    return isTFunc(this.resourceDataStore)
        ? this.resourceDataStore("all")
        : this.resourceDataStore.getMsg("tables.rowCount.all");
  }

  /** !!!Works for all positive odd integers!!!  */
  private readonly maxPageButtons: number = 7;

  constructor(private readonly resourceDataStore: ResourceDataStore | TFunction) {
    makeObservable(this, {
      currentPage: observable,
      currentPageSize: observable,
      noOfEntries: observable,
      maxPageLinks: observable,
      customPageSizes: observable,
      allText: computed,
      setNoOfEntries: action,
      pageSizes: computed,
      setCurrentPageSize: action,
      showAll: computed,
      setCurrentPage: action,
      adjustCurrentPage: action,
      isCurrentPageFirstPage: computed,
      isCurrentPageLastPage: computed,
      noOfPages: computed,
      numberOfStartEndPages: computed,
      maxPageButtonsShown: computed,
      cutOffPoint: computed,
      centerOffset: computed,
      hasLessOrEqualMaxShownPages: computed,
      isCurrentPageAtStart: computed,
      isCurrentPageAtEnd: computed
    });

    autoBind(this);
  }

  setNoOfEntries(noOfEntries: number) {
    this.noOfEntries = noOfEntries;
  }

  /** Available page sizes. */
  get pageSizes(): KeyValueType[] {
    const defaultValues = [
      {key: "5", value: "5"},
      {key: "10", value: "10"},
      {key: "25", value: "25"},
      {key: "-1", value: this.allText}
    ];
    return this.customPageSizes ?? defaultValues;
  }

  /**
   * Set the number of lines per page.
   * @param pageSize lines per page
   */
  setCurrentPageSize(pageSize: number) {
    this.currentPageSize = pageSize;
    this.currentPage = this.noOfPages < this.currentPage ? this.noOfPages : this.currentPage;
  }

  get showAll(): boolean {
    return this.currentPageSize === -1;
  }

  /**
   * Set the number of the current page.
   * @param page page number
   */
  setCurrentPage(page: number) {
    if (page < 1) {
      this.currentPage = 1;
    } else if (page > this.noOfPages) {
      this.currentPage = this.noOfPages;
    } else {
      this.currentPage = page;
    }
  }

  /**
   * Adjusts the current page number if it is outside of the displayed range.
   */
  adjustCurrentPage() {
    this.currentPage = this.currentPage > this.noOfPages ? 1 : this.currentPage;
  }

  /**
   * Checks if the current page is the first page
   */
  get isCurrentPageFirstPage(): boolean {
    return this.currentPage === 1;
  }

  /**
   * Checks if the current page is the last page
   */
  get isCurrentPageLastPage(): boolean {
    return this.currentPage === this.noOfPages;
  }

  /**
   * Calculates the number of pages from the number of entries and the current page size
   */
  get noOfPages(): number {
    if (!this.noOfEntries) {
      return 1;
    }

    if (this.currentPageSize === -1) {
      return 1;
    }

    return (this.noOfEntries % this.currentPageSize === 0
        ? this.noOfEntries / this.currentPageSize
        : Math.floor(this.noOfEntries / this.currentPageSize) + 1);
  }

  /**
   * Calculates the number of pages considered to be at the start/end of the the pagination.
   */
  get numberOfStartEndPages(): number {
    return (this.maxPageButtonsShown % 2 === 0
        ? this.maxPageButtonsShown / 2
        : Math.floor(this.maxPageButtonsShown / 2) + 1);
  }

  /**
   * Gets the maximum number of page buttons to be rendered in the pagination.
   */
  get maxPageButtonsShown(): number {
    return this.maxPageButtons % 2 === 0 ? 7 : this.maxPageButtons;
  }

  /**
   * Calculates the maximum number of consecutive page buttons possible at the start/end of the the pagination.
   */
  get cutOffPoint(): number {
    return this.maxPageButtonsShown - 2;
  }

  /**
   * The center offset determines how many page number buttons left and right from the current page shall be shown at a minimum.
   * This value is used to ensure, that the previous and next page buttons around the current page are shown even if there are placeholder
   * buttons.
   */
  get centerOffset(): number {
    return (Math.floor((this.maxPageButtonsShown - 4) / 2));
  }

  /**
   * Checks if the pagination currently has less or equal number of pages than the maximum number of shown page buttons.
   */
  get hasLessOrEqualMaxShownPages(): boolean {
    return this.noOfPages <= this.maxPageButtonsShown;
  }

  /**
   * Checks if the current page is in the range of the first few pages based on the number of start/end pages.
   */
  get isCurrentPageAtStart(): boolean {
    return this.currentPage <= (this.numberOfStartEndPages);
  }

  /**
   * Checks if the current page is in the range of the last few pages based on the number of start/end pages.
   */
  get isCurrentPageAtEnd(): boolean {
    return this.currentPage >= (this.noOfPages - (this.numberOfStartEndPages - 1));
  }
}
