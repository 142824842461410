export type DHLTableCellResourceKeyProps = {
  /** Spaltentitel. */
  title: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type: "resourceKey";

  /** CSS-Klassen. */
  className?: string;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Funktion zur Auflösung von Resource-Keys. */
  resolve?: (msg: string) => string;

  /** Kind-Komponenten. */
  children?: never;
};

/** Tabellenspaltendefinition - Ressourcenschlüssel wird in Klartext übersetzt. */
export const DHLTableCellResourceKey = (props: DHLTableCellResourceKeyProps) => {
  return null;
};

DHLTableCellResourceKey.defaultProps = {
  type: "resourceKey",
  sortable: false
};
