/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect } from "react";

export const TrackPageViewHelper = ({pagename}:{ pagename: string }) => {
  useEffect(() => trackPageCall(pagename), [pagename]);
  return null;
}

export function trackPageCall(pageName: string): void {
  window.parent.postMessage({
    event_id: "customPageView",
    data: {
      page: { name: pageName },
      application: { 
        name: "gkp",
        events: ["customPageView"]
      }
    }}, window.location.origin);
}
