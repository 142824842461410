/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import "./DHLDataFilter.scss";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { uid } from "react-uid";
import { DHLChip, DHLDataFilterChipGroup, DHLIcon } from "../../..";
import { useDataFilterStore } from "../../../utils/useDataFilterStore";

export type DHLDataFilterProps<T> = {
  /** Name, is used to generate the Test-ID. */
  name: string;

  onRemoveFilterString?: Function;

  onResetFilterStrings?: Function;

  //legacy: remove "?"
  // filters in first line
  mainFilters: React.ReactNode;

  //legacy: remove "?"
  // filters in second line
  additionalFilters?: React.ReactNode;

  initialStateClosed?: boolean;
};

/** Output of success and error messages. */
export const DHLDataFilter = observer(<T extends unknown>(
    {
      name,
      onRemoveFilterString,
      onResetFilterStrings,
      mainFilters,
      additionalFilters,
      initialStateClosed = false,
    }: DHLDataFilterProps<T>
) => {
  const dataFilterStore = useDataFilterStore<T>();

  const {resourceDataStore} = dataFilterStore;
  const [filterClosed, setFilterClosed] = useState(initialStateClosed);

  const chips = [...dataFilterStore.filterData.entries()]
      .filter(([owner, _]) => owner !== "toggle")
      .flatMap(([owner, {values: filterValues, localize}]) =>
          [...filterValues.values()].map(filterValue => ({owner, filterValue, localize}))
      )
      .map(({owner, filterValue, localize}) =>
          <DHLChip
              key={uid(filterValue)}
              name={name + "-chip-" + filterValue}
              label={localize(filterValue)}
              onClick={(event: React.MouseEvent) => {
                dataFilterStore.removeFilterValues(owner, filterValue);
                onRemoveFilterString?.(event);
              }}
          />
      );
  const filterActive = chips.length > 0;

  const filterChipGroup =
      <DHLDataFilterChipGroup
          name={name + "-chips"}
          resetText={resourceDataStore.getButton("dataFilter.reset")}
          onReset={() => {
            dataFilterStore.clearFilterValues();
            onResetFilterStrings?.();
          }}
      >
        {chips}
      </DHLDataFilterChipGroup>;

  const renderFilter = () => {
    return (
        <div data-testid={name + "-filterSet"} className={classNames("dhlDataFilterContainer")}>
          <div data-testid={name + "filter-input"} className={classNames("dhlDataFilterInput")} hidden={filterClosed}>
            {mainFilters}
          </div>
          <div className={classNames("dhlDataFilterContainer-closedFilterDiv")}>
            <div className={"activeFilters"}>
              {filterClosed && !filterActive &&
                  <div className={classNames("noFilterText")}>
                    {resourceDataStore.getLabel("dataFilter.noFilterActive")}
                  </div>}
              <div className={classNames("additionalFilters")} hidden={filterClosed}>
                {additionalFilters}
              </div>
              <div className={classNames(!filterClosed ? "filterOpen" : "")} hidden={!filterActive}>
                {filterChipGroup}
              </div>
            </div>
            <div data-testid={name + "-toggle"} className={classNames("dhlDataFilterContainer-noFilterSet")}>
              <div onClick={() => setFilterClosed(!filterClosed)}>
                {filterClosed && !filterActive && resourceDataStore.getLabel("dataFilter.showFilter")}
                <DHLIcon
                    name={""}
                    className={classNames("toggleFilterIcon")}
                    icon={filterClosed ? "arrow-down" : "arrow-up"}
                />
              </div>
            </div>
          </div>
        </div>
    );
  };

  return (
      <>
        {renderFilter()}
      </>
  );

});

