/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { useRef, useState } from "react";
import "./DHLDropzone.scss";
import { DHLIcon, IconType } from "../DHLIcon/DHLIcon";
import { logger } from "../../../utils/logger";

export type DHLDropzoneProps = {
  /**
   * Label inside dropzone
   */
  dzLabel?: string | JSX.Element;

  /** Deaktivieren? */
  disabled?: boolean;
  interactionDisabled?: boolean;
  visualState: "normal" | "preloader" | "success" | "failed" // merge disabled into here? what about disabled&preloader?

  /**
   * Allowed file type, filename extension or mime-type. Only serves as a hint for file dialog!
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#attr-accept
   */
  accept?: string;

  /** multiple files hint */
  multiple?: boolean;

  /** Callback on files selected/dropped */
  onFilesAdded: (files: File[]) => void;
};

/** Dropzone für Dateiupload. */
export const DHLDropzone = ({accept, multiple, onFilesAdded, disabled, visualState, interactionDisabled, dzLabel}: DHLDropzoneProps) => {
  const [highlight, setHighlight] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const calcInteractionDisabled = disabled || interactionDisabled;

  let calcIcon: IconType = "upload";
  switch (visualState) {
    case "preloader":
      calcIcon = "preloader-no-bg";
      break;
    case "success":
      calcIcon = "ofi-form-checked";
      break;
    case "failed":
      calcIcon = "storniert";
      break;
  }

  const openFileDialog = (): void => {
    if (calcInteractionDisabled) {
      return;
    }

    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChange = (event: any): void => {
    if (calcInteractionDisabled) {
      return;
    }

    const files = event.target.files;
    logger.log("selected files", event.target.files);

    if (onFilesAdded) {
      const array = fileListToArray(files);

      onFilesAdded(array);
    }
  };

  const onDragOver = (event: any): void => {
    event.preventDefault();

    if (calcInteractionDisabled) {
      return;
    }

    setHighlight(true);
  };

  const onDragLeave = (): void => {
    setHighlight(false);
  };

  const onDrop = (event: any): void => {
    event.preventDefault();

    if (calcInteractionDisabled) {
      return;
    }

    const files = event.dataTransfer.files;
    logger.log("dropped files", event.dataTransfer);

    if (onFilesAdded) {
      const array = fileListToArray(files);

      onFilesAdded(array);
    }

    setHighlight(false);
  };

  const fileListToArray = (list: FileList): File[] => {
    const array: File[] = [];

    for (let i = 0; i < list.length; i++) {
      const file = list.item(i);

      if (file) {
        array.push(file);
      }
    }

    return array;
  };

  return (
      <div
          className={classNames("dhlDropzone", {
            "dhlDropzone-highlight": highlight,
            "disabled": disabled,
            "interactable": !calcInteractionDisabled,
            "success": visualState === "success",
            "error": visualState === "failed",
          })}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
      >
        <input ref={fileInputRef} className="dhlDropzone-fileinput" type="file" accept={accept} multiple={multiple}
               onChange={(event: any) => {
                 onChange(event);
                 // resetting value of input, so onChange still triggers if same file is selected again
                 event.target.value = null;
               }} />
        <DHLIcon icon={calcIcon} className={"dhlDropzone-icon"} name={""} />
        <span className="label">{dzLabel}</span>
      </div>
  );
};
