/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { DataFilterStore, DataFilterStoreContext, DHLButton, KeyValueType } from "../../..";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import { DHLFieldNote } from "../../atoms/DHLFieldNote/DHLFieldNote";
import "./DHLSelectManyShuttle.scss";
import { ShuttleInput } from "./ShuttleInput";

export type DHLSelectManyShuttleProps<T extends KeyValueType> = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen einzigen Change-Handler verwenden zu
   * können. Wir dauch für die Generierung der Test-ID ververwendet. */
  name: string;

  /** Labeltext für das gesamte Shuttle. */
  label?: string;

  /** Labeltext für Auswahlliste mit verfügbaren Einträgen.  */
  labelAvailable?: string;

  /** Labeltext für Auswahlliste mit ausgewählten Einträgen.  */
  labelSelected?: string;

  /** CSS-Klassen für das gesamte Element */
  className?: string;

  /** Label mit Pflichtfeldmarkierung versehen. */
  required?: boolean;

  /** Bearbeitungsmodus des Formulars (true = Neuanalage, false = Bearbeitung). Der Pflichtfeldstatus des Eingabefeldes kann vom Bearbeitungsmodus
   * abhängen.  */
  createMode?: boolean;

  /** Deaktivieren? */
  disabled?: boolean;

  /** Add one button deaktivieren? */
  addOneDisabled?: boolean;

  /** Remove one button deaktivieren? */
  removeOneDisabled?: boolean;

  /** Add all button deaktivieren? */
  addAllDisabled?: boolean;

  /** Remove all button deaktivieren? */
  removeAllDisabled?: boolean;

  /** Tooltip für deaktivierten add one button */
  addOneDisabledTooltip?: string;

  /** Tooltip für deaktivierten add all button */
  addAllDisabledTooltip?: string;

  /** Tooltip für deaktivierten remove one button */
  removeOneDisabledTooltip?: string;

  /** Tooltip für deaktivierten remove all button */
  removeAllDisabledTooltip?: string;

  /** Listener für das (De-)Selektieren einer verfügbaren Option */
  onAvailableSelectionChanged?: (items: KeyValueType[]) => void

  /** Listener für das (De)-Selektieren einer gewählten Option */
  onSelectedSelectionChanged?: (items: KeyValueType[]) => void

  /** Element ausgeben? */
  render?: boolean;

  /** optional: fixed display height in count of entries.
   * For non-broken design minimum 6 required (minor blemishes), 7 recommended
   */
  fixedHeight?: number;

  /** Funktion für onChange-Aufrufe. Die nach der Änderung selektierten Elemente werden als Set übergeben.*/
  onChange: (selection: Set<KeyValueType>) => void;

  /** Ausgewählte Werte. */
  value?: string[];

  /** Validierungsregel mit Konfigurationsdaten für das Eingabefeld. */
  validationRule?: ValidationRuleType;

  /** Verfügbare Optionen. */
  data: T[];

  /** Fehlertext(e), wenn ein oder mehrere Fehler aufgetreten sind. */
  error?: string | string[] | null;

  /** Nur das Eingabefeld markieren oder auch Fehlertexte anzeigen? */
  errorMarkerOnly?: boolean;
};

/** Shuttle für die Auswahl mehrerer Werte. */
export const DHLSelectManyShuttle = observer(<T extends KeyValueType>({
                                                                        name,
                                                                        label,
                                                                        labelAvailable,
                                                                        labelSelected,
                                                                        className,
                                                                        data,
                                                                        required,
                                                                        createMode,
                                                                        disabled,
                                                                        addOneDisabled,
                                                                        removeOneDisabled,
                                                                        addAllDisabled,
                                                                        removeAllDisabled,
                                                                        addOneDisabledTooltip,
                                                                        addAllDisabledTooltip,
                                                                        removeOneDisabledTooltip,
                                                                        removeAllDisabledTooltip,
                                                                        onAvailableSelectionChanged,
                                                                        onSelectedSelectionChanged,
                                                                        render = true,
                                                                        value,
                                                                        error,
                                                                        errorMarkerOnly,
                                                                        validationRule,
                                                                        fixedHeight,
                                                                        onChange
                                                                      }: DHLSelectManyShuttleProps<T>) => {
  const initialKeyValue: T[] = [];
  const [dataAvailable, setDataAvailable] = useState(initialKeyValue);
  const [dataSelected, setDataSelected] = useState(initialKeyValue);
  const {predicate} = useContext<DataFilterStore<T> | undefined>(DataFilterStoreContext) ?? {predicate: () => true};

  useEffect(() => {
    const selectedKeys: Set<string> = new Set();
    const available: T[] = [];
    const selected: T[] = [];

    if (value) {
      value.forEach((key: string) => {
        selectedKeys.add(key);
      });
    }

    data.forEach((keyValue: T) => {
      if (selectedKeys.has(keyValue.key)) {
        selected.push(keyValue);
      } else {
        available.push(keyValue);
      }
    });

    setDataAvailable(available);
    setDataSelected(selected);
  }, [data, value]);

  const initialKeys: Set<string> = new Set();
  const [selectedAvailableKeys, setSelectedAvailableKeys] = useState(initialKeys);
  const [selectedSelectedKeys, setSelectedSelectedKeys] = useState(initialKeys);


  const onChangeAvailable = (newValues: string[]): void => {
    if (onAvailableSelectionChanged) {
      const items = dataAvailable.filter(data => newValues.includes(data.key));
      onAvailableSelectionChanged(items);
    }
    setSelectedAvailableKeys(new Set(newValues));
  };

  const onChangeSelected = (newValues: string[]): void => {
    if (onSelectedSelectionChanged) {
      const items = dataSelected.filter(data => newValues.includes(data.key));
      onSelectedSelectionChanged(items);
    }
    setSelectedSelectedKeys(new Set(newValues));
  };

  const dataIsSelectedAvailableAndVisible = (key: string): boolean =>
      (selectedAvailableKeys.has(key) &&
          dataAvailable.filter(predicate)
              .some(kv => kv.key == key));

  const dataIsSelectedSelectedAndVisible = (key: string): boolean =>
      (selectedSelectedKeys.has(key) &&
          dataSelected.filter(predicate)
              .some(kv => kv.key == key));

  const resetSelections = (): void => {
    setSelectedAvailableKeys(new Set());
    setSelectedSelectedKeys(new Set());
  };

  const addAll = (): void => {
    const currentSelected: T[] = dataSelected;

    dataAvailable.filter(predicate).forEach((keyValue: T) => {
      currentSelected.push(keyValue);
    });

    setDataSelected(currentSelected);
    setDataAvailable(dataAvailable.filter(data => !predicate(data)));
    resetSelections();

    onChange(new Set(currentSelected));
  };

  const addSelected = (): void => {
    const currentSelected: T[] = dataSelected;

    dataAvailable.forEach((keyValue: T) => {
      if (dataIsSelectedAvailableAndVisible(keyValue.key)) {
        currentSelected.push(keyValue);
      }
    });

    setDataSelected(currentSelected);
    setDataAvailable(dataAvailable.filter(entry => !dataIsSelectedAvailableAndVisible(entry.key)));
    setSelectedAvailableKeys(new Set());

    onChange(new Set(currentSelected));
  };

  const removeSelected = (): void => {
    const currentAvailable: T[] = dataAvailable;

    dataSelected.forEach((keyValue: T) => {
      if (dataIsSelectedSelectedAndVisible(keyValue.key)) {
        currentAvailable.push(keyValue);
      }
    });

    setDataAvailable(currentAvailable);

    const currentSelected = dataSelected.filter(entry => !dataIsSelectedSelectedAndVisible(entry.key));
    setDataSelected(currentSelected);
    setSelectedSelectedKeys(new Set());

    onChange(new Set(currentSelected));
  };

  const removeAll = (): void => {
    const currentSelectedKeys: T[] = dataSelected.filter(predicate);

    dataAvailable.forEach((keyValue: T) => {
      currentSelectedKeys.push(keyValue);
    });

    setDataAvailable(currentSelectedKeys);
    setDataSelected(dataSelected.filter(data => !predicate(data)));
    resetSelections();

    onChange(new Set(dataSelected.filter(data => !predicate(data))));
  };

  let calcRequired = required;
  let calcErrorMarkerOnly = errorMarkerOnly;
  let calcDisabled = disabled;

  if (calcRequired === undefined && validationRule !== undefined) {
    if (!createMode && validationRule.createModeOnly) {
      calcRequired = false;
    } else {
      calcRequired = validationRule.required;
    }
  }

  if (calcErrorMarkerOnly === undefined && validationRule !== undefined) {
    calcErrorMarkerOnly = validationRule.errorMarkerOnly;
  }

  if (disabled === undefined && createMode !== undefined && validationRule !== undefined && validationRule.createModeOnly) {
    calcDisabled = true;
  }
  const calcAddAllDisabled = calcDisabled || addAllDisabled || dataAvailable.filter(predicate).length === 0;
  const calcAddOneDisabled = calcDisabled || addOneDisabled || [...selectedAvailableKeys].filter(dataIsSelectedAvailableAndVisible).length === 0;
  const calcRemoveAllDisabled = calcDisabled || removeAllDisabled || dataSelected.filter(predicate).length === 0;
  const calcRemoveOneDisabled = calcDisabled || removeOneDisabled || [...selectedSelectedKeys].filter(dataIsSelectedSelectedAndVisible).length === 0;

  if (!render) {
    return null;
  }
  const cssPrefix = "dhlSelectManyShuttle";

  const output = (
      <div data-testid={name} className={classNames(cssPrefix, className)}>
        {label}
        {calcRequired && <span className="dhl-required"> *</span>}
        <div className={cssPrefix + "-inputs"}>
          <ShuttleInput
              label={labelAvailable}
              className={cssPrefix + "-inputs-available"}
              name={name + "-available"}
              value={[...selectedAvailableKeys]}
              onChange={onChangeAvailable}
              disabled={calcDisabled}
              data={dataAvailable.filter(predicate)}
              fixedHeight={fixedHeight}
          />
          <div className={cssPrefix + "-inputs-control"}>
            <div className="control-top">
              <DHLButton
                  name={name + "addSelected"}
                  disabled={calcAddOneDisabled}
                  disabledTooltip={addOneDisabledTooltip}
                  iconPosition={"icon"}
                  icon={"link-arrow-next"}
                  onClick={addSelected}
              />
              <DHLButton
                  name={name + "addAll"}
                  disabled={calcAddAllDisabled}
                  disabledTooltip={addAllDisabledTooltip}
                  iconPosition={"icon"}
                  icon={"link-arrow-last"}
                  onClick={addAll}
              />
            </div>
            <div className="control-bottom">
              <DHLButton
                  name={name + "removeAll"}
                  disabled={calcRemoveAllDisabled}
                  disabledTooltip={removeAllDisabledTooltip}
                  iconPosition={"icon"}
                  icon={"link-arrow-first"}
                  onClick={removeAll}
              />
              <DHLButton
                  name={name + "removeSelected"}
                  disabled={calcRemoveOneDisabled}
                  disabledTooltip={removeOneDisabledTooltip}
                  iconPosition={"icon"}
                  icon={"link-arrow-prev"}
                  onClick={removeSelected}
              />
            </div>
          </div>
          <ShuttleInput
              label={labelSelected}
              className={cssPrefix + "-inputs-selected"}
              name={name + "-selected"}
              value={[...selectedSelectedKeys]}
              onChange={onChangeSelected}
              disabled={calcDisabled}
              data={dataSelected.filter(predicate)}
              fixedHeight={fixedHeight}
          />
        </div>
        {!calcErrorMarkerOnly && error && <DHLFieldNote classname={"default"} name={name + "-error"} type={"error"} notes={error} />}
      </div>
  );

  return <>{output}</>;
});