/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { DHLTooltip, FormField, logger } from "../../..";
import { calculateBooleanOnChange, calculateLabel, calculateName, calculateValue } from "../../../utils/calcPropertiesValue";
import "./DHLToggle.scss";
import { useCallback, useState } from "react";
import * as Popper from "popper.js";

export type DHLToggleProps = {
  /** Name of the input field. Should match the name of the property for the input value to use a single change handler.
   * Also used for generating the Test ID. */
  name?: string;

  /** Label text. */
  label?: string;

  /** placment of label to left or right of toggle */
  labelPlacement?: "left" | "right"

  /** CSS-classes for label. */
  labelClassName?: string;

  /** CSS-classes for input field. */
  className?: "gogreen" | string;

  /** disabled? */
  disabled?: boolean;

  /** tooltip when button is disabled */
  disabledTooltip?: string;

  /** tooltip placement */
  tooltipPlacement?: Popper.Placement;

  /** Input value (optional to allow Storybook etc. input). */
  value?: any;

  /* display as indeterminate (3rd display-state, masks real state) */
  indeterminate?: false, //boolean

  /** Function for onChange-calls. */
  onChange?: React.ChangeEventHandler<any>;

  /** size */
  size?: "xs" | "sm" | "lg";

  /** Defines a form field with name, label, value, validation rule and error text. Single parameters have priority. */
  formField?: FormField<any>;

};

/** Checkbox with integrated Label. */
export const DHLToggle = observer((
    {
      name,
      label,
      labelClassName,
      labelPlacement = "right",
      className,
      disabled,
      disabledTooltip,
      tooltipPlacement = "right",
      value,
      indeterminate = false,
      onChange,
      size = "xs",
      formField
    }: DHLToggleProps
) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = useCallback(() => setTooltipOpen(oldValue => !oldValue), [setTooltipOpen]);
  const calcName = calculateName(name, formField);

  if (!calcName) {
    logger.error("Components needs an explicit name or formField parameter");
    return null;
  }

  const calcLabel = calculateLabel(label, formField);
  const calcValue = calculateValue(value, formField);
  const calcOnChange = calculateBooleanOnChange(onChange, formField);
  const calcDisabled = disabled;

  const calcOnClick = calcDisabled ? () => { /* intended use */ } : calcOnChange;

  const labelElement = calcLabel && <label
      id={`${calcName}-label`}
      data-testid={`${calcName}-label`}
      onClick={calcOnClick}
      className={classNames("toggle-label", labelClassName)}
  >{calcLabel}</label>;

  return (
      <div
          id={`dhlToggle-container-${calcName}`}
          className={classNames(
              className,
              "dhlToggle-container",
              `size-${size}`,
              calcDisabled ? "disabled" : null,
              calcValue ? "checked" : null,
              {indeterminate}
          )}
      >
        {labelPlacement === "left" && labelElement}
        <div
            id={calcName}
            aria-labelledby={`${calcName}-label`}
            role={"switch"}
            aria-checked={!!calcValue}
            className={classNames("toggle-switch",calcDisabled ? "disabled" : null)}
            onClick={calcOnClick}
            tabIndex={-1}
        >
          <div className={classNames("toggle-knob", calcDisabled ? "disabled" : null)} />
        </div>
        {labelPlacement === "right" && labelElement}
        {(disabled && disabledTooltip !== undefined || tooltipOpen)
            && <DHLTooltip
                placement={tooltipPlacement}
                tooltipOpen={disabledTooltip !== undefined && tooltipOpen}
                target={`dhlToggle-container-${calcName}`}
                toggle={toggleTooltip}>
              {disabledTooltip}
            </DHLTooltip>
        }
      </div>
  );
});
