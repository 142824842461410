/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AccessTokenProvider } from "@gkuis/gkp-authentication";

const isHeaders = (headers: HeadersInit): headers is Headers => {
  return (headers as Headers).append !== undefined
      && (headers as Headers).delete !== undefined;
};

const is2DStringArray = (headers: HeadersInit): headers is [string, string][] => {
  return (headers as string[][]).concat !== undefined &&
      (headers as string[][]).slice !== undefined &&
      (headers as string[][]).length !== undefined;
};

//TODO naming: setzt auch options.credentials und .referrerPolicy und nicht nur header
export const addHeaders = async (options: RequestInit & { method: string },
                                 accessTokenProvider?: AccessTokenProvider,
                                 doNotSetRequestContentType: boolean = false,
                                 doNotRefreshToken: boolean = false): Promise<RequestInit> => {
  options.credentials = options.credentials || "omit";
  options.referrerPolicy = options.referrerPolicy || "same-origin";

  let additionalHeaders: Record<string, string> = {
    Accept: "application/json"
  };

  const accessToken = accessTokenProvider && (doNotRefreshToken
      ? accessTokenProvider.getAccessTokenWithoutRefresh()
      : await accessTokenProvider.getAccessToken());
  if(accessToken) {
    additionalHeaders = {
      ...additionalHeaders,
      Authorization: `Bearer ${accessToken}`
    }
  }

  const method = options.method.toUpperCase();
  if (method === "DELETE" || method === "POST" || method === "PUT") {
    additionalHeaders = {
      ...additionalHeaders,
      Pragma: "no-cache",
      "Cache-Control": "no-cache,no-store,must-revalidate",
      Expires: "0",
    };
    // when doing a multipart/form-data request, browser sets Content-Type header and it must not be set by us
    if ((method === "POST" || method === "PUT") && !doNotSetRequestContentType) {
      additionalHeaders = {
        ...additionalHeaders,
        "Content-Type": "application/json"
      };
    }
  }

  const headers = options.headers || {};
  if (is2DStringArray(headers)) {
    //TODO tritt dieser Fall jemals auf?
    throw new Error("Will not apply auth headers to string array");
  } else if (isHeaders(headers)) {
    const nextHeaders: Headers = new Headers(additionalHeaders);
    headers.forEach((headerValue, headerName) => nextHeaders.set(headerName, headerValue))
    return {...options, headers: nextHeaders};
  } else { //headers is Record<string, string>
    const nextHeaders: Record<string, string> = {...additionalHeaders, ...headers};
    return {...options, headers: nextHeaders};
  }
};