import { ReactElement } from "react";
import { uid } from "react-uid";
import { DHLLoading, DHLTableRow, extractObjectData, logger } from "../../..";
import "./DHLTableBody.scss";

export type DHLTableBodyProps<T> = {
  /** Name, wird für die Generierung derID verwendet. */
  name: string;

  /** Tabellen Datenspeicher. */
  data: T[];

  /** Ladestatus der anzuzeigenden Daten. */
  loadingState?: string;

  /** Text der während des Ladevorgangs angezeigt wird. */
  loadingText?: string;

  /** Ausgabe bei leeren Daten. */
  dataEmpty?: string | ReactElement;

  /** Name des Properties, das die ID enthält. */
  idPropertiesName: string;

  /** Kind-Komponenten. */
  cellDefs: ReactElement[] | ReactElement;

  /** Initialisierte Formatierer für die diversen Spalten. */
  formatter: Map<string, any>;

  /** Wird bei Selektion einer Tabellenzeile aufgerufen, die Daten der aktuellen Zeile werden an die Funktion übergeben. */
  selected?: (event: React.MouseEvent, data: any) => void;

  /** Function used to identify (a) row(s) to be highlighted based on data the row provides */
  highlightRows?: (data: Object) => boolean;

  /** Function used to identify (a) row(s) to be disabled based on data the row provided */
  disabledRows?: (data: Object) => boolean;

  /** Function used to identify (a) row(s) which have a detail view */
  hasDetailViewFunc?: (data: T) => boolean;
};

/** Tabellen-Body. */
export const DHLTableBody = <T extends unknown>({
                                                  name,
                                                  data,
                                                  loadingState,
                                                  loadingText,
                                                  dataEmpty,
                                                  formatter,
                                                  idPropertiesName,
                                                  cellDefs,
                                                  selected,
                                                  highlightRows,
                                                  disabledRows,
                                                  hasDetailViewFunc
                                                }: DHLTableBodyProps<T>) => {
  logger.log("DHLTableBody");

  const getColumnCount = (): number => {
    return Array.isArray(cellDefs) ? cellDefs.length : 1;
  };

  let rows: any = null;

  if (data && data.length > 0) {
    rows = data.map(rowData => {
      const rowId: any = extractObjectData(rowData, idPropertiesName);

      return (
          <DHLTableRow
              key={uid(rowData)}
              name={name + "-r-" + rowId}
              colItems={cellDefs}
              data={rowData}
              rowId={rowId}
              selected={selected}
              formatter={formatter}
              idPropertiesName={idPropertiesName}
              highlightRow={highlightRows}
              disabledRow={disabledRows}
              hasDetailViewFunc={hasDetailViewFunc}
          />
      );
    });
  } else {
    if (!loadingState || loadingState === "LOADED") {
      rows = (
          <tr data-testid={name + "-empty"} className="table-body-row">
            <td colSpan={getColumnCount()} className="table-body-empty">{dataEmpty}</td>
          </tr>
      );
    } else if (loadingState === "LOADING") {
      rows = (
          <tr className="table-body-row">
            <td colSpan={getColumnCount()} className={"table-body-empty"}>
              <DHLLoading name={name + "-loading"} loading={true} info={loadingText} />
            </td>
          </tr>
      );
    }
  }

  return (
      <tbody data-testid={name} className="">
      {rows}
      </tbody>
  );
};
