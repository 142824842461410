import classNames from "classnames";
import { DHLTabProps } from "../../atoms/DHLTab/DHLTab";
import { ReactElement } from "react";
import { uid } from "react-uid";
import { DHLTabNavigationItem, TabItemType } from "../../atoms/DHLTabNavigationItem/DHLTabNavigationItem";
import "./DHLTabNavigation.scss";

export type DHLTabNavigationProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** ID des aktiven Tab-Items. */
  activeTabId: string;

  /** Elemente des Tab-Reiters. */
  navItems: ReactElement<DHLTabProps>[];

  /** Display type (primary | secondary) */
  displayType?: TabItemType;

  /** onClick-Handler */
  onClickTabItem: (event: React.MouseEvent, tabId: string) => void;

};


/** Tab-Navigation. */
export const DHLTabNavigation = ({name, activeTabId, navItems, displayType, onClickTabItem}: DHLTabNavigationProps) => {

  const navItemList = navItems.map(navItem => {
    const {label, icon, tabId, disabled, tooltip} = navItem.props;
    return (
        <DHLTabNavigationItem
            key={uid(navItem)}
            name={name + "-" + tabId}
            tabId={tabId}
            label={label}
            icon={icon}
            activeTabId={activeTabId}
            disabled={disabled}
            displayType={displayType}
            onClickTabItem={onClickTabItem}
            tooltip={tooltip}
        />
    );
  });

  return (
      <ul data-testid={name} className={classNames("nav", "nav-tabs", "dhlTabNavigation", displayType)}>
        <div className={classNames("dhlTabNavigationItemContainer", displayType)}>
          {navItemList}
        </div>
      </ul>
  );
};
