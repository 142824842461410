/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, makeObservable, observable } from "mobx";
import { DHLMultiBoxAvailableEntry } from "../components/atoms/DHLMultiBox/DHLMultiBox";
import { DHLFilteredComboBoxDataType } from "../components/molecules/DHLFilteredComboBox/DHLFilteredComboBox";
import { normalizeFilter } from "../utils/stringUtils";
import { markMatchesInEntry, normalizeSearchFilter } from "../utils/searchAndFilterUtils";
import { FormField } from "./FormField";


/** data store for filteredComboBox */
export class FilteredComboBoxStore {

  availableEntries: DHLFilteredComboBoxDataType[] = [];

  constructor(public formField: FormField<string>) {
    makeObservable(this, {
      availableEntries: observable,
      setAvailableEntries: action,
      searchString: computed,
      inputMatchesExactly: computed,
      normalizedSearchString: computed,
      matchingEntries: computed,
      matchingEntriesMarked: computed
    });
  }

  setAvailableEntries(availableEntries: DHLFilteredComboBoxDataType[]) {
    this.availableEntries = availableEntries;
  }

  get searchString(): string {
    return this.formField.value;
  }

  get inputMatchesExactly(): boolean {
    return this.availableEntries.filter((entry: DHLFilteredComboBoxDataType) => this._matchesExactly(entry.value)).length === 1;
  }

  get normalizedSearchString(): string {
    return normalizeSearchFilter(this.searchString);
  }

  get matchingEntries(): DHLFilteredComboBoxDataType[] {
    const checkEntryContainsSearched = (entry: DHLFilteredComboBoxDataType) => this._containsSearched(entry.value)
        || (entry.additionalInformation && this._containsSearched(entry.additionalInformation));
    return this.availableEntries.filter(checkEntryContainsSearched);
  }

  get matchingEntriesMarked(): DHLMultiBoxAvailableEntry[] {
    return this.matchingEntries.map(entry => ({
      value: entry.value,
      displayValue: markMatchesInEntry(entry.value, this.searchString),
      additionalInfo: entry.additionalInformation ? markMatchesInEntry(entry.additionalInformation, this.searchString) : undefined
    }));
  }

  private _containsSearched(s: string): boolean {
    return normalizeFilter(s).indexOf(this.normalizedSearchString) !== -1;
  }

  private _matchesExactly(s: string): boolean {
    return s.indexOf(this.searchString) !== -1;
  }
}
