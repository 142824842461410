/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, flow, makeObservable, observable, reaction } from "mobx";
import { SelectionListClient } from "../clients/SelectionListClient";
import { LocaleManager } from "@gkuis/gkp-authentication";
import { SelectionListType } from "../types/DataTypes";

export class SelectionListManager {

  private readonly localizedSelectionLists = {
    "de": observable.array<SelectionListType>(),
    "en": observable.array<SelectionListType>()
  };

  private readonly loaded = {
    "de": false,
    "en": false
  };

  constructor(private readonly selectionListClient: SelectionListClient, private readonly localeManager: LocaleManager) {
    makeObservable<SelectionListManager, "localizedSelectionLists">(this, {
      localizedSelectionLists: observable,
      selectionLists: computed,
      loadSelectionLists: action
    });
    reaction(
        () => localeManager.language,
        () => {
          if (this.loaded.de || this.loaded.en) {
            this.loadSelectionLists();
          }
        },
        {fireImmediately: true});
  }

  get selectionLists(): SelectionListType[] {
    return this.localizedSelectionLists[this.localeManager.language];
  }

  loadSelectionLists = flow(function* loadSelectionLists(this: SelectionListManager) {
    // store language in variable to prevent race conditions (language updated while this method is running)
    const language = this.localeManager.language;

    if (this.loaded[language]) {
      return;
    }

    const selectionList: SelectionListType[] = yield this.selectionListClient.fetchSelectionLists(language);
    this.localizedSelectionLists[language].replace(selectionList);

    this.loaded[language] = true;
  });
}


