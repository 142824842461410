/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, makeObservable, observable } from "mobx";
import { ResourceDataStore } from "./ResourceDataStore";
import { ValidationRuleDataStore } from "./ValidationRuleDataStore";
import { LocalizationPack } from "./LocalizationPack";
import { TFunction } from "i18next";
import { isTFunc } from "../utils/i18next";

export class FormField<T> {
  name: string;
  labelKey: string;
  value: T;
  private readonly initialValue: T;
  ruleName: string;
  error: string | null = null;
  errorMarkerOnly: boolean = false;
  hint: string | null = null;
  source: string | null = null;
  resourceDataStore: ResourceDataStore | LocalizationPack | TFunction;
  validationRuleDataStore: ValidationRuleDataStore;

  constructor(
      name: string,
      value: T,
      resourceDataStore: ResourceDataStore | LocalizationPack | TFunction,
      validationRuleDataStore: ValidationRuleDataStore,
      labelKey?: string, ruleName?: string
  ) {
    this.name = name;
    this.labelKey = (labelKey !== null && labelKey !== undefined) ? labelKey : name;
    this.initialValue = value;
    this.value = value;
    this.ruleName = ruleName ? ruleName : name;
    // use complete store - no access to localizationPack, this will break language switches because the language change will not be detected 
    this.resourceDataStore = resourceDataStore;
    this.validationRuleDataStore = validationRuleDataStore;
    makeObservable(this, {
      name: observable,
      labelKey: observable,
      value: observable,
      ruleName: observable,
      error: observable,
      errorMarkerOnly: observable,
      hint: observable,
      source: observable,
      resourceDataStore: observable,
      validate: action,
      isValid: action,
      updateValue: action,
      toggleBoolean: action,
      clearError: action,
      setError: action,
      setErrorMarkerOnly: action,
      clearHint: action,
      setHint: action,
      clearSource: action,
      setSource: action,
      label: computed,
      rule: computed,
      hasError: computed,
      reset: action
    });
  }

  isLegacy(resourceDataStore: ResourceDataStore | LocalizationPack): resourceDataStore is ResourceDataStore {
    return typeof resourceDataStore !== "function";
  }

  validate(additionalArgs: any[] = []): boolean {
    const inputErr = this.validationRuleDataStore.validate(this.value, this.ruleName, additionalArgs);

    if (inputErr) {
      this.error = isTFunc(this.resourceDataStore)
          //TODO
          ? this.resourceDataStore(inputErr, this.value ? {0: String(this.value)} : undefined)
          : this.resourceDataStore.getMessage(inputErr, this.value ? [String(this.value)] : undefined);
    } else {
      this.clearError();
    }

    return !inputErr;
  }

  /**
   * Checks if the form field content is valid without setting an error to the form field
   *
   * @param {any[]} additionalArgs additional args
   * @returns {boolean} if value is valid
   */
  isValid(additionalArgs: any[] = []): boolean {
    const error = this.validationRuleDataStore.validate(this.value, this.ruleName, additionalArgs);
    return error === null || error === "";
  }

  updateValue(value: T): void {
    this.value = value;
  }

  toggleBoolean(): void {
    if (typeof this.value === "boolean") {
      this.value = !this.value as any;
    }
  }

  reset(): void {
    this.value = this.initialValue;
    this.clearError();
    this.clearHint();
    this.clearSource();
  }

  clearError(): void {
    this.error = null;
    this.errorMarkerOnly = false;
  }

  setError(error: string): void {
    this.error = error;
  }

  setErrorMarkerOnly(errorMarkerOnly: boolean): void {
    this.errorMarkerOnly = errorMarkerOnly;
  }

  clearHint(): void {
    this.hint = null;
  }

  setHint(hint: string): void {
    this.hint = hint;
  }

  clearSource(): void {
    this.source = null;
  }

  setSource(source: string): void {
    this.source = source;
  }

  get label(): string {
    return this.labelKey && (
        isTFunc(this.resourceDataStore)
            ? this.resourceDataStore(this.labelKey)
            : this.resourceDataStore.getLabel(this.labelKey)
    );
  }

  get rule(): any {
    return this.ruleName && this.validationRuleDataStore.getRule(this.ruleName);
  }

  get hasError(): boolean {
    return this.error !== null;
  }
}
