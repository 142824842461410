/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { DHLButton, DHLSelectOne } from "../../..";
import { MonthOfYear, Year } from "../../../types/DatePickerTypes";
import "./DHLDatePicker.scss";
import { DHLSelectOneOptionType } from "../../molecules/DHLSelectOne/DHLSelectOne";
import { useLocaleManager } from "../../../locale/useLocaleManager";

export type WeekNavigationHeaderProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;
  isPreviousMonthAvailable: boolean;
  isNextMonthAvailable: boolean;
  monthsOfYear: MonthOfYear[];
  years: Year[];
  displayedMonth: number;
  displayedYear: number;
  onPreviousMonth: () => void;
  onNextMonth: () => void;
  onMonthSelected: (month: number) => void;
  onYearSelected: (year: number) => void;
};

const calcMonthKeyValues = (months: MonthOfYear[], locale: string): DHLSelectOneOptionType[] => {
  const date = moment().locale(locale);
  return months.map(m => ({
    key: m.month.toString(),
    value: date.month(m.month).format("MMMM"),
    disabled: !m.clickable,
  }));
};

const calcYearsKeyValues = (years: Year[]): DHLSelectOneOptionType[] => {
  return years.map(y => ({
    key: y.year.toString(),
    value: y.year.toString(),
    disabled: !y.clickable,
  }));
};

/** Stellt die Navigation im Kalender-Header dar. */
export const WeekNavigationHeader = observer(({
                                                name,
                                                isPreviousMonthAvailable,
                                                isNextMonthAvailable,
                                                monthsOfYear,
                                                years,
                                                displayedMonth,
                                                displayedYear,
                                                onPreviousMonth,
                                                onNextMonth,
                                                onMonthSelected,
                                                onYearSelected
                                              }: WeekNavigationHeaderProps) => {
  const localeManager = useLocaleManager();
  return (
      <div data-testid={name} className={"dhlDatePicker-expanded-weeknav"}>
        <div>
          <DHLButton name={name + "-prevMonth"}
                     onClick={onPreviousMonth}
                     type={"ghost"}
                     size={"sm"}
                     icon={"link-arrow-prev"}
                     iconPosition={"icon"}
                     label={"da"}
                     disabled={!isPreviousMonthAvailable}
          />
        </div>
        <div>
          <DHLSelectOne data={calcMonthKeyValues(monthsOfYear, localeManager.language)}
                        name={name + "-selectorMonth"}
                        className={classNames("input-slim", "calendar-select")}
                        onChange={(e) => {
                          onMonthSelected(+(e.target.value));
                        }}
                        value={displayedMonth.toString()}
          />
        </div>
        <div>
          <DHLSelectOne data={calcYearsKeyValues(years)}
                        name={name + "-selectorYear"}
                        className={classNames("input-slim", "calendar-select")}
                        onChange={(e) => {
                          onYearSelected(+(e.target.value));
                        }}
                        value={displayedYear.toString()}
          />
        </div>
        <div>
          <DHLButton name={name + "-nextMonth"}
                     onClick={onNextMonth}
                     type={"ghost"}
                     size={"sm"}
                     icon={"link-arrow-next"}
                     iconPosition={"icon"}
                     disabled={!isNextMonthAvailable}
          />
        </div>
      </div>
  );
});
