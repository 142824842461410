/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { DHLButton, DHLFormGroup, DHLTextInput, } from "../../..";
import "./DHLDataFilterSelectModule.scss";
import { observer } from "mobx-react-lite";
import { action, observable, ObservableSet, reaction, runInAction, values } from "mobx";
import { normalizeFilter } from "../../../utils/stringUtils";
import { DHLFilteredCheckBoxEntry } from "./DHLFilteredCheckBoxEntry";
import { normalizeSearchFilter } from "../../../utils/searchAndFilterUtils";
import { useDataFilterStore } from "../../../utils/useDataFilterStore";

export type DHLDataFilterSelectModuleProps<T> = {
  label: string;

  applyButtonLabel: string;

  data: Set<string>;

  idSymbol: string;

  predicate?: (values: Set<string>) => (data: T) => boolean;

  localize?: (value: string) => string;

  disabled?: boolean;

  isFilterable?: boolean;

  filterInputLabel?: string;

  alignElementsToTop?: boolean;

  /** CSS-Klassen. */
  className?: string;
};

// do not inline: inlining will recreate function on every render which can lead to infinite render loops
const defaultPredicate = () => () => true;

export const DHLDataFilterSelectModule = observer(<T extends unknown>(
        {
          label,
          applyButtonLabel,
          data,
          idSymbol,
          predicate = defaultPredicate,
          localize,
          disabled = false,
          isFilterable = false,
          filterInputLabel,
          alignElementsToTop = false,
          className
        }: DHLDataFilterSelectModuleProps<T>
    ) => {
      const [isDropdownOpen, setDropdownOpen] = useState(false);
      const [newSelection] = useState<ObservableSet<string>>(observable.set());
      const [filterText, setFilterText] = useState("");
      const [displayedData, setDisplayedData] = useState([...data].slice());
      const wrapperRef = useRef<HTMLDivElement | null>(null);

      const dataFilterStore = useDataFilterStore<T>();

      const updateSelectionFromFilterStore = action(() => {
        newSelection.clear();
        [...(dataFilterStore.filterData.get(idSymbol)?.values ?? new ObservableSet<string>(observable.set()))]
            .forEach(filterValue => newSelection.add(filterValue));
      });

      useEffect(() => {
        updateSelectionFromFilterStore();
      }, []);

      useEffect(() => {
        function handleClickOutsideOrEscape(event: any) {
          if (!wrapperRef?.current?.contains(event.target) || event.keyCode === 27) {
            setDropdownOpen(false);
            updateSelectionFromFilterStore();
          }
        }

        document.addEventListener("mousedown", handleClickOutsideOrEscape);
        document.addEventListener("keydown", handleClickOutsideOrEscape);
        return () => {
          document.removeEventListener("mousedown", handleClickOutsideOrEscape);
          document.removeEventListener("keydown", handleClickOutsideOrEscape);
        };
      }, [wrapperRef]);

      useEffect(
          () => dataFilterStore.register(idSymbol, predicate, localize),
          [dataFilterStore, idSymbol, predicate, localize]
      );

      useEffect(() => reaction(
          () => values(dataFilterStore.filterData.get(idSymbol)?.values ?? new ObservableSet<string>(observable.set())),
          updateSelectionFromFilterStore
      ), [dataFilterStore, idSymbol]);

      useEffect(() => _filterData(), [filterText, data, data.size]);

      const containsFilterText = (dataElement: string): boolean => {
        return normalizeFilter(dataElement).includes(normalizeSearchFilter(filterText));
      };

      const _filterData = () => {
        setDisplayedData(filterText ? [...data].slice().filter(containsFilterText).slice() : [...data].slice());
      };

      const _onFilterTextEntered = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
      };

      const selectedCheckboxCounter = dataFilterStore.filterData.get(idSymbol)?.values?.size ?? 0;

      return (
          <div className={classNames(className, "dhlDataFilterSelectModule", "dhlDataFilterSelectModule-visible-div")} ref={wrapperRef}>
            <div className={"datafilter-dropdown-anchor"}>
              <DHLButton
                  label={label + (selectedCheckboxCounter > 0 ? ` (${selectedCheckboxCounter})` : "")}
                  size={"sm-selectmodule"}
                  type={"selectmodule"}
                  iconPosition={"icon-last"}
                  icon={"arrow-down"}
                  onClick={() => setDropdownOpen(oldValue => !oldValue)}
                  disabled={disabled || data.size <= 0}
              />
              {isDropdownOpen &&
                  <div className={"dhlDataFilterSelectModule dhlDataFilterSelectModule-visible-bottom"}>
                    {isFilterable &&
                        <DHLTextInput
                            type="text"
                            value={filterText}
                            onChange={_onFilterTextEntered}
                            name={"dhlDataFilterSelectModule-filterTextInput"}
                            label={filterInputLabel}
                        />}
                    <DHLFormGroup checkboxGroup>
                      {[...displayedData].map(dataEntry => (
                          <DHLFilteredCheckBoxEntry
                              value={localize !== undefined ? localize(dataEntry) : dataEntry}
                              checkBoxValue={newSelection.has(dataEntry)}
                              filterText={filterText}
                              key={dataEntry}
                              onChange={() => runInAction(() => {
                                if (newSelection.has(dataEntry)) {
                                  newSelection.delete(dataEntry);
                                } else {
                                  newSelection.add(dataEntry);
                                }
                              })}
                              verticalAlignment={alignElementsToTop ? "alignTop" : undefined}
                          />
                      ))}
                    </DHLFormGroup>
                    <DHLButton
                        label={applyButtonLabel}
                        size={"sm"}
                        type={"primary"}
                        onClick={() => {
                          dataFilterStore.setFilterValues(idSymbol, [...newSelection]);
                          setDropdownOpen(false);
                        }}
                    />
                  </div>}
            </div>
          </div>
      );
    }
);
