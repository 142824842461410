/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import * as Popper from "popper.js";
import { DHLIcon, DHLLabel, DHLTooltip, FormField, logger } from "../../..";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import {
  calculateBooleanOnChange,
  calculateDisabled,
  calculateError,
  calculateErrorMarkerOnly,
  calculateHint,
  calculateLabel,
  calculateName,
  calculateRequired,
  calculateSource,
  calculateValidationRule,
  calculateValue
} from "../../../utils/calcPropertiesValue";
import { DHLFieldNote } from "../../atoms/DHLFieldNote/DHLFieldNote";
import "./DHLCheckbox.scss";

export type DHLCheckboxProps = {
  /** Name of the input field. Should match the name of the property for the input value to use a single change handler.
   * Also used for generating the Test ID. */
  name?: string;

  /** Label text. */
  label?: string;

  /** CSS-classes for label. */
  labelClassName?: string;

  /** Tooltip with Icon after label */
  labelTooltip?: string;

  /** CSS-classes for input field. */
  className?: string;

  /** Label with mandatory field marking. */
  required?: boolean;

  /** Position cursor in input field? */
  autoFocus?: boolean;

  /** Edit mode of the form (true = new creation, false = editing). The mandatory field status of the input field may depend on the edit mode.  */
  createMode?: boolean;

  /** disabled? */
  disabled?: boolean;

  /** render element? */
  render?: boolean;

  /** Input value (optional to allow Storybook etc. input). */
  value?: any;

  /** display as indeterminate (3rd display-state, masks real state) */
  indeterminate?: boolean

  /** Function for onChange-calls. */
  onChange?: React.ChangeEventHandler<HTMLElement>;

  /** Validation rule with configuration data for the input field. */
  validationRule?: ValidationRuleType;

  /** Error text(s), if one or more errors have occurred. */
  error?: string | string[] | null;

  /** Only mark the input field or also show error texts? */
  errorMarkerOnly?: boolean;

  /** Note text(s), if one or more notes are given. */
  hint?: string | string[] | null;

  /** Source(s), if one or more sources are indicated. */
  source?: string | string[] | null;

  /** default or slim size. */
  size?: "default" | "slim";

  /** Defines a form field with name, label, value, validation rule and error text. Single parameters have priority. */
  formField?: FormField<any>;

  /** Tooltip. */
  tooltip?: string | React.ReactChild;

  /**Tooltip alignment */
  tooltipPlacement?: Popper.Placement;

  /** Tooltip, when inputField disabled */
  disabledTooltip?: string | React.ReactChild;

  /** Control the vertical alignment of checkbox and label. Center if not set specifically to align top. */
  verticalAlignment?: "alignTop" | undefined;

  /** Flag, indicating, that the label text should be converted with the method interpreting special tags. */
  useSpecialTags?: boolean;
};

/** Checkbox with integrated Label. */
export const DHLCheckbox = observer(
    ({
       name,
       label,
       labelClassName,
       labelTooltip,
       className,
       required,
       autoFocus,
       createMode,
       disabled,
       render = true,
       value,
       indeterminate,
       onChange,
       validationRule,
       error,
       errorMarkerOnly,
       hint,
       source,
       size = "default",
       formField,
       tooltip,
       disabledTooltip,
       tooltipPlacement = "bottom",
        verticalAlignment,
       useSpecialTags = false
     }: DHLCheckboxProps) => {
      const [tooltipOpen, setTooltipOpen] = useState(false);

      if (!render) {
        return null;
      }

      const toggle = () => setTooltipOpen(!tooltipOpen);

      let usedTooltip = tooltip;

      if (disabled && disabledTooltip) {
        usedTooltip = disabledTooltip;
      }

      const calcName = calculateName(name, formField);

      if (!calcName) {
        logger.error("Components needs an explicit name or formField parameter");
        return null;
      }

      const calcValidationRule = calculateValidationRule(validationRule, formField);

      const calcLabel = calculateLabel(label, formField);
      const calcError = calculateError(error, formField);
      const calcHint = calculateHint(hint, formField);
      const calcSource = calculateSource(source, formField);
      const calcValue = calculateValue(value, formField);
      const calcOnChange = calculateBooleanOnChange(onChange, formField);
      const calcRequired = calculateRequired(required, calcValidationRule, createMode);
      const calcErrorMarkerOnly = calculateErrorMarkerOnly(errorMarkerOnly, calcValidationRule);
      const calcDisabled = calculateDisabled(disabled, calcValidationRule, createMode);
      const calcErrorClass = calcError !== null && calcError !== undefined && calcError.length > 0 ? "error" : "";

      const calcIsIcon = calcValue || indeterminate;
      const calcIconType = indeterminate ? "some-selected" : "check";
      const calcCheckboxCssClasses = [
        "checkbox",
        calcDisabled ? "disabled" : "",
        calcIsIcon ? "checked" : "emptyCheckbox",
        indeterminate ? "indeterminate" : "",
        size,
        calcErrorClass
      ];
      const calcOnClick = calcDisabled ? () => { /* intended use */ } : calcOnChange;

      const tooltipRef = useRef<HTMLDivElement|null>(null);

      const output = (
          <div className={classNames("checkbox-container", "form-checkbox", className)}>
            <div data-testid={`${calcName}-container`}
                 className={classNames("checkbox-and-label-container", "form-checkbox", calcDisabled ? "disabled" : null, verticalAlignment)}>
              {/* inner dom element changes, so can't attach tooltip directly */}
              <div ref={tooltipRef}>
              {calcIsIcon
                  ? <DHLIcon name={calcName}
                             icon={calcIconType}
                             className={classNames(...calcCheckboxCssClasses)}
                             ariaLabel={calcLabel}
                             onClick={calcOnClick} />
                  : <div id={calcName} data-testid={calcName}
                         className={classNames(...calcCheckboxCssClasses)}
                         aria-label={calcLabel}
                         onClick={calcOnClick} />}
              </div>
              {calcLabel &&
                    <DHLLabel
                        name={`${calcName}-label`}
                        htmlFor={calcName}
                        label={calcLabel}
                        required={calcRequired}
                        checkbox={true}
                        onClick={calcOnClick}
                        className={classNames("form-check-label", labelClassName)}
                        tooltip={labelTooltip}
                        useSpecialTags={useSpecialTags}
                    />}
              {usedTooltip &&
              <DHLTooltip
                  placement={tooltipPlacement}
                  tooltipOpen={tooltipOpen}
                  target={tooltipRef}
                  toggle={toggle}>
                {usedTooltip}
              </DHLTooltip>}
            </div>
            {!calcErrorMarkerOnly && calcError && <DHLFieldNote classname={size} name={`${calcName}-error`} type={"error"} notes={calcError} />}
            {calcHint && <DHLFieldNote classname={size} name={`${calcName}-hint`} type={"hint"} notes={calcHint} />}
            {calcSource && <DHLFieldNote classname={size} name={`${calcName}-source`} type={"source"} notes={calcSource} />}
          </div>
      );

      return <>{output}</>;
    }
);
