/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { logger } from "../logger";
import {FetchAdapter} from "./FetchAdapter";

/**
 * Kapselt den POST Aufruf von "fetch".
 *
 * @param fetchAdapter the FetchAdapter to use
 * @param url die URL die aufgerufen werden soll
 * @param body die Daten für den Service
 * @param successCallback callback that will be called in case of successful fetch
 * @param errorCallback callback that will be called in case of errors
 * @param finalCallback callback that will be called after the success or error callback
 */
export const fetchServicePost = async (fetchAdapter: FetchAdapter,
                                       url: string,
                                       body: any, successCallback?: (response: Response) => void,
                                       errorCallback?: (response: Response) => void,
                                       finalCallback?: () => void): Promise<void> => {
  logger.log("fetchServicePost - fetching...", url, body);

  try {
    const response = await fetchAdapter.post(url, {body: JSON.stringify(body)});

    if (response.ok) {
      if (successCallback !== undefined) {
        successCallback(response);
      }
    } else if (errorCallback !== undefined) {
      errorCallback(response);
    }

    if (finalCallback !== undefined) {
      finalCallback();
    }
  } catch (error) {
    logger.error("Beim fetchServicePost von " + url + " ist ein Fehler aufgetreten:", error);

    if (errorCallback !== undefined) {
      const errorBody = JSON.stringify({
        errorMessages: [
          {
            messageKey: "framework.error.fetchServicePost"
          }
        ]
      });
      const response = new Response(errorBody, {status: 500});
      errorCallback(response);
    }

    if (finalCallback !== undefined) {
      finalCallback();
    }
  }
};
