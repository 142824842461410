/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import {DHLDateInput, DHLDateInputProps} from "./DHLDateInput";
import {DHLIcon} from "../../..";
import "./DHLDateInputGroup.scss";
import {observer} from "mobx-react-lite";

export const DHLDateInputGroup = observer(({pickerStart, pickerEnd}: {pickerStart:DHLDateInputProps, pickerEnd: DHLDateInputProps}) => {
  return <div className={"dhlDateInputGroup"}>
    <DHLDateInput {...pickerStart} />
    <DHLIcon className="dhlDateInputGroup-separator" name={pickerStart.name+"-separator-icon"} icon="date-picker-separator"/>
    <DHLDateInput {...pickerEnd} />
  </div>
});