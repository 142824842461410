import classNames from "classnames";
import { observer } from "mobx-react-lite";
import "./DHLHTMLOutput.scss";
import DOMPurify from "dompurify";

/** WICHTIG: Kein extends verwenden, da Storybook damit nicht umgehen kann und die Attribute der übergeordneten Interfaces/Typen nicht anzeigt. */
export type DHLHTMLOutputProps = {

  /** CSS-Klassen für das Eingabefeld. */
  className?: string;

  /** Element ausgeben? */
  render?: boolean;

  /** Eingabewert (optional damit Storybook usw. Eingaben zulassen). */
  htmlContent: string;
};

/** Text Input mit integriertem Label. */
export const DHLHTMLOutput = observer(({
                                         className,
                                         render = true,
                                         htmlContent
                                       }: DHLHTMLOutputProps) => {
  if (!render) {
    return null;
  }
  const purifiedRawHTML = DOMPurify.sanitize(htmlContent, {
    USE_PROFILES: {html: true},
    ALLOWED_TAGS: ["br",
      "p",
      "div",
      "ol",
      "ul",
      "li",
      "hr",
      "figure",
      "figcaption",
      "img",
      "table",
      "thead",
      "tbody",
      "tr",
      "th",
      "td",
      "a",
      "b",
      "strong",
      "var",
      "i",
      "em",
      "u",
      "s",
      "span",
      "strike",
      "sub",
      "sup",
      "svg"],
    ADD_ATTR: ["target"]
  });

  const output = (
      <div className={classNames("dhlTextHTMLContainer", className)} dangerouslySetInnerHTML={{__html: purifiedRawHTML}} />
  );

  return <>{output}</>;
});