/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ReactNode } from "react";
import classNames from "classnames";

type DialogFormContentProps = {
  leftContent70: ReactNode,
  rightMarginal30: ReactNode,
  className?: string,
  preamble?: ReactNode,
  postamble?: ReactNode
}

export const DialogFormContent7030 = ({
                                        preamble,
                                        postamble,
                                        leftContent70,
                                        rightMarginal30,
                                        className
                                      }: DialogFormContentProps) => {
  return <div
      className={classNames(className, "dhlDialog-formContent formContent7030-container")}>
    {preamble}
    <div className={"formContent7030-inner"}>
      <div className={"formContent7030-70"}>
        {leftContent70}
      </div>
      <div className={"formContent7030-30"}>
        {rightMarginal30}
      </div>
        </div>
    {postamble}
  </div>;
};