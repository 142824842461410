/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */



import { observer } from "mobx-react-lite";
import { DHLCheckbox } from "../DHLCheckbox/DHLCheckbox";
import { markMatchesInEntry } from "../../../utils/searchAndFilterUtils";
import "./DHLFilteredCheckBoxEntry.scss";
import "../DHLCheckbox/DHLCheckbox.scss";
import "../../atoms/DHLLabel/DHLLabel.scss";

export type DHLFilteredCheckBoxEntryProps = {
  /** Entry to display in the dropdown list */
  value: string;

  /** Value for the checkBox */
  checkBoxValue: boolean;

  /** Input of search field */
  filterText: string;

  /** Function for onChange-calls. */
  onChange?: React.ChangeEventHandler<HTMLElement>;

  /** Control the vertical alignment of checkbox and label. Center if not set specifically to align top. */
  verticalAlignment?: "alignTop" | undefined;

};

export const DHLFilteredCheckBoxEntry = observer((
  {
    value,
    checkBoxValue,
    filterText,
    onChange,
    verticalAlignment
  }: DHLFilteredCheckBoxEntryProps) => {

    const listEntry = markMatchesInEntry(value, filterText);

    return (
      <div className={"checkbox-container form-checkbox checkBoxAndEntryContainer "}>
        <DHLCheckbox
          key={value}
          name={value}
          label={""}
          size={"slim"}
          value={checkBoxValue}
          verticalAlignment={verticalAlignment}
          onChange={onChange}
        />
        <div>
          {listEntry}
        </div>
      </div>
    );
  }
);