/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { DatePickerDataStore, DHLDatePicker, DHLTextInput, FormField, logger } from "../../..";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import { observer } from "mobx-react-lite";
import {
  calculateName,
  calculateValue,
  calculateValidationRule,
  calculateMaxDate,
  calculateMinDate
} from "../../../utils/calcPropertiesValue";

const LOG_MODULE = "DHLDateInput";

export type DHLDateInputProps = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen
   *  einzigen Change-Handler verwenden zu können. Wird auch für die Generierung der Test-ID ververwendet. */
  name?: string;

  /** Labeltext */
  label?: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: (date: string) => void;

  /** Label mit Pflichtfeldmarkierung versehen? */
  required?: boolean;

  /** Cursor im Eingabefeld positionieren? */
  autoFocus?: boolean;

  /** Platzhaltertext für leere Eingabefelder. */
  placeholder?: string;

  /** Minimal erlaubtes Datum. */
  minDate?: moment.Moment;

  /** Maximal erlaubtes Datum. */
  maxDate?: moment.Moment;

  /** NOT IMPLEMENTED, DEPRECATED: Timeout für automatisches schließen des Date-Pickers. */
  autoDismissalTimeout?: number;

  /** Deaktivieren? */
  disabled?: boolean;

  /** Element ausgeben? */
  render?: boolean;

  /** Datumseingabe. */
  value?: string;

  /** Validierungsregel. */
  validationRule?: ValidationRuleType;

  /** Definiert ein Formularfeld mit Name, Label, Wert, Validierungsregel und Fehlertext. Einzelparameter haben Priorität. */
  formField?: FormField<string>;

  /**
   * Prädikat, dass ein anzuzeigendes Datum übergeben bekommt und zurückgibt, ob der Tag im
   * DatePicker deaktiviert/inaktiv
   * angezeigt werden soll, d.h. auch nicht auswählbar ist.
   * Daten ausserhalb von startDate und endDate sind automatisch nicht auswählbar.
   * Hat kein Einfluss auf die manuelle (textuelle) Eingabe.
   */
  datePickerDisabledDatesPredicate?: (day: moment.Moment) => boolean;

  /** Fehlertext(e), wenn ein oder mehrere Fehler aufgetreten sind. */
  error?: string | string[] | null;

  /**
   * date format to use when displaying dates
   */
  dateFormat?: string;
};

/** Datumseingabe mit integriertem Label. */
export const DHLDateInput = observer(({
                                        value,
                                        label,
                                        error,
                                        onChange,
                                        required,
                                        autoFocus,
                                        placeholder,
                                        minDate,
                                        maxDate,
                                        disabled,
                                        render = true,
                                        name,
                                        datePickerDisabledDatesPredicate,
                                        validationRule,
                                        formField,
                                        dateFormat = "L"
                                      }: DHLDateInputProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [dataStore] = useState(new DatePickerDataStore(dateFormat));

  //necessary to handle conflicts of mouse and focus events
  const [lastShowDatePickerState, setLastShowDatePickerState] = useState(false);

  const wrapperRef: React.MutableRefObject<any> = useRef(null);

  const calculateOnChange = (onChange?: (date: string) => void, formField?: FormField<string> | undefined)
      : React.ChangeEventHandler<HTMLInputElement> => {
    if (onChange) {
      return (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
    }

    if (formField && !onChange) {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        formField.updateValue(event.target.value);
      };
    }

    return () => {};
  };

  const calcName = calculateName(name, formField);
  const calcValue = calculateValue(value, formField);
  const calcOnChange = calculateOnChange(onChange, formField);
  const calcValidationRule = calculateValidationRule(validationRule, formField);
  const calcMinDate = calculateMinDate(minDate, calcValidationRule);
  const calcMaxDate = calculateMaxDate(maxDate, calcValidationRule);

  useEffect(() => {

    if (calcMinDate) {
      dataStore.setStartDate(calcMinDate);
    }

    if (calcMaxDate) {
      dataStore.setEndDate(calcMaxDate);
    }

    if (datePickerDisabledDatesPredicate) {
      dataStore.disabledDatePredicate = datePickerDisabledDatesPredicate;
    }

    if (calcValue !== undefined && moment(calcValue, dateFormat, true).isValid()) {
      dataStore.initFromDate(moment(calcValue, dateFormat, true));
    }

    document.addEventListener("keydown", _onEscapePressed);
    document.addEventListener("mousedown", _onClickOutside);

    return () => {
      document.removeEventListener("keydown", _onEscapePressed);
      document.removeEventListener("mousedown", _onClickOutside);
    };
  }, [calcMinDate, calcMaxDate, calcValue, dataStore, datePickerDisabledDatesPredicate, wrapperRef]);

  useEffect(() => {
    dataStore.dateFormat = dateFormat;
  }, [dateFormat]);


  const _onFocus = (): void => {
    setShowDatePicker(true);
  };

  const _onDateSelected = (date: moment.Moment): void => {
    if (formField && !onChange) {
      formField.updateValue(date.format(dateFormat));
    } else if (onChange) {
      onChange(date.format(dateFormat));
    }

    setShowDatePicker(false);
  };

  const _onEscapePressed = useCallback((event) => {
    const KEY_ESCAPE = 27;
    if (event.keyCode === KEY_ESCAPE) {
      setShowDatePicker(false);
    }
  }, []);

  const _onMouseDown = () => {
    setLastShowDatePickerState(showDatePicker);
  };

  const _onMouseUp = () => {
    setShowDatePicker(!lastShowDatePickerState);
  };

  const _onClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  if (!render) {
    return null;
  }

  logger.log(LOG_MODULE, "supplied/used date format: " + dateFormat);


  // onBlur auf Komponente funktioniert nicht, da dann das onClick-Event nicht ankommt. Hierfür brauchen wir noch eine Lösung.
  return (
      <div ref={wrapperRef}>
        <>
          <DHLTextInput
              name={name}
              label={label}
              placeholder={placeholder}
              onChange={calcOnChange}
              onFocus={_onFocus}
              onMouseDown={_onMouseDown}
              onMouseUp={_onMouseUp}
              value={value}
              maxLength={10}
              error={error}
              required={required}
              disabled={disabled}
              autoFocus={autoFocus}
              autoCompleteOff={true}
              validationRule={validationRule}
              formField={formField}
              functionIcon="date-picker"
          />
          {showDatePicker && <DHLDatePicker name={calcName + "-dp"} dataStore={dataStore} onDateSelected={_onDateSelected} />}
        </>
      </div>
  );
});
