/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";


export type DHLTableColLinkProps = {

  /** Value for transfer to onClick. */
  value: any;

  /** ID of the cell. */
  cellId: string;

  /** Function is called when you click on the link. */
  onClick: (event: React.MouseEvent, value: any) => void;

  /** Link-Text. */
  label?: string;

  /** Deactivation status */
  disabled?: boolean;
};

/** Ausgabe einer Tabellenzelle - Link. */
export const DHLTableColLink = observer(({
                                           label,
                                           cellId,
                                           onClick,
                                           value,
                                           disabled
                                         }: DHLTableColLinkProps) => {
  const onLinkClick = (evt: React.MouseEvent): void => {
    evt.preventDefault();

    onClick(evt, value);
  };

  let linkText = label;

  if (!label) {
    linkText = value;
  }

  if (disabled) {
    return <a data-testid={cellId}>{linkText}</a>;
  } else {
    return <a data-testid={cellId} href="#" onClick={onLinkClick}>{linkText}</a>;
  }
});
