import classNames from "classnames";
import { observer } from "mobx-react-lite";
import "./DHLTextOutput.scss";

/** WICHTIG: Kein extends verwenden, da Storybook damit nicht umgehen kann und die Attribute der übergeordneten Interfaces/Typen nicht anzeigt. */
export type DHLTextOutputProps = {

  /** Name to identify element (i.e. for testing) */
  name?: string;

  /** CSS-Klassen für das Eingabefeld. */
  className?: string;

  /** Element ausgeben? */
  render?: boolean;

  /** Eingabewert (optional damit Storybook usw. Eingaben zulassen). */
  value?: any;

  /** If footnote identifier is set, it indicates, that the text is meant to be a footnote with the string set in this prop as the footnote
   *  identifier */
  footnoteIdentifier?: string

  /** Ausrichtung des auszugebenden Textes*/
  textAlignment?: "left" | "center" | "right";
};

/** Text Input mit integriertem Label. */
export const DHLTextOutput = observer(({
                                         name,
                                         className,
                                         render = true,
                                         value,
                                         footnoteIdentifier,
                                         textAlignment
                                       }: DHLTextOutputProps
) => {
  if (!render) {
    return null;
  }

  return (
      <div data-testid={name + "-textoutput"}
           className={classNames("dhlTextOutputContainer", footnoteIdentifier ? "footnote" : null, className)}
      >
        {footnoteIdentifier
            && <p
                className={classNames(
                    "dhlTextOutput",
                    "footnote-identifier",
                    footnoteIdentifier ? "align-left" : null,
                    !footnoteIdentifier && textAlignment ? "align-" + textAlignment : null
                )}
            >
              {footnoteIdentifier}
            </p>
        }
        <p
            className={classNames(
                "dhlTextOutput",
                footnoteIdentifier ? "footnote-text" : null,
                footnoteIdentifier ? "align-left" : null,
                !footnoteIdentifier && textAlignment ? "align-" + textAlignment : null
            )}
        >
          {value}
        </p>
      </div>
  );
});