/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observable, makeObservable } from "mobx";

export class SelectManyShuttleStore<T> {

  /** The available options . */
  allOptions: T[] = [];

  /** The selected options . */
  selectedOptions: string[] = [];

  constructor(allOptions: T[], selectedOptions: string[]) {
    this.allOptions = allOptions;
    this.selectedOptions = selectedOptions;
    makeObservable(this, {
      allOptions: observable,
      selectedOptions: observable
    });
  }
}
