/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import React from "react";
import { Progress } from "reactstrap";
import "./DHLColorProgressBar.scss";
import classNames from "classnames";

type ProgressBarColor = "red" | "green" | "yellow";

export type ColorMap = {
  color: ProgressBarColor
  threshold: number
}

export type DHLColorProgressBarProps = {
  /** The maximum value of progress bar */
  maxValue: number

  /** The current value of the progress */
  currentValue: number

  /** Array of tuples that defines the color and the threshold from which the color is to apply on the progress bar. The threshold 1 must
   *  always be provided. */
  colorMapping: ColorMap[]
}

export const DHLColorProgressBar = ({
                                      maxValue,
                                      currentValue,
                                      colorMapping
                                    }: DHLColorProgressBarProps) => {

  const currentPercent = getCurrentPercent(maxValue, currentValue);
  const colorToApply = getColorToApply(colorMapping, currentPercent);
  const colorStyleToApply = getColorStyle(colorToApply);

  return (
    <Progress
      value={currentValue}
      max={maxValue}
      className={classNames("colorProgressBar", colorStyleToApply)} />
  );

};

function getCurrentPercent(maxValue: number, currentValue: number): number {
  return (maxValue < currentValue) ? 1
    : (currentValue <= 0) ? 0
      : currentValue / maxValue;
}

function getColorToApply(colorMapping: ColorMap[], currentPercent: number): ProgressBarColor {
  return colorMapping.sort((a, b) => a.threshold - b.threshold)
    .find(cm => currentPercent <= cm.threshold)?.color ?? colorMapping[0]?.color;
}

function getColorStyle(color: ProgressBarColor): string | undefined {
  switch (color) {
    case "red":
      return "bar-color-red";
    case "green":
      return "bar-color-green";
    case "yellow":
      return "bar-color-yellow";
    default:
      return "bar-color-black";
  }
}