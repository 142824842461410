/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {observer} from "mobx-react-lite";
import {Placement} from "popper.js";
import {DHLButton} from "../../..";
import {normalizeName} from "../../../utils/stringUtils";
import {IconType} from "../DHLIcon/DHLIcon";

export type DHLTableColButtonProps = {

  /** Cell id. */
  name: string;
  /** Button label. */
  label?: string;
  /** Value send on click. */
  value: any;
  /** Button size (allowed values: lg, normal, sm, xs; default value: 'normal') */
  size?: "lg" | "normal" | "sm" | "xs";
  /** Button type (allowed values: default, primary, secondary; default value: 'default') */
  buttonType?: "default" | "primary" | "secondary" | "ghost";
  /** Button type regarding icone (allowed values: no-icon, icon, icon-first, icon-last; default value: 'no-icon').*/
  iconPosition?: "no-icon" | "icon" | "icon-first" | "icon-last";
  /** The icon to integrate in the button . */
  icon?: IconType;
  /** onClick function. */
  onClick: (event: React.MouseEvent, value: any) => void;
  /** Text displayed in tooltip on hover. */
  tooltip?: string | React.ReactNode;
  /** Alignment of the tooltip. */
  tooltipPlacement?: Placement;
  /** Flag for the disabeled state */
  disabled?: boolean;
  /** Tooltip text displayed on hover if button is disabled. */
  disabledTooltip?: string;
};

/** Ausgabe einer Tabebellenzelle - Icon (Edit, Delete, ..). */
export const DHLTableColButton = observer(({
                                             name,
                                             label,
                                             value, size,
                                             buttonType,
                                             iconPosition,
                                             icon,
                                             onClick, tooltip,
                                             tooltipPlacement,
                                             disabled,
                                             disabledTooltip}
                                             : DHLTableColButtonProps) => {
  const onButtonClick = (evt: React.MouseEvent): void => {
    evt.preventDefault();

    onClick(evt, value);
  };
  const normalizedName = normalizeName(name);
  return (
      <DHLButton
          name={normalizedName}
          label={label}
          size={size}
          type={buttonType}
          iconPosition={iconPosition}
          icon={icon}
          onClick={onButtonClick}
          tooltip={tooltip}
          tooltipPlacement={tooltipPlacement}
          disabled={disabled}
          disabledTooltip={disabledTooltip}
      />
  );

});
