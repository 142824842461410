/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import {DHLIcon} from "../../..";

import "./DHLChipTag.scss";

export type DHLChipProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;
  /** Chiptext. */
  label: string;
  /** Funktion für onClick-Aufrufe. */
  onClick: React.EventHandler<any>;
};

export type DHLTagProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;
  /** Tagtext. */
  label: string;
  /** Funktion für onClick-Aufrufe. */
  onClick?: React.EventHandler<any>;
};

export const DHLChip = (props: DHLChipProps) => <DHLChipTag {...props} closeIcon={true} />;
export const DHLTag = (props: DHLTagProps) => <DHLChipTag {...props} closeIcon={false} />;

type DHLChipTagProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;
  /** Chiptext. */
  label: string;
  closeIcon: boolean;
  /** Funktion für onClick-Aufrufe. */
  onClick?: React.EventHandler<any>
};

const DHLChipTag = ({name, label, closeIcon, onClick}: DHLChipTagProps) => {
  const interactable = onClick !== undefined;
  return (
      <div
          className={classNames("dhlChipTag", {interactable})}
          tabIndex={interactable ? 0 : undefined}
          onClick={interactable ? onClick : undefined}
          onKeyPress={interactable ? (e => {
            if (e.key === " " && onClick) {
              e.preventDefault();
              onClick(e);
            }
          }) : undefined}
      >
        {label}
        {closeIcon ? <DHLIcon name={name + "-remove"} icon={"close-thin"} /> : null}
      </div>);
};