/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export const checkFileType = (file: File, allowedTypes?: string | undefined): boolean => {
  let valid = true;

  if (allowedTypes && allowedTypes.length > 0) {
    const fileTypes = allowedTypes.split(",");

    valid = fileTypes.indexOf(file.type) > -1;
  }

  return valid;
};

export const checkFileSize = (file: File, maxFileSize: number | undefined): boolean => {
  let valid = true;

  if (maxFileSize) {
    valid = file.size <= maxFileSize;
  }

  return valid;
};

export const checkTotalFileSize = (file: File, currentTotalSize: number, maxTotalFileSize: number | undefined): boolean => {
  let valid = true;

  if (maxTotalFileSize) {
    valid = file.size + currentTotalSize <= maxTotalFileSize;
  }

  return valid;
};
