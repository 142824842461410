/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager, AuthenticationManagerImpl2, Language } from "@gkuis/gkp-authentication";
import { makeObservable, override } from "mobx";

//used to get only language but no tokens
export class AuthenticationManagerWithoutAuth extends AuthenticationManagerImpl2 {

  constructor(private readonly authManager: AuthenticationManager) { //pass the real authManager!
    super("url", "realm", "clientId"); // placeholders to suppress "[AuthenticationManager] Error initializing: clientId missing"
    makeObservable(this, {
      language: override
    });
  }

  getAccessTokenWithoutRefresh(): string | undefined {
    return undefined;
  }

  async getAccessToken(minValiditySeconds: number = 3): Promise<string | undefined> {
    return undefined;
  }

  public get language(): Language {
    return this.authManager.language;
  }
}
