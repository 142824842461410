/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { DHLFormGroup } from "../../atoms/DHLFormGroup/DHLFormGroup";
import React from "react";
import { DHLRadioButton } from "../DHLRadioButton/DHLRadioButton";
import { FormField } from "../../../stores/FormField";
import "./DHLRadioButtonTile.scss";

export type DHLRadioButtonTileProps = {

  /** for all buttons of radio button group */
  formField: FormField<string>

  /** Selection of the radio button returns this value. */
  selectValue: string;

  /** Headline */
  headline: string

  /** content of tile */
  children: React.ReactNode

  /** color of border if tile is selected */
  selectionBorderColor?: "red" | "green"

  /** tile disabled */
  disabled?: boolean;
}
export const DHLRadioButtonTile = observer((
    {
      formField,
      selectValue,
      headline,
      children,
      disabled = false,
      selectionBorderColor = "red"
    }: DHLRadioButtonTileProps
) => {
  return (
      <div
          key={formField.name}
          className={`radio-button-tile-container ${formField.value === selectValue ? "checked-" + selectionBorderColor : ""}`}
          onClick={() => disabled ? () => {} : formField.updateValue(selectValue)}
      >
        <DHLFormGroup checkboxGroup>
          <DHLRadioButton
              name={`radio-button-tile-${formField.name}`}
              selectValue={selectValue}
              className={`radio-button-tile-radiobutton-text`}
              formField={formField}
              label={headline}
              disabled={disabled}
          />
        </DHLFormGroup>
        {children}
      </div>
  );
});
