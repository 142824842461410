/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { DHLOptionTreeLeafEntry, DHLOptionTreeNodeEntry, DHLOptionTreeStore, DHLOptionTreeSubTreeEntry } from "../../../stores/DHLOptionTreeStore";
import { DHLButton } from "../../atoms/DHLButton/DHLButton";
import { DHLButtonGroup } from "../../atoms/DHLButtonGroup/DHLButtonGroup";
import { DHLCheckbox } from "../DHLCheckbox/DHLCheckbox";
import { DHLOptionSubTree } from "./DHLOptionSubTree";
import "./DHLOptionTree.scss";

export type DHLOptionTreeProps = {
  name: string
  expandAllLabel?: string,
  collapseAllLabel?: string,
  selectAllLabel?: string,
  store: DHLOptionTreeStore
}

export const DHLOptionTree = observer(({
                                         name,
                                         expandAllLabel,
                                         collapseAllLabel,
                                         selectAllLabel,
                                         store
                                       }: DHLOptionTreeProps) => {

  const createCheckBoxWithAdditional = (leaf: DHLOptionTreeLeafEntry, id: string) => {
    const renderAdditional = leaf.renderAdditionalComponent;
    return (
      <div key={`div-${id}`} className={classNames("node")}>
        <div>
          <DHLCheckbox
            name={name}
            onChange={() => {
              leaf.formField.toggleBoolean();
              store.onSelectedValueChanged(leaf, leaf.formField.value);
            }}
            formField={leaf.formField} />
        </div>
        <div className={classNames("additional")}>
          {renderAdditional && renderAdditional(!leaf.formField.value, leaf.additionalFormField)}
        </div>
      </div>
    );
  };

  const createCheckbox = (leaf: DHLOptionTreeLeafEntry, id: string) => {
    return (
      <div key={`div-${id}`} className={classNames("node")}>
        <DHLCheckbox
          name={name}
          onChange={() => {
            leaf.formField.toggleBoolean();
            store.onSelectedValueChanged(leaf, leaf.formField.value);
          }}
          formField={leaf.formField} />
      </div>
    );
  };

  const createSubTree = (subTree: DHLOptionTreeSubTreeEntry, id: string, depth: number) => {
    const children: JSX.Element[] = subTree.children.map((child: DHLOptionTreeNodeEntry, index: number) => {
      const childID = `${id}-${index}`;
      return mapOptionTreeNode(child, childID, depth + 1);
    });
    return (
      <div key={`div-${id}`} className={classNames("node", "subtree", depth === 0 ? "rootNode" : "")}>
        <DHLOptionSubTree
          entry={subTree}
          onClick={id => store.toggleSubTreeExpansion(id)}>
          {children}
        </DHLOptionSubTree>
      </div>
    );
  };

  const mapOptionTreeNode = (node: DHLOptionTreeNodeEntry, treeName: string, depth: number) => {
    const isLeaf = (node as DHLOptionTreeLeafEntry).formField !== undefined;
    if (isLeaf) {
      const leaf = node as DHLOptionTreeLeafEntry;
      const showAdditionalChildren = leaf.renderAdditionalComponent !== undefined;
      return showAdditionalChildren ? createCheckBoxWithAdditional(leaf, treeName) : createCheckbox(leaf, treeName);
    } else {
      const subTree = node as DHLOptionTreeSubTreeEntry;
      return createSubTree(subTree, treeName, depth);
    }
  };

  const rootNodes = (
    <div className={"options-tree"}>
      {
        store.rootNodes.map((node: DHLOptionTreeSubTreeEntry, index: number) => {
          const nodeName = `${name}-rootNode-${index}`;
          return mapOptionTreeNode(node, nodeName, 0);
        })
      }
    </div>
  );

  const expandAllButton = (
    <DHLButton
      name={`${name}-expandAll`}
      icon={"plus"}
      size={"xs"}
      iconPosition={"icon-first"}
      label={expandAllLabel}
      type={"default"}
      onClick={() => store.expandAll()}
      disabled={store.expandAllButtonDisabled} />
  );

  const collapseAllButton = (
    <DHLButton
      name={`${name}-collapseAll`}
      icon={"some-selected"}
      size={"xs"}
      iconPosition={"icon-first"}
      label={collapseAllLabel}
      type={"default"}
      onClick={() => store.collapseAll()}
      disabled={store.collapseAllButtonDisabled} />
  );

  const selectAllButton = (
      <DHLCheckbox
          name={`${name}-selectAll`}
          label={selectAllLabel}
          value={store.atLeastOneSelected}
          indeterminate={store.someButNotAllSelected}
          onChange={() => store.cycleSelectAll()}
      />
  );

  const expansionButtonBar = (
    <div className={classNames("buttons")}>
      <DHLButtonGroup name={`${name}-buttons-expansion`}>
        {expandAllLabel && expandAllButton}
        {collapseAllLabel && collapseAllButton}
      </DHLButtonGroup>
    </div>
  );

  const selectionButtonBar = (
    <div className={classNames("buttons")}>
      <DHLButtonGroup name={`${name}-buttons-selection`}>
        {selectAllLabel && selectAllButton}
      </DHLButtonGroup>
    </div>
  );

  return (
    <div className={classNames("dhlOptionTree")}>
      {selectionButtonBar}
      {rootNodes}
      {expansionButtonBar}
    </div>
  );
});