/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { FormField } from "../../../stores/FormField";
import { ValidationRuleDataStore } from "../../../stores/ValidationRuleDataStore";
import { DHLTextInput } from "../DHLTextInput/DHLTextInput";
import { fullTextFilterPredicate } from "../../../stores/DataFilterStore";
import { useDataFilterStore } from "../../../utils/useDataFilterStore";
import { ResourceDataStore } from "../../../stores/ResourceDataStore";
import { filterTextsSymbol } from "../../../stores/TableDataStore";
import { useInRouterContext, } from "react-router-dom";
import { reaction } from "mobx";
import { useSharedSearchParams } from "../../../utils/useSharedSearchParams";

export type DHLTextInputFilterProps<T> = {
  name: string;

  // language switching within FormFields requires a resourceDataStore (not localizationPack)!
  resourceDataStore: ResourceDataStore;
  validationRuleDataStore: ValidationRuleDataStore;
  disabled?: boolean;
  idSymbol: string;
  predicate?: (values: Set<string>) => (data: T) => boolean;
  localize?: (value: string) => string;
  searchButtonDisabled?: (currentInputValue: string) => boolean;
  searchButtonDisabledTooltip?: string;
  placeholder?: string;
  ruleLabel?: string;
  /**
   * search params (aka query params) used by this component.
   * `name` is used for input and output, `additionalInputNames` are read from search params but written as `name`.
   */
  searchParamNames?: {
    name: string; //used for input and output from/to url
    additionalInputNames?: string[]; //used only for input from url
  }
}

export const DHLTextInputFilter = <T extends { [filterTextsSymbol]?: string[] }>(
    {
      name,
      resourceDataStore,
      validationRuleDataStore,
      idSymbol,
      disabled = false,
      predicate = fullTextFilterPredicate,
      localize,
      searchButtonDisabled = () => false,
      searchButtonDisabledTooltip,
      placeholder,
      ruleLabel,
      searchParamNames
    }: DHLTextInputFilterProps<T>
) => {
  const dataFilterStore = useDataFilterStore<T>();
  if (searchParamNames !== undefined) {
    searchParamNames.additionalInputNames = searchParamNames.additionalInputNames ?? [];
  }

  useEffect(
      () => dataFilterStore.register(idSymbol, predicate, localize),
      [dataFilterStore, idSymbol, predicate, localize]
  );

  const [inputField] = useState(new FormField<string>(
      "dataFilterInput",
      "",
      resourceDataStore,
      validationRuleDataStore,
      placeholder ?? "dataFilter.input",
      ruleLabel ?? "dataFilter.input"
  ));

  const addFilterString = useCallback(
      () => {
        if (!searchButtonDisabled(inputField.value)) {
          inputField.clearError();
          if (inputField.value.trim().length >= 1 && inputField.validate()) {
            dataFilterStore.addFilterValues(idSymbol, inputField.value.trim());
            inputField.updateValue("");
          } else if (inputField.value.trim().length === 0) {
            inputField.updateValue("");
          }
        }
      },
      [inputField, dataFilterStore, searchButtonDisabled]
  );

  const onKeyUp = useCallback(
      (event: KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter" && inputField.value.length === 0) {
          event.currentTarget.blur();
          event.currentTarget.parentNode?.parentElement?.blur();
        }
        if (event.key === "Enter") {
          addFilterString();
        }
      },
      [addFilterString]
  );

  if (useInRouterContext()) {
    const [searchParams, setSearchParams] = useSharedSearchParams();
    useEffect(
        () => {
          if (searchParamNames === undefined) {
            return;
          }
          const searchParamName = searchParamNames.name;

          const inputParamsWithoutOutputParam = (searchParamNames.additionalInputNames ?? [])
              .filter(inputName => inputName !== searchParamName);
          if (inputParamsWithoutOutputParam.length > 0 && inputParamsWithoutOutputParam.some(inputName => searchParams.has(inputName))) {
            //map input params to output param
            inputParamsWithoutOutputParam
                .forEach(inputSearchParamName => {
                  searchParams
                      .getAll(inputSearchParamName)
                      .forEach(currVal => searchParams.append(searchParamName, currVal));
                  searchParams.delete(inputSearchParamName);
                });
            setSearchParams(searchParams);
          }

          //add filter values from url
          searchParams
              .getAll(searchParamName)
              .forEach(inputValue => {
                inputField.updateValue(inputValue);
                addFilterString();
              });

          return reaction(
              () => [...dataFilterStore.filterData.get(idSymbol)?.values ?? []],
              (filterValues) => {
                searchParams.delete(searchParamName);
                filterValues.forEach(filterValue => searchParams.append(searchParamName, filterValue));
                setSearchParams(searchParams);
              }
          );
        },
        [searchParams, setSearchParams, dataFilterStore]
    );
  }

  return <DHLTextInput
      name={name + "-filter"}
      formField={inputField}
      disabled={disabled}
      onKeyUp={onKeyUp}
      functionIcon={"search"}
      functionIconType={"primary"}
      functionIconDisabled={searchButtonDisabled}
      functionIconDisabledTooltip={searchButtonDisabledTooltip}
      onFunctionClick={addFilterString}
  />;
};
