/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export const requestParameters = (): Map<string, string> => {
  const params: Map<string, string> = new Map();

  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value): string {
    params.set(key, value);

    return str;
  });

  return params;
};
