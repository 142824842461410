/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { createMessageWithSpecialTags } from "../../../utils/messagesUtils";
import React from "react";
import { DHLIcon, IconType } from "../DHLIcon/DHLIcon";
import { observer } from "mobx-react-lite";
import "./DHLList.scss";

export type DHLListProps = {

  /* content, as array or localization containing array
  * example: "[li|Item1] [li|Item2] [li|Item3]"
  */
  content: string;

  /* iconType */
  iconType: IconType;

}

export const DHLList = observer((
    {content, iconType}: DHLListProps) => {
  const contentWithSpecialTags = createMessageWithSpecialTags(content);
  if (!Array.isArray(contentWithSpecialTags)) {
    return <div>{contentWithSpecialTags}</div>;
  }
  return (
      <ul className={"dhlListContainer"}>
        {(contentWithSpecialTags as (string | JSX.Element | null)[]).map(item => {
          if (item === null || typeof item !== "object") {
            return item;
          }
          return (<li key={item.key}>
            <DHLIcon name={""} icon={iconType} />
            <span>{item.props.children}</span>
          </li>);
        })}
      </ul>
  );
});