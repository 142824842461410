/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import { FormField, KeyValueType } from "..";
import { DHLSelectOneOptionType } from "../components/molecules/DHLSelectOne/DHLSelectOne";
import { ValidationRuleType } from "../types/ValidationRuleTypes";
import moment from "moment";
import { KeyResourceDataType } from "../types/ResourceDataStoreTypes";

export const calculateValue = (value: any, formField?: FormField<any>): any => {
  let calcValue = value;

  if (formField && (calcValue === null || calcValue === undefined)) {
    calcValue = formField?.value;
  }

  if (calcValue === null || calcValue === undefined) {
    calcValue = "";
  }

  return calcValue;
};

export const calculateOnChange = (onChange?: React.ChangeEventHandler<any>, formField?: FormField<any> | undefined): any => {
  let calcOnChange = onChange;

  if (formField && !calcOnChange) {
    calcOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      formField.updateValue(event.target.value);
    };
  }

  return calcOnChange;
};

export const calculateBooleanOnChange = (onChange?: React.ChangeEventHandler<any>, formField?: FormField<any> | undefined): any => {
  let calcOnChange = onChange;

  if (formField && !calcOnChange) {
    calcOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      formField.toggleBoolean();
    };
  }

  return calcOnChange;
};

export const calculateRadioOnChange = (onChange?: Function, newValue?: string, formField?: FormField<any> | undefined): any => {
  if (onChange) {
    return () => onChange(newValue);
  } else if (formField) {
    return () => {
      formField.updateValue(newValue);
    };
  } else {
    return onChange;
  }
};

export const calculateName = (name?: string, formField?: FormField<any> | undefined): string | undefined => {
  return name || formField?.name;
};

export const calculateLabel = (label?: string, formField?: FormField<any> | undefined): string | undefined => {
  return label ?? formField?.label;
};

export const calculateValidationRule = (validationRule?: ValidationRuleType, formField?: FormField<any> | undefined): ValidationRuleType => {
  return validationRule || formField?.rule;
};

export const calculateError = (error?: string | string[] | null, formField?: FormField<any> | undefined): string | string[] | null | undefined => {
  return error || formField?.error;
};

export const calculateHint = (hint?: string | string[] | null, formField?: FormField<any> | undefined): string | string[] | null | undefined => {
  return hint || formField?.hint;
};

export const calculateSource = (source?: string | string[] | null,
                                formField?: FormField<any> | undefined): string | string[] | null | undefined => {
  return source || formField?.source;
};

export const calculateMaxLength = (maxLength?: number, validationRule?: ValidationRuleType): number | undefined => {
  let calcMaxLength = maxLength;

  if (calcMaxLength === undefined && validationRule !== undefined) {
    calcMaxLength = validationRule.maxLength;
  }

  return calcMaxLength;
};

export const calculateMinValue = (minValue?: number, validationRule?: ValidationRuleType): number | undefined => {
  let calcMinValue = minValue;

  if (calcMinValue === undefined && validationRule !== undefined) {
    calcMinValue = validationRule.minValue;
  }

  return calcMinValue;
};

export const calculateMaxValue = (maxValue?: number, validationRule?: ValidationRuleType): number | undefined => {
  let calcMaxValue = maxValue;

  if (calcMaxValue === undefined && validationRule !== undefined) {
    calcMaxValue = validationRule.maxValue;
  }

  return calcMaxValue;
};

export const calculateRequired = (required?: boolean, validationRule?: ValidationRuleType, createMode?: boolean): boolean | undefined => {
  return required ?? calculateRequiredFromRule(validationRule, createMode);
};

export function calculateRequiredFromRule(validationRule?: ValidationRuleType, createMode: boolean = false): boolean | undefined {
  if (validationRule === undefined) {
    return undefined;
  }
  if (!createMode && validationRule.createModeOnly) {
    return false;
  } else {
    return validationRule.required;
  }
}

export const calculateMinDate = (minDate?: moment.Moment, validationRule?: ValidationRuleType): moment.Moment | undefined => {
  return minDate ?? calculateMinDateFromRule(validationRule);
};

export function calculateMinDateFromRule(validationRule?: ValidationRuleType): moment.Moment | undefined {
  if (validationRule !== undefined && validationRule.typeDateBetween) {
    if (validationRule.compareDateExpression === "TODAY_INTERVAL_DAY") {
      return moment().subtract(validationRule.minValue, "days").startOf("day");
    } else if (validationRule.compareDateExpression === "TODAY_INTERVAL_MONTH") {
      return moment().subtract(validationRule.minValue, "months").startOf("day");
    }
  }
  return undefined;
}

export const calculateMaxDate = (maxDate?: moment.Moment, validationRule?: ValidationRuleType): moment.Moment | undefined => {
  return maxDate ?? calculateMaxDateFromRule(validationRule);
};

export function calculateMaxDateFromRule(validationRule?: ValidationRuleType): moment.Moment | undefined {
  if (validationRule !== undefined && validationRule.typeDateBetween) {
    if (validationRule.compareDateExpression === "TODAY_INTERVAL_DAY") {
      return moment().add(validationRule.maxValue, "days").startOf("day");
    } else if (validationRule.compareDateExpression === "TODAY_INTERVAL_MONTH") {
      return moment().add(validationRule.maxValue, "months").startOf("day");
    }
  }
  return undefined;
}


export function calculateErrorMarkerOnly<T>(
    errorMarkerOnly?: boolean,
    validationRule?: ValidationRuleType,
    formField?: FormField<T>): boolean {
  return errorMarkerOnly
      || validationRule?.errorMarkerOnly
      || formField?.errorMarkerOnly
      || formField?.validationRuleDataStore.getRule(formField!.ruleName)?.errorMarkerOnly
      || false; // instead of undefined
}

export function calculateDisabled(disabled: boolean = false, validationRule?: ValidationRuleType, createMode: boolean = false): true | undefined {
  return nullDeCoalescing(disabled || calculateDisabledFromRule(validationRule, createMode));
}

export function calculateDisabledFromRule(validationRule?: ValidationRuleType, createMode: boolean = false): boolean {
  return createMode && validationRule !== undefined && validationRule.createModeOnly;
}

function nullDeCoalescing(input: boolean): true | undefined {
  return input ? true : undefined;
}


export const calcSelectOptionData = (data: (KeyValueType | KeyResourceDataType | DHLSelectOneOptionType)[]): DHLSelectOneOptionType[] => {
  const calcData: DHLSelectOneOptionType[] = data.map((d): DHLSelectOneOptionType => {
    return {
      key: d.key,
      value: "value" in d ? d.value : null,
      resourceDataKey: "resourceDataKey" in d ? d.resourceDataKey : null,
      disabled: "disabled" in d ? d.disabled : false
    } as DHLSelectOneOptionType;
  });
  return calcData;
};