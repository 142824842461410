/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export function difference<T>(minuend: Set<T> | T[], subtrahend: Set<T> | T[]): T[] {
  const minuendAsArray = Array.isArray(minuend) ? minuend : [...minuend];
  const subtrahendAsArray = Array.isArray(subtrahend) ? subtrahend: [...subtrahend];
  return minuendAsArray.filter(it => !subtrahendAsArray.includes(it));
}

/**
 * Returns if param is not undefined. Can be used in `Array.filter` calls to help the compiler:
 *
 * ```ts
 * const arr1 = ["str", undefined].filter(e => e !== undefined); //arr1 has type (string | undefined)[]
 * const arr2 = ["str", undefined].filter(isNotUndefined); //arr2 has type string[]
 * ```
 *
 * @param {T | undefined} it thing to check
 * @returns {item is T} true, iff it is not undefined
 */
export function isNotUndefined<T>(it: T | undefined): it is T {
  return it !== undefined;
}
