/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLTableCellIconButtonProps } from "./DHLTableCellIconButton";
import React from "react";

export type DHLTableCellIconButtonContextMenuProps = DHLTableCellIconButtonProps & {
  /** Context menu to open on an icon click. */
  contextMenuContent: React.ReactNode;

  /** Context menu should be open */
  contextMenuOpen: (value: any) => boolean;

  /** Invoked when a click event occurs outside the component */
  onClickOutside: (value: any) => void

  /** Placement of opened context menu. */
  placement: "left" | "right";

}

/** Tabellenspaltendefinition - Icon-Button. */
export const DHLTableCellIconButtonContextMenu = (props: DHLTableCellIconButtonContextMenuProps) => {
  return null;
};

DHLTableCellIconButtonContextMenu.defaultProps = {
  type: "iconButtonWithContextMenu",
  contentAlignment: "center",
  disableMinWidth: true,
};
