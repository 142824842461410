/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {ReactNode} from "react";
import classNames from "classnames";

type DialogFormContentProps = {
  children: ReactNode,
  /**
   * if true restricts form content (children) to eight columns in the center, based on grid outside of DHLDialogNonModal
   */
  restrictToEightColumnsFromOuterGrid?: boolean,
  className?: string,
}
export const DialogFormContent = ({
                                    children,
                                    restrictToEightColumnsFromOuterGrid = false,
                                    className
                                  }: DialogFormContentProps) => {
  return <div
      className={classNames(className, "dhlDialog-formContent", restrictToEightColumnsFromOuterGrid ? "restrictToExternalGrid" : null)}>
    {restrictToEightColumnsFromOuterGrid ?
        <div className={"row"}>
          <div className={"col-8 offset-2"} /* bootstrap! */>
            {children}
          </div>
        </div>
        : children
    }
  </div>;
};