/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { UIDConsumer } from "react-uid";
import { Input } from "reactstrap";
import { DateRangePickerDataStore, DHLIcon, DHLLabel } from "../../..";
import "./DHLDateRangeInputField.scss";
import { DHLFieldNote } from "../../atoms/DHLFieldNote/DHLFieldNote";

export type DHLDateRangeInputFieldProps = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen
   *  einzigen Change-Handler verwenden zu können. Wird auch für die Generierung der Test-ID ververwendet. */
  name: string;

  /** CSS-Klassen für das Eingabefeld. */
  className?: string;

  /** Labeltext. */
  label?: string;

  /** Label mit Pflichtfeldmarkierung versehen? */
  required?: boolean;

  /** Deaktivieren? */
  disabled?: boolean;

  /** Funktion für onFocus-Aufrufe. */
  onFocus?: React.FocusEventHandler<any>;
  onChangeStartDate?: React.ChangeEventHandler<any>;
  onChangeEndDate?: React.ChangeEventHandler<any>;
  onCalendarInputFieldClick?: React.MouseEventHandler<any>;
  dataStore: DateRangePickerDataStore;

};

/** Datumseingabe mit integriertem Label. */
export const DHLDateRangeInputField = observer(({
                                                  name,
                                                  className,
                                                  label,
                                                  required, disabled,
                                                  onFocus,
                                                  onChangeStartDate,
                                                  onChangeEndDate,
                                                  onCalendarInputFieldClick,
                                                  dataStore,
                                                }: DHLDateRangeInputFieldProps) => {

  const [dynamicContainerClasses, setDynamicContainerClasses] = useState("");


  const _onReturnPressed = useCallback((event: KeyboardEvent) => {
    const KEY_RETURN = "Enter";
    if (event.key === KEY_RETURN) {
      dataStore.isValidDateRange();
    }
  }, [dataStore.isValidDateRange]);

  useEffect(() => {
    document.addEventListener("keydown", _onReturnPressed);

    return () => {
      document.removeEventListener("keydown", _onReturnPressed);
    };
  }, [_onReturnPressed]);

  const _onFocus = (event: React.FocusEvent) => {
    setDynamicContainerClasses("inputfield-focused");
    if (onFocus && typeof onFocus === "function") {
      onFocus(event);
    }
  };
  const _onBlur = () => {
    dataStore.isValidDateRange();
    setDynamicContainerClasses("");
  };

  const startDateSet = dataStore.startDateFormatted !== "";
  const endDateSet = dataStore.endDateFormatted !== "";
  const calcLabelActive = startDateSet || endDateSet;
  const calcDisabled = disabled ? disabled : false;
  const calcError = !!dataStore.validationError;
  return (<UIDConsumer>
        {uid => (
            <>
              <div data-testid={"dhl-date-range-input-field-container-" + name}
                   className={classNames("dhl-date-range-input-field-container", dynamicContainerClasses, className, calcError ? "invalid" : "")}>
                <Input
                    id={uid + "-start"}
                    name={name + "-date-range-input-field-start-date"}
                    type={"text"}
                    data-testid={"date-range-input-field-start-date"}
                    value={dataStore.startDateFormatted}
                    disabled={calcDisabled}
                    onChange={onChangeStartDate ? onChangeStartDate : () => { /* intended use */ }}
                    onFocus={_onFocus}
                    onBlur={_onBlur}
                    maxLength={10}
                    className={
                      classNames(
                          className,
                          "dhl-date-range-input-field", "dhl-date-range-input-start"
                      )}
                />
                <div className={classNames("dhl-date-range-delimeter")}
                     onClick={onCalendarInputFieldClick}>-
                </div>
                <Input
                    id={uid + "-end"}
                    name={name + "date-range-input-field-end-date"}
                    type={"text"}
                    data-testid={"date-range-input-field-end-date"}
                    value={dataStore.endDateFormatted}
                    disabled={calcDisabled}
                    onChange={onChangeEndDate ? onChangeEndDate : () => { /* intended use */ }}
                    onFocus={_onFocus}
                    onBlur={_onBlur}
                    maxLength={10}
                    className={
                      classNames(
                          className,
                          "dhl-date-range-input-field", "dhl-date-range-input-end"
                      )}
                />
                <DHLLabel name={"date-range-input-field-label"} htmlFor={uid} label={label} required={required}
                          className={classNames("labelCompact",
                              {labelActive: calcLabelActive},
                              {hasError: calcError}
                          )} />
                <div
                    className={classNames("input-function", {
                      "disabled": calcDisabled
                    }, "date-picker")}
                    onClick={calcDisabled ? () => { /* intended use */ } : onCalendarInputFieldClick}
                >
                  <DHLIcon name={"date-range-input-field-function-icon-" + name} icon={"date-picker"} />
                </div>
              </div>
              {calcError &&
                  <div className={classNames("date-range-input-field-error-container")}>
                    <DHLFieldNote name={name + "-date-range-input-field-start-date-error"} type={"error"} notes={dataStore.validationError} />
                  </div>
              }
            </>
        )}
      </UIDConsumer>
  );
});
