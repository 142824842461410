/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect } from "react";
import { observer } from "mobx-react-lite";

export const DHLRedirectTo404 = observer(() => {
  useEffect(() => {
    window.location.href = `/404.html`;
  }, []);

  return null;
});
