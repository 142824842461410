/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import moment, { Moment } from "moment";
import { ValidationRuleType } from "../types/ValidationRuleTypes";
import { logger } from "./logger";

/** Data memory for validation rules of a module. */
export class Validator {

  /** Inelegant way to show invalid Date Input */
  private invalidDateInput = false;

  /**
   * Constructor.
   *
   * @param rule rule that shall be validated.
   */
  constructor(
      private readonly rule: ValidationRuleType
  ) {
  }

  validate = (value: any, additionalArgs: any[]): string | null => {
    logger.log("Validating ", value, this.rule.name, additionalArgs);

    let errorKey = null;

    if (this.rule.required && (value === undefined || value === null || value === "")) {
      errorKey = this.rule.requiredLocalizationName ? this.rule.requiredLocalizationName : this.rule.errorLocalizationName;

      if (!errorKey) {
        errorKey = "framework.error.required";
      }
    } else {
      this.invalidDateInput = false;
      if (!this._applyRule(value, additionalArgs)) {
        errorKey = this.invalidDateInput ? this.rule.invalidDateErrorLocalizationName : this.rule.errorLocalizationName;
      }
    }

    logger.log("Validation result", errorKey);

    return errorKey === undefined ? null : errorKey;
  };

  /**
   * Evaluates the given value.
   *
   * @param value          value to be evaluated
   * @param additionalArgs other parameters
   *
   * @return true true if the check was successful, false if the input is incorrect
   */
  _applyRule = (value: any | null, additionalArgs: any[] | null): boolean => {
    let ok: boolean;

    if (this.rule.typeSingleDate) {
      ok = this._isValidSingleDate(value);
    } else if (this.rule.typeSingleDateTime) {
      ok = this._isValidSingleDateTime(value);
    } else if (this.rule.typeDateBetween) {
      ok = this._isValidDateBetween(value);
    } else if (this.rule.typeCompareDate) {
      ok = this._applyCompareDateRule(value, (additionalArgs ?? [])[0]);
    } else if (this.rule.typeFile) {
      ok = this._isValidFile(value);
    } else if (this.rule.typePlugin) {
      ok = this._isValidPlugin(value, additionalArgs);
    } else if (this.rule.typeBoolean) {
      ok = this._isValidBoolean(value);
    } else {
      ok = this._isValidText(value);
    }

    return ok;
  };

  _applyCompareDateRule = (value: string, additionalArg: string | Date | undefined): boolean => {
    const compareDate: Date | null | undefined = ("TODAY" === this.rule.compareDateExpression)
        ? new Date()
        : this._compareDateFromAdditionalArg(additionalArg);

    if (compareDate) {
      return this._isValidCompareDate(value, compareDate);
    }
    return false;
  };

  // immer moment oder date oder string?
  _compareDateFromAdditionalArg(additionalArg: string | Date | undefined): Date | undefined {
    if (typeof additionalArg === "string") {
      return moment(additionalArg, "L").toDate();
    }
    if (typeof additionalArg === undefined) {
      return undefined;
    } else {
      return additionalArg as Date;
    }
  }


  /**
   * Evaluates the given value asynchronously.
   *
   * @param value          value to be evaluated
   * @param additionalArgs other parameters
   *
   * @return true true if the check was successful, false if the input is incorrect
   */
  _applyRuleAsync = async (value: any | null, additionalArgs: any[] | null): Promise<boolean> => {
    let ok = Promise.resolve(false);

    if (this.rule.typePlugin) {
      ok = this._isValidPluginAsync(value, additionalArgs);
    }

    return ok;
  };

  /**
   * Checks if a date input is a valid date.
   * @param dateInput Date as text
   * @return true if the text is a valid date, otherwise false
   */
  _isValidSingleDate = (dateInput: any): boolean => {
    return moment(dateInput, "L", true).isValid();
  };

  /**
   * Checks if a date input is a valid date time.
   * @param dateTimeInput DateTime as text
   * @return true if the text is a valid date time, otherwise false
   */
  _isValidSingleDateTime = (dateTimeInput: any): boolean => {
    return moment(dateTimeInput, undefined, true).isValid();
  };

  /**
   * Checks if a date input is in an interval.
   * @param dateInput Date as text
   * @return true if the text is a valid date, otherwise false
   */
  _isValidDateBetween = (dateInput: string | Moment): boolean => {
    logger.log("Checking date", dateInput, moment.locale());
    const date = this.dateToMoment(dateInput);

    let ok: boolean = false;

    if (date.isValid()) {
      logger.log("Date format is valid, checking ranges");
      let startDate: Moment | null = null;
      let endDate: Moment | null = null;

      if ("TODAY_INTERVAL_DAY" === this.rule.compareDateExpression) {
        startDate = moment().clone().subtract(this.rule.minValue, "days").startOf("day");
        endDate = moment().clone().add(this.rule.maxValue, "days").startOf("day");
      } else if ("TODAY_INTERVAL_MONTH" === this.rule.compareDateExpression) {
        startDate = moment().clone().subtract(this.rule.minValue, "months").startOf("day");
        endDate = moment().clone().add(this.rule.maxValue, "months").startOf("day");
      }

      if (startDate && endDate) {
        ok = date.isBetween(startDate, endDate, "day", "[]");
      }
    } else {
      logger.log("Date format is invalid");
      this.invalidDateInput = true;
    }

    return ok;
  };

  dateToMoment(dateInput: string | Moment): Moment {
    return (typeof dateInput === "string")
        ? moment(dateInput, "L", true)
        : dateInput;
  }

  /**
   * Checks whether a date entry is a valid date and then compares it with a comparison date based on the rule type.
   * @param dateInput Date as text
   * @param compareDate the comparison date
   * @return true if the text is a valid date, otherwise false
   */
  _isValidCompareDate = (dateInput: string, compareDate: Date): boolean => {
    const date = moment(dateInput, "L", true);
    let ok: boolean = false;

    if (date.isValid()) {
      if (this.rule.typeDateAfter) {
        ok = date.isAfter(compareDate, "day");
      } else if (this.rule.typeDateNotAfter) {
        ok = date.isSameOrBefore(compareDate, "day");
      } else if (this.rule.typeDateBefore) {
        ok = date.isBefore(compareDate, "day");
      } else if (this.rule.typeDateNotBefore) {
        ok = date.isSameOrAfter(compareDate, "day");
      }
    } else {
      this.invalidDateInput = true;
    }

    return ok;
  };

  /**
   * Checks if it is a valid file.
   *
   * @param value file
   *
   * @return true if it is a valid file, otherwise false
   */
  _isValidFile = (value: File | null): boolean => {
    let ok = !this.rule.required;

    if (value != null && this.rule.maxLength) {
      ok = value.size <= this.rule.maxLength;
    }

    return ok;
  };

  /**
   * Checks if the text is valid. The passed value is first converted into a string and then checked.
   *
   * @param value Text
   *
   * @return true if it is a valid text, otherwise false
   */
  _isValidText = (value: string | null): boolean => {
    let textValue = value;
    let ok = false;

    if (value !== null) {
      textValue = String(value);
    }

    if (this.rule.typeAutoRegExp) {
      ok = this._isValidAutoRegExp(textValue);
    } else if (this.rule.typeRegExp) {
      ok = this._isValidRegExp(textValue);
    }

    return ok;
  };

  /**
   * Checks if the text matches the given regular expression.
   *
   * @param text to be checked
   *
   * @return true if the regular expression is true, otherwise false
   */
  _isValidAutoRegExp = (text: string | null) => {
    let ok = !this.rule.required;

    if (text !== null && text.length > 0 && this.rule.rule) {
      const regExp = new RegExp(this.rule.rule);

      ok = regExp.test(text);

      if (ok) {
        ok = this._checkMinMax(text);
      }
    }

    return ok;
  };

  /**
   * Checks if the Boolean has the required value.
   *
   * @param value to be checked
   *
   * @return true if the regular expression is true, otherwise false
   */
  _isValidBoolean = (value: boolean | null) => {
    return (this.rule.allowedValues ?? []).some(v => v === value);
  };

  /**
   * Checks whether the input can be validated by the transferred function.
   *
   * @param value Value to be checked
   * @param additionalArgs further parameters, the first entry contains the function to be called for the validation
   *
   * @return true if the input can be validated by the transferred function, otherwise false
   */
  _isValidPlugin = (value: any | null, additionalArgs: any[] | null): boolean => {
    let ok = !this.rule.required;

    if (value != null && additionalArgs && additionalArgs.length > 0) {
      ok = additionalArgs[0].get(this.rule.validationFunctionName)(value, additionalArgs.length > 1 ? additionalArgs.slice(1) : null);
    }

    return ok;
  };

  /**
   * Checks asynchronously whether the input can be validated by the transferred function.
   *
   * @param value Value to be checked
   * @param additionalArgs further parameters, the first entry contains the function to be called for the validation
   *
   * @return true if the input can be validated by the transferred function, otherwise false
   */
  _isValidPluginAsync = async (value: any | null, additionalArgs: any[] | null): Promise<boolean> => {
    let ok = !this.rule.required;

    if (value != null && additionalArgs && additionalArgs.length > 0) {
      ok = additionalArgs[0].get(this.rule.validationFunctionName)(value, additionalArgs.slice(1));
    }

    return ok;
  };

  /**
   * Checks the min and max values, if they were specified in the rule.
   *
   * @param text input
   *
   * @return true true if the check was successful or no check took place because no values were configured, otherwise false
   */
  _checkMinMax = (text: any): boolean => {
    let ok = true;

    if (this.rule.minValue) {
      if (!text) {
        ok = false;
      } else if (typeof text === "number") {
        ok = this.rule.minValue <= text;
      } else {
        ok = this.rule.minValue <= Number(text);
      }
    }

    if (ok && this.rule.maxValue != null) {
      if (!text) {
        ok = false;
      } else if (typeof text === "number") {
        ok = this.rule.maxValue >= text;
      } else {
        ok = this.rule.maxValue >= Number(text);
      }
    }

    return ok;
  };

  /**
   * Checks if the text matches the given regular expression.
   *
   * @param text to be checked
   *
   * @return true if the regular expression is true, otherwise false
   */
  _isValidRegExp = (text: string | null): boolean => {
    let ok = !this.rule.required;

    if (text && this.rule.rule) {
      ok = text.match(this.rule.rule) != null;
    }

    return ok;
  };
}