import classNames from "classnames";
import { ReactElement, useState } from "react";
import "./DHLTabNavigationItem.scss";
import { normalizeName } from "../../../utils/stringUtils";
import { DHLTooltip } from "../../molecules/DHLTooltip/DHLTooltip";

export type TabItemType = "primary" | "secondary";

export type DHLTabNavigationItemProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** this tab's id. User for comparing against selected in container and for stringly-typed-clickevents */
  tabId: string;

  /** Tab's label */
  label?: string | React.ReactNode;

  /** Tab's icon  (could become complete formatted label?) */
  icon?: ReactElement,

  /** identificator of active tab in an enclosing container. Used to compare against this tab's id */
  activeTabId: string;

  /** Tab deaktiviert? */
  disabled?: boolean;

  /** Display type (primary | secondary) */
  displayType?: TabItemType;

  /** onClick-Handler. */
  onClickTabItem: (event: React.MouseEvent, tabId: string) => void;

  /** CSS-Klassen. */
  className?: string;

  /** Tooltiptext. */
  tooltip?: string | React.ReactNode;
};

/** Tab-Navigationseintrag. */
export const DHLTabNavigationItem = ({
                                       name,
                                       tabId,
                                       label: plaintextLabel,
                                       icon,
                                       activeTabId,
                                       disabled,
                                       displayType,
                                       onClickTabItem,
                                       className,
                                       tooltip
                                     }: DHLTabNavigationItemProps) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const onClick = (evt: React.MouseEvent): void => {
    evt.preventDefault();

    onClickTabItem(evt, tabId);
  };

  const label = <>{plaintextLabel}{icon}</>;

  const iconOnlyClassName = icon && !plaintextLabel ? "iconOnly" : null;

  const notPlainTextClass = typeof plaintextLabel !== "string" ? "notPlainTextLabel" : null;

  const tabStatus: "selected" | "selectable" | "notSelectable" | "hidden" = (function () {
    if (tabId === activeTabId) {
      return "selected";
    }
    if (disabled) {
      return "notSelectable";
    }
    return "selectable";
  })();

  const getClassNameForActiveItem = () => {
    switch (displayType) {
      case "primary":
        return classNames("tab-item", "tab-item-primary", "tab-item-primary-active", {disabled: disabled}, iconOnlyClassName, notPlainTextClass);
      case "secondary":
        return classNames("tab-item", "tab-item-secondary", "tab-item-secondary-active", {disabled: disabled}, iconOnlyClassName, notPlainTextClass);
      default:
        return classNames("nav-item", {disabled: disabled});
    }
  };

  const getClassNameForInactiveItem = () => {
    switch (displayType) {
      case "primary":
        return classNames("tab-item", "tab-item-primary", {disabled: disabled}, iconOnlyClassName, notPlainTextClass);
      case "secondary":
        return classNames("tab-item", "tab-item-secondary", {disabled: disabled}, iconOnlyClassName, notPlainTextClass);
      default:
        return classNames("nav-item", {disabled: disabled});
    }
  };

  const renderActiveItem = () => {
    return (
        (tabStatus === "selected" || tabStatus === "notSelectable") && (
            // eslint-disable-next-line
            <li data-testid={name} className={getClassNameForActiveItem()} onClick={tabStatus === "selected" ? e => onClick(e) : undefined}>
              <a data-testid={name + "-link"} className={displayType ? "" : "nav-link active"} data-toggle="tab">
                {label}
              </a>
            </li>
        )
    );
  };

  const renderInactiveItem = () => {
    return (
        (tabStatus === "selectable") && (
            // eslint-disable-next-line
            <li data-testid={name} className={getClassNameForInactiveItem()} onClick={e => onClick(e)}>
              <a data-testid={name + "-link"} className={displayType ? "" : "nav-link"} data-toggle="tab">
                {label}
              </a>
            </li>
        )
    );
  };

  const normalizedName = normalizeName(name ?? "");

  return (
      <div id={"container-" + normalizedName} className={classNames(className ? className + "-container" : null)}>
        {renderActiveItem()}
        {renderInactiveItem()}
        {tooltip &&
        <DHLTooltip testid={normalizedName + "-tooltip"} placement={"top"} tooltipOpen={tooltipOpen} target={"container-" + normalizedName}
                    toggle={toggle}>
          {tooltip}
        </DHLTooltip>}
      </div>
  );
};
