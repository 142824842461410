import {observer} from "mobx-react-lite";
import {DateFormatType, TimeFormatType} from "../../../types/DataTypes";
import {formatDate, formatDateTime, formatTime} from "../../../utils/formatter";

export type DHLTableColDateTimeProps = {
  /** Anzuzeigender Wert. */
  value?: any;

  /** Typ. */
  type?: "dateTime" | "date" | "time";

  /** Formatierung für Datumsanteil. */
  dateFormat?: DateFormatType;

  /** Formatierung für Uhrzeitanteil. */
  timeFormat?: TimeFormatType;

  /** Funktion zur spezifischen Formatierung. */
  formatter?: any;
};

/** Ausgabe einer Tabebellenzelle - Datum/Zeit. */
export const DHLTableColDateTime = observer(({value,
                                               type = "dateTime",
                                               dateFormat = "DD.MM.YYYY",
                                               timeFormat = "HH:mm:ss",
                                               formatter}: DHLTableColDateTimeProps) => {
  let output = value;

  if (output) {
    if (formatter) {
      if (typeof value === "number") {
        output = formatter(new Date(value));
      } else {
        output = formatter(value);
      }
    } else {
      if ("dateTime" === type) {
        output = formatDateTime(output, dateFormat, timeFormat);
      } else if ("date" === type) {
        output = formatDate(output, dateFormat);
      } else {
        output = formatTime(output, timeFormat);
      }
    }
  }

  return <>{output}</>;
});
