/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DataFilterStore, DataFilterStoreContext } from "../stores/DataFilterStore";
import { useContext } from "react";

export const useDataFilterStore = <T>() => {
  const dataFilterStore = useContext<DataFilterStore<T> | undefined>(DataFilterStoreContext);

  if (dataFilterStore === undefined) {
    throw new Error("useDataFilterStore hook must be used inside DataFilterStoreContext provider");
  }

  return dataFilterStore;
};