import {observer} from "mobx-react-lite";


export type DHLTableColResourceKeyProps = {
  /** Funktion zur Auflösung von Resource-Keys. */
  resolve: (msg: string) => string;

  /** Anzuzeigender Wert. */
  value?: any;
};

/** Ausgabe einer Tabebellenzelle - Übersetzung von Keys in Klartexte Wert. */
export const DHLTableColResourceKey = observer(({resolve, value}: DHLTableColResourceKeyProps) => {
  return <>{value && resolve(value)}</>;
});
