/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useContext } from "react";
import { AuthenticationManagerContext } from "./AuthenticationManagerContext";
import { AuthenticationManager } from "@gkuis/gkp-authentication";

export const useAuthenticationManager = () => {
  const authenticationManager: AuthenticationManager | undefined = useContext(AuthenticationManagerContext);

  if (authenticationManager === undefined) {
    throw new Error("useAuthenticationManager hook must be used inside AuthenticationManagerContext provider");
  }

  return authenticationManager;
};
