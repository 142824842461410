/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, flow, makeObservable } from "mobx";
import { KeyValueType, LanguageType } from "../types/DataTypes";
import { ParameterizedMessageType } from "../types/ResourceDataStoreTypes";
import { FetchAdapter } from "../utils/fetch/FetchAdapter";
import { LocalizationManager } from "./LocalizationManager";
import { LocalizationPack } from "./LocalizationPack";
import { ResourceDataClient } from "../clients/ResourceDataClient";
import { SelectionListClient } from "../clients/SelectionListClient";
import { SelectionListManager } from "./SelectionListManager";
import { SelectionListPack } from "./SelectionListPack";
import { AuthenticationManagerImpl2 } from "@gkuis/gkp-authentication";

/** Data store for the localization of a module. */
export class ResourceDataStore {

  /**
   * Constructor.
   * @param fetchAdapter the FetchAdapter to use
   * @param baseUrl base url of the backend
   * @param language country ISO-Code -- deprecated
   * @param module text for the modul loading
   * @param listsRequired
   * @param selectionListManager
   * @param localizationManager
   */
  constructor(
      private readonly fetchAdapter: FetchAdapter,
      baseUrl: string,
      language: LanguageType,
      private readonly module: string,
      private readonly listsRequired: boolean = false,
      private readonly selectionListManager: SelectionListManager = new SelectionListManager(
          new SelectionListClient(fetchAdapter, baseUrl),
          fetchAdapter.authenticationManager || new AuthenticationManagerImpl2("url", "realm", "clientId")
      ),
      private readonly localizationManager: LocalizationManager = new LocalizationManager(new ResourceDataClient(fetchAdapter, baseUrl))
  ) {
    makeObservable(this, {
      language: computed,
      messageMap: computed,
      selectionLists: computed,
      loading: computed,
      initialLoading: computed,
      hasData: computed,
      loadedLocalizations: computed,
      loadedSelectionLists: computed,
      warnings: computed,
      selectionListPack: computed,
      localizationPack: computed,
      load: action,
      isLoading: computed,
      loadLocalizations: action,
      loadSelectionLists: action
    });
  }

  /** Current language. */
  get language(): LanguageType {
    // TODO Temporary solution. Define an authManager-property instead
    const authManager = this.fetchAdapter.authenticationManager ?? new AuthenticationManagerImpl2("url", "realm", "clientId");
    return authManager.language;
  }

  /** Mapping Keys on text. */
  get messageMap(): Map<string, string> {
    return this.localizationPack.localizations;
  }

  /** Mapping Selection list names on their content. */
  get selectionLists(): Map<string, KeyValueType[]> {
    return this.selectionListPack.selectionLists;
  }

  /** Gibt an, wie viele Dinge gerade noch geladen werden (müssen). */
  get loading(): number {
    const howMuchIsLoaded =
        (this.loadedLocalizations ? 1 : 0)
        + (this.loadedSelectionLists ? 1 : 0);
    return this.initialLoading - howMuchIsLoaded;
  }

  /** Gibt an, wie viele Dinge geladen werden müssen */
  get initialLoading(): number {
    return this.listsRequired
        ? 2
        : 1;
  }

  get hasData(): boolean {
    return this.loadedLocalizations;
  }

  /** Loaded locations? */
  get loadedLocalizations(): boolean {
    return this.localizationPack.hasData;
  }

  /** Selection lists already loaded? */
  get loadedSelectionLists() {
    return this.selectionListPack.hasData;
  }

  /** Set of keys for which no plaintext was found. */
  get warnings(): Set<string> {
    return this.localizationPack.warnings;
  }

  /**
   * Das SelectionListPack dieses ResourceDataStores.
   * @returns {SelectionListPack} SelectionListPack dieses ResourceDataStores
   */
  get selectionListPack(): SelectionListPack {
    return new SelectionListPack(this.selectionListManager.selectionLists)
  }

  /**
   * Das LocalizationPack dieses ResourceDataStores.
   * @returns {LocalizationPack} LocalizationPack dieses ResourceDataStores
   */
  get localizationPack(): LocalizationPack {
    return new LocalizationPack(this.module, this.localizationManager.localizations);
  }

  /**
   * Texte und Auswahllisten vom Server laden.
   */
  async load(): Promise<void> {
    await Promise.all([this.loadLocalizations(), this.loadSelectionLists()]);
  }

  getScopedMessage(key: string, params?: string[]) {
    return this.localizationPack.getScopedMessage(key, params);
  }

  /**
   * Msg-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getMsg(key: string, params?: string[]): string {
    return this.localizationPack.getMsg(key, params);
  }

  /**
   * Label-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getLabel(key: string, params?: string[]): string {
    return this.localizationPack.getLabel(key, params);
  }

  getSelectionListOptionLabel(key: string): string{
    return this.localizationPack.getSelectionListOptionLabel(key);
  }

  /**
   * Tooltip-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getTooltip(key: string, params?: string[]): string {
    return this.localizationPack.getTooltip(key, params);
  }

  /**
   * Placeholder-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getPlaceholder(key: string, params?: string[]): string {
    return this.localizationPack.getPlaceholder(key, params);
  }

  /**
   * Button-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getButton(key: string, params?: string[]): string {
    return this.localizationPack.getButton(key, params);
  }

  /**
   * Error-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getError(key: string, params?: string[]): string {
    return this.localizationPack.getError(key, params);
  }

  /**
   * Button-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getSuccess(key: string, params?: string[]): string {
    return this.localizationPack.getSuccess(key, params);
  }

  /**
   * Ermittelt den lokalisierten Text zum Schlüssel.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getMessage(key: string, params?: string[] | null): string {
    return this.localizationPack.getMessage(key, params || undefined);
  }

  /**
   * Ermittelt alle lokalisierten Texte.
   * @param msgs Array mit Objekten im Format {messageKey: "", params: ""}
   * @returns Array mit den  Meldungen und ersetzten Platzhaltern
   */
  getMessages(msgs: ParameterizedMessageType[]) {
    return this.localizationPack.getMessages(msgs);
  }

  /**
   * Ermittelt den Inhalt für die Auswahlliste mit dem angebenen Namen.
   * @param name Name der Auswahlliste
   * @return Array mit den Optionen der Liste
   */
  getSelectionList(name: string): KeyValueType[] | undefined {
    return this.selectionListPack.getSelectionList(name);
  }

  getHelp(key: string, params?: string[]): string {
    return this.localizationPack.getHelp(key, params);
  }

  /** Gibt an, ob die Lokalisierungen und Auswahllisten noch geladen werden (müssen). */
  get isLoading(): boolean {
    return this.loading > 0;
  }

  /**
   * Texte vom Server laden.
   */
  loadLocalizations = flow(function* loadLocalizations(this: ResourceDataStore) {
    if (this.loadedLocalizations || this.language === null) {
      return;
    }

    yield this.localizationManager.loadLocalizations(this.module);
  });

  /**
   * Auswahllisten vom Server laden.
   */
  loadSelectionLists = flow(function* loadSelectionLists(this: ResourceDataStore) {
    if (!this.listsRequired) {
      return;
    }
    if (this.loadedSelectionLists) {
      return;
    }
    yield this.selectionListManager.loadSelectionLists();
  });
}
