
import { UIDConsumer } from "react-uid";
import { Input } from "reactstrap";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import { DHLLabel } from "../../atoms/DHLLabel/DHLLabel";
import { DHLFieldNote } from "../../atoms/DHLFieldNote/DHLFieldNote";
import { KeyValueType } from "../../../types/DataTypes";

export type DHLSelectManyProps = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen einzigen Change-Handler verwenden zu
   * können. Wir dauch für die Generierung der Test-ID ververwendet. */
  name: string;

  /** Labeltext */
  label?: string;

  /** CSS-Klassen für das Label. */
  labelClassName?: string;

  /** CSS-Klassen für das Eingabefeld. */
  className?: string;

  /** Label mit Pflichtfeldmarkierung versehen. */
  required?: boolean;

  /** Cursor im Eingabefeld positionieren? */
  autoFocus?: boolean;

  /** Autovervollständig erlaubt? */
  autoCompleteOff?: boolean;

  /** Bearbeitungsmodus des Formulars (true = Neuanalage, false = Bearbeitung). Der Pflichtfeldstatus des Eingabefeldes kann vom Bearbeitungsmodus
   * abhängen.  */
  createMode?: boolean;

  /** Deaktivieren? */
  disabled?: boolean;

  /** Element ausgeben? */
  render?: boolean;

  /** Funktion für onChange-Aufrufe. */
  onChange: React.ChangeEventHandler<HTMLElement>;

  /** Eingabewerte (optional damit Storybook usw. Eingaben zulassen). */
  value?: any[];

  /** Validierungsregel mit Konfiurationsdaten für das Eingabefeld. */
  validationRule?: ValidationRuleType;

  /** Verfügbare Optionen. */
  data: KeyValueType[];

  /** Fehlertext(e), wenn ein oder mehrere Fehler aufgetreten sind. */
  error?: string | string[] | null;

  /** Nur das Eingabefeld markieren oder auch Fehlertexte anzeigen? */
  errorMarkerOnly?: boolean;
};

/** Auswahlliste für Mehrfachauswahlen. */
export const DHLSelectMany = ({
                                name,
                                label,
                                labelClassName,
                                className,
                                data,
                                required,
                                autoFocus,
                                autoCompleteOff,
                                createMode,
                                disabled,
                                render = true,
                                value,
                                error,
                                errorMarkerOnly,
                                validationRule,
                                onChange
                              }: DHLSelectManyProps) => {
  if (!render) {
    return null;
  }

  let calcRequired = required;
  let calcErrorMarkerOnly = errorMarkerOnly;
  let calcDisabled = disabled;

  if (calcRequired === undefined && validationRule !== undefined) {
    if (!createMode && validationRule.createModeOnly) {
      calcRequired = false;
    } else {
      calcRequired = validationRule.required;
    }
  }

  if (calcErrorMarkerOnly === undefined && validationRule !== undefined) {
    calcErrorMarkerOnly = validationRule.errorMarkerOnly;
  }

  if (disabled === undefined && createMode !== undefined && validationRule !== undefined && validationRule.createModeOnly) {
    calcDisabled = true;
  }

  const output = (
      <UIDConsumer>
        {(uid, itemUid) => (
            <div>
              {label && <DHLLabel name={name + "-label"} htmlFor={uid} label={label} required={calcRequired} className={labelClassName} />}
              <Input
                  id={uid}
                  data-testid={name}
                  type="select"
                  value={value}
                  onChange={onChange}
                  className={className}
                  autoComplete={autoCompleteOff ? "off" : ""}
                  autoFocus={autoFocus}
                  disabled={calcDisabled}
                  invalid={error !== null && error !== undefined && error.length > 0}
                  multiple
              >
                {data.map((option, index) => (
                    <option key={itemUid(option, index)} value={option.key}>
                      {option.value}
                    </option>
                ))}
              </Input>
              {!calcErrorMarkerOnly && error && <DHLFieldNote name={name + "-error"} type={"error"} notes={error} />}
            </div>
        )}
      </UIDConsumer>
  );

  return <>{output}</>;
};
