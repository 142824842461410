/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { DHLIcon } from "../../..";
import "./DHLMailIcon.scss";

export type DHLMailIconProps = {
  /** Name for generation of test-id */
  name: string;

  /** Amount of items shown in counter */
  counterValue: number;
};

export const DHLMailIcon = observer(({counterValue}: DHLMailIconProps) => {

    const getClassName = () => {
      if (counterValue === 0) {
        return;
      } else if (counterValue < 10 && counterValue > 0) {
        return classNames("dhlMailIcon-badge-round", "badge rounded-pill badge-notification bg-danger");
      } else {
        return classNames("dhlMailIcon-badge", "badge rounded-pill badge-notification bg-danger");
      }
    };

    const getCounterValueString = () => {
      if (counterValue === 0) {
        return "";
      } else if (counterValue < 11 && counterValue > 0) {
        return String(counterValue);
      } else {
        return "10+";
      }
    };

    return (
      <div className={"dhlMailIcon"}>
        <DHLIcon name={"mail"} icon={"e-mail"} />
        <sup><span className={getClassName()}>{getCounterValueString()}</span></sup>
      </div>
    );
  })
;