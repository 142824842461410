import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {DHLIcon} from "../../..";
import "./DHLCriteriaCatalog.scss";

export type Criterion = {
  description: string
  state: boolean | null
}

export type DHLCriteriaCatalogProps = {
  title: string
  criteria: Criterion[]
};

/** Output of success and error messages. */
export const DHLCriteriaCatalog = observer((
    {
      title,
      criteria,
    }: DHLCriteriaCatalogProps
) => {
  return <div className="dhlCriteriaCatalog">
    <div>{title}</div>
    <ul>
      {criteria.map((value, index) => {
        return <li key={index} className={classNames("criterion",{
          "unknown": value.state === null,
          "passed": value.state === true,
          "failed": value.state === false,
        })}>{mapStateToIcon(value.state)}<span>{value.description}</span></li>
      })}
    </ul>
  </div>
});


const mapStateToIcon = (state: boolean | null) => {
  return state || state === null ? <DHLIcon name={""} icon={"check"} /> : <DHLIcon name={""} icon={"no-service"} />;
};