/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { TableCellType } from "../components/atoms/DHLTableCell/DHLTableCell";
import { extractEarliestValueOfArrayOrObject } from "./extractEarliestValueOfArrayOrObject";
import { extractObjectData } from "./extractObjectData";
import { ResourceDataStore } from "../stores/ResourceDataStore";
import { TFunction } from "i18next";

export type SortFunction<T> = (a: T, b: T) => number;

export enum SortOrder {
  ASC = 1,
  DESC = -1
}

const dynamicCellSort = <T>(property: string, sortOrder: SortOrder): SortFunction<T> =>
    (a: T, b: T): number => (extractObjectData(a, property) - extractObjectData(b, property)) * sortOrder;

const dynamicStringSort = <T>(property: string, sortOrder: SortOrder): SortFunction<T> =>
    (a: T, b: T): number => {
      const dataA = extractObjectData(a, property) ?? "";
      const dataB = extractObjectData(b, property) ?? "";

      const compareDataA = extractEarliestValueOfArrayOrObject(dataA);
      const compareDataB = extractEarliestValueOfArrayOrObject(dataB);

      let result;
      if ("boolean" === typeof compareDataA) {
        result = compareDataA === compareDataB ? 0 : compareDataA ? -1 : 1;
      } else if ("number" === typeof compareDataA) {
        result = compareDataA - compareDataB;
      } else {
        result = (compareDataA).localeCompare(compareDataB);
      }
      return result * sortOrder;
    };

const dynamicResourceKeySort = <T>(resourceDataStore: ResourceDataStore | TFunction, property: string, sortOrder: SortOrder): SortFunction<T> =>
    (a: T, b: T): number => {
      const t = typeof resourceDataStore === "function"
          ? resourceDataStore
          : resourceDataStore.getMessage.bind(resourceDataStore);
      const dataA = t(extractObjectData(a, property));
      const dataB = t(extractObjectData(b, property));
      const result = dataA.localeCompare(dataB);

      return result * sortOrder;
    };

/**
 * Returns a function that returns an IResourceDataStore (optional), a property name,
 * and a sort order to a function for sorting an array of objects by the specified property name.
 * @param type Type of the column to sort by
 */
export const getColSorterForType = <T>(type: TableCellType) => (
    resourceDataStore: ResourceDataStore | TFunction | null,
    propertiesName: string,
    sortOrder: SortOrder
): SortFunction<T> => {
  if ("text" === type || "custom" === type || "link" === type) {
    return dynamicStringSort(propertiesName, sortOrder);
  } else if ("resourceKey" === type && resourceDataStore) {
    return dynamicResourceKeySort(resourceDataStore, propertiesName, sortOrder);
  } else {
    return dynamicCellSort(propertiesName, sortOrder);
  }
};