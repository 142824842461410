/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect, useState } from "react";

const isDesktopWidth = (size: number) => size >= 1024;
const isTabletWidth = (size: number) => size < 1024 && size >= 640;

export type Viewport = "desktop" | "tablet" | "smartphone"

const determineViewportType = (size: number): Viewport => {
  if (isDesktopWidth(size)) {
    return "desktop";
  } else if (isTabletWidth(size)) {
    return "tablet";
  } else {
    return "smartphone";
  }
};

export const useViewportWidth = (active: boolean = true) => {
  const [viewportWidth, setViewportWidth] = useState<number | null>(active ? window.innerWidth : null);

  useEffect(() => {
    if (!active) {
      setViewportWidth(null);
      return () => {};
    }
    setViewportWidth(window.innerWidth);
    const listener = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [setViewportWidth, active]);

  return viewportWidth;
}


export const useCurrentViewportType = () => {
  const viewportWidth = useViewportWidth(true);
  return determineViewportType(viewportWidth!);
};

export const useIsDesktopViewport = () => {
  const viewport = useCurrentViewportType();
  return viewport === "desktop";
};

export const useIsTabletViewport = () => {
  const viewport = useCurrentViewportType();
  return viewport === "tablet";
};

export const useIsSmartphoneViewport = () => {
  const viewport = useCurrentViewportType();
  return viewport === "smartphone";
};
