
import {Form} from "reactstrap";
import { ReactNode } from "react";

export type DHLFormProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;

  /** Inline? */
  inline?: boolean;

  /** CSS Klassen. */
  className?: string;

  /** Element ausgeben? */
  render?: boolean;

  /** Kind-Komponenten .*/
  children: ReactNode;
};

/** Formular. */
export const DHLForm = ({name, className, inline, render = true, children}: DHLFormProps) => {
  if (!render) {
    return null;
  }

  return (
      <Form data-testid={name} className={className} inline={inline}>
        {children}
      </Form>
  );
};
