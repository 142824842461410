
import {Row} from "reactstrap";
import { ReactNode } from "react";

export type DHLRowProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;

  /** Keine Abstände. */
  noGutters?: boolean;

  /** Verwendung im Formular. */
  form?: boolean;

  /** CSS-Klassen. */
  className?: string;

  /** Element ausgeben? */
  render?: boolean;

  /** Kind-Komponenten */
  children?: ReactNode;
};

/** Zeile eines Bootstrap Grids. */
export const DHLRow = ({name, noGutters, form, className, render = true, children}: DHLRowProps) => {
  if (!render) {
    return null;
  }

  return (
      <Row data-testid={name} noGutters={noGutters} form={form} className={className}>
        {children}
      </Row>
  );
};
