/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
export function * integers(first: number): Iterator<number> {
  let index = first;
  while (true) {
    yield index;
    index = index + 1;
  }
}
