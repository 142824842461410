/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {observer} from "mobx-react-lite";
import { logger } from "../../../utils/logger";

export type DHLTableColCustomProps = {
  /** Anzuzeigender Wert. */
  value?: any;

  /** ID der Zelle. */
  cellId: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: (value: boolean, cellId: string, idPropertyName: string, colPropertyName: string) => void;

  /** Name des Properties mit der ID des Datensatzes. */
  idPropertyName?: string;

  /** Name des Properties dessen Daten in dieser Spalte angezeigt werden. */
  colPropertyName?: string;

  /** Gesamte Zeile. */
  row: Object;

  /** Funktion zum Rendern des Zelleninhalts. */
  customRenderer: (value: any, row: Object, cellId: string) => any;

};

/** Ausgabe einer Tabebellenzelle - freie Ausgabe. */
export const DHLTableColCustom = observer(({value,
                                             cellId,
                                             onChange,
                                             idPropertyName,
                                             colPropertyName,
                                             row,
                                             customRenderer}: DHLTableColCustomProps) => {
  let output = value;

  if (onChange && !(idPropertyName && colPropertyName)) {
    logger.error("Missing parameters, need names of id and col properties when using onChange");
  }

  if (output !== undefined && output !== null) {
    if (onChange && idPropertyName && colPropertyName) {
      // not yet implemented
    } else {
      output = <div data-testid={cellId}>{customRenderer(value, row, cellId)}</div>;
    }
  }

  return <>{output}</>;
});
