/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FormField } from "../../../stores/FormField";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import { calculateMaxValue, calculateMinValue, calculateValidationRule } from "../../../utils/calcPropertiesValue";
import { formatNumber, isAnyValidFloat, parseFormattedFloat } from "../../../utils/formatter";
import { logger } from "../../../utils/logger";
import { DHLTextInput } from "../../molecules/DHLTextInput/DHLTextInput";
import { DHLIcon } from "../DHLIcon/DHLIcon";
import "./DHLInputCounter.scss";
import { useLocaleManager } from "../../../locale/useLocaleManager";

const LOG_MODULE = "DHLInputCounter";

export type DHLInputCounterProps = {
  /** Form field containing the string for the number value */
  formField: FormField<number>

  //** The mode of in-/decrementation
  // ADD/SUB the stepValue | STEP to the next/previous increment according to stepValue */
  stepMode?: "addition" | "steps"

  /** The value to increment or decrement by */
  stepValue: number

  /** The minimum value of the input field. If not set formField value will be used if present */
  minValue?: number

  /** The maximum value of the input field. If not set formField value will be used if present */
  maxValue?: number

  /** Validation rule with configuration data for the input field. */
  validationRule?: ValidationRuleType;

  /** A number value of which the formatted value will be used as a reference for the minimum width the input field will have.*/
  minWidthByValue?: number;

  /** True if the component shall be displayed disabled */
  disabled?: boolean
}

export const DHLInputCounter = observer(({
                                           formField,
                                           stepMode = "steps",
                                           stepValue,
                                           minValue,
                                           maxValue,
                                           validationRule,
                                           minWidthByValue = 0,
                                           disabled = false
                                         }: DHLInputCounterProps) => {
  const localeManager = useLocaleManager();
  const formatValue = (value: number) => formatNumber(localeManager, value, 2, 2, true);
  const calcValidationRule = calculateValidationRule(validationRule, formField);
  const calcMinValue = calculateMinValue(minValue, calcValidationRule) ?? Number.MIN_VALUE;
  const calcMaxValue = calculateMaxValue(maxValue, calcValidationRule) ?? Number.MAX_VALUE;

  const formattedMinWidthByValue = formatValue(minWidthByValue);
  const minWidth = formattedMinWidthByValue.length + 2;
  const formattedMaxValue = formatValue(calcMaxValue);
  const maxWidth = formattedMaxValue.length + 2;

  useEffect(() => {
    const formattedValue = formatValue(formField.value);
    const formattedValueLength = formattedValue.length + 2;
    let calculatedWidth = Math.max(formattedValueLength, minWidth);
    calculatedWidth = Math.min(calculatedWidth, maxWidth);
    const inputLength = calculatedWidth + "ch";
    document.documentElement.style.setProperty("--inputSize", inputLength);
    return () => {
      document.documentElement.style.removeProperty("--inputSize");
    };
  }, [undefined, formField, formField.value]);

  const [currentInput, setCurrentInput] = useState<string | null>(null);

  const incrementValue = () => {
    const currentValue = formField.value;
    let newValue: number;
    switch (stepMode) {
      case "addition":
        newValue = currentValue + stepValue;
        break;
      case "steps":
        const multiplier = Math.floor(currentValue / stepValue);
        newValue = (multiplier + 1) * stepValue;
        break;
      default:
        logger.error(LOG_MODULE, `Invalid value for stepMode: ${stepMode}`);
        return;
    }
    updateFormField(newValue);
  };

  const decrementValue = () => {
    const currentValue = formField.value;
    let newValue: number;
    switch (stepMode) {
      case "addition":
        newValue = currentValue - stepValue;
        break;
      case "steps":
        const multiplier = Math.floor(currentValue / stepValue);
        const modifier = currentValue % stepValue === 0 ? 1 : 0;
        newValue = (multiplier - modifier) * stepValue;
        break;
      default:
        logger.error(LOG_MODULE, `Invalid value for stepMode: ${stepMode}`);
        return;
    }
    updateFormField(newValue);
  };

  const updateFormField = (value: number) => {
    let newValue = value;
    if (value > calcMaxValue) {
      newValue = calcMaxValue;
    } else if (value < calcMinValue) {
      newValue = calcMinValue;
    }
    formField.updateValue(newValue);
  };

  const renderDecrementButton = () => {
    const isDisabled = disabled || formField.value <= calcMinValue;
    return (
        <DHLIcon
            name={"decrementButton"}
            className={classNames("button-decrement", isDisabled ? "disabled" : "")}
            icon={"minus"}
            onClick={() => {
              if (!isDisabled) {
                decrementValue();
              }
            }} />
    );
  };

  const renderIncrementButton = () => {
    const isDisabled = disabled || formField.value >= calcMaxValue;
    return (
        <DHLIcon
            name={"incrementButton"}
            className={classNames("button-increment", isDisabled ? "disabled" : "")}
            icon={"plus"}
            onClick={() => {
              if (!isDisabled) {
                incrementValue();
              }
            }} />
    );
  };

  const updateValueFromInput = (inputValue: string) => {
    if (isAnyValidFloat(inputValue, 2)) {
      updateFormField(parseFormattedFloat(inputValue));
    }
    setCurrentInput(null);
  };

  const renderTextInput = () => {
    return (
        <DHLTextInput
            name="inputCounter-inputField"
            className={classNames("inputCounter-inputField")}
            value={currentInput !== null ? currentInput : formatValue(formField.value)}
            onChange={event => setCurrentInput(event.target.value)}
            onBlur={event => updateValueFromInput(event.target.value)}
            disabled={disabled} />
    );
  };

  return (
      <div className={"inputCounter"}>
        {renderDecrementButton()}
        {renderTextInput()}
        {renderIncrementButton()}
      </div>
  );
});
