/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import SunEditor from "suneditor-react";
import { FormField } from "../../../stores/FormField";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import { calculateError, calculateMaxLength, calculateValidationRule } from "../../../utils/calcPropertiesValue";
import { DHLFieldNote } from "../../atoms/DHLFieldNote/DHLFieldNote";
import "./SunEditor.scss";

export type DHLWysiwygEditorProps = {
  /** Name of the editor */
  name: string;

  /** Width of editor */
  width?: string;

  /** Height of editor*/
  height?: string;

  /** Initial text shown in the editor*/
  // Placeholder results in error with jest tests

  /** Buttons for the editor*/
  buttons?: string[][];

  /** Whitelist for html tags.
   *
   * Caution: These tags will only be ADDED to suneditor's internal whitelist.
   * To blacklist from this default use 'tagsBlacklist'.
   *
   * Default: Empty
   *
   * default whitelist from suneditor:
   * br|p|div|pre|blockquote|h1|h2|h3|h4|h5|h6|ol|ul|li|hr|figure|figcaption|img|iframe|audio|video|table|thead|tbody|tr|th|td|a|b|strong|
   * var|i|em|u|ins|s|span|strike|del|sub|sup|code|svg|path
   */
  tagsWhitelist?: string;

  /** Blacklist for html tags.
   *
   * Default:
   * pre|blockquote|h1|h2|h3|h4|h5|h6|iframe|audio|video|ins|del|code|path
   */
  tagsBlacklist?: string;

  /** Event handler called when focus is removed from editor. */
  onBlur?: (event: FocusEvent, editorContents: string) => void;

  /** If true the editor is disabled */
  disabled?: boolean;

  /** Form field with Name, Label, Value, Validationrule and error text.*/
  formField: FormField<any>;

  /** Validationrule configuration */
  validationRule?: ValidationRuleType;

  /** Errortext in case of errors */
  error?: string | string[] | null;

  /** maximal number of characters */
  maxLength?: number;

  /** function to be called when code view is active */
  toggleCodeView?: (isCodeView: boolean) => void;
};

/** DHLWysiwygEditor */
export const DHLWysiwygEditor = observer(({
                                            name,
                                            width = "100%",
                                            height = "300px",
                                            buttons = [
                                              ["undo", "redo"],
                                              ["bold", "italic", "underline", "strike", "superscript", "subscript", "fontColor", "hiliteColor",
                                                "removeFormat"],
                                              ["align", "outdent", "indent", "horizontalRule", "list", "table"],
                                              ["link", "image"],
                                              ["fullScreen", "codeView"]
                                            ],
                                            tagsWhitelist = "",
                                            tagsBlacklist = "pre|blockquote|h1|h2|h3|h4|h5|h6|iframe|audio|video|ins|del|code|path",
                                            disabled,
                                            onBlur,
                                            formField,
                                            validationRule,
                                            error,
                                            maxLength,
                                            toggleCodeView,
                                          }: DHLWysiwygEditorProps) => {

    const calcValidationRule = calculateValidationRule(validationRule, formField);
    const calcError = calculateError(error, formField);
    const calcMaxLength = calculateMaxLength(maxLength, calcValidationRule);
    const calcValue = formField.value;

    const onChange = (content: string) => {
      formField.value = content;
    };

    function onBlurWithSetFormFieldContent(event: FocusEvent, editorContents: string) {
      // in html mode, formField value must be set onBlur, otherwise the value is not used on submit in html mode
      onChange(editorContents);
      if (onBlur) {
        onBlur(event, editorContents);
      }
    }

    reaction(() => formField.value, value => {
      setContentsOfEditor(value as string);
    });

    const editorRef = useRef<SunEditor>(null);

    const setContentsOfEditor = (val: string) => {
      const core = editorRef?.current?.editor.core;
      if (core) {
        const currentValue = core.getContents(false);
        if (currentValue !== val) {
          core.setContents(val);
        }
      }
    };

    const output = (
      <div className={classNames("wysiwyg-container", name)}>
        <SunEditor lang="de"
                   name={name}
                   defaultValue={calcValue}
                   disable={disabled}
                   enableToolbar={!disabled}
                   onBlur={onBlurWithSetFormFieldContent}
                   onChange={onChange}
                   ref={editorRef}
                   toggleCodeView={toggleCodeView}
                   setOptions={{
                     width: width,
                     height: height,
                     buttonList: buttons,
                     addTagsWhitelist: tagsWhitelist,
                     pasteTagsWhitelist: tagsWhitelist,
                     tagsBlacklist: tagsBlacklist,
                     pasteTagsBlacklist: tagsBlacklist,
                     defaultStyle: "font-family:Delivery; font-size:11px;",
                     maxCharCount: calcMaxLength,
                     charCounterType: "byte-html",
                     attributesWhitelist: {"a": "target"}
                   }}
        />
        {calcError && <DHLFieldNote name={name + "-error"} type={"error"} notes={calcError} />}
      </div>

    );

    return <>{output}</>;
  }
);
