/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { computed, makeObservable, observable } from "mobx";
import { logger } from "../utils/logger";
import { requestParameters } from "../utils/requestParameters";
import { format } from "../utils/stringUtils";
import { ParameterizedMessageType } from "../types/ResourceDataStoreTypes";

function displayKeyOverride() {
  return requestParameters().get("show_messages") === "U2hvdyBtZSB0aGUga2V5cyBub3QgdGhlIG1lc3NhZ2VzIQ==";
}

export type LocalizationPackType = {
  warnings: Set<string>,

  hasData: boolean,
  hasKey(key: string): boolean,
  getScopedMessage(key: string, params?: string[]): string,
  getSelectionListOptionLabel(key: string): string,
  getMsg(key: string, params?: string[]): string,
  getLabel(key: string, params?: string[]): string,
  getTooltip(key: string, params?: string[]): string,
  getPlaceholder(key: string, params?: string[]): string,
  getButton(key: string, params?: string[]): string,
  getError(key: string, params?: string[]): string,
  getSuccess(key: string, params?: string[]): string,
  getHelp(key: string, params?: string[]): string,
  getMessage(key: string, params?: string[]): string,
  getMessages(msgs: ParameterizedMessageType[]): string[]
}

export class LocalizationPack implements LocalizationPackType {

  /** Set of keys which no plaintext was found. */
  readonly warnings: Set<string> = new Set();
  readonly localizations: Map<string, string>;

  constructor(private readonly module: string, localizations: Map<string, string>) {
    this.localizations = localizations;
    makeObservable(this, {
      localizations: observable,
      hasData: computed
    });
  }

  get hasData(): boolean {
    return this.localizations.size > 0;
  }

  hasKey(key: string): boolean {
    return this.localizations.has(key);
  }

  getSelectionListOptionLabel(key: string): string {
    return this.getMessage(key, []);
  }

  getScopedMessage(key: string, params?: string[]): string {
    return this.getMessage(this.module + "." + key, params);
  }

  getMsg(key: string, params?: string[]): string {
    return this.getScopedMessage("msg." + key, params);
  }

  getLabel(key: string, params?: string[]): string {
    return this.getScopedMessage("label." + key, params);
  }

  getTooltip(key: string, params?: string[]): string {
    return this.getScopedMessage("tooltip." + key, params);
  }

  getPlaceholder(key: string, params?: string[]): string {
    return this.getScopedMessage("placeholder." + key, params);
  }

  getButton(key: string, params?: string[]): string {
    return this.getScopedMessage("button." + key, params);
  }

  getError(key: string, params?: string[]): string {
    return this.getScopedMessage("error." + key, params);
  }

  getSuccess(key: string, params?: string[]): string {
    return this.getScopedMessage("success." + key, params);
  }

  getHelp(key: string, params?: string[]): string {
    return this.getScopedMessage("help." + key, params);
  }

  getMessage(key: string, params?: string[]): string {
    if (displayKeyOverride()) {
      return key;
    }

    if (!this.localizations.has(key)) {
      if (!this.warnings.has(key)) {
        this.warnings.add(key);
        logger.warn("Missing text for key ", key);
      }
      return key;
    }

    const message = this.localizations.get(key)!;
    return params ? format(message, params)! : message;
  }

  getMessages(msgs: ParameterizedMessageType[]): string[] {
    return msgs.map(msg => this.getMessage(msg.messageKey, msg.params || undefined));
  }
}
