/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import { uid } from "react-uid";
import { DayOfMonth, WeekOfMonth } from "../../../types/DatePickerTypes";
import { Week } from "./Week";
import { WeekdayHeader } from "./WeekdayHeader";

export type MonthProps = {
  name: string
  daysOfMonthGroupedByWeek: WeekOfMonth[]
  showWeekNumbers: boolean
  onDateSelected: (day: DayOfMonth) => void

  /** Callback function that gets called when the mouse enters the day cell/div */
  onMouseEnterDay?: (day: DayOfMonth) => void;

  /** Callback function that gets called when the mouse leaves the day cell/div */
  onMouseLeaveDay?: (day: DayOfMonth) => void;
}

export const Month = ({name, daysOfMonthGroupedByWeek, showWeekNumbers, onDateSelected, onMouseEnterDay, onMouseLeaveDay}: MonthProps) => {
  const weeks: WeekOfMonth[] = daysOfMonthGroupedByWeek;
  return (
      <table data-testid={name}>
        <thead>
        {<WeekdayHeader name={name + "-wh-wd"} showWeekNumbers={showWeekNumbers} />}
        </thead>
        <tbody>
        {
          weeks.map((week, index) => {
            return <Week
                name={name + "-" + index}
                key={uid(week)}
                week={week}
                showWeekNumbers={showWeekNumbers}
                onDaySelected={onDateSelected}
                onMouseEnterDay={onMouseEnterDay}
                onMouseLeaveDay={onMouseLeaveDay} />;
          })
        }
        </tbody>
      </table>
  );
};