/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import {ReactNode, Children} from "react";
import {DHLButton} from "../../..";
import "./DHLDataFilterChipGroup.scss"

type DHLDataFilterChipGroupProps = {
  name?: string;
  /** label for reset button, leave undefined for no button */
  resetText?: string;
  onReset?: ()=>void;
  /** some DHLChip's */
  children?: ReactNode
}

export const DHLDataFilterChipGroup = ({name, resetText,onReset,children}: DHLDataFilterChipGroupProps) => {
  if(Children.toArray(children).length === 0) {
    return null;
  }

  const resetButton = resetText!==undefined?
      <DHLButton name={name+"-reset"} label={resetText}
                 type="ghost" size="xs" iconPosition="icon-first" icon="reload"
                 onClick={()=>onReset&&onReset()}
                 className={"dhlDataFilterChipGroup-reset"}
      />
      :null;
  return <div data-testid={name} className={"dhlDataFilterChipGroup"}>
    {children}
    {resetButton}
  </div>
};
