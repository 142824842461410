/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ResourceDataStore } from "../stores/ResourceDataStore";
import { LocalizationPack } from "../stores/LocalizationPack";
import { TFunction } from "i18next";
import type { TOptions } from "i18next/typescript/options";
import { $Dictionary } from "i18next/typescript/helpers";
import { ResourceDataStoreTAdapter } from "../stores/ResourceDataStoreTAdapter";

export const isTFunc = (resourceDataStore: ResourceDataStore | LocalizationPack | TFunction): resourceDataStore is TFunction =>
    typeof resourceDataStore === "function";

export const isResourceDataStoreTAdapter = (resourceDataStore: ResourceDataStore | LocalizationPack): resourceDataStore is ResourceDataStoreTAdapter =>
    "t" in resourceDataStore && typeof resourceDataStore.t === "function";

const isDictionary = (params: string[] | $Dictionary | null | undefined): params is $Dictionary =>
    params !== undefined && params !== null && !Array.isArray(params);

//TODO das ist sehr unschön: i18next verwendet benamte Parameter (someKey: "Today is {{date}}") aber unsere Backends/Spring verwenden Indices
// ("someKey=Today is {0}).
// Unsere BEs sollen eigentlich immer nur Keys statt Texte ans FE liefern.
// Benötigen unsere BEs überhaupt noch Lokalisierungen?
// Falls nein: Support für Lokalisierungen deaktivieren (oder einfach leere ResourceBundles hinterlegen?)
// Falls ja: sind die identisch zu den FE-Lokalisierungen?
//            Falls nein: Lokalisierungen splitten: BE verwendet indexbasiert, FE namenbasiert
//            Falls ja: doof, evtl. müssen wir dann bei diesen paar keys bei index statt name bleiben oder diese Lokalisierungen duplizieren
// Als übergang: das FE schaut, was das BE liefert: string[] oder $Dictionary (entspricht Map<String, Object> in Java)
//    -> muss in BaseLibs und BEs noch umgebaut werden
export const transformParams: (params: string[] | $Dictionary | null | undefined) => $Dictionary = params =>
    isDictionary(params)
        ? params
        : (params?.reduce((acc, val, index) => {
              acc[index] = val;
              return acc;
            },
            {} as TOptions
        ) || {});

export const ensureOldParams: (params: string[] | $Dictionary | null | undefined) => string[] | null | undefined = params => {
    if(isDictionary(params)) {
      throw new Error();
    }
    return params;
};
