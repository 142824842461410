/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ReactElement } from "react";
import { uid } from "react-uid";
import { DHLTableHeaderCol, logger, TableDataStore } from "../../..";
import { genericObserver } from "../../../utils/genericObserver";
import { DHLTableHeaderAllSelectableBooleanCol } from "../DHLTableHeaderAllSelectableBooleanCol/DHLTableHeaderAllSelectableBooleanCol";
import "./DHLTableHeaderRow.scss";
import { detailViewType } from "../../atoms/DHLTableCell/DHLTableCellDetailView";

export type DHLTableHeaderRowProps<T extends {}> = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Tabellen Datenspeicher. */
  dataStore: TableDataStore<T>;

  /** Tabellenspalten. */
  colItems: ReactElement[] | ReactElement;

  /** sticky (mitscrollender) Tabellenheader **/
  sticky?: boolean;

  /** Simple sorting mode without context menu */
  complexSortingEnabled?: boolean;

  /** Disables everything except sorting and paging **/
  disabled?: boolean;
};

/** Tabellen-Headerzeile. */
export const DHLTableHeaderRow = genericObserver(function <T extends {}>(
    {
      name,
      dataStore,
      colItems,
      sticky,
      complexSortingEnabled = false,
      disabled = false
    }: DHLTableHeaderRowProps<T>
) {
  return (
      <thead data-testid={name} className={"dhlTable-sort-header" + (sticky ? " dhlTableheader-sticky" : "")}>
      <tr>
        {(Array.isArray(colItems) ? colItems : [colItems])
            .filter(s => s.props.type !== detailViewType).map(colItem => {
              const {propertiesName} = colItem.props;
              logger.log("DHLTableHeaderRow - col", propertiesName, colItem.props, typeof colItem);

              if (!colItem.props.allSelectableAccessors) {
                return (
                    <DHLTableHeaderCol
                        name={name + "-col-" + propertiesName}
                        key={uid(colItem)}
                        dataStore={dataStore}
                        colItem={colItem}
                        complexSortingEnabled={complexSortingEnabled}
                    />
                );
              } else {
                return (
                    <DHLTableHeaderAllSelectableBooleanCol
                        key={uid(colItem)}
                        dataStore={dataStore}
                        colItem={colItem}
                        disable={disabled}
                    />);
              }
            })}
      </tr>
      </thead>
  );
});
