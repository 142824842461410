
import "./DHLLoading.scss";

export type DHLLoadingProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Wird gerade geladen? */
  loading?: boolean;

  /** Textausgabe während des Ladevorgangs. */
  info?: string;
};

/** Ladeanzeige. */
export const DHLLoading = ({name, loading, info}: DHLLoadingProps) => {
  if (!loading) {
    return null;
  }

  return (
        <div data-testid={name} className="spinner-border" role="status">
          <span className="sr-only">{info ? info : "..."}</span>
        </div>
  );
};
