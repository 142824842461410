/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import React from "react";
import "./DHLRadioButtonTileGroup.scss";

export type DHLRadioButtonTileGroupProps = {

  /** content */
  children: React.ReactNode
}

export const DHLRadioButtonTileGroup = observer(({children}: DHLRadioButtonTileGroupProps) => {
  return <div className={"dhl-radio-tile-button-group"}>
    {children}
  </div>;
});