import {CurrencyType} from "../../../types/DataTypes";

export type DHLTableCellCurrencyProps = {
  /** Spaltentitel. */
  title: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type?: "currency";

  /** CSS-Klassen. */
  className?: string;

  /** Mindestanzahl Nachkommastellen. */
  minFractionDigits?: number;

  /** Maximalanzahl Nachkommastellen. */
  maxFractionDigits?: number;

  /** Tausendertrennzeichen verwenden? */
  groupingEnabled?: boolean;

  /** Währungskennzeichen. */
  currencyType?: CurrencyType;

  /** Display symbol (e.g. '€'; if false: 'EUR'). */
  displaySymbol?: boolean;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Kind-Komponenten. */
  children?: never;
};

/** Tabellenspaltendefinition - Währung. */
export const DHLTableCellCurrency = (props: DHLTableCellCurrencyProps) => {
  return null;
};

DHLTableCellCurrency.defaultProps = {
  type: "currency",

  minFractionDigits: 2,

  maxFractionDigits: 2,

  groupingEnabled: true,

  currencyType: "EUR",

  displaySymbol: false,

  sortable: false
};
