/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { SortFunction, SortOrder } from "../../../utils/dynamicCellSort";
import { DHLTableCellBooleanAllSelectableProps } from "./DHLTableCellBooleanAllSelectable";
import * as Popper from "popper.js";
import { ResourceDataStore } from "../../../stores/ResourceDataStore";
import type { TFunction } from "i18next";

export type DHLTableCellCustomProps = {
  /** Spaltentitel. */
  title: string;

  /** Tooltip an Spaltentitel. */
  titleTooltip?: string;

  /** Tooltip Placement. */
  titleTooltipPlacement?: Popper.Placement;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type?: "custom";

  /** CSS-Klassen. */
  className?: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: (value: boolean, cellId: string, idProperyName: string, colPropertyName: string) => void;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Function to sort elements (. value1 < value1 => -1, value1 = value2 => 0, value1 > value2 => 1) */
  comparator?: (resourceDataStore: ResourceDataStore | TFunction | null,
                propertiesName: string,
                sortOrder: SortOrder) => SortFunction<any>;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Funktion zum Rendern des Zelleninhalts. */
  customRenderer?: (value: any, row: Object, cellId: string) => any;

  /** Kind-Komponenten. */
  children?: never;
} & DHLTableCellBooleanAllSelectableProps<any>;

/** Tabellenspaltendefinition - freie Ausgabe. */
export const DHLTableCellCustom = (props: DHLTableCellCustomProps) => {
  return null;
};

DHLTableCellCustom.defaultProps = {
  type: "custom",

  sortable: false
};
