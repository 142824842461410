/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import React, { ReactNode, useEffect, useState } from "react";
import { DHLPagetemplate, DHLPagetemplateProps } from "./DHLPagetemplate";
import { DHLProgressBar } from "../DHLProgressBar/DHLProgressBar";
import { useSharedSearchParams } from "../../../utils/useSharedSearchParams";
import { DHLDialogNonModal } from "../../organisms/DHLDialog/DHLDialogNonModal";
import { DialogFormContent } from "../../organisms/DHLDialog/DialogFormContent";
import { DHLButtonGroup } from "../../atoms/DHLButtonGroup/DHLButtonGroup";
import { DHLButton, DHLButtonProps } from "../../atoms/DHLButton/DHLButton";

export type DHLPageTemplateWithProgressBarButtonProps = Omit<DHLButtonProps, "name" | "onClick"> & {
  /** Returned/resolved value of true or void proceeds to next step, returned/resolved value of false blocks navigation to next step. */
  onClick?: () => void | boolean | Promise<void | boolean>
}

type DHLPageTemplatePropsReduced = Omit<DHLPagetemplateProps, "name" | "progressBar" | "children">;

export type DHLPageTemplateWithProgressBarStep = DHLPageTemplatePropsReduced & {
  label: string;
  element: ReactNode;
  backButton?: DHLPageTemplateWithProgressBarButtonProps;
  nextOrFinishButton?: DHLPageTemplateWithProgressBarButtonProps;
}

export type DHLPageTemplateWithProgressBarProps = DHLPageTemplatePropsReduced & {
  steps: DHLPageTemplateWithProgressBarStep[],

  /**
   * Called on first render of the component. Can be used to validate if `requestedStep` is valid as the current step
   * (e.g. user has a deeplink to a process step, does not fill form content on step 1, but tries to manipulate url to get to page 2).
   *
   * @param {number} requestedStep step from url search params
   * @returns {number | Promise<number>} step to switch to
   */
  determineEntryStep?: (requestedStep: number) => number | Promise<number>;
  backButton?: DHLPageTemplateWithProgressBarButtonProps;
  nextOrFinishButton?: DHLPageTemplateWithProgressBarButtonProps;
};

/**
 *  Template for page structure with progress bar.
 *  The props for DHLPagetemplate and back/next buttons can be overridden by each step.
 */
export const DHLPageTemplateWithProgressBar = (
    {
      steps,
      determineEntryStep,
      backButton: backButtonFromProps = {},
      nextOrFinishButton: nextOrFinishButtonFromProps = {},
      ...restProps
    }: DHLPageTemplateWithProgressBarProps
) => {
  const [searchParams, setSearchParams] = useSharedSearchParams();
  const [currentStepValidated, setCurrentStepValidated] = useState(false);

  useEffect(() => {
    if (currentStepValidated) {
      // this effect has already run and step from searchParams is already validated
      return;
    }

    const stepAsString = searchParams.get("step");
    let step = 0;
    if (stepAsString !== null && /^(0|[1-9]\d*)$/.test(stepAsString)) { // stepsAsString is no valid number
      step = Number(stepAsString);
    }

    // step < 0 is not possible: regex above only matches positive integers and 0
    if (step >= steps.length) {
      step = steps.length - 1;
    }

    (async function validateStepInEffect() {
      searchParams.set("step", String(await determineEntryStep?.(step) ?? step));
      setSearchParams(searchParams);
      setCurrentStepValidated(true);
    })();
  }, [searchParams.get("step"), setSearchParams, setCurrentStepValidated]);

  if (!currentStepValidated) {
    return null;
  }

  const currentStep: number = Number(searchParams.get("step"));
  const {label, element, backButton: backButtonFromStepProps, nextOrFinishButton: nextOrFinishButtonFromStepProps, ...pageTemplateFromStepProps}
      = steps[currentStep];

  const backButton = {
    ...backButtonFromProps,
    ...backButtonFromStepProps
  };
  const nextOrFinishButton = {
    ...nextOrFinishButtonFromProps,
    ...nextOrFinishButtonFromStepProps
  };

  const progressBar = <DHLProgressBar
      steps={steps.map(s => ({label: s.label}))}
      activeIndex={currentStep}
  />;

  const actionbarButtons = <>
    {(currentStep === 0 && backButton.onClick === undefined)
        ? <div /> // on first step when no onClick is passed, there is nothing to do.
        : <DHLButtonGroup alignment="left">
          <DHLButton
              {...backButton}
              onClick={async () => {
                const onClickResult = await backButton.onClick?.();
                if (onClickResult !== false && currentStep > 0) {
                  searchParams.set("step", String(currentStep - 1));
                  setSearchParams(searchParams);
                }
              }}
          />
        </DHLButtonGroup>
    }
    {(currentStep === steps.length - 1 && nextOrFinishButton.onClick === undefined)
        ? <div /> // on last step when no onClick is passed, there is nothing to do.
        : <DHLButtonGroup alignment="right">
          <DHLButton
              type="primary"
              {...nextOrFinishButton}
              onClick={async () => {
                const onClickResult = await nextOrFinishButton.onClick?.();
                if (onClickResult !== false && currentStep < steps.length - 1) {
                  searchParams.set("step", String(currentStep + 1));
                  setSearchParams(searchParams);
                }
              }}
          />
        </DHLButtonGroup>}
  </>;


  return (
      <DHLPagetemplate
          {...restProps}
          {...pageTemplateFromStepProps}
          progressBar={progressBar}
      >
        <DHLDialogNonModal actionbar={actionbarButtons}>
          <DialogFormContent restrictToEightColumnsFromOuterGrid>
            {element}
          </DialogFormContent>
        </DHLDialogNonModal>
      </DHLPagetemplate>
  );
};
