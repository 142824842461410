/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AccessToken, AuthenticationManagerImpl2 } from "@gkuis/gkp-authentication";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { logger, useAuthenticationManager } from "../../..";
import { isExpectedSource, sendAnswer, shouldEventBeRejected } from "../../../utils/iframeCommunications";
import { useViewportWidth } from "../../../utils/viewportHooks";
import "./DHLIFrame.scss";

export type DHLIframeProps = {

  /** CSS classes. */
  className?: string;

  /** Url which should be used in iFrame. */
  url: string;

  /** Title */
  title: string;

  /** Width (in pixels) */
  width?: number;

  /** Height (in pixels) */
  height?: number;

  /** Auto resizing to viewport width? */
  resizeToViewportWidth?: boolean;

  /** Auto resizing to event height? */
  resizeToEventHeight?: boolean;

  onMessage?: (event: MessageEvent) => void;

  /** Use history */
  navListenerBasePath?: string;
};

type ResizeMessage = {
  function: "resizeIframe",
  newHeight?: number
  arguments?: number[], // in first array index height
}

type RequestDataMessage = {
  function: "requestData",
  token: string,
  arguments?: string[]
}

declare global {
  interface Window {
    switchGkpMenuState: (menuItemKey: string) => void;
  }
}

const isTypeResizeMessage = (obj: unknown): obj is ResizeMessage => {
  // loose check only
  return obj !== null
      && (typeof obj === "object")
      && (obj as any).function === "resizeIframe";
};

const isGetAuthorizationDataMessage = (obj: unknown): boolean => {
  return obj !== null
      && (typeof obj === "object")
      && (obj as any).function === "getAuthorizationData";
};

const isKeepAliveMessage = (obj: unknown): boolean => {
  return obj !== null
      && (typeof obj === "object")
      && (obj as any).function === "keepAlive";
};

const isRequestDataMessage = (obj: unknown): obj is RequestDataMessage => {
  return obj !== null
      && (typeof obj === "object")
      && (obj as any).function === "requestData";
};

const isRequestOriginMessage = (obj: unknown): boolean => {
  return obj !== null
      && (typeof obj === "object")
      && (obj as any).function === "requestOrigin";
};

const defaultWidthFallback = 100;
const defaultHeightFallback = 500;

export const DHLIFrame = ({
                            className,
                            url,
                            title,
                            width,
                            height,
                            resizeToViewportWidth = false,
                            resizeToEventHeight = false,
                            onMessage = () => { /* intended use */
                            },
                            navListenerBasePath
                          }: DHLIframeProps
) => {
  const outerDiv = useRef<HTMLDivElement>(null);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [iframeWidth, setIframeWidth] = useState<number>(width ?? defaultWidthFallback);
  const [iframeHeight, setIframeHeight] = useState<number>(height ?? defaultHeightFallback);
  const authenticationManager = useAuthenticationManager();

  const viewportWidth = useViewportWidth(resizeToViewportWidth);

  useEffect(() => {
        const messageCallback: typeof onMessage = (event: MessageEvent) => {
          if (!isExpectedSource(event, iFrameRef.current)) {  //event is not from this iframe
            logger.log("onMessage iframe event discard: ", event);
            return;
          }
          onMessage(event);
        };
        window.addEventListener("message", messageCallback);
        return () => {
          window.removeEventListener("message", messageCallback);
        };
      },
      [iFrameRef.current?.contentWindow, onMessage]
  );

  useLayoutEffect(() => {
    if (resizeToViewportWidth) {
      const clientWidth = outerDiv.current?.clientWidth;
      setIframeWidth(clientWidth!);
    } else {
      setIframeWidth(width ?? defaultWidthFallback);
    }
    // hopefully no flickering/resizing-loops
  }, [viewportWidth, width, resizeToViewportWidth]);

  useEffect(() => {
    if (resizeToEventHeight) {
      const resizeListener = (event: MessageEvent) => {
        const data: unknown = event.data;
        if (isTypeResizeMessage(data)) {
          if (shouldEventBeRejected(event, url, window, iFrameRef)) {
            // event is not from this iframe and or event is not from origin we rendered as frame src
            logger.log("resize message iframe event discard: ", event.origin, new URL(url, window.location.toString()).origin);
            return;
          }
          if (data.newHeight) {
            // Legacy Classic HEIDY-Iframe-Taskflow
            setIframeHeight(data.newHeight);
          } else if (data.arguments && data.arguments[0] !== undefined) {
            // Legacy Classic externalFormIframe
            setIframeHeight(data.arguments[0]);
          } else {
            logger.warn("invalid resizeIframe event received", event);
          }
        }
      };
      window.addEventListener("message", resizeListener);
      return () => window.removeEventListener("message", resizeListener);
    } else {
      setIframeHeight(height ?? defaultHeightFallback);
    }
  }, [height, resizeToEventHeight, url, iFrameRef, setIframeHeight]);

  useEffect(() => {
    const getAuthorizationDataMessageListener = (event: MessageEvent) => {
      if (isGetAuthorizationDataMessage(event.data)) {
        if (shouldEventBeRejected(event, url, window, iFrameRef)) {
          // event is not from this iframe and or event is not from origin we rendered as frame src
          logger.log("getAuthorization message iframe event discard: ", event.origin, new URL(url, window.location.toString()).origin);
          return;
        }
        const expectedIframeOrigin = new URL(url, window.location.toString()).origin;
        // legacy: getAuthorizationDataForIframe only used for iFrame-integration. Remove when all services with authorization use widget-integration
        sendAnswer(event, iFrameRef, (authenticationManager as AuthenticationManagerImpl2).getAuthorizationDataForIframe(), expectedIframeOrigin);
      }
    };
    window.addEventListener("message", getAuthorizationDataMessageListener);
    return () => window.removeEventListener("message", getAuthorizationDataMessageListener);
  }, [url, iFrameRef, authenticationManager]);

  useEffect(() => {
    const keepAliveMessageListener = (event: MessageEvent) => {
      if (isKeepAliveMessage(event.data)) {
        if (shouldEventBeRejected(event, url, window, iFrameRef)) {
          // event is not from this iframe and or event is not from origin we rendered as frame src
          logger.log("keepAlive message iframe event discard: ", event.origin, new URL(url, window.location.toString()).origin);
          return;
        }
        authenticationManager.updateToken(-1);
      }
    };
    window.addEventListener("message", keepAliveMessageListener);
    return () => window.removeEventListener("message", keepAliveMessageListener);
  }, [url, iFrameRef, authenticationManager]);

  useEffect(() => {
    const requestDataListener = async (event: MessageEvent) => {
      const data: unknown = event.data;
      if (isRequestDataMessage(data)) {
        const expectedIframeOrigin = new URL(url, window.location.toString()).origin;
        if (shouldEventBeRejected(event, url, window, iFrameRef)) {
          logger.log("requestData message iframe event discard: ", event.origin, new URL(url, window.location.toString()).origin);
          return;
        }
        const response: object[] = [];

        if (authenticationManager.authenticated && data.arguments && data.arguments.length) {
          await authenticationManager.getAccessTokenParsed()
              .then((token: AccessToken | undefined) => {
                data.arguments?.includes("user_profile_ekp") && response.push({user_profile_ekp: token!.ekp});
                data.arguments?.includes("user_profile_email") && response.push({user_profile_email: token!.email});
                data.arguments?.includes("user_profile_firstname") && response.push({user_profile_firstname: token!.given_name});
                data.arguments?.includes("user_profile_lastname") && response.push({user_profile_lastname: token!.family_name});
                data.arguments?.includes("user_phone") && response.push({user_phone: token!.phone});
              }).catch(() => {
                logger.log("cannot determine accesstoken");
              });

          const frameResponse = {function: "responseData", token: data.token, arguments: response};

          sendAnswer(event, iFrameRef, frameResponse, expectedIframeOrigin);
        } else {
          logger.log("requestData message iframe event cannot be processed: user not authenticated or no data requested");
        }

      }
    };
    window.addEventListener("message", requestDataListener);
    return () => window.removeEventListener("message", requestDataListener);
  }, [url, iFrameRef, authenticationManager]);

  useEffect(() => {
    const requestOriginMessageListener = (event: MessageEvent) => {
      if (isRequestOriginMessage(event.data)) {
        if (shouldEventBeRejected(event, url, window, iFrameRef)) {
          // event is not from this iframe and or event is not from origin we rendered as frame src
          logger.log("requestOrigin message iframe event discard: ", event.origin, new URL(url, window.location.toString()).origin);
          return;
        }
        const gkpOrigin = window.location.origin;
        const frameResponse = {function: "gkpOrigin", gkpOrigin: gkpOrigin};
        const expectedIframeOrigin = new URL(url, window.location.toString()).origin;

        sendAnswer(event, iFrameRef, frameResponse, expectedIframeOrigin);
      }
    };
    window.addEventListener("message", requestOriginMessageListener);
    return () => window.removeEventListener("message", requestOriginMessageListener);
  }, [url, iFrameRef, authenticationManager]);

  const observer = useMemo(() => new ResizeObserver(() => {
    if (resizeToViewportWidth) {
      // safari doesn't support ResizeObserverEntry et al., but we also have only one entry anyway

      // hopefully no flickering/resizing-loops
      setIframeWidth(outerDiv.current?.clientWidth!);
    }
  }), []);

  useLayoutEffect(() => {
    const div = outerDiv.current;
    if (!div) {
      logger.warn("DHLIFrame: div is not set when observing, function broken");
      return () => { /* intended use */
      };
    }
    observer.observe(div);

    return () => observer.disconnect(); // disconnect all previous
  }, [observer]);

  if (navListenerBasePath) {
    window.switchGkpMenuState = (menuItemKey: string) => {
      //HEIDY-10131: disable menu highlighting changes signalled from iFrame
      // history.push triggers rerender of iFrame with new URL which is not working properly (e. g. in VLS missing "?4")
      // TODO: fix HEIDY-10131 and re-enable
      // logger.log("switching url location via switchGkpMenuState", menuItemKey)
      // history.push(navListenerBasePath + menuItemKey);
    };
  }

  return (
      <>
        <div ref={outerDiv} className="dhlIFrame">
          <iframe
              className={className}
              width={iframeWidth}
              src={url}
              height={iframeHeight}
              title={title}
              ref={iFrameRef} />
        </div>
      </>
  );
};
