/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { DHLIcon } from "../DHLIcon/DHLIcon";
import "./DHLContextmenu.scss";

/** WICHTIG: Kein extends verwenden, da Storybook damit nicht umgehen kann und die Attribute der übergeordneten Interfaces/Typen nicht anzeigt. */
export type DHLContextmenuProps = {
  /** Ist das Kontextmenü sichtbar? */
  visible: boolean,
  /** Die React-Children dieser Komponente. */
  children?: React.ReactNode
};

export const DHLContextmenu = observer(({visible, children}: DHLContextmenuProps) => {
  return (
      <div className={"dhlContextmenu" + (visible ? " dhlContextmenu-visible" : "")} onClick={(e) => {
        // Klickevents nicht nach "oben" z.B. zu einem Button weiterreichen, der uns geöffnet hat und dadurch
        // wieder schließt
        e.stopPropagation();
      }}>
        {children}
      </div>
  );
});
export const DHLContextmenuGroup = (props: { headline?: string, children?: React.ReactNode }) => {
  return <div className="dhlContextmenu-group">{props.headline ?
      <DHLContextmenuHeadline>{props.headline}</DHLContextmenuHeadline> : null}{props.children}</div>;
};
export const DHLContextmenuHeadline = (props: { children?: React.ReactNode }) => {
  return <div className={"dhlContextmenu-headline"}>{props.children}</div>;
};
export const DHLContextmenuLabel = observer((props: {
  name?: string,
  onClick?: React.MouseEventHandler<HTMLElement>,
  checked: boolean,
  label: string,
  className?: string;
}) => {

  return <button
      data-testid={props.name}
      onClick={props.onClick}
      className={classNames("dhlContextmenu-label" + (props.checked ? " checked" : ""), props.className)}
  >
    {(props.checked ? <span className={"checkmark"}><DHLIcon name={""} icon={"check"} /></span> : null)}<span>{props.label}</span>
  </button>;
});
