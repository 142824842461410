/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { FetchAdapter } from "../utils/fetch/FetchAdapter"
import { logger } from "../utils/logger";
import { LanguageType, SelectionListType } from "../types/DataTypes";

const LOG_MODULE = "SelectionListClient";

export class SelectionListClient{
  constructor(private readonly fetchAdapter: FetchAdapter, private readonly baseUrl: string) { }
  
  public async fetchSelectionLists(language: LanguageType): Promise<SelectionListType[]> {
    logger.log(LOG_MODULE, "loading selection lists");

    const response = await this.fetchAdapter.get(`${this.baseUrl}/selectionlists/options?language=${language}`);

    if (response.ok || response.status === 304) {
      return (await response.json()) as SelectionListType[];
    }else{
      logger.warn(LOG_MODULE, "loading selection lists failed");
      return [];
    }
  }
}