/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import "moment/locale/de";
import { DayOfMonth, MonthOfYear, WeekOfMonth, Year } from "../types/DatePickerTypes";
import autoBind from "auto-bind";

/** Datenspeicher für DatePicker. */
export class DatePickerDataStore {
  private static readonly OFFSET_MINIMUM_YEARS = -10;
  private static readonly OFFSET_MAXIMUM_YEARS = +10;

  // the earliest date that is allowed to be selected
  startDate: moment.Moment | null = null;
  // the latest date that is allowed to be selected
  endDate: moment.Moment | null = null;
  /** currently selected year */
  selectedYear: number | undefined = undefined;
  /** Der ausgewählte Monat. (0-Index) */
  selectedMonth: number | undefined = undefined;
  /** Der ausgewählte Tag. (1-Index) */
  selectedDay: number | undefined = undefined;
  /** Der aktuell angezeigte Monat. */
  displayedMonth: number;
  /** Das aktuell angezeigte Jahr. */
  displayedYear: number;

  /**
   * Prädikat, dass ein anzuzeigendes Datum übergeben bekommt und zurückgibt, ob der Tag deaktiviert/inaktiv
   * angezeigt werden soll, d.h. auch nicht auswählbar ist.
   * Daten ausserhalb von startDate und endDate sind automatisch nicht auswählbar.
   */
  disabledDatePredicate: (day: moment.Moment) => boolean = () => false;

  constructor(public dateFormat: string) {
    // Aufruf von initFromDate wird hier von TypeScript/ESLint nicht korrekt erkannt und displayedYear sowie displayedMonth
    // werden als nicht initialisiert gemeldet.
    const today = moment();
    this.selectedYear = today.year();
    this.selectedMonth = today.month();
    this.displayedYear = this.selectedYear;
    this.displayedMonth = this.selectedMonth;

    makeObservable(this, {
      startDate: observable,
      endDate: observable,
      selectedYear: observable,
      selectedMonth: observable,
      selectedDay: observable,
      displayedYear: observable,
      displayedMonth: observable,
      disabledDatePredicate: observable,
      setStartDate: action,
      setEndDate: action,
      setSelectedYear: action,
      setSelectedMonth: action,
      setSelectedDay: action,
      setDisplayedYear: action,
      setDisplayedMonth: action,
      initFromDate: action,
      daysOfMonthGroupedByWeek: computed,
      years: computed,
      onPreviousMonth: action,
      onNextMonth: action,
      onDaySelected: action,
      onMonthSelected: action,
      onYearSelected: action
    });

    autoBind(this);
  }

  setStartDate(startDate: moment.Moment | null) {
    this.startDate = startDate;
  }

  setEndDate(endDate: moment.Moment | null) {
    this.endDate = endDate;
  }

  setSelectedYear(selectedYear: number | undefined) {
    this.selectedYear = selectedYear;
  }

  setSelectedMonth(selectedMonth: number | undefined) {
    this.selectedMonth = selectedMonth;
  }

  setSelectedDay(selectedDay: number | undefined) {
    this.selectedDay = selectedDay;
  }

  setDisplayedMonth(displayedMonth: number) {
    this.displayedMonth = displayedMonth;
  }

  setDisplayedYear(displayedYear: number) {
    this.displayedYear = displayedYear;
  }

  /**
   * Initialisiert das aktuelle Datum.
   * @param {moment.Moment | null} initialDate
   */
  initFromDate(initialDate: moment.Moment | null): void {
    if (initialDate) {
      this.selectedDay = initialDate.date();
      this.selectedMonth = initialDate.month();
      this.selectedYear = initialDate.year();
    } else {
      const today = moment();
      this.selectedYear = today.year();
      this.selectedMonth = today.month();
    }

    this.displayedYear = this.selectedYear;
    this.displayedMonth = this.selectedMonth;
  }

  /**
   * Liefert das ausgewählte Datum als String im angegebenen Format.
   * @returns {string | null}
   */
  formattedSelectedDate(): string | null {
    const selectedDate = this.getSelectedDate();

    if (selectedDate !== null) {
      return selectedDate.format(this.dateFormat);
    }

    return null;
  }

  /**
   * Die im Kalender anzuzeigenden Tage, gruppiert nach Kalenderwochen.
   * @returns {WeekOfMonth[]}
   */
  get daysOfMonthGroupedByWeek(): WeekOfMonth[] {
    const startOfDisplayedMonth: moment.Moment = moment([this.displayedYear, this.displayedMonth, 1]);
    const endOfDisplayedMonth: moment.Moment = moment([this.displayedYear, this.displayedMonth, 1]);

    endOfDisplayedMonth.add(1, "month");
    endOfDisplayedMonth.subtract(1, "day");

    const weekdayStart: number = startOfDisplayedMonth.weekday();
    const weeksOfMonth: WeekOfMonth[] = this._initDaysOfMonth(startOfDisplayedMonth.week(), endOfDisplayedMonth.week());

    this._initDaysOfDisplayedMonth(weeksOfMonth, startOfDisplayedMonth, endOfDisplayedMonth, weekdayStart);

    return weeksOfMonth;
  }

  /**
   * Die im Kalender anzuzeigenden Monate
   * @returns {MonthOfYear[]}
   */
  get monthsOfYear(): MonthOfYear[] {
    const months: MonthOfYear[] = [];
    const date = moment([this.displayedYear, 0, 1]);

    for (let numberOfMonth: number = 0; numberOfMonth < 12; numberOfMonth++) {
      months[numberOfMonth] = {
        month: numberOfMonth,
        selected: this.selectedMonth === numberOfMonth && this.selectedYear === this.displayedYear,
        clickable: (!this.startDate || date.isSameOrAfter(this.startDate, "month")) && (!this.endDate || date.isSameOrBefore(this.endDate, "month"))
      };

      date.add(1, "month");
    }

    return months;
  }


  //  Es ist nicht genau definiert, ob das und andere Jahre angezeigt werden,
  //  wenn das ausgewählte Datum ausserhalb des normal gültigen Jahresbereiches liegt.
  get _minDisplayedYear(): number {
    return Math.min(
        // Grenzjahr, sonst undefinierte 10 Jahre Abstand von jetzt.
        this.startDate?.year() ?? (moment().year() + DatePickerDataStore.OFFSET_MINIMUM_YEARS),
        // gerade angezeigtes Jahr
        this.displayedYear,
        // Nur wenn ein Jahr ausgewählt...
        this.selectedYear ?? Number.MAX_SAFE_INTEGER
    );
  }

  get _maxDisplayedYear(): number {
    return Math.max(
        this.endDate?.year() ?? (moment().year() + DatePickerDataStore.OFFSET_MAXIMUM_YEARS),
        this.displayedYear,
        this.selectedYear ?? Number.MIN_SAFE_INTEGER
    );
  }

  /**
   * Die im Kalender anzuzeigenden Jahresintervalle.
   * @returns {Year[]}
   */
  get years(): Year[] {
    const years: Year[] = [];

    let year = this._minDisplayedYear;
    const date = moment([year, 0, 1]);
    for (let j: number = 0; j < this._maxDisplayedYear - this._minDisplayedYear + 1; j++) {
      years[j] = {
        year: year,
        selected: this.selectedYear === year,
        clickable: (!this.startDate || date.isSameOrAfter(this.startDate, "year")) && (!this.endDate || date.isSameOrBefore(this.endDate, "year"))
      };

      date.add(1, "year");
      year++;
    }

    return years;
  }

  _initDaysOfMonth(startWeek: number, endWeek: number): WeekOfMonth[] {
    const weeksOfMonth: WeekOfMonth[] = [];
    let date = moment([this.displayedYear, this.displayedMonth, 1]);
    let weekCount = endWeek - startWeek;

    // Fall Januar+Dezember, wenn KW eines Jahres in das andere reinreicht.
    if (weekCount < 0) {
      if (this.displayedMonth === 0) {
        date = moment([this.displayedYear - 1, 11, 1]);
      }

      weekCount = date.weeksInYear() - startWeek + endWeek;
    }

    for (let i: number = 0; i <= weekCount; i++) {
      weeksOfMonth[i] = {calenderweek: 0, days: []};

      for (let j: number = 0; j < 7; j++) {
        weeksOfMonth[i].days[j] = {
          day: 0,
          old: false,
          new: false,
          selected: false,
          clickable: false,
          today: false,
        };
      }
    }

    return weeksOfMonth;
  }

  _initDaysOfDisplayedMonth(
      weeksOfMonth: WeekOfMonth[],
      startOfCurrentMonth: moment.Moment,
      endOfCurrentMonth: moment.Moment,
      daysOfPreviousMonth: number
  ): void {
    const dateInMonth = startOfCurrentMonth.clone();

    for (let dayInMonth: number = 1; dayInMonth <= endOfCurrentMonth.get("date"); dayInMonth++) {
      const weekIndex: number = Math.floor(((dayInMonth - 1) + daysOfPreviousMonth) / 7);
      const dayInWeekIndex: number = ((dayInMonth - 1) + daysOfPreviousMonth) % 7;

      // Ineffizient immer neu zu setzen, aber hier wissen wir ein konkretes Datum. Gerade KW zu Jahresbeginn ist
      // komplexer zu bestimmen.
      weeksOfMonth[weekIndex].calenderweek = dateInMonth.week();

      weeksOfMonth[weekIndex].days[dayInWeekIndex].day = dateInMonth.get("date");
      weeksOfMonth[weekIndex].days[dayInWeekIndex].selected = this._isDaySelected(dayInMonth);
      weeksOfMonth[weekIndex].days[dayInWeekIndex].clickable =
          (!this.startDate || dateInMonth.isSameOrAfter(this.startDate, "day")) && (!this.endDate || dateInMonth.isSameOrBefore(this.endDate, "day"))
          && !this.disabledDatePredicate(dateInMonth);
      weeksOfMonth[weekIndex].days[dayInWeekIndex].today = dateInMonth.isSame(moment(), "day");
      dateInMonth.add(1, "day");
    }
  }

  _isDaySelected(day: number): boolean {
    return this.displayedYear === this.selectedYear && this.displayedMonth === this.selectedMonth && this.selectedDay === day;
  }

  /**
   * Prüft, ob der vorherige Monat angezeigt werden darf (also im Interval zwischen Start- und Enddatum liegt).
   * @returns {boolean}
   */
  isPreviousMonthAvailable(): boolean {
    let available;
    const date = moment([this.displayedYear, this.displayedMonth, 1]);

    if (this.startDate) {
      available = this.startDate < date;
    } else {
      available = date.isAfter(moment([this._minDisplayedYear, 0, 1]), "month");
    }

    return available;
  }

  /**
   * Prüft, ob der nächste Monat angezeigt werden darf (also im Interval zwischen Start- und Enddatum liegt).
   * @returns {boolean}
   */
  isNextMonthAvailable(): boolean {
    let available;

    const date = moment([this.displayedYear, this.displayedMonth, 1]);

    if (this.endDate) {
      date.add(1, "month");
      date.subtract(1, "day");

      available = this.endDate > date;
    } else {
      available = date.isBefore(moment([this._maxDisplayedYear, 11, 1]), "month");
    }

    return available;
  }

  /**
   * Den vorherigen Monat anzeigen.
   */
  onPreviousMonth(): void {
    if (!this.isPreviousMonthAvailable()) {
      return;
    }
    if (this.displayedMonth === 0) {
      this.displayedMonth = 11;
      this.displayedYear--;
    } else {
      this.displayedMonth--;
    }
  }

  /**
   * Den nächsten Monat anzeigen.
   */
  onNextMonth(): void {
    if (!this.isNextMonthAvailable()) {
      return;
    }
    if (this.displayedMonth === 11) {
      this.displayedMonth = 0;
      this.displayedYear++;
    } else {
      this.displayedMonth++;
    }
  }

  /**
   * Einen Kalendertag auswählen.
   * @param {DayOfMonth} day
   */
  onDaySelected(day: DayOfMonth): void {
    if (day.clickable) {
      day.selected = true;
      this.selectedDay = day.day;
      this.selectedMonth = this.displayedMonth;
      this.selectedYear = this.displayedYear;
    }
  }

  /**
   * Einen Monat auswählen.
   * @param {number} month
   */
  onMonthSelected(month: number): void {
    this.displayedMonth = month;
  }

  /**
   * Ein Jahr auswählen.
   * @param {number} year
   */
  onYearSelected(year: number): void {
    this.displayedYear = year;
  }

  /**
   * Ermittelt das selektierte Datum. Null wenn kein Datum selektiert wurde.
   * @returns {moment.Moment | null}
   */
  getSelectedDate(): moment.Moment | null {
    if (this.selectedYear && this.selectedMonth && this.selectedDay) {
      return moment([this.selectedYear, this.selectedMonth, this.selectedDay]);
    }

    return null;
  }
}
