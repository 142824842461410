/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { PaginationDataStore } from "../../../stores/PaginationDataStore";
import { DHLSelectOne } from "../DHLSelectOne/DHLSelectOne";

export type DHLSizeSelectorProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;
  /** Label für die Auswahlliste mit den Seitengrößen. */
  label?: string;
  /** Auswahlliste zur Steuerung der Seitengröße anzeigen? */
  showPageSizeSelector?: boolean;
  /** Pager-Datenspeicher. */
  dataStore: PaginationDataStore;
};

/** Seitennavigation, z.B. für Tabellen */
export const DHLPageSizeSelector = observer(({name, label, dataStore, showPageSizeSelector = true}: DHLSizeSelectorProps) => {

  const onChangePageSizeChanged = useCallback((event: React.FormEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(event.currentTarget.value, 10);

    dataStore.setCurrentPageSize(newPageSize);
    // eslint-disable-next-line
  }, []);

  const neededPageSizeSelector = (dataStore.noOfEntries > 5);

  if (showPageSizeSelector && neededPageSizeSelector) {
    return <div>
      <DHLSelectOne
          name={name + "-pagesize"}
          label={label}
          value={dataStore.currentPageSize}
          data={dataStore.pageSizes}
          onChange={onChangePageSizeChanged}
      />
    </div>;
  }
  return null;
});
