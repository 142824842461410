/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { logger } from "../logger";
import {FetchAdapter} from "./FetchAdapter";

/**
 * Kapselt den PUT Aufruf von "fetch".
 *
 * @param fetchAdapter the FetchAdapter to use
 * @param path die URL die aufgerufen werden soll
 * @param body die Daten für den Service
 * @param successCallback callback that will be called in case of successful fetch
 * @param errorCallback callback that will be called in case of errors
 * @param finalCallback callback that will be called after the success or error callback
 */
export const fetchServicePut = async (fetchAdapter: FetchAdapter,
                                      path: string,
                                      body: any, successCallback?: (response: Response) => void,
                                      errorCallback?: (response: Response) => void,
                                      finalCallback?: () => void): Promise<void> => {
  logger.log("fetchServicePut - fetching...", path, body);

  try {
    const response = await fetchAdapter.put(path, {body: JSON.stringify(body)});

    if (response.ok) {
      if (successCallback !== undefined) {
        successCallback(response);
      }
    } else if (errorCallback !== undefined) {
      errorCallback(response);
    }

    if (finalCallback !== undefined) {
      finalCallback();
    }
  } catch (error) {
    logger.error("Beim fetchServicePut von " + path + " ist ein Fehler aufgetreten:", error);

    if (errorCallback !== undefined) {
      const errorBody = JSON.stringify({
        errorMessages: [
          {
            messageKey: "framework.error.fetchServicePut"
          }
        ]
      });
      const response = new Response(errorBody, {status: 500});
      errorCallback(response);
    }

    if (finalCallback !== undefined) {
      finalCallback();
    }
  }
};
