/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, makeObservable, observable } from "mobx";
import moment, { Moment } from "moment";
import { DayOfMonth, MonthOfYear, WeekOfMonth, Year } from "../types/DatePickerTypes";
import { ResourceDataStore } from "./ResourceDataStore";
import { dateFormatForLanguage, KeyValueType } from "..";

export const LOCALIZATION_PREFIX = "dateRangePicker";

const allowedDateCharactersRegExDe = /^[0-9.]+$/;
const allowedDateCharactersRegExEn = /^[0-9\/]+$/;

// allows 01.01.1900 -- 31.12.2999 in three blocks for months with (a) 31 days, (b) 30 days, and (c) february = 29
const dateValidationRegEx = /^((([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))([.\/])(0?[13578]|10|12)([.\/])((19)(\d{2})|(2)(\d{3}))|(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))([.\/])(0?[469]|11)([.\/])((19)(\d{2})|(2)(\d{3}))|(([1-9])|(0[1-9])|([12])([0-9]?))([.\/])(0?[2])([.\/])((19)(\d{2})|(2)(\d{3})))$/;

export type PickerSide = "left" | "right";

export type MaxDateRangeType = {
  length: number,
  granularity: "days" | "weeks" | "months" | "years"
}

export interface DateRangePreset {
  getLocalization: () => string;
  getStartDate: () => moment.Moment;
  getEndDate: () => moment.Moment;
}

export class DateRangePickerDataStore {
  private static readonly OFFSET_MINIMUM_YEARS = -10;
  private static readonly OFFSET_MAXIMUM_YEARS = +10;

  earliestAllowedDate: moment.Moment | null = null;

  latestAllowedDate: moment.Moment | null = null;

  maxDateRange: MaxDateRangeType = {
    length: 1,
    granularity: "years"
  };

  selectedStartYear: number | undefined;
  selectedStartMonth: number | undefined;
  selectedStartDay: number | undefined;
  selectedEndYear: number | undefined;
  selectedEndMonth: number | undefined;
  selectedEndDay: number | undefined;
  manualStartDate: string | undefined;
  manualEndDate: string | undefined;
  activeStartDate!: moment.Moment;
  activeEndDate!: moment.Moment;
  leftDisplayedMonth!: number;
  leftDisplayedYear!: number;
  rightDisplayedMonth!: number;
  rightDisplayedYear!: number;
  showWeekNumbers: boolean = false;
  openRangePickerToLeft: boolean = false;
  initialRangePreset: number = 0;
  hoveredOverDay: number | undefined = undefined;
  hoveredOverMonth: number | undefined = undefined;
  hoveredOverYear: number | undefined = undefined;
  validationError: string | undefined = undefined;
  isSubmitActive: boolean = false;

  setEarliestAllowedDate(earliestAllowedDate: moment.Moment | null) {
    this.earliestAllowedDate = earliestAllowedDate;
  }

  setLatestAllowedDate(latestAllowedDate: moment.Moment | null) {
    this.latestAllowedDate = latestAllowedDate;
  }

  setMaxDateRange(maxDateRange: MaxDateRangeType) {
    this.maxDateRange = maxDateRange;
  }

  setSelectedStartYear(selectedStartYear: number | undefined) {
    this.selectedStartYear = selectedStartYear;
  }

  setSelectedStartMonth(selectedStartMonth: number | undefined) {
    this.selectedStartMonth = selectedStartMonth;
  }

  setSelectedStartDay(selectedStartDay: number | undefined) {
    this.selectedStartDay = selectedStartDay;
  }

  setSelectedEndYear(selectedEndYear: number | undefined) {
    this.selectedEndYear = selectedEndYear;
  }

  setSelectedEndMonth(selectedEndMonth: number | undefined) {
    this.selectedEndMonth = selectedEndMonth;
  }

  setManualStartDate(manualStartDate: string | undefined) {
    this.manualStartDate = manualStartDate;
  }

  setManualEndDate(manualEndDate: string | undefined) {
    this.manualEndDate = manualEndDate;
  }

  setSelectedEndDay(selectedEndDay: number | undefined) {
    this.selectedEndDay = selectedEndDay;
  }

  setLeftDisplayedMonth(leftDisplayedMonth: number) {
    this.leftDisplayedMonth = leftDisplayedMonth;
  }

  setLeftDisplayedYear(leftDisplayedYear: number) {
    this.leftDisplayedYear = leftDisplayedYear;
  }

  setRightDisplayedMonth(rightDisplayedMonth: number) {
    this.rightDisplayedMonth = rightDisplayedMonth;
  }

  setRightDisplayedYear(rightDisplayedYear: number) {
    this.rightDisplayedYear = rightDisplayedYear;
  }

  setShowWeekNumber(showWeekNumbers: boolean) {
    this.showWeekNumbers = showWeekNumbers;
  }

  setOpenRangePickerToLeft(openRangePickerToLeft: boolean) {
    this.openRangePickerToLeft = openRangePickerToLeft;
  }

  setSelectedStartDate(startDate: Moment) {
    if (startDate.isValid() && !startDate.isSame(this.selectedStartDate)) {
      this.resetManualEntries();
      this.setSelectedStartYear(startDate.year());
      this.setSelectedStartMonth(startDate.month());
      this.setSelectedStartDay(startDate.date());
    }
  }

  setSelectedEndDate(endDate: Moment) {
    if (endDate.isValid() && !endDate.isSame(this.selectedEndDate)) {
      this.resetManualEntries();
      this.setSelectedEndYear(endDate.year());
      this.setSelectedEndMonth(endDate.month());
      this.setSelectedEndDay(endDate.date());
    }
  }

  resetManualEntries() {
    this.setManualStartDate(undefined);
    this.setManualEndDate(undefined);
  }

  resetPickerDates() {
    this.setSelectedStartYear(undefined);
    this.setSelectedStartMonth(undefined);
    this.setSelectedStartDay(undefined);
    this.setSelectedEndYear(undefined);
    this.setSelectedEndMonth(undefined);
    this.setSelectedEndDay(undefined);
  }

  setHoveredOverDay(hoveredOverDay: number | undefined) {
    this.hoveredOverDay = hoveredOverDay;
  }

  setHoveredOverMonth(hoveredOverMonth: number | undefined) {
    this.hoveredOverMonth = hoveredOverMonth;
  }

  setHoveredOverYear(hoveredOverYear: number | undefined) {
    this.hoveredOverYear = hoveredOverYear;
  }

  setValidationError(validationError: string | undefined) {
    this.validationError = validationError;
  }

  setIsSubmitActive(toActive: boolean) {
    this.isSubmitActive = toActive;
  }

  private setActiveDates(activeStartDate: moment.Moment, activeEndDate: moment.Moment) {
    this.activeStartDate = activeStartDate;
    this.activeEndDate = activeEndDate;
  }

  constructor(
    readonly resourceDataStore: ResourceDataStore,
    readonly rangePresets?: DateRangePreset[],
    public dateFormat: string = "L"
    ) {
    this.resourceDataStore = resourceDataStore;
    if (rangePresets && rangePresets.length > 0) {
      this.rangePresets = rangePresets;
      this.applyPresetRange(this.rangePresets[this.initialRangePreset]);
    } else {
      this.setDisplayedMonths(moment().subtract(1, "months"), moment());
      this.setSelectedStartDate(moment());
      this.setSelectedEndDate(moment());
    }
    this.commitSelectedDateAsActive();
    makeObservable<DateRangePickerDataStore, "setActiveDates">(this, {
      earliestAllowedDate: observable,
      latestAllowedDate: observable,
      maxDateRange: observable,
      selectedStartYear: observable,
      selectedStartMonth: observable,
      selectedStartDay: observable,
      selectedEndYear: observable,
      selectedEndMonth: observable,
      selectedEndDay: observable,
      manualStartDate: observable,
      manualEndDate: observable,
      activeStartDate: observable,
      activeEndDate: observable,
      leftDisplayedMonth: observable,
      leftDisplayedYear: observable,
      rightDisplayedMonth: observable,
      rightDisplayedYear: observable,
      showWeekNumbers: observable,
      openRangePickerToLeft: observable,
      initialRangePreset: observable,
      hoveredOverDay: observable,
      hoveredOverMonth: observable,
      hoveredOverYear: observable,
      validationError: observable,
      isSubmitActive: observable,
      setEarliestAllowedDate: action,
      setLatestAllowedDate: action,
      setMaxDateRange: action,
      setSelectedStartYear: action,
      setSelectedStartMonth: action,
      setSelectedStartDay: action,
      setSelectedEndYear: action,
      setSelectedEndMonth: action,
      setManualStartDate: action,
      setManualEndDate: action,
      setSelectedEndDay: action,
      setLeftDisplayedMonth: action,
      setLeftDisplayedYear: action,
      setRightDisplayedMonth: action,
      setRightDisplayedYear: action,
      setShowWeekNumber: action,
      setOpenRangePickerToLeft: action,
      setSelectedStartDate: action,
      setSelectedEndDate: action,
      resetManualEntries: action,
      resetPickerDates: action,
      setHoveredOverDay: action,
      setHoveredOverMonth: action,
      setHoveredOverYear: action,
      setValidationError: action,
      setIsSubmitActive: action,
      setActiveDates: action,
      selectedStartDate: computed,
      selectedEndDate: computed,
      startDateFormatted: computed,
      endDateFormatted: computed,
      currentHoveredOverDate: computed,
      selectedPresetKey: computed,
      applyPresetRangeKey: action,
      onPreviousMonth: action,
      onNextMonth: action,
      onLeftMonthSelected: action,
      onRightMonthSelected: action,
      onLeftYearSelected: action,
      onRightYearSelected: action,
      onLeftDaySelected: action,
      onRightDaySelected: action,
      onDateSelected: action,
      onChangeStartDate: action,
      onChangeEndDate: action,
      onLeftMouseEnterDay: action,
      onRightMouseEnterDay: action,
      onMouseEnterDate: action,
      onLeftMouseLeaveDay: action,
      onRightMouseLeaveDay: action,
      onMouseLeaveDate: action,
      jumpToSelectedDates: action,
    });
  }

  getRangePresetsAsKeyValue(): KeyValueType[] {
    return this.rangePresets!.map((preset, index) => ({key: index.toString(), value: preset.getLocalization()}));
  }

  get selectedPresetKey(): string | undefined {
    if (!this.rangePresets) {
      return undefined;
    }
    for (let i = 0; i < this.rangePresets.length; i++) {
      const preset = this.rangePresets[i];
      const startDate = preset.getStartDate();
      const endDate = preset.getEndDate();
      if (startDate.isSame(this.selectedStartDate, "day") && endDate.isSame(this.selectedEndDate, "day")) {
        return i.toString();
      }
    }
    return undefined;
  }

  private readonly _initDaysOfMonth = (startWeek: number, endWeek: number, displayedYear: number, displayedMonth: number): WeekOfMonth[] => {
    const weeksOfMonth: WeekOfMonth[] = [];
    let date = moment([displayedYear, displayedMonth]);
    let weekCount = endWeek - startWeek;

    // Fall Januar+Dezember, wenn KW eines Jahres in das andere reinreicht.
    if (weekCount < 0) {
      if (displayedMonth === 0) {
        date = moment([displayedYear - 1, 11]);
      }

      weekCount = date.weeksInYear() - startWeek + endWeek;
    }

    for (let i: number = 0; i <= weekCount; i++) {
      weeksOfMonth[i] = {calenderweek: 0, days: []};

      for (let j: number = 0; j < 7; j++) {
        weeksOfMonth[i].days[j] = {
          day: 0,
          old: false,
          new: false,
          selected: false,
          clickable: false,
          today: false,
        };
      }
    }

    return weeksOfMonth;
  };

  private readonly _initDaysOfDisplayedMonth = (
      weeksOfMonth: WeekOfMonth[],
      startOfCurrentMonth: moment.Moment,
      endOfCurrentMonth: moment.Moment,
      daysOfPreviousMonth: number,
      displayedYear: number,
      displayedMonth: number
  ): void => {
    const dateInMonth = startOfCurrentMonth.clone();
    for (let dayInMonth: number = 1; dayInMonth <= endOfCurrentMonth.get("date"); dayInMonth++) {
      const weekIndex: number = Math.floor(((dayInMonth - 1) + daysOfPreviousMonth) / 7);
      const dayInWeekIndex: number = ((dayInMonth - 1) + daysOfPreviousMonth) % 7;
      if (weekIndex >= weeksOfMonth.length) {
        break;
      }

      // Ineffizient immer neu zu setzen, aber hier wissen wir ein konkretes Datum. Gerade KW zu Jahresbeginn ist
      // komplexer zu bestimmen.
      weeksOfMonth[weekIndex].calenderweek = dateInMonth.week();

      weeksOfMonth[weekIndex].days[dayInWeekIndex].day = dateInMonth.date();
      weeksOfMonth[weekIndex].days[dayInWeekIndex].selected = this._isDaySelected(displayedYear, displayedMonth, dayInMonth);
      weeksOfMonth[weekIndex].days[dayInWeekIndex].clickable =
          (!this.earliestAllowedDate || dateInMonth.isSameOrAfter(this.earliestAllowedDate, "day"))
          && (!this.latestAllowedDate || dateInMonth.isSameOrBefore(this.latestAllowedDate, "day"));
      weeksOfMonth[weekIndex].days[dayInWeekIndex].positionInRange = this._calcPositionInRange(dateInMonth);
      weeksOfMonth[weekIndex].days[dayInWeekIndex].today = dateInMonth.isSame(moment(), "day");
      dateInMonth.add(1, "days");
    }
  };

  get selectedStartDate() {
    return moment([(this.selectedStartYear ?? 0), (this.selectedStartMonth ?? 0), (this.selectedStartDay ?? 1)]);
  }

  get selectedEndDate() {
    return moment([(this.selectedEndYear ?? 9999), (this.selectedEndMonth ?? 11), (this.selectedEndDay ?? 31)]);
  }

  get startDateFormatted(): string {
    const startDate = this.selectedStartDate;
    if (this.isStartDateSelected() && startDate.isValid()) {
      return startDate.format(this.dateFormat);
    } else {
      return this.manualStartDate ? this.manualStartDate : "";
    }
  }

  get endDateFormatted(): string {
    const endDate = this.selectedEndDate;
    if (this.isEndDateSelected() && endDate.isValid()) {
      return endDate.format(this.dateFormat);
    } else {
      return this.manualEndDate ? this.manualEndDate : "";
    }
  }

  get currentHoveredOverDate() {
    if (this.hoveredOverDay !== undefined && this.hoveredOverMonth !== undefined && this.hoveredOverYear !== undefined) {
      return moment([this.hoveredOverYear, this.hoveredOverMonth, this.hoveredOverDay]);
    } else {
      return null;
    }
  }

  //  Es ist nicht genau definiert, ob das und andere Jahre angezeigt werden,
  //  wenn das ausgewählte Datum ausserhalb des normal gültigen Jahresbereiches liegt.
  private _minDisplayedYear(side: PickerSide): number {
    const displayedYear = side === "left" ? this.leftDisplayedYear : this.rightDisplayedYear;
    const selectedYear = side === "left" ? this.selectedStartYear : this.selectedEndYear;
    return Math.min(
        // Grenzjahr, sonst undefinierte 10 Jahre Abstand von jetzt.
        this.earliestAllowedDate?.year() ?? (moment().year() + DateRangePickerDataStore.OFFSET_MINIMUM_YEARS),
        // gerade angezeigtes Jahr
        displayedYear,
        // Nur wenn ein Jahr ausgewählt...
        selectedYear ?? Number.MAX_SAFE_INTEGER
    );
  }

  private _maxDisplayedYear(side: PickerSide): number {
    const displayedYear = side === "left" ? this.leftDisplayedYear : this.rightDisplayedYear;
    const selectedYear = side === "left" ? this.selectedStartYear : this.selectedEndYear;
    return Math.max(
        this.latestAllowedDate?.year() ?? (moment().year() + DateRangePickerDataStore.OFFSET_MAXIMUM_YEARS),
        displayedYear,
        selectedYear ?? Number.MIN_SAFE_INTEGER
    );
  }

  private readonly _calcPositionInRange = (date: Moment): "start" | "middle" | "end" | null => {
    let startOfRange: Moment | undefined = undefined;
    let endOfRange: Moment | undefined = undefined;
    if (this._isRangeSelected()) {
      if (!this.selectedStartDate.isSame(this.selectedEndDate)) {
        startOfRange = this.selectedStartDate;
        endOfRange = this.selectedEndDate;
      }
    } else if (this.isStartDateSelected() && this.currentHoveredOverDate && !this.currentHoveredOverDate.isSame(this.selectedStartDate)) {
      if (this.currentHoveredOverDate.isBefore(this.selectedStartDate)) {
        startOfRange = this.currentHoveredOverDate;
        endOfRange = this.selectedStartDate;
      } else {
        startOfRange = this.selectedStartDate;
        endOfRange = this.currentHoveredOverDate;
      }
    }
    if (startOfRange && endOfRange && date.isBetween(startOfRange, endOfRange, undefined, "[]")) {
      if (date.isSame(startOfRange)) {
        return "start";
      } else if (date.isSame(endOfRange)) {
        return "end";
      } else {
        return "middle";
      }
    } else {
      return null;
    }
  };

  private readonly _isDaySelected = (displayedYear: number, displayedMonth: number, day: number): boolean => {
    return (displayedYear === this.selectedStartYear && displayedMonth === this.selectedStartMonth && this.selectedStartDay === day)
        || (displayedYear === this.selectedEndYear && displayedMonth === this.selectedEndMonth && this.selectedEndDay === day);
  };

  private readonly _isRangeSelected = (): boolean => {
    return this.isStartDateSelected()
        && this.isEndDateSelected();
  };

  private readonly getDateFormat = (): string => {
    return dateFormatForLanguage(moment.locale().substr(0, 2));
  };

  isStartDateSelected = (): boolean => {
    return this.selectedStartDay !== undefined && this.selectedStartMonth !== undefined && this.selectedStartYear !== undefined;
  };

  isEndDateSelected = (): boolean => {
    return this.selectedEndDay !== undefined && this.selectedEndMonth !== undefined && this.selectedEndYear !== undefined;
  };

  isPreviousMonthAvailable = (side: PickerSide): boolean => {
    const leftDate = moment([this.leftDisplayedYear, this.leftDisplayedMonth]);
    const rightDate = moment([this.rightDisplayedYear, this.rightDisplayedMonth]);
    const earliestAllowedDate = this.earliestAllowedDate || moment([this._minDisplayedYear(side), 0, 1]);
    if (side === "left") {
      return earliestAllowedDate < leftDate;
    } else {
      return earliestAllowedDate < rightDate.subtract(1, "months");
    }
  };

  isNextMonthAvailable = (side: PickerSide): boolean => {
    const leftDate = moment([this.leftDisplayedYear, this.leftDisplayedMonth]);
    const rightDate = moment([this.rightDisplayedYear, this.rightDisplayedMonth]);
    const latestAllowedDate = this.latestAllowedDate || moment([this._maxDisplayedYear(side), 11]);
    if (side === "right") {
      rightDate.add(1, "months");
      rightDate.subtract(1, "days");
      return latestAllowedDate > rightDate;
    } else {
      const leftNextMonth = leftDate.add(1, "month");
      const leftReachesBound = leftNextMonth.isSameOrAfter(this.latestAllowedDate!, "month");
      return !leftReachesBound;
    }
  };

  getOnPreviousMonth(side: PickerSide): () => void {
    return () => this.onPreviousMonth(side);
  }

  getOnNextMonth(side: PickerSide): () => void {
    return () => this.onNextMonth(side);
  }

  getOnMonthSelected(side: PickerSide): (month: number) => void {
    return side === "left" ? this.onLeftMonthSelected : this.onRightMonthSelected;
  }

  getOnYearSelected(side: PickerSide): (month: number) => void {
    return side === "left" ? this.onLeftYearSelected : this.onRightYearSelected;
  }

  getDisplayedMonth(side: PickerSide) {
    return side === "left" ? this.leftDisplayedMonth : this.rightDisplayedMonth;
  }

  getDisplayedYear(side: PickerSide) {
    return side === "left" ? this.leftDisplayedYear : this.rightDisplayedYear;
  }

  getOnDaySelected(side: PickerSide) {
    return side === "left" ? this.onLeftDaySelected : this.onRightDaySelected;
  }

  getOnMouseLeaveDay(side: PickerSide) {
    return side === "left" ? this.onLeftMouseLeaveDay : this.onRightMouseLeaveDay;
  }

  getOnMouseEnterDay(side: PickerSide) {
    return side === "left" ? this.onLeftMouseEnterDay : this.onRightMouseEnterDay;
  }

  selectableMonthsOfYear(side: PickerSide): MonthOfYear[] {
    const months: MonthOfYear[] = [];
    const displayedYear = side === "left" ? this.leftDisplayedYear : this.rightDisplayedYear;
    // initialize date as beginning of displayed year
    const date = moment([displayedYear]);
    const selectedYear = side === "left" ? this.selectedStartYear : this.selectedEndYear;
    const selectedMonth = side === "left" ? this.selectedStartMonth : this.selectedEndMonth;
    for (let numberOfMonth: number = 0; numberOfMonth < 12; numberOfMonth++) {
      const dateIsLaterThanEarliestAllowed = (!this.earliestAllowedDate
          || (side === "left" && date.isSameOrAfter(this.earliestAllowedDate, "month"))
          || (side === "right" && date.isAfter(this.earliestAllowedDate, "month")));
      const dateIsEarlierThanLatestAllowed = (!this.latestAllowedDate
          || (side === "left" && date.isBefore(this.latestAllowedDate, "month"))
          || (side === "right" && date.isSameOrBefore(this.latestAllowedDate, "month")));
      const dateIsCurrentlySelected = selectedMonth === numberOfMonth && selectedYear === displayedYear;
      months[numberOfMonth] = {
        month: numberOfMonth,
        selected: dateIsCurrentlySelected,
        clickable: dateIsLaterThanEarliestAllowed && dateIsEarlierThanLatestAllowed,
      };
      date.add(1, "months");
    }
    return months;
  }

  selectableYears(side: PickerSide): Year[] {
    const years: Year[] = [];

    const selectedYear = side === "left" ? this.selectedStartYear : this.selectedEndYear;
    let year = this._minDisplayedYear(side);
    const date = moment([year]);
    for (let j: number = 0; j < this._maxDisplayedYear(side) - this._minDisplayedYear(side) + 1; j++) {
      years[j] = {
        year: year,
        selected: selectedYear === year,
        clickable: (!this.earliestAllowedDate || date.isSameOrAfter(this.earliestAllowedDate, "year"))
            && (!this.latestAllowedDate || date.isSameOrBefore(this.latestAllowedDate, "year"))
      };

      date.add(1, "year");
      year++;
    }

    return years;
  }

  daysOfMonthGroupedByWeek(side: PickerSide): WeekOfMonth[] {
    const displayedMonth = side === "left" ? this.leftDisplayedMonth : this.rightDisplayedMonth;
    const displayedYear = side === "left" ? this.leftDisplayedYear : this.rightDisplayedYear;
    const startOfDisplayedMonth: moment.Moment = moment([displayedYear, displayedMonth]);
    const endOfDisplayedMonth: moment.Moment = moment([displayedYear, displayedMonth]);

    endOfDisplayedMonth.add(1, "months");
    endOfDisplayedMonth.subtract(1, "days");

    const weekdayStart: number = startOfDisplayedMonth.weekday();
    const weeksOfMonth: WeekOfMonth[] = this._initDaysOfMonth(startOfDisplayedMonth.week(), endOfDisplayedMonth.week(), displayedYear,
        displayedMonth);

    this._initDaysOfDisplayedMonth(weeksOfMonth, startOfDisplayedMonth, endOfDisplayedMonth, weekdayStart, displayedYear, displayedMonth);
    return weeksOfMonth;
  }

  applyPresetRangeKey(presetKey: string) {
    this.setValidationError(undefined);
    this.setIsSubmitActive(true);
    this.applyPresetRange(this.rangePresets![parseInt(presetKey)]);
  }

  private applyPresetRange(preset: DateRangePreset) {
    const presetStartDate = preset.getStartDate();
    const presetEndDate = preset.getEndDate();
    this.setSelectedStartDate(presetStartDate);
    this.setSelectedEndDate(presetEndDate);
    const startMonth = moment([presetStartDate.year(), presetStartDate.month()]);
    const endMonth = moment([presetEndDate.year(), presetEndDate.month()]);
    if (startMonth.isSame(endMonth)) {
      startMonth.subtract(1, "months");
    }
    this.setDisplayedMonths(startMonth, endMonth);
  }

  private setDisplayedMonths(startMonth: moment.Moment, endMonth: moment.Moment) {
    this.setLeftDisplayedMonth(startMonth.month());
    this.setLeftDisplayedYear(startMonth.year());
    this.setRightDisplayedMonth(endMonth.month());
    this.setRightDisplayedYear(endMonth.year());
  }

  onPreviousMonth = (side: PickerSide): void => {
    if (!this.isPreviousMonthAvailable(side)) {
      return;
    }
    const displayedYear = side === "left" ? this.leftDisplayedYear : this.rightDisplayedYear;
    const displayedMonth = side === "left" ? this.leftDisplayedMonth : this.rightDisplayedMonth;
    if (side === "left") {
      if (displayedMonth === 0) {
        this.setLeftDisplayedMonth(11);
        this.setLeftDisplayedYear(displayedYear - 1);
      } else {
        this.setLeftDisplayedMonth(displayedMonth - 1);
      }
    } else {
      let newDisplayedMonth, newDisplayedYear;
      if (displayedMonth === 0) {
        newDisplayedMonth = 11;
        newDisplayedYear = displayedYear - 1;
      } else {
        newDisplayedMonth = displayedMonth - 1;
        newDisplayedYear = displayedYear;
      }
      if (newDisplayedYear === this.leftDisplayedYear && newDisplayedMonth === this.leftDisplayedMonth) {
        this.onPreviousMonth("left");
      }
      this.setRightDisplayedMonth(newDisplayedMonth);
      this.setRightDisplayedYear(newDisplayedYear);
    }
    if (side === "left") {
      this.updateByLeftSelection(moment([this.leftDisplayedYear, this.leftDisplayedMonth]));
    } else {
      this.updateByRightSelection(moment([this.rightDisplayedYear, this.rightDisplayedMonth]));
    }
  };

  onNextMonth = (side: PickerSide): void => {
    if (!this.isNextMonthAvailable(side)) {
      return;
    }
    const displayedMonth = side === "left" ? this.leftDisplayedMonth : this.rightDisplayedMonth;
    const displayedYear = side === "left" ? this.leftDisplayedYear : this.rightDisplayedYear;

    if (side === "left") {
      let newDisplayedMonth, newDisplayedYear;
      if (displayedMonth === 11) {
        newDisplayedMonth = 0;
        newDisplayedYear = displayedYear + 1;
      } else {
        newDisplayedMonth = displayedMonth + 1;
        newDisplayedYear = displayedYear;
      }
      if (newDisplayedYear === this.rightDisplayedYear && newDisplayedMonth === this.rightDisplayedMonth) {
        this.onNextMonth("right");
      }
      this.setLeftDisplayedMonth(newDisplayedMonth);
      this.setLeftDisplayedYear(newDisplayedYear);
    } else {

      if (displayedMonth === 11) {
        this.setRightDisplayedMonth(0);
        this.setRightDisplayedYear(displayedYear + 1);
      } else {
        this.setRightDisplayedMonth(displayedMonth + 1);
      }
    }
    if (side === "left") {
      this.updateByLeftSelection(moment([this.leftDisplayedYear, this.leftDisplayedMonth]));
    } else {
      this.updateByRightSelection(moment([this.rightDisplayedYear, this.rightDisplayedMonth]));
    }
  };

  onLeftMonthSelected = (month: number): void => {
    const newDate = moment([this.leftDisplayedYear, month]);
    this.updateByLeftSelection(newDate);
  };

  onRightMonthSelected = (month: number): void => {
    const newDate = moment([this.rightDisplayedYear, month]);
    this.updateByRightSelection(newDate);
  };

  onLeftYearSelected = (year: number): void => {
    const newDate = moment([year, this.leftDisplayedMonth]);
    this.updateByLeftSelection(newDate);
  };

  onRightYearSelected = (year: number): void => {
    const newDate = moment([year, this.rightDisplayedMonth]);
    this.updateByRightSelection(newDate);
  };

  onLeftDaySelected = (day: DayOfMonth) => {
    if (day.clickable) {
      const selectedDate = moment([this.leftDisplayedYear, this.leftDisplayedMonth, day.day]);
      this.onDateSelected(selectedDate);
    }
  };

  onRightDaySelected = (day: DayOfMonth) => {
    if (day.clickable) {
      const selectedDate = moment([this.rightDisplayedYear, this.rightDisplayedMonth, day.day]);
      this.onDateSelected(selectedDate);
    }
  };

  onDateSelected = (date: moment.Moment) => {
    this.setValidationError(undefined);
    this.setIsSubmitActive(true);
    if (this.isStartDateSelected() && !this.isEndDateSelected()) {
      const currentStartDate = this.selectedStartDate;
      if (date.isBefore(currentStartDate)) {
        this.setSelectedEndDate(currentStartDate);
        this.setSelectedStartDate(date);
      } else {
        this.setSelectedEndDate(date);
      }
      this.isValidDateRange();
    } else {
      this.setSelectedStartDate(date);
      this.setSelectedEndYear(undefined);
      this.setSelectedEndMonth(undefined);
      this.setSelectedEndDay(undefined);
    }
  };

  onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "" || this.manualValueOnlyContainsAllowedCharacters(event.target.value)) {
      if (!this.manualStartDate && !this.manualEndDate && this.isEndDateSelected()) {
        this.setManualEndDate(this.endDateFormatted);
      }
      this.resetPickerDates();
      this.setManualStartDate(event.target.value);
      if (dateValidationRegEx.test(event.target.value) && dateValidationRegEx.test(this.manualEndDate ? this.manualEndDate : "")) {
        const newStartDate = moment(event.target.value, this.getDateFormat());
        const newEndDate = moment(this.manualEndDate, this.getDateFormat());
        if (this.isValidDateRange()) {
          this.setSelectedStartDate(newStartDate);
          this.setSelectedEndDate(newEndDate);
          this.jumpToSelectedDates();
        }
      }
    }
  };

  onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "" || this.manualValueOnlyContainsAllowedCharacters(event.target.value)) {
      if (!this.manualStartDate && !this.manualEndDate && this.isStartDateSelected()) {
        this.setManualStartDate(this.startDateFormatted);
      }
      this.resetPickerDates();
      this.setManualEndDate(event.target.value);
      if (dateValidationRegEx.test(event.target.value) && dateValidationRegEx.test(this.manualStartDate ? this.manualStartDate : "")) {
        const newStartDate = moment(this.manualStartDate, this.getDateFormat());
        const newEndDate = moment(event.target.value, this.getDateFormat());
        if (this.isValidDateRange()) {
          this.setSelectedStartDate(newStartDate);
          this.setSelectedEndDate(newEndDate);
          this.jumpToSelectedDates();
        }
      }
    }
  };

  manualValueOnlyContainsAllowedCharacters = (enteredDate: string): boolean => {
    if (moment.locale().startsWith("en")) {
      return allowedDateCharactersRegExEn.test(enteredDate);
    } else {
      return allowedDateCharactersRegExDe.test(enteredDate);
    }
  };

  isValidDateRange = (): boolean => {
    if (!this._isRangeSelected()) {
      // validate both dates are valid
      const newStartDate = moment(this.manualStartDate, this.getDateFormat());
      const newEndDate = moment(this.manualEndDate, this.getDateFormat());
      if (!dateValidationRegEx.test(this.manualStartDate ? this.manualStartDate : "")
          || !dateValidationRegEx.test(this.manualEndDate ? this.manualEndDate : "")
          || !newStartDate.isValid() || !newEndDate.isValid()) {
        this.setValidationError(this.resourceDataStore.getError(`${LOCALIZATION_PREFIX}.invalidDate`));
        return false;
      }
      // validate start date before end date
      if (newStartDate.isAfter(newEndDate)) {
        this.setValidationError(this.resourceDataStore.getError(`${LOCALIZATION_PREFIX}.invalidDateOrder`));
        return false;
      }
      // are dates in allowed limits
      if ((this.earliestAllowedDate && (newStartDate.isBefore(this.earliestAllowedDate) || newEndDate.isBefore(this.earliestAllowedDate)))
          || (this.latestAllowedDate && (newStartDate.isAfter(this.latestAllowedDate) || newEndDate.isAfter(this.latestAllowedDate)))) {
        this.setValidationError(this.resourceDataStore.getError(
          `${LOCALIZATION_PREFIX}.datesOutsideAllowedRange`, 
          [
            this.earliestAllowedDate ? this.earliestAllowedDate.format(this.dateFormat) : "", 
            this.latestAllowedDate ? this.latestAllowedDate.format(this.dateFormat) : ""
          ]),
        );
        return false;
      }
    }

    const startDate: Moment = this.isStartDateSelected() ? this.selectedStartDate.clone() : moment(this.manualStartDate, this.getDateFormat());
    const endDate: Moment = this.isEndDateSelected() ? this.selectedEndDate.clone() : moment(this.manualEndDate, this.getDateFormat());

    // validate max range
    endDate.subtract(this.maxDateRange.length, this.maxDateRange.granularity);
    if (endDate.isAfter(startDate)) {
      this.setValidationError(this.resourceDataStore.getError(`${LOCALIZATION_PREFIX}.rangeExceedsMaxRange`));
      return false;
    }

    this.setValidationError(undefined);
    return true;
  };

  onLeftMouseEnterDay = (day: DayOfMonth) => {
    if (this.isStartDateSelected() && !this.isEndDateSelected()) {
      const hoveredOverDate = moment([this.leftDisplayedYear, this.leftDisplayedMonth, day.day]);
      this.onMouseEnterDate(hoveredOverDate);
    }
  };

  onRightMouseEnterDay = (day: DayOfMonth) => {
    if (this.isStartDateSelected() && !this.isEndDateSelected()) {
      const hoveredOverDate = moment([this.rightDisplayedYear, this.rightDisplayedMonth, day.day]);
      this.onMouseEnterDate(hoveredOverDate);
    }
  };

  onMouseEnterDate = (date: moment.Moment) => {
    this.setHoveredOverYear(date.year());
    this.setHoveredOverMonth(date.month());
    this.setHoveredOverDay(date.date());
  };

  onLeftMouseLeaveDay = (day: DayOfMonth) => {
    if (this.isStartDateSelected() && !this.isEndDateSelected()) {
      const oldHoveredOverDate = moment([this.leftDisplayedYear, this.leftDisplayedMonth, day.day]);
      if (!this.currentHoveredOverDate || this.currentHoveredOverDate.isSame(oldHoveredOverDate)) {
        this.onMouseLeaveDate();
      }
    }
  };

  onRightMouseLeaveDay = (day: DayOfMonth) => {
    if (this.isStartDateSelected() && !this.isEndDateSelected()) {
      const oldHoveredOverDate = moment([this.rightDisplayedYear, this.rightDisplayedMonth, day.day]);
      if (!this.currentHoveredOverDate || this.currentHoveredOverDate.isSame(oldHoveredOverDate)) {
        this.onMouseLeaveDate();
      }
    }
  };

  onMouseLeaveDate = () => {
    this.setHoveredOverYear(undefined);
    this.setHoveredOverMonth(undefined);
    this.setHoveredOverDay(undefined);
  };

  jumpToSelectedDates = () => {
    const startMonth = this.selectedStartDate.clone();
    const endMonth = this.selectedEndDate.clone();
    if (startMonth.isSame(endMonth, "month")) {
      startMonth.subtract(1, "months");
    }
    this.leftDisplayedMonth = startMonth.month();
    this.leftDisplayedYear = startMonth.year();
    this.rightDisplayedMonth = endMonth.month();
    this.rightDisplayedYear = endMonth.year();
  };


  private updateByRightSelection(newDate: moment.Moment) {
    this.setIsSubmitActive(true);
    if (newDate.isAfter(this.latestAllowedDate!, "day")) {
      newDate = moment(this.latestAllowedDate!);
    } else if (newDate.isSameOrBefore(this.earliestAllowedDate!, "month")) {
      newDate = moment(this.earliestAllowedDate!);
      newDate = newDate.add(1, "month");
    }

    this.setRightDisplayedMonth(newDate.month());
    this.setRightDisplayedYear(newDate.year());

    if (newDate.isSameOrBefore(moment([this.leftDisplayedYear, this.leftDisplayedMonth]), "month")) {
      if (newDate.month() === 0) {
        this.setLeftDisplayedMonth(11);
        this.setLeftDisplayedYear(newDate.year() - 1);
      } else {
        this.setLeftDisplayedMonth(newDate.month() - 1);
        this.setLeftDisplayedYear(newDate.year());
      }
    }
    this.setVisibleDatesAsSelected();
  }

  private updateByLeftSelection(newDate: moment.Moment) {
    this.setIsSubmitActive(true);
    if (newDate.isBefore(this.earliestAllowedDate!, "day")) {
      newDate = moment(this.earliestAllowedDate!);
    } else if (newDate.isSameOrAfter(this.latestAllowedDate!, "month")) {
      newDate = moment(this.latestAllowedDate!);
      newDate = newDate.subtract(1, "month");
    }

    this.setLeftDisplayedMonth(newDate.month());
    this.setLeftDisplayedYear(newDate.year());

    if (newDate.isSameOrAfter(moment([this.rightDisplayedYear, this.rightDisplayedMonth]), "month")) {
      if (newDate.month() === 11) {
        this.setRightDisplayedMonth(0);
        this.setRightDisplayedYear(newDate.year() + 1);
      } else {
        this.setRightDisplayedMonth(newDate.month() + 1);
        this.setRightDisplayedYear(newDate.year());
      }
    }
    this.setVisibleDatesAsSelected();
  }

  private setVisibleDatesAsSelected() {
    const firstDayOfLeftMonth = moment(new Date(this.leftDisplayedYear, this.leftDisplayedMonth, 1));
    const lastDayOfRightMonth = moment(new Date(this.rightDisplayedYear, this.rightDisplayedMonth + 1, 0));
    if (firstDayOfLeftMonth.isBefore(this.earliestAllowedDate!)) {
      this.setSelectedStartDate(this.earliestAllowedDate!);
    } else {
      this.setSelectedStartDate(firstDayOfLeftMonth);
    }
    if (lastDayOfRightMonth.isSameOrBefore(this.latestAllowedDate!)) {
      this.setSelectedEndDate(lastDayOfRightMonth);
    } else {
      this.setSelectedEndDate(this.latestAllowedDate!);
    }
    this.isValidDateRange();
  }


  adjustPickerToActiveDates() {
    this.setSelectedStartDate(this.activeStartDate);
    this.setSelectedEndDate(this.activeEndDate);
    const leftDate = this.activeStartDate.clone();
    const rightDate = this.activeEndDate.clone();
    if (this.activeStartDate.isSame(this.activeEndDate, "month")) {
      if (this.earliestAllowedDate && leftDate.isSame(this.earliestAllowedDate, "month")) {
        rightDate.add(1, "months");
      } else {
        leftDate.subtract(1, "months");
      }
    }
    this.setLeftDisplayedMonth(leftDate.month());
    this.setRightDisplayedMonth(rightDate.month());
    this.setLeftDisplayedYear(leftDate.year());
    this.setRightDisplayedYear(rightDate.year());
  }

  commitSelectedDateAsActive() {
    this.setActiveDates(this.selectedStartDate, this.selectedEndDate);
    this.setIsSubmitActive(false);
  }
}
