/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import {useCallback, Fragment} from "react";
import { PaginationDataStore } from "../../../stores/PaginationDataStore";
import { DHLIcon } from "../../atoms/DHLIcon/DHLIcon";
import "./DHLPagination.scss";

export type DHLPaginationProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Pager-Datenspeicher. */
  dataStore: PaginationDataStore;
};

/** Seitennavigation, z.B. für Tabellen */
export const DHLPagination = observer(({name, dataStore}: DHLPaginationProps
) => {
  const onClickPageChange = useCallback((event: React.MouseEvent, selectedPageNo: number) => {
    dataStore.setCurrentPage(selectedPageNo);

    event.preventDefault();
    // eslint-disable-next-line
  }, []);

  const renderPreviousPageLinkButton = () => {
    return (
        <li key={"previous-page-link-list-item"}
            data-testid={"previous-page-link-list-item"}
            className={classNames(
                "dhlPagination-item", "previous-page-link",
                dataStore.currentPage > 1 ? "previous-page-link-active" : null
            )}>
          <button
              data-testid={name + "-previous"}
              className=""
              onClick={(event: React.MouseEvent) => {
                onClickPageChange(event, dataStore.currentPage - 1);
              }}
              disabled={dataStore.isCurrentPageFirstPage}>
            <DHLIcon
                name={""}
                icon={"link-arrow-prev"} />
          </button>
        </li>
    );
  };

  const renderNextPageLinkButton = () => {
    return (
        <li key={"next-page-link-list-item"}
            data-testid={"next-page-link-list-item"}
            className={classNames(
                "dhlPagination-item", "next-page-link",
                dataStore.noOfPages > dataStore.currentPage ? "next-page-link-active" : null
            )}>
          <button
              data-testid={name + "-next"}
              className=""
              onClick={(event: React.MouseEvent) => {
                onClickPageChange(event, dataStore.currentPage + 1);
              }}
              disabled={dataStore.isCurrentPageLastPage}
          ><DHLIcon name={""} icon={"link-arrow-next"} /></button>
        </li>
    );
  };

  const renderPageRange = () => {
    const pages = Array.from(new Array(dataStore.noOfPages), (val, index) => index + 1);

    const placeholderLeft = (
        <li key={"pagination-button-list-item-placeholder-left"}
            data-testid={"pagination-button-list-item-placeholder-left"}
            className={classNames("dhlPagination-item", "page-placeholder")}>
          <button data-testid={name + "-toomanypages"}>
            ...
          </button>
        </li>
    );
    const placeholderLeftRight = (
        <li key={(dataStore.noOfPages - 1)}
            data-testid={"pagination-button-list-item-placeholder-right"}
            className={classNames("dhlPagination-item", "page-placeholder")}>
          <button data-testid={name + "-toomanypages"}>
            ...
          </button>
        </li>
    );

    return pages.map(page => {
      const active = (
          <span
              data-testid={name + "-selectedpage-" + page}
              className="dhlPagination-item current-page">
          {page}
        </span>
      );
      const inactive = (
          <PageLinkButton
              testid={name}
              onClickPageChange={onClickPageChange}
              toPage={page}
          />
      );
      const pageButton = (
          <li
              key={page}
              className={classNames("dhlPagination-item", page === dataStore.currentPage ? "current-page" : "link-to-page")}
              data-testid={"pagination-button-list-item-" + page}
          >
            {page === dataStore.currentPage ? active : inactive}
          </li>
      );

      // A page Number is shown if:
      // 1. The current page is in the range of the first few pages and the page number to be displayed is lower than the cut off point
      // 2. The current page is in the range of the last few pages and the page number to be displayed is higher than the cut off point
      // 3. The current page is in the range of the center pages and the page number is higher/lower than the center offset
      // e.g.
      // maxPageButtons = 7
      // numberOfPages = 15
      // => range first pages = 1-4
      // => range of last pages = 12-15
      // => cut of points 5 and 11
      // => offset = 1
      //-------
      // current page 3 -> 1,2,[3],4,5,[.],15
      // current page 6 -> 1,[.],5,[6],7,[.],15
      // current page 12 -> 1,[.],11,[12],13,14,15
      const shownPageNumber = (dataStore.isCurrentPageAtStart && page <= (dataStore.cutOffPoint))
          || (dataStore.isCurrentPageAtEnd && page >= (dataStore.noOfPages - dataStore.cutOffPoint + 1))
          || (dataStore.currentPage >= (page - dataStore.centerOffset) && dataStore.currentPage <= (page + dataStore.centerOffset));

      let output = null;
      if (page === 1) {
        output = (
            <Fragment key="onFirst">
              {pageButton}
              {!dataStore.hasLessOrEqualMaxShownPages && !dataStore.isCurrentPageAtStart && placeholderLeft}
            </Fragment>
        );
      } else if (page === dataStore.noOfPages) {
        output = (
            <Fragment key="onLast">
              {!dataStore.hasLessOrEqualMaxShownPages && !dataStore.isCurrentPageAtEnd && placeholderLeftRight}
              {pageButton}
            </Fragment>
        );
      } else if (dataStore.hasLessOrEqualMaxShownPages) {
        output = pageButton;
      } else if (shownPageNumber) {
        output = pageButton;
      } else {
        // for every other page no output will be generated
      }
      return output;
    });
  };

  const moreThanOnePage = (dataStore.noOfPages > 1);
  const anyEntry = (dataStore.noOfPages > 0);
  let widgetOutput: JSX.Element | null = null;

  if (anyEntry && moreThanOnePage) {
    widgetOutput = (
        <nav data-testid={name}>
          <ul data-testid={"pagination-button-list"} className="dhlPagination">
            {renderPreviousPageLinkButton()}
            {renderPageRange()}
            {renderNextPageLinkButton()}
          </ul>
        </nav>
    );
  }

  return widgetOutput;
});

const PageLinkButton = (props: { testid: string, toPage: number, onClickPageChange: (event: React.MouseEvent, page: number) => void}) =>
    <button
        data-testid={props.testid + "-page-" + props.toPage}
        className={classNames("dhlPagination-item")}
        onClick={(event: React.MouseEvent) => props.onClickPageChange(event, props.toPage)}
    >
      {props.toPage.toString()}
    </button>;
