import { observer } from "mobx-react-lite";
import { CurrencyType } from "../../../types/DataTypes";
import { formatCurrency } from "../../../utils/formatter";
import { useLocaleManager } from "../../../locale/useLocaleManager";

export type DHLTableColCurrencyProps = {
  /** Displayed value */
  value?: any;

  /** Currency */
  currencyType?: CurrencyType;

  /** Display symbol or code (e.g. € or EUR)? **/
  displaySymbol?: boolean;

  /** Tausendertrennzeichen verwenden? */
  groupingEnabled?: boolean;

  /** Formatierer für Spalte. */
  formatter?: any;
};

/** Ausgabe einer Tabellenzelle - Währung. */
export const DHLTableColCurrency = observer(({value, currencyType = "EUR", displaySymbol = false, groupingEnabled = true, formatter}
                                                 : DHLTableColCurrencyProps
) => {
  const localeManager = useLocaleManager();
  let output = value;

  if (undefined !== output && null !== output) {
    if (formatter) {
      output = formatter.format(value);
    } else {
      output = formatCurrency(localeManager, value, groupingEnabled, currencyType, displaySymbol);
    }
  }

  return <>{output}</>;
});
