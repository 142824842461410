import classNames from "classnames";
import {FormGroup} from "reactstrap";
import "./DHLFormGroup.scss";
import { ReactNode } from "react";

/** Gruppierung innerhalb eines Formulars. */
export type DHLFormGroupProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;

  /** Fügt die "row" CSS-Klasse hinzu, wenn true. */
  row?: boolean;

  /** Fügt die "form-check-inline" CSS-Klasse hinzu, wenn true und gleichzeitig checkboxGroup true ist.*/
  inline?: boolean;

  /** Fügt die "form-check" CSS-Klasse hinzu, wenn true. Fügt die "form-group" CSS-Klase hinzu, wenn false. */
  checkboxGroup?: boolean;

  /** Fügt die "disabled" CSS-Klasse hinzu, wenn true und gleichzeitig checkboxGroup true ist.*/
  disabled?: boolean;

  /** Anzahl Spalten für extra small devices - Bildschirme mit weniger als 576px. */
  xs?: number;

  /** Anzahl Spalten für small devices - Bildschirme mit mindestens 576px. */
  sm?: number;

  /** Anzahl Spalten für medium devices - Bildschirme mit mindestens 768px. */
  md?: number;

  /** Anzahl Spalten für large devices - Bildschirme mit mindestens 992px. */
  lg?: number;

  /** Anzahl Spalten für extra large devices - Bildschirme mit mindestens 1200px. */
  xl?: number;

  /** CSS-Klassen */
  className?: string;

  /** Element ausgeben? */
  render?: boolean;

  /** Kind-Komponenten */
  children?: ReactNode;
};

/** Gruppierung innerhalb eines Formulars. */
export const  DHLFormGroup = ({
                               name,
                               row = false,
                               inline = false,
                               checkboxGroup = false,
                               disabled = false,
                               xs,
                               sm,
                               md,
                               lg,
                               xl,
                               className,
                               render = true,
                               children
                             }: DHLFormGroupProps) => {
  if (!render) {
    return null;
  }

  return (
      <FormGroup
          data-testid={name}
          className={classNames(
              className,
              checkboxGroup ? "dhl-radio-checkbox-group" : null,
              {[`col-${xs}`]: xs},
              {[`col-sm-${sm}`]: sm},
              {[`col-md-${md}`]: md},
              {[`col-lg-${lg}`]: lg},
              {[`col-xl-${xl}`]: xl}
          )}
          row={row}
          inline={inline}
          check={checkboxGroup}
          disabled={disabled}
      >
        {children}
      </FormGroup>
  );
};
