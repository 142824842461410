import classNames from "classnames";
import { ReactChild, ReactFragment, ReactNode, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DHLButton, DHLHelpButton, DHLIcon } from "../../..";
import "./DHLModal.scss";

/** Constant for the default value of colWidth*/
export const DEFAULT_COL_WIDTH = "col-8";
/** Constant for the default value of colOffset*/
export const DEFAULT_COL_OFFSET = "offset-2";

/** Props for the DHL modal dialog */
export type DHLModalProps = {
  /** Name used as test-id for testing. */
  name?: string;

  /** Flag indicating, if the dialog is currently shown */
  show: boolean;

  /** Title text. */
  title: string;

  /** DEPRECATED: Old prop for content of the dialog. If content is not set, message will be used. If both are set, content will supersede message */
  message?: string | any;

  /** Content of the dialog. */
  content?: ReactNode;

  /** Function triggered when the close icon is clicked. */
  onClickClose?: React.MouseEventHandler<HTMLElement>;

  /** Optional target for online help. If present an online help icon will be rendered. */
  onlineHelpTarget?: string;

  /** Cursor im Eingabefeld positionieren? */
  autoFocus?: boolean;

  /** Tooltip text for online help. MUST be present when onlineHelpTarget given. */
  onlineHelpTooltip?: string;

  /** Optional header component to render instead of title. If not set title is used */
  headerComponent?: JSX.Element

  /**
   * Actionbar/Button-Bereich
   *
   * Design-Hinweis:
   * Alles btn-normal.
   * Wenn es eine primäre und eine sekundäre Aktion gibt, steht die
   *  sekundäre Aktion als erstes und die primäre Aktion als zweites.
   * Wenn es neben primärer und sekundärer Aktion noch weitere gibt, werden diese als "Secondary Buttons"
   *  dargestellt und zwischen primärer und sekundärer Aktion eingefügt.
   */
  actionbar?: ReactChild | ReactFragment;

  /** DEPRECATED: Old prop for cancel button of the dialog. If actionbar is not set, cancelButton and okButton will be used. If actionbar is set,
   *  it will supersede the single buttons */
  cancelButton?: string;

  /** DEPRECATED: Old prop for ok button of the dialog. If actionbar is not set, cancelButton and okButton will be used. If actionbar is set,
   *  it will supersede the single buttons */
  okButton?: string;

  /** DEPRECATED: Old prop for cancel action of the dialog. If actionbar is not set, cancel and ok actions will be used. If actionbar is set,
   *  it will supersede the single actions */
  onClickCancel?: React.MouseEventHandler<HTMLElement>;

  /** DEPRECATED: Old prop for cancel action of the dialog. If actionbar is not set, cancel and ok actions will be used. If actionbar is set,
   *  it will supersede the single actions */
  onClickOk?: React.MouseEventHandler<HTMLElement>;

  /** Additional style classes added. */
  className?: string;

  /** Flag to deactivate the actionbar */
  noActionBar?: boolean;

  /** Sets colWidth of DHLModal */
  colWidth?: "col-4" | "col-6" | "col-8" | "col-10"

  /** Sets colOffset of DHLModal */
  colOffset?: "offset-1" | "offset-2" | "offset-3" | "offset-4"

};

/** Dialog zur Anzeige von Sicherheitsabfragen.  */
export const DHLModal = ({
                           name,
                           show,
                           title,
                           message,
                           content,
                           onClickClose,
                           autoFocus,
                           onlineHelpTarget,
                           onlineHelpTooltip,
                           headerComponent,
                           actionbar,
                           cancelButton,
                           okButton,
                           onClickCancel = () => { /* intended use */ },
                           onClickOk = () => { /* intended use */ },
                           className,
                           noActionBar = false,
                           colWidth = DEFAULT_COL_WIDTH,
                           colOffset = DEFAULT_COL_OFFSET
                         }: DHLModalProps) => {


      useEffect(() => {
        if (show) {
          window.scrollTo(0, 0);
        }
      }, [show]);

      if (!show) {
        return null;
      }

      content = content ?? message;
      if (!actionbar && !noActionBar) {
        actionbar =
            <>
              <DHLButton name={name + "-cancel"} label={cancelButton} onClick={onClickCancel} type={"default"} />
              <DHLButton name={name + "-submit"} label={okButton} onClick={onClickOk} type={"primary"} />
            </>;
      }

      const closeBtn = (onClickClose)
          ? <DHLIcon name={name + "-close"}
                     icon="close-thin"
                     className={classNames("dialog-modal-close", "dialog-modal-icon")}
                     onClick={onClickClose} />
          : null;
      const onlineHelp = (onlineHelpTarget)
          ? <DHLHelpButton testId="dialog-online-help"
                           type="dialog"
                           url={onlineHelpTarget}
                           tooltipText={onlineHelpTooltip || ""}
                           className={classNames("dialog-modal-question", closeBtn ? null : "no-close-icon", "dialog-modal-icon")}
          />
          : null;
      return (
          <Modal data-testid={name} isOpen={show} className={classNames("dhlDialogModal", "row", className)}
                 contentClassName={classNames("dhlDialogModal-content", colWidth, colOffset)}
                 modalClassName={"dhlModal"} autoFocus={autoFocus !== false} returnFocusAfterClose={false}>
            {onlineHelp}
            {closeBtn}
            <ModalHeader data-testid={name + "-header"} tag={headerComponent ? "div" : "h4"}>
              {headerComponent || title}
            </ModalHeader>
            <ModalBody data-testid={name + "-body"}>{content}</ModalBody>
            {!noActionBar && <ModalFooter data-testid={name + "-footer"} className={classNames("dhlDialogModal-actionbar")}>
              {actionbar}
            </ModalFooter>}
          </Modal>);
    }
;
