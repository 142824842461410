/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { DateRangePickerDataStore, LOCALIZATION_PREFIX } from "../../../stores/DateRangePickerDataStore";
import { DHLButton } from "../../atoms/DHLButton/DHLButton";
import { DHLSelectOne } from "../DHLSelectOne/DHLSelectOne";
import "./FooterPanel.scss";

export type FooterPanelProps = {
  name: string,
  onSubmit: React.MouseEventHandler<HTMLElement>,
  onCancel: React.MouseEventHandler<HTMLElement>,
  dataStore: DateRangePickerDataStore,
  keyboardOnlyMode?: boolean
}

export const FooterPanel = observer(({
                                       name,
                                       onSubmit,
                                       onCancel,
                                       dataStore,
                                       keyboardOnlyMode
                                     }: FooterPanelProps) => {
  const onPreselectionSelected = (e: ChangeEvent<HTMLSelectElement>) => {
    const key = e.target.value;
    dataStore.applyPresetRangeKey(key);
  };

  const inputSelectClassName = "dhlDateRangePicker-expanded-footerPanel-rangePresets" +
      `${keyboardOnlyMode ? "-keyboardOnly" : ""}`;

  const inputSelect = dataStore.rangePresets && <DHLSelectOne
      name={name + "-presetRangeSelector"}
      className={classNames("input-slim", inputSelectClassName)}
      onChange={onPreselectionSelected}
      data={dataStore.getRangePresetsAsKeyValue()}
      value={dataStore.selectedPresetKey}
      placeholder={dataStore.selectedPresetKey !== undefined ? undefined :
          dataStore.resourceDataStore.getLabel(`${LOCALIZATION_PREFIX}.presetSelect`)
      }
      forceNoEmptyOption={true}
  />;

  const resourceDataStore = dataStore.resourceDataStore;
  const cancelText = resourceDataStore.getButton(`${LOCALIZATION_PREFIX}.cancel`);
  const submitText = resourceDataStore.getButton(`${LOCALIZATION_PREFIX}.submit`);

  const cancelButton = <DHLButton
      name={name + "-cancel"}
      label={cancelText}
      className={"dhlDateRangePicker-expanded-footerPanel-button"}
      onClick={onCancel}
      size={"sm"}
      type={"default"}
  />;
  const submitButton = <DHLButton
      name={name + "-submit"}
      label={submitText}
      className={"dhlDateRangePicker-expanded-footerPanel-button"}
      onClick={onSubmit}
      size={"sm"}
      type={"primary"}
      disabled={!dataStore.isSubmitActive
      || !dataStore.isEndDateSelected()
      || dataStore.validationError !== undefined
      }
  />;
  return (
      <div
          data-testid={name}
          className={classNames("dhlDateRangePicker-expanded-footerPanel", dataStore.rangePresets ? "" : "no-presets")}>
        {inputSelect}
        {cancelButton}
        {submitButton}
      </div>
  );
});