/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { logger } from "../logger";
import {FetchAdapter} from "./FetchAdapter";

/**
 * Kapselt den DELETE Aufruf von "fetch".
 *
 * @param fetchAdapter the FetchAdapter to use
 * @param url die URL die aufgerufen werden soll
 * @param successCallback callback that will be called in case of successful fetch
 * @param errorCallback callback that will be called in case of errors
 * @param finalCallback callback that will be called after the success or error callback
 */
export const fetchServiceDelete = async (fetchAdapter: FetchAdapter,
                                         url: string,
                                         successCallback?: (response: Response) => void,
                                         errorCallback?: (response: Response) => void,
                                         finalCallback?: () => void): Promise<void> => {
  logger.log("fetchServiceDelete - fetching...", url);

  try {
    const response = await fetchAdapter.delete(url);

    if (response.ok) {
      if (successCallback !== undefined) {
        successCallback(response);
      }
    } else if (errorCallback !== undefined) {
      errorCallback(response);
    }

    if (finalCallback !== undefined) {
      finalCallback();
    }
  } catch (error) {
    logger.error("Beim fetchServiceDelete von " + url + " ist ein Fehler aufgetreten:", error);

    if (errorCallback !== undefined) {
      const body = JSON.stringify({
        errorMessages: [
          {
            messageKey: "framework.error.fetchServiceDelete"
          }
        ]
      });
      const response = new Response(body, {status: 500});
      errorCallback(response);
    }

    if (finalCallback !== undefined) {
      finalCallback();
    }
  }
};
