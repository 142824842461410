/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import "./DHLAlert.scss";
import { DHLIcon } from "../DHLIcon/DHLIcon";

export type DHLAlertProps = {
  name?: string;
  label?: string;
  type?: "default" | "success" | "warning" | "error";
  variant?: "default" | "medium" | "flex";
  closable?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  children?: React.ReactNode;
}

export const DHLAlert = ({name, label, type = "default", variant = "default", closable, className, children}: DHLAlertProps) => {
  const alertClass = variant === "default" ? "dhlAlert" : "dhlAlert-" + variant;

  let alertIcon;
  if (variant === "flex") {
    if (type === "success") {
      alertIcon = <DHLIcon name={"alert-flex-icon-" + type} icon={"alert-success"} />;
    } else if (type === "error") {
      alertIcon = <DHLIcon name={"alert-flex-icon-" + type} icon={"alert-error"} />;
    } else if (type === "warning") {
      alertIcon = <DHLIcon name={"alert-flex-icon-" + type} icon={"alert-warning"} />;
    } else {
      alertIcon = <DHLIcon name={"alert-flex-icon-" + type} icon={"alert-info"} />;
    }
  }

  const alertMediumFlexOutput =
      variant === "flex" ?
          <>
            <div className={classNames("alert-flex-icon")}>{alertIcon}</div>
            <div className={classNames("alert-flex-text")}>{children}</div>
          </>
          :
          <>
            {children}
          </>;

  const output =
      <div data-testid={name}
           className={classNames(alertClass, type, className)}
      >
        {label}
        {variant !== "default" && alertMediumFlexOutput}
        {closable &&
        <div className={classNames("alert-flex-close-icon")}>
          <DHLIcon name={"closeAlertIcon"} icon={"close-thin"} onClick={closable} />
        </div>}
      </div>;
  return (output);
};