/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, makeObservable } from "mobx";
import { BaseDataStore, ErrorResponse } from "./BaseDataStore";
import { FormField } from "./FormField";
import { MessageDataStore } from "./MessageDataStore";
import { ResourceDataStore } from "./ResourceDataStore";
import { ValidationRuleDataStore } from "./ValidationRuleDataStore";
import { TFunction } from "i18next";
import { ensureOldParams } from "../utils/i18next";

/**
 * Contains the stores for the localizations and the global success and error messages. It also manages the input fields of a form.
 */
export class BaseFormStore extends BaseDataStore {

  inputFormFields: FormField<any>[] = [];

  /**
   * Constructor.
   * @param resourceDataStore Message-Ressource with located text
   * @param validationRuleDataStore validation rules
   * @param messageDataStore message data store
   */
  constructor(resourceDataStore: ResourceDataStore | TFunction,
              validationRuleDataStore: ValidationRuleDataStore,
              messageDataStore: MessageDataStore | null | undefined) {
    super(resourceDataStore, validationRuleDataStore, messageDataStore, true);
    makeObservable(this, {
      resetInputFields: action,
      clearInputFieldErrors: action,
      mapServerErrorsToInputFields: action
    });
  }

  resetInputFields(): void {
    this.inputFormFields.forEach(field => field.reset());
  }

  clearInputFieldErrors(): void {
    this.clearTemporaryMessages();
    this.inputFormFields.forEach(field => field.clearError());
  }

  hasAnyError = (): boolean => {
    return this._hasAnyInputFieldError() || this.messageDataStore.getErrorMsgs().length > 0;
  };

  _hasAnyInputFieldError(): boolean {
    return this.inputFormFields.some(field => field.hasError);
  }

  mapServerErrorsToInputFields(errors: ErrorResponse) {
    if (errors && errors.validationPropertyResults) {
      const fieldMapping = new Map(this.inputFormFields.map(field => [field.name, field]));

      errors.validationPropertyResults.forEach(element => {
        element.propertyNames
            .filter(propName => fieldMapping.has(propName))
            .forEach(propName => fieldMapping.get(propName)!.error =
                this.resourceDataStore.getMessage(element.messageKey, ensureOldParams(element.parameters))
            );
      });
    }
  }
}
