
import {Col} from "reactstrap";
import { ReactNode } from "react";

export type DHLColProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;

  /** Anzahl belegter Spalten für extra small devices - Bildschirme mit weniger als 576px. */
  xs?: number;

  /** Anzahl belegter Spalten für small devices - Bildschirme mit mindestens 576px. */
  sm?: number;

  /** Anzahl belegter Spalten für medium devices - Bildschirme mit mindestens 768px. */
  md?: number;

  /** Anzahl belegter Spalten für large devices - Bildschirme mit mindestens 992px. */
  lg?: number;

  /** Anzahl belegter Spalten für extra large devices - Bildschirme mit mindestens 1200px. */
  xl?: number;

  /** Element ausgeben? */
  render?: boolean;

  /** CSS-Klassen. */
  className?: string;

  /** Kind-Komponenten */
  children?: ReactNode;
};

/** Spalte eines Bootstrap-Grids. */
export const DHLCol = ({name, className, xs, sm, md, lg, xl, render = true, children}: DHLColProps) => {
  if (!render) {
    return null;
  }

  return (
      <Col data-testid={name} className={className} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        {children}
      </Col>
  );
};
