/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {useUIDSeed} from "react-uid";
import "./DHLMultiBox.scss";

export type DHLMultiBoxAdditionalInfoAlignmentType = "inline" | "left" | "right";

export type DHLMultiBoxAvailableEntry = {
  /** The key contains the (input) value represented by this entry */
  value: string,
  /** The value displayed in the multibox for this entry */
  displayValue: JSX.Element,
  /** The additional infos displayed in the multibox for this entry */
  additionalInfo?: JSX.Element
}

/** Props Definition for the DHLMultiBox component. */
export type DHLMultiBoxProps = {
  /** Name used as part of the unique test-id for testing the component. */
  name?: string;
  /** Flag, indicating if the component is disabled. */
  disabled?: boolean;
  /** List of values that need to be displayed in the selection list.  */
  availableValues: DHLMultiBoxAvailableEntry[];
  /** Alignment of the additional Informations (if present).
   * Note: alignment left is not GKDS-conform and should not be used */
  additionalInfoAlignment?: DHLMultiBoxAdditionalInfoAlignmentType;
  /** Flag indicating if the selection list is currently shown or hidden. */
  isOpen?: boolean;
  /** Function called if an entry in the selection list is selected/clicked. */
  onSelectValue?: Function;
  /** Alternativ text which is displayed if the selection list is empty. */
  emptyListText?: string;
  /** The maximum number of elements shown in the selection list, before there will be scroll bar. (implemented
   *  2-20, should not be more than 7 (GKDS) */
  maxShownElements?: number;
  /** Additional style classes i.e. related to the context in which this component is embedded. */
  className?: string;
};

/** The component DHLMultiBox. This component displays a selection list of values which can be selected/clicked. */
export const DHLMultiBox = observer(({
                                       name,
                                       availableValues,
                                       additionalInfoAlignment = "right",
                                       isOpen = false,
                                       onSelectValue = () => { /* intended use */ },
                                       emptyListText,
                                       maxShownElements = 7,
                                       className
                                     }: DHLMultiBoxProps) => {
  const itemUid = useUIDSeed();
  if (!isOpen) {
    return null;
  }

  const addtionalInfosAvailable = availableValues.filter(entry => entry.additionalInfo).length > 0;

  const showElementsWithInlineAdditionalInfo = availableValues.map((entry) => (
    <div data-testid={name + "multibox-entry-" + entry.value} key={itemUid(entry)} className={classNames("multibox-entry")}
         onClick={() => onSelectValue(entry.value)}>
      {entry.displayValue}<span className={"inline-additional-info"}>{entry.additionalInfo}</span>
    </div>
  ));

  const showElementsWithSeparateAdditionalInfo = <>
    <div data-testid={name + "multibox-entry-container"} className={classNames("multibox-entry-container")}>
      {availableValues.map((entry) => (
        <div data-testid={name + "multibox-value-" + entry.value} key={itemUid(entry)} className={classNames("multibox-value")}
             onClick={() => onSelectValue(entry.value)}>
          {entry.displayValue}
        </div>))}
    </div>
    {addtionalInfosAvailable &&
    <div data-testid={name + "multibox-additionalInfo-container"}
         className={classNames("multibox-additionalInfo-container", "align-" + additionalInfoAlignment)}>
      {availableValues.map((entry) => (
        <div data-testid={name + "multibox-additionalInfo-" + entry.value} key={itemUid(entry)}
             className={classNames("multibox-additionalInfo")}
             onClick={() => onSelectValue(entry.value)}>
          <span className={"inline-additional-info"}>{entry.additionalInfo}</span>
        </div>))}
    </div>}
  </>;

  return (
    <>
      <div data-testid={name + "-multibox-container"}
           className={classNames(className, "multibox-container", "max-shown-no-elements-" + maxShownElements,
             availableValues.length === 0 && emptyListText ? "empty-list" : null, additionalInfoAlignment === "inline" ? "additional-infos-inline"
               : "additional-infos-column", "align-" + additionalInfoAlignment)}>
        {availableValues.length === 0 && emptyListText &&
        <div data-testid={name + "multibox-entry-empty-list"} className={classNames("multibox-entry", "empty-entry")}>
          {emptyListText}
        </div>}
        {additionalInfoAlignment === "inline"
          ? showElementsWithInlineAdditionalInfo
          : showElementsWithSeparateAdditionalInfo
        }
      </div>
    </>
  );

});