/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, flow, makeObservable, observable } from "mobx";
import { CancellablePromise } from "mobx/dist/api/flow";
import { ValidationRuleType } from "../types/ValidationRuleTypes";
import { ValidationRuleDataClient } from "../clients/ValidationRuleDataClient";
import { Validator } from "../utils/Validator";
import { FetchAdapter } from "../utils/fetch/FetchAdapter";
import { logger } from "../utils/logger";

/** Data memory for validation rules of a module. */
export class ValidationRuleDataStore {
  /** Maps names to validation rules. */
  validationRuleMap: Map<string, ValidationRuleType> = new Map();

  /** Specifies whether the validation rules are currently being loaded. */
  loading: boolean = true;

  /** Set of rule names for which no validation rules were found. */
  warnings: Set<string> = new Set();

  /**
   * Constructor.
   * @param fetchAdapter the FetchAdapter to use
   * @param baseUrl Base URL of the backend
   * @param module Load texts for this module
   * @param client optional client to load validation rules
   */
  constructor(
      private readonly fetchAdapter: FetchAdapter,
      readonly baseUrl: string,
      readonly module: string,
      private readonly client: ValidationRuleDataClient = new ValidationRuleDataClient(fetchAdapter, baseUrl)
  ) {
    makeObservable(this, {
      validationRuleMap: observable,
      loading: observable,
      isLoading: computed,
      setLoading: action,
      load: action
    });
  }

  /**
   * Determines the full name of a rule.
   * @param name rule name
   * @return rule name with module prefix
   */
  getRuleName(name: string): string {
    return this.module ? `${this.module}.${name}` : name;
  }

  /**
   * Determines the rule for a name.
   * @param ruleName rule name
   * @return rule
   */
  getRule(ruleName: string): ValidationRuleType | undefined {
    const completeRuleName: string = this.getRuleName(ruleName);
    const rule: ValidationRuleType | undefined = this.validationRuleMap.get(completeRuleName);

    if (!rule && !this.warnings.has(completeRuleName)) {
      this.warnings.add(completeRuleName);
      logger.warn("Missing rule for name ", completeRuleName);
    }

    return rule;
  }

  /**
   * Returns a Validator for the queried rule
   * @param {string} name rule name
   * @returns {Validator} validator for given rule or null
   */
  getValidator(name: string): Validator | undefined {
    const rule = this.getRule(name);
    if (!rule) {
      return undefined;
    }
    return new Validator(rule);
  }

  /**
   * Indicates whether the validation rules are loading.
   * @returns {boolean} whether the validation rules are loading
   */
  get isLoading(): boolean {
    return this.loading;
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  /**
   * Load validation rules.
   * @returns {Promise<void>} Promise resolving after completion
   */
  load: () => CancellablePromise<void> = flow(function* load(this: ValidationRuleDataStore) {
    if (!this.loading) {
      return;
    }

    try {
      const rules: ValidationRuleType[] = yield this.client.load(this.module);

      rules.forEach(rule => this.validationRuleMap.set(rule.name, rule));
      this.setLoading(false);
    } catch (e) {
      this.validationRuleMap.clear();
    }
  });

  /**
   * Validates the input value with the specified rule.
   *
   * @param value input value
   * @param additionalArgs Array with additional input
   * @param ruleName rule name
   * @return Name of the error location or zero if the check was successful
   */
  validate(value: any, ruleName: string, additionalArgs: any[]): string | null {
    const validator = this.getValidator(ruleName);
    if (!validator) {
      logger.error("Unknown validation rule " + this.getRuleName(ruleName)
          + ". Input is, for security reasons, always false, if rule can't be found");
      return "framework.error.unknownValidationRule";
    }
    return validator.validate(value, additionalArgs);
  }
}
