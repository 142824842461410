/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { KeyValueType, SelectionListType } from "../types/DataTypes";
import { observable, computed, makeObservable } from "mobx";

export class SelectionListPack{
  /** Mapped selection list names on their content. */
  selectionLists: Map<string, KeyValueType[]>;

  constructor(rawSelectionLists: SelectionListType[]){
    this.selectionLists = new Map(rawSelectionLists.map(entry => [entry.name, entry.options]));
    makeObservable(this, {
      selectionLists: observable,
      hasData: computed
    });
  }

  get hasData(): boolean{
    return this.selectionLists.size > 0;
  }

  getSelectionList(name: string): KeyValueType[] | undefined {
    return this.selectionLists.get(name) ? this.selectionLists.get(name) : [];
  }
}
