/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import React, { useEffect, useRef, useState } from "react";
import {
  DHLIcon,
  IconType
} from "../../atoms/DHLIcon/DHLIcon";
import "./DHLClickTooltip.scss";
import { DHLPopover, DHLPopoverPlacement } from "../../atoms/DHLPopover/DHLPopover";

export type DHLClickTooltipProps = {
  name: string
  children: React.ReactElement | React.ReactElement[]
  tooltipPlacement?: DHLPopoverPlacement
  icon?: IconType
}

export const DHLClickTooltip = ({
                                  name,
                                  children,
                                  tooltipPlacement,
                                  icon = "alert-info"
                                }: DHLClickTooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(e.target as Node)) {
        setTooltipOpen(false);
      }
    };
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });

  const id = `dhlClickTooltip-${name}`;
  return (
    <div className={"dhlClickTooltip"} ref={componentRef}>
      <div
        id={id}
        className={"icon"}
        onClick={() => setTooltipOpen(!tooltipOpen)}>
        <DHLIcon
          name={"dhlClickTooltip-icon"}
          icon={icon} />
      </div>
      <DHLPopover
        targetId={id}
        placement={tooltipPlacement}
        isOpen={tooltipOpen}>
        {children}
      </DHLPopover>
    </div>
  );
};