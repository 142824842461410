export type DHLTableCellNumberProps = {
  /** Spaltentitel. */
  title: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type?: "number";

  /** CSS-Klassen. */
  className?: string;

  /** Mindestanzahl Nachkommastellen. */
  minFractionDigits?: number;

  /** Maximalanzahl Nachkommastellen. */
  maxFractionDigits?: number;

  /** Tausendertrennzeichen verwenden? */
  groupingEnabled?: boolean;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Kind-Komponenten. */
  children?: never;
};

/** Tabellenspaltendefinition - Numerisch. */
export const DHLTableCellNumber = (props: DHLTableCellNumberProps) => {
  return null;
};

DHLTableCellNumber.defaultProps = {
  type: "number",

  minFractionDigits: 0,

  maxFractionDigits: 0,

  groupingEnabled: true,

  sortable: false
};
