/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect, useState } from "react";
import { reaction } from "mobx";
import { useAuthenticationManager } from "./useAuthenticationManager";
import { ScopeCheckFn, Scope } from "@gkuis/gkp-authentication";

/**
 * Checks if the given requirement is satisfied.
 * Note: requirement MUST NOT change between re-renders. Changes are ignored.
 *
 * @param {Scope | ScopeCheckFn} requirement requirement to check. MUST NOT CHANGE between calls.
 * @returns {undefined | boolean} true, if requirement is satisfied, false if unsatisfied and undefined if result is not ready yet (e.g. async
 * code is still running or failed)
 */
export const useScopeCheck = (requirement: (Scope | ScopeCheckFn)) => {
  const authenticationManager = useAuthenticationManager();
  const [requirementSatisfied, setRequirementSatisfied] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const checkRequirementAndUpdateState = () => authenticationManager
        .scopeCheck(requirement)
        .then(setRequirementSatisfied)
        .catch(() => setRequirementSatisfied(undefined))
    // noinspection JSIgnoredPromiseFromCall
    checkRequirementAndUpdateState();
    return authenticationManager.addEventListener("onAuthSuccess", checkRequirementAndUpdateState);
  }, [authenticationManager, setRequirementSatisfied]);

  return requirementSatisfied;
};
