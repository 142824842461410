/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {observer} from "mobx-react-lite";
import {DateRangePickerDataStore, PickerSide} from "../../../stores/DateRangePickerDataStore";
import {Month} from "../../atoms/DHLDatePicker/Month";
import {WeekNavigationHeader} from "../../atoms/DHLDatePicker/WeekNavigationHeader";

export type PickerPanelProps = {
    name: string
    dataStore: DateRangePickerDataStore
    side: PickerSide
}

export const PickerPanel =  observer(({name, dataStore, side}: PickerPanelProps) => {
    const onPreviousMonth = dataStore.getOnPreviousMonth(side);
    const onNextMonth = dataStore.getOnNextMonth(side);
    const onMonthSelected = dataStore.getOnMonthSelected(side);
    const onYearSelected = dataStore.getOnYearSelected(side);
    const onDateSelected = dataStore.getOnDaySelected(side);
    const onMouseLeaveDay = dataStore.getOnMouseLeaveDay(side);
    const onMouseEnterDay = dataStore.getOnMouseEnterDay(side);
    const pickerHeader = (
        <div className="dhlDatePicker-expanded-header">
            <WeekNavigationHeader name={name + "-header"}
                                   isNextMonthAvailable={dataStore.isNextMonthAvailable(side)}
                                   isPreviousMonthAvailable={dataStore.isPreviousMonthAvailable(side)}
                                   monthsOfYear={dataStore.selectableMonthsOfYear(side)}
                                   years={dataStore.selectableYears(side)}
                                   displayedMonth={dataStore.getDisplayedMonth(side)}
                                   displayedYear={dataStore.getDisplayedYear(side)}
                                   onPreviousMonth={onPreviousMonth}
                                   onNextMonth={onNextMonth}
                                   onMonthSelected={onMonthSelected}
                                   onYearSelected={onYearSelected}  />
        </div>
    );

    const datePicker = (
        <div className={"dhlDatePicker-expanded-dates"}>
            <Month
                name={name + "-dates"}
                daysOfMonthGroupedByWeek={dataStore.daysOfMonthGroupedByWeek(side)}
                showWeekNumbers={dataStore.showWeekNumbers}
                onDateSelected={onDateSelected}
                onMouseLeaveDay={onMouseLeaveDay}
            onMouseEnterDay={onMouseEnterDay}/>
        </div>
    );

    return (
        <div
            data-testid={name}
            className={"dhlDateRangePicker-expanded-pickerPanel"}>
            {pickerHeader}
            {datePicker}
        </div>
    );
});