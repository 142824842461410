/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { makeObservable, observable, } from "mobx";
import { ResourceDataStore } from "./ResourceDataStore";
import { MessageDataStore } from "./MessageDataStore";

export class DHLDecisionDialogStore {

  runningConfirm: boolean;
  showDialog: boolean;

  /**
   * Constructor.
   * @param resourceDataStore resources
   * @param messageDataStore optional message data store
   */
  constructor(
      public resourceDataStore: ResourceDataStore,
      public messageDataStore: MessageDataStore = new MessageDataStore()
  ) {
    this.runningConfirm = false;
    this.showDialog = false;

    makeObservable(this, {
      runningConfirm: observable,
      showDialog: observable
    });
  }
}
