/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { reaction } from "mobx";
import { useEffect, useState } from "react";
import { ValidationRuleDataStore } from "..";
import { Validator } from "./Validator";

export const useValidation = (validationRuleDataStore: ValidationRuleDataStore, rule: string) => {
  const [validator, setValidator] = useState<Validator|undefined>(validationRuleDataStore.getValidator(rule));

  useEffect(() => {
    validationRuleDataStore.load();
    return reaction(
        () => validationRuleDataStore.validationRuleMap,
        () => setValidator(validationRuleDataStore.getValidator(rule))
    );
  }, [rule]);

  return validator;
};