/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";


export type BorderState = "done" | "active" | "default" | "invisible";

export type ProgressBarBorderProps = {
  borderState: BorderState;
}

export const ProgressBarBorder = ({borderState}: ProgressBarBorderProps) => {
  const className = "dhlProgressBar-border";
  const classNameWithState = `${className}-${borderState}`;

  return <span className={classNames(className, classNameWithState)} />;
};