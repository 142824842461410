/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Scope, ScopeCheckFn } from "@gkuis/gkp-authentication";
import { useScopeCheck } from "../../../authentication/useScopeCheck";
import { DHLMissingAuthorizationErrorPage } from "../DHLMissingAuthorizationErrorPage/DHLMissingAuthorizationErrorPage";
import { PropsWithChildren } from "react";

export type RequireAuthProps = PropsWithChildren<{ requirement: (Scope | ScopeCheckFn), showError?: boolean }>;

export const RequireAuth = ({requirement, showError = true, children}: RequireAuthProps) => {
  const requirementSatisfied = useScopeCheck(requirement);

  if (requirementSatisfied === undefined) {
    return null;
  }
  if (!requirementSatisfied) {
    if (showError) {
      return <DHLMissingAuthorizationErrorPage />;
    }
    return <></>
  }

  return <>{children}</>;
};
