/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLTableCellBooleanAllSelectableProps } from "./DHLTableCellBooleanAllSelectable";

export type DHLTableCellBooleanProps = {
  /** Spaltentitel. */
  title: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** Ausgabetyp. */
  type?: "boolean";

  /** CSS-Klassen. */
  className?: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: (value: boolean, cellId: string, idProperyName: string, colPropertyName: string) => void;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Zelle auf Basis dieses Row-Properties disablen. */
  disabledPropertiesName?: string;

  /** Kind-Komponenten. */
  children?: never;

} & DHLTableCellBooleanAllSelectableProps<any>;

/** Tabellenspaltendefinition - boolscher Wert als Checkbox dargestellt. */
export const DHLTableCellBoolean = (props: DHLTableCellBooleanProps) => {
  return null;
};

DHLTableCellBoolean.defaultProps = {
  type: "boolean",
  contentAlignment: "center",
  sortable: false
};
