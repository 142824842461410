/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { DHLHeadline } from "../../atoms/DHLHeadline/DHLHeadline";
import { DHLFormGroup } from "../../atoms/DHLFormGroup/DHLFormGroup";
import { DHLCol } from "../../atoms/DHLCol/DHLCol";
import classNames from "classnames";
import "./DHLDataCard.scss";
import { DHLImage } from "../../atoms/DHLImage/DHLImage";

export type DHLDataCardProps = {

  /** Name of the data card. Used for generating the Test ID. */
  name?: string;

  /** CSS-class for data card. */
  className?: string;

  /** Headline for data card */
  headline?: string;

  /** Headline type */
  headlineType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  /** Details to display */
  details: JSX.Element[] | string[];

  /** Button for editing */
  editButton?: JSX.Element;

  /** Image source for headline image */
  imageSrc?: string;

}

export const DHLDataCard = observer(
        ({
           name,
           className,
           headline,
           headlineType = "h5",
           details,
           editButton,
           imageSrc
         }: DHLDataCardProps) => {

          const elements = details.map(detail => {
            return <DHLFormGroup className={"dataCard-row"}>
              {typeof detail === "string" ?
                  <DHLCol>
                    <p className={"dataCard-detail"}> {detail} </p>
                  </DHLCol>
                  : detail
              }
            </DHLFormGroup>;
          });

          return (
              <div className={classNames(className, "dataCard-box-container")} id={name}>
                <div className={classNames("dataCard-box-container-header")}>
                  {imageSrc &&
                      <DHLImage className={"dataCard-image"} src={imageSrc} />}
                  {headline &&
                      <DHLHeadline className={"dataCard-headline"} type={headlineType}>{headline}</DHLHeadline>}
                  {editButton}
                </div>
                <div className={"dataCard-box-container-details"}>
                  {elements}
                </div>
              </div>
          );
        }
    )
;