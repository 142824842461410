/*
* Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
* This code is licensed and the sole property of Deutsche Post DHL Group.
*/

import {useEffect, useState} from "react";
import {FetchAdapter} from "../../..";

export type DHLImageProps = {
  /** URL des Bildes */
  src: string;

  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;

  /** Image-Alttext. */
  alt?: string;

  /** FetchAdapter zum laden des Bildes */
  fetchAdapter?: FetchAdapter

  /** CSS-Klassen */
  className?: string
};

/** DHLImage to display a img-element with ability to get image via provided fetchAdapter */
export const DHLImage: React.FC<DHLImageProps> = ({
                                                    src,
                                                    name,
                                                    alt,
                                                    fetchAdapter,
                                                    className
                                                  }: DHLImageProps) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (fetchAdapter) {
      // fetch image with fetchAdapter
      (async function () {
        const response = await fetchAdapter.get(src, {headers: {Accept: "image/*"}});
        if (response.ok) {
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          setImageSrc(objectUrl);
        }
      })();
    } else {
      // let user browser directly fetch image without control over http-headers
      setImageSrc(src);
    }
  }, [src, fetchAdapter]);

  if (imageSrc) {
    return (
        <img data-testid={name}
             alt={alt}
             src={imageSrc}
             className={className}
             referrerPolicy={fetchAdapter===undefined?"no-referrer":undefined}
        />
    );
  } else {
    return <></>;
  }
};
