/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { integers } from "./integers";


type matchBlocks = {
  start: number,
  end: number,
  marked: boolean
}

export function normalizeSearchFilter(filter: string) {
  const softHyphen = "\u00AD";
  return filter.toLocaleLowerCase().replace(softHyphen, "");
}

function mergeBlockReducer(previousValue: matchBlocks[], currentValue: matchBlocks[]): matchBlocks[] {
  if (previousValue.length === 0) { // initially
    return currentValue;
  }
  const previousMarked = previousValue[previousValue.length - 2]; // if marked regions overlap
  if (previousMarked.end >= currentValue[1].start) {
    return [...previousValue.slice(0, previousValue.length - 2), // remove previous marked and trailing
      {start: previousMarked.start, end: currentValue[1].end, marked: true}, // introduce new marked
      currentValue[2]];
  }
  const previousTrailing = previousValue[previousValue.length - 1];
  return [...previousValue.slice(0, previousValue.length - 1), // remove previous end piece
    {start: previousTrailing.start, end: currentValue[1].start, marked: false}, // introduce new unmarked intermediate piece
    currentValue[1],
    currentValue[2]];
}

function buildBlocks(matchStart: number, entryLength: number, filterText: string): matchBlocks[] {
  return [
    {start: 0, end: matchStart, marked: false},
    {start: matchStart, end: matchStart + filterText.length, marked: true},
    {start: matchStart + filterText.length, end: entryLength, marked: false}
  ];
}

function buildTextBlock(area: string, marked: boolean, keySource: () => number): JSX.Element {
  return marked
    ? <span className={"boldface-text"} key={keySource()}>{area}</span>
    : <span key={keySource()}>{area}</span>;
}

function buildTextBlocks(entry: string, blocks: matchBlocks[]): JSX.Element {
  const keySource = integers(1);
  return <>{blocks.map(
    block => buildTextBlock(entry.substring(block.start, block.end), block.marked, () => keySource.next().value)
  )}</>;
}

function mergeBlocks(blocks: matchBlocks[][]): matchBlocks[] {
  return blocks.reduce(mergeBlockReducer, []);
}

function containsSearched(s: string, filterText: string): boolean {
  return normalizeSearchFilter(s).indexOf(normalizeSearchFilter(filterText)) !== -1;
}

export function markMatchesInEntry(entry: string, filterText: string): JSX.Element {
  if (filterText.length === 0 || !containsSearched(entry, filterText)) {
    return <>{entry}</>;
  }

  const matches: number[] = [];
  let startOfSearch = 0;

  while (true) {
    const matchStart = normalizeSearchFilter(entry).indexOf(normalizeSearchFilter(filterText), startOfSearch);
    if (matchStart === -1) {
      break;
    }
    matches.push(matchStart);
    startOfSearch = matchStart + 1;
  }

  const blocks = mergeBlocks(matches.map(match => buildBlocks(match, entry.length, normalizeSearchFilter(filterText))));

  return buildTextBlocks(entry, blocks);
}