/*!
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {observer} from "mobx-react-lite";
import "./DHLFatalErrorPage.scss"

/** Show error text. */
export const DHLFatalErrorPage = observer(() => (
    <div className={"dHLFatalErrorPage"}>
        <h1 className={"errorMessageTitle"}>Fehlermeldung - Wir bitten um Entschuldigung</h1>
      <div className={"errorMessage"}>
        Die von Ihnen gewünschte Funktion konnte leider nicht erfolgreich aufgerufen werden. Bitte versuchen Sie es später erneut
        oder kontaktieren den technischen Support.
      </div>
      <hr/>
        <h1 className={"errorMessageTitle"}>Error message - we apologize for any inconvenience</h1>
      <div className={"errorMessage"}>
          Unfortunately, the function you requested could not be called successfully. Please try again later or contact technical support.
      </div>
    </div>
));
