/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import * as Popper from "popper.js";
import {IconType} from "../DHLIcon/DHLIcon";
import {DHLTableCellCommonProps} from "./DHLTableCell";

export type DHLTableCellButtonProps = DHLTableCellCommonProps & {
  /** Spaltentitel. */
  title?: string;

  /** Name des anzuzeigenden Properties. */
  propertiesName: string;

  /** CSS-Klassen. */
  className?: string;

  /** Spalte sortierbar? */
  sortable?: boolean;

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Zelle auf Basis dieses Row-Properties disablen. */
  disabledPropertiesName?: string;

  /** Button label. */
  label?: string;
  /** Button size (allowed values: lg, normal, sm, xs; default value: 'normal') */
  size?: "lg" | "normal" | "sm" | "xs";
  /** Button type (allowed values: default, primary, secondary; default value: 'default') */
  buttonType?: "default" | "primary" | "secondary" | "ghost";
  /** Button type regarding icone (allowed values: no-icon, icon, icon-first, icon-last; default value: 'no-icon').*/
  iconPosition?: "no-icon" | "icon" | "icon-first" | "icon-last";
  /** The icon to integrate in the button . */
  icon?: IconType;
  /** onClick function. */
  onClick: (event: React.MouseEvent, value: any) => void;
  /** Text displayed in tooltip on hover. */
  tooltip?: string | React.ReactNode;
  /** Alignment of the tooltip. */
  tooltipPlacement?: Popper.Placement;
  /** Tooltip text displayed on hover if button is disabled. */
  disabledTooltip?: string;
}

/** Tabellenspaltendefinition - Button. */
export const DHLTableCellButton = (props: DHLTableCellButtonProps) => {
  return null;
};

DHLTableCellButton.defaultProps = {
  type: "button",
  contentAlignment: "center",
};
