/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ErrorResponse } from "../../stores/BaseDataStore";
import { logger } from "../logger";
import { addHeaders } from "./addHeaders";
import { AuthenticationManager } from "@gkuis/gkp-authentication";

export class FetchAdapter {
  readonly authenticationManager?: AuthenticationManager;

  constructor(authenticationManager?: AuthenticationManager) {
    this.authenticationManager = authenticationManager;
  }

  private async doFetch(
      url: string,
      options: RequestInit & { method: string },
      doNotSetRequestContentType: boolean = false,
      doNotRefreshToken: boolean = false
  ): Promise<Response> {
    const opts = await addHeaders(options, this.authenticationManager, doNotSetRequestContentType, doNotRefreshToken);
    return await fetch(url, opts);
  }

  async getFile(urlString: string, method?: string, options?: RequestInit): Promise<Response> {
    return this.getBlob(urlString, method, options).then(({blob, filename, response}) => {
      try {
        this.openDownloadPrompt(blob, filename);
        return Promise.resolve(response);
      } catch (error) {
        logger.error(`Beim GET von ${urlString} ist ein Fehler aufgetreten:`, error);
        const errorResponse: ErrorResponse = FetchAdapter.createErrorResponse("fetchServiceGetFile");
        return Promise.resolve(new Response(JSON.stringify(errorResponse), {status: 503}));
      }
    }).catch(reason => {
      return Promise.resolve(reason);
    });
  }

  async getBlobUrl(urlString: string, fileName: string, fileOptions?: FilePropertyBag, method?: string, fetchOptions?: RequestInit): Promise<string> {
    return this.getBlob(urlString, method, fetchOptions).then(({blob}) => {
      try {
        const file = new File([blob], fileName, fileOptions);
        const url = window.URL.createObjectURL(file);
        setTimeout(() => {
          // Für FF muss gewartet werden
          window.URL.revokeObjectURL(url);
        }, 200);
        return url;
      } catch (error) {
        logger.error(`Beim GET von ${urlString} ist ein Fehler aufgetreten:`, error);
        const errorResponse: ErrorResponse = FetchAdapter.createErrorResponse("fetchServiceGetFile");
        return Promise.reject(errorResponse);
      }
    }).catch(reason => {
      return Promise.reject(reason);
    });
  }

  private async getBlob(urlString: string, method?: string, options?: RequestInit): Promise<{ blob: Blob, filename: string, response: Response }> {
    try {
      if (!options) {
        options = FetchAdapter.createDefaultOptions();
      }
      const requestMethod = method || "GET";
      const response = await this.doFetch(urlString, {method: requestMethod, ...(options || {})});

      if (!response.ok) {
        return Promise.reject(response);
      }

      const {contentType, filename} = FetchAdapter.extractData(response);
      const newBlob: Blob = await FetchAdapter.createBlob(contentType, response);
      logger.log("Downloaded file: ", filename, contentType, response.headers);

      return {filename: filename, blob: newBlob, response: response};
    } catch (error) {
      logger.error(`Beim GET von ${urlString} ist ein Fehler aufgetreten:`, error);
      const errorResponse: ErrorResponse = FetchAdapter.createErrorResponse("fetchServiceGetFile");
      return Promise.reject(new Response(JSON.stringify(errorResponse), {status: 503}));
    }
  }

  private static createErrorResponse(errorKey: string) {
    return {
      globalError: true,
      errorMessages: [
        {
          messageKey: `framework.error.${errorKey}`
        }
      ]
    };
  }

  private static createDefaultOptions() {
    return {
      headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache,no-store,must-revalidate",
        Expires: "0"
      }
    };
  }

  private openDownloadPrompt(newBlob: Blob, filename: string) {
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      // Für FF muss gewartet werden
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  private static extractData(response: Response) {
    let contentDisposition: string | null = response.headers.get("Content-Disposition");
    if (contentDisposition === undefined) {
      contentDisposition = response.headers.get("content-disposition");
    }
    let contentType: string | null = response.headers.get("Content-Type");
    if (contentType === undefined) {
      contentType = response.headers.get("content-type");
    }
    const filename = FetchAdapter.extractFilename(contentDisposition);
    return {contentType, filename};
  }

  private static async createBlob(contentType: string | null, response: Response) {
    let newBlob;

    if (contentType != null) {
      newBlob = new Blob([await response.blob()], {type: contentType});
    } else {
      newBlob = new Blob([await response.blob()]);
    }
    return newBlob;
  }

  private static extractFilename(contentDisposition: string | null) {
    let filename: string = "default.txt";
    if (contentDisposition != null) {
      filename = contentDisposition.split("filename=")[1];

      if (filename.startsWith("\"")) {
        filename = filename.substring(1);
      }

      if (filename.endsWith("\"")) {
        filename = filename.substring(0, filename.length - 1);
      }
    }
    return filename;
  }

  async get(url: string, options?: RequestInit, doNotRefreshToken = false): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "GET", ...(options || {})}, false, doNotRefreshToken);
    } catch (error) {
      logger.error(`Beim GET von ${url} ist ein Fehler aufgetreten:`, error);
      const body: ErrorResponse = {
        globalError: true,
        errorMessages: [
          {
            messageKey: "framework.error.fetchServiceGet"
          }
        ]
      };
      return Promise.resolve(new Response(JSON.stringify(body), {status: 503}));
    }
  }

  async post(url: string, options?: RequestInit, doNotSetRequestContentType: boolean = false): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "POST", ...(options || {})}, doNotSetRequestContentType);
    } catch (error) {
      logger.error(`Beim POST von ${url} ist ein Fehler aufgetreten:`, error);
      const body: ErrorResponse = {
        globalError: true,
        errorMessages: [
          {
            messageKey: "framework.error.fetchServicePost"
          }
        ]
      };
      return Promise.resolve(new Response(JSON.stringify(body), {status: 503}));
    }
  }

  async put(url: string, options?: RequestInit, doNotSetRequestContentType: boolean = false): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "PUT", ...(options || {})}, doNotSetRequestContentType);
    } catch (error) {
      logger.error(`Beim PUT von ${url} ist ein Fehler aufgetreten:`, error);
      const body: ErrorResponse = {
        globalError: true,
        errorMessages: [
          {
            messageKey: "framework.error.fetchServicePut"
          }
        ]
      };
      return Promise.resolve(new Response(JSON.stringify(body), {status: 503}));
    }
  }

  async delete(url: string, options?: RequestInit): Promise<Response> {
    try {
      return await this.doFetch(url, {method: "DELETE", ...(options || {})});
    } catch (error) {
      logger.error(`Beim DELETE von ${url} ist ein Fehler aufgetreten:`, error);
      const body: ErrorResponse = {
        globalError: true,
        errorMessages: [
          {
            messageKey: "framework.error.fetchServiceDelete"
          }
        ]
      };
      return Promise.resolve(new Response(JSON.stringify(body), {status: 503}));
    }
  }

  async promisify<T>(wrappedResponse: Response): Promise<T> {
    if(wrappedResponse.status === 409) {
      logger.log("Received conflict response", await wrappedResponse.json());
      return Promise.reject(FetchAdapter.createErrorResponse("conflict"));
    }

    if (!wrappedResponse.ok) {
      logger.log("Received error response", wrappedResponse.status);
      return Promise.reject(await wrappedResponse.json());
    }

    logger.log("Received ok response", wrappedResponse.status);

    if (wrappedResponse.status === 204) {
      return Promise.resolve({} as T);
    }

    const body = await wrappedResponse.json() as T;

    if (body !== undefined && body !== null) {
      return Promise.resolve(body);
    }

    const error: ErrorResponse = {
      globalError: true,
      errorMessages: [
        {
          messageKey: "framework.error.unknownType"
        }
      ]
    };

    logger.error("Type conversion error", wrappedResponse.status);

    return Promise.reject(error);
  }

}

export const fetchAdapterWithoutTokenAuthenticationHelper = new FetchAdapter(undefined);