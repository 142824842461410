import { useRef, useState } from "react";
import "./DHLTooltipIcon.scss";
import { DHLTooltip } from "../../molecules/DHLTooltip/DHLTooltip";
import { DHLIcon, IconType } from "../DHLIcon/DHLIcon";
import classNames from "classnames";

export type DHLTooltipIconProps = {
  /** Name. */
  name: string;

  /** Tooltip-Text. */
  tooltip: string;

  /** Icon */
  icon?: IconType;

  /** Platzierung des Tooltips. */
  placement?: "top" | "bottom" | "left" | "right";

  /** onClick function. */
  onClick?: React.MouseEventHandler<HTMLElement>;

  /** Size  */
  size?: "lg" | "normal" | "sm" | "xs";
};

/** Tooltip-Icon. */
export const DHLTooltipIcon = ({name, icon = "alert-info", tooltip, placement = "right", onClick, size}: DHLTooltipIconProps) => {
  const iconRef = useRef<HTMLDivElement | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return <>
    <DHLIcon
        innerRef={iconRef}
        name={name}
        icon={icon}
        className={classNames("dhlTooltipIcon", size !== undefined ? ("dhlTooltipIcon-" + size) : null)}
        onClick={onClick}
    />
    <DHLTooltip testid={name + "-tooltip"} tooltipOpen={tooltipOpen} target={iconRef} placement={placement} toggle={toggle}>
      {tooltip}
    </DHLTooltip>
  </>;
};
