/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { logger } from "./logger";

export const setObjectData = (data: object, propertyName: string, value: any): void => {
  const propNames: string[] = propertyName.split(".");

  let field: any = data;
  propNames
      .slice(0, -1)
      .forEach(propName => {
        if (field instanceof Object && propName in field) {
          field = field[propName];
        } else {
          logMissingProperty(propName, field);
        }
      });

  const lastPropName = propNames[propNames.length - 1];
  if (field instanceof Object && lastPropName in field) {
    field[lastPropName] = value;
  } else {
    logMissingProperty(lastPropName, field);
  }
};

const logMissingProperty = (propertyName: string, obj: object) =>
    logger.error(new Error(`Property ${propertyName} does not exist in ${JSON.stringify(obj)} of type ${typeof obj}`).stack);
