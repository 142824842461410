/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import React from "react";
import { Fade, Popover } from "reactstrap";
import "./DHLPopover.scss";

export type DHLPopoverPlacement = "top" | "right" | "bottom" | "left";

export type DHLPopoverProps = {
  targetId: string
  isOpen: boolean
  placement?: DHLPopoverPlacement
  children: React.ReactElement | React.ReactElement[]
}

export const DHLPopover = ({
                             targetId,
                             isOpen,
                             placement = "right",
                             children
                           }: DHLPopoverProps) => {
  return (
      <Fade className={"dhlPopover-fade"} in={isOpen}>
        <Popover
          className={"dhlPopover"}
          target={targetId}
          placement={placement}
          isOpen={isOpen}>
          <div className={"dhlPopover-content"}>
            {children}
          </div>
        </Popover>
      </Fade>
  );
};