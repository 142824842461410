/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {isObservableArray, toJS} from "mobx";

export const extractEarliestValueOfArrayOrObject = (data: any): any => {
  if (Array.isArray(data)) {
    if (data.length === 0) {
      return "";
    } else {
      return extractEarliestValueOfArrayOrObject(data[0]);
    }
  } else if (typeof data === 'object' && data !== null) {
    if (isObservableArray(data)){
      return extractEarliestValueOfArrayOrObject(toJS(data));
    } else if (Object.keys(data).length === 0) {
      return "";
    } else {
      return extractEarliestValueOfArrayOrObject(data[Object.keys(data)[0]])
    }
  } else {
    return data;
  }
};
