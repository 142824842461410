/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { action, computed, makeObservable, observable } from "mobx";
import { logger } from "../utils/logger";
import { FormField } from "./FormField";

export const LOG_MODULE = "DHLOptionTreeStore";

export class DHLOptionTreeStore {

  rootNodes: DHLOptionTreeSubTreeEntry[];

  constructor() {
    this.rootNodes = [];
    makeObservable(this, {
      rootNodes: observable,
      atLeastOneSelected: computed,
      allSelected: computed,
      someButNotAllSelected: computed,
      expandAllButtonDisabled: computed,
      collapseAllButtonDisabled: computed,
      cycleSelectAll: action,
      toggleSubTreeExpansion: action,
      expandAll: action,
      collapseAll: action,
      expandSubTree: action,
      collapseSubTree: action,
      selectAll: action,
      deselectAll: action,
      reset: action,
      setRootNodes: action
    });
  }

  get atLeastOneSelected():boolean {
    return this.rootNodes.some(item => this.hasSelected(item));
  }

  get someButNotAllSelected():boolean {
    return this.atLeastOneSelected && !this.allSelected;
  }

  get allSelected():boolean {
    return !this.rootNodes.some(item => this.hasUnselected(item));
  }

  cycleSelectAll() {
    if (this.allSelected) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  setRootNodes(rootNodes: DHLOptionTreeSubTreeEntry[]) {
    this.rootNodes = [...rootNodes];
  }

  reset() {
    this.rootNodes = [];
  }

  get expandAllButtonDisabled(): boolean {
    const subTreeEntries = findAllSubTrees(this.rootNodes);
    return subTreeEntries.every(subTree => subTree.expanded);
  }

  get collapseAllButtonDisabled(): boolean {
    const subTreeEntries = findAllSubTrees(this.rootNodes);
    return subTreeEntries.every(subTree => !subTree.expanded);
  }

  toggleSubTreeExpansion(id: string) {
    const subTree = findSubTree(id, this.rootNodes)?.subTree;
    if (!subTree) {
      logger.error(LOG_MODULE, "Could not find sub tree to toggle expansion with id", id);
      return;
    }
    subTree.expanded ? this.collapseWithChildren(subTree) : subTree.expanded = true;
  }

  expandSubTree(id: string) {
    const searchResult = findSubTree(id, this.rootNodes);
    if (!searchResult) {
      logger.error(LOG_MODULE, "Could not find sub tree to expand with id", id);
      return;
    }
    const {subTree, ancestors} = searchResult;
    subTree.expanded = true;
    ancestors.forEach(node => node.expanded = true);
  }

  collapseSubTree(id: string) {
    const subTree = findSubTree(id, this.rootNodes)?.subTree;
    if (!subTree) {
      logger.error(LOG_MODULE, "Could not find sub tree to collapse with id", id);
      return;
    }
    this.collapseWithChildren(subTree);
  }

  expandAll() {
    this.rootNodes.forEach(item => this.expandWithChildren(item));
  }

  collapseAll() {
    this.rootNodes.forEach(item => this.collapseWithChildren(item));
  }

  selectAll() {
    this.rootNodes.forEach(item => this.select(item));
  }

  deselectAll() {
    this.rootNodes.forEach(item => this.deselect(item));
  }

  onSelectedValueChanged(leaf: DHLOptionTreeLeafEntry, value: boolean) {
    if (leaf.onChange) {
      leaf.onChange(value);
    }
  }

  private expandWithChildren(subTree: DHLOptionTreeSubTreeEntry) {
    subTree.expanded = true;
    subTree.children
      .filter(child => isSubTree(child))
      .forEach(child => this.expandWithChildren(child as DHLOptionTreeSubTreeEntry));
  }

  private collapseWithChildren(subTree: DHLOptionTreeSubTreeEntry) {
    subTree.expanded = false;
    subTree.children
      .filter(child => isSubTree(child))
      .forEach(child => this.collapseWithChildren(child as DHLOptionTreeSubTreeEntry));
  }

  private select(subTree: DHLOptionTreeSubTreeEntry) {
    subTree.children.forEach(child => {
      if (isSubTree(child)) {
        this.select(child as DHLOptionTreeSubTreeEntry);
      } else {
        const leaf = child as DHLOptionTreeLeafEntry;
        leaf.formField.value = true;
        this.onSelectedValueChanged(leaf, true);
      }
    });
  }

  private deselect(subTree: DHLOptionTreeSubTreeEntry) {
    subTree.children.forEach(child => {
      if (isSubTree(child)) {
        this.deselect(child as DHLOptionTreeSubTreeEntry);
      } else {
        const leaf = child as DHLOptionTreeLeafEntry;
        leaf.formField.value = false;
        this.onSelectedValueChanged(leaf, false);
      }
    });
  }

  private hasSelected(subTree: DHLOptionTreeSubTreeEntry): boolean {
    return subTree.children.some(child => {
      if (isSubTree(child)) {
        if (this.hasSelected(child as DHLOptionTreeSubTreeEntry)) {
          return true;
        }
      } else {
        const leaf = child as DHLOptionTreeLeafEntry;
        if (leaf.formField.value) {
          return true;
        }
      }
      return false;
    });
  }

  private hasUnselected(subTree: DHLOptionTreeSubTreeEntry): boolean {
    return subTree.children.some(child => {
      if (isSubTree(child)) {
        if (this.hasUnselected(child as DHLOptionTreeSubTreeEntry)) {
          return true;
        }
      } else {
        const leaf = child as DHLOptionTreeLeafEntry;
        if (!leaf.formField.value) {
          return true;
        }
      }
      return false;
    });
  }

}

export type DHLOptionTreeNodeEntry = DHLOptionTreeSubTreeEntry | DHLOptionTreeLeafEntry

export type DHLOptionTreeSubTreeEntry = {
  id: string
  label: string,
  children: DHLOptionTreeNodeEntry[]
  expanded: boolean
}

export type DHLOptionTreeLeafEntry = {
  formField: FormField<boolean>
  onChange?: (selected: boolean) => void
  renderAdditionalComponent?: (disabled: boolean, formField: FormField<unknown> | undefined) => JSX.Element;
  additionalFormField?: FormField<unknown>;
}

type SubTreeSearchResult = {
  subTree: DHLOptionTreeSubTreeEntry
  ancestors: DHLOptionTreeSubTreeEntry[]
}

function findSubTree(id: string, nodeEntries: DHLOptionTreeNodeEntry[]): SubTreeSearchResult | undefined {
  for (const node of nodeEntries) {
    if (!isSubTree(node)) {
      continue;
    }
    const subTree = node as DHLOptionTreeSubTreeEntry;
    if (subTree.id === id) {
      return {subTree: subTree, ancestors: []};
    }
    const searchResult = findSubTree(id, subTree.children);
    if (searchResult) {
      return {subTree: searchResult.subTree, ancestors: [subTree, ...searchResult.ancestors]};
    }
  }
}

function findAllSubTrees(nodeEntries: DHLOptionTreeNodeEntry[]): DHLOptionTreeSubTreeEntry[] {
  const subTreeEntries: DHLOptionTreeSubTreeEntry[] = [];
  for (const node of nodeEntries) {
    if (isSubTree(node)) {
      const subTree = node as DHLOptionTreeSubTreeEntry;
      subTreeEntries.push(subTree);
      subTreeEntries.push(...findAllSubTrees(subTree.children));
    }
  }
  return subTreeEntries;
}

function isSubTree(node: DHLOptionTreeNodeEntry): boolean {
  return (node as DHLOptionTreeSubTreeEntry).id !== undefined;
}