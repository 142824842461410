import classNames from "classnames";
import { uid } from "react-uid";
import { DayOfMonth, WeekOfMonth } from "../../../types/DatePickerTypes";
import { Day } from "./Day";

export type WeekProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Wochentage. */
  week: WeekOfMonth;

  /** Wochennummern anzeigen */
  showWeekNumbers: boolean

  /** Funktion zur Speicherung des ausgewählten Tages. */
  onDaySelected: (day: DayOfMonth) => void;

  /** Callback function that gets called when the mouse enters the day cell/div */
  onMouseEnterDay?: (day: DayOfMonth) => void;

  /** Callback function that gets called when the mouse leaves the day cell/div */
  onMouseLeaveDay?: (day: DayOfMonth) => void;
};

/** Stellt eine Kalendarwoche dar. */
export const Week = ({name, showWeekNumbers, week, onDaySelected, onMouseEnterDay, onMouseLeaveDay}: WeekProps) => {
  return (
      <tr data-testid={name}>
        {showWeekNumbers && <td className="kw">
          <div
              data-testid={name + "-kw-" + week.calenderweek}
              className={classNames("kw")}>
            {week.calenderweek}
          </div>
        </td>}
        {week.days.map(day => (
            <Day name={name + "-" + day.day} key={uid(day)} day={day} onDaySelected={onDaySelected} onMouseEnterDay={onMouseEnterDay}
                 onMouseLeaveDay={onMouseLeaveDay} />
        ))}
      </tr>
  );
};
