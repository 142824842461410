/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import {uid} from "react-uid";
import "./DHLFieldNote.scss";

export type DHLFieldNoteProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Typ, bestimmt die Ausprägung des Elements. */
  type: "error" | "hint" | "source";

  /** Fehlertexte, wenn Fehler aufgetreten sind. */
  notes?: string[] | string;

  /** Style-Klassen, die von außen ergänzt werden können.*/
  classname?: string;
};

/** Fehlermeldung zu einem Feld ausgeben. */
export const DHLFieldNote = ({name, type = "hint", notes, classname}: DHLFieldNoteProps) => {
  let noteArray:string[] = [];
  if (notes) {
    noteArray = Array.isArray(notes) ? notes : [notes];
  }
      return (
        <>
          {noteArray.map(currentNote => {
            return (
                <div data-testid={name} key={uid(currentNote)} className={classNames(classname, "fieldNote", type)}>
                  {currentNote}
                </div>
            );
          })}
        </>
    );
};
