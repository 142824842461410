import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as Popper from "popper.js";
import { useState } from "react";
import { UIDConsumer } from "react-uid";
import { Input } from "reactstrap";
import { DHLIcon, DHLLabel, FormField, logger } from "../../..";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import {
  calculateDisabled,
  calculateError,
  calculateErrorMarkerOnly,
  calculateHint,
  calculateLabel,
  calculateMaxLength,
  calculateName,
  calculateOnChange,
  calculateRequired,
  calculateSource,
  calculateValidationRule,
  calculateValue
} from "../../../utils/calcPropertiesValue";
import { DHLFieldNote } from "../../atoms/DHLFieldNote/DHLFieldNote";
import { IconType } from "../../atoms/DHLIcon/DHLIcon";
import { DHLTooltip } from "../DHLTooltip/DHLTooltip";
import "./DHLTextInput.scss";

/** WICHTIG: Kein extends verwenden, da Storybook damit nicht umgehen kann und die Attribute der übergeordneten Interfaces/Typen nicht anzeigt. */
export type DHLTextInputProps = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen einzigen Change-Handler verwenden zu
   * können. Wir dauch für die Generierung der Test-ID ververwendet. */
  name?: string;

  /** Typ des Eingabefeldes. */
  type?: "text" | "number" | "password" | "email" | "textarea";

  /** Labeltext. */
  label?: string;

  /** CSS-Klassen für das Label. */
  labelClassName?: string;

  /** CSS-Klassen für das Eingabefeld. */
  className?: string;

  /** Label mit Pflichtfeldmarkierung versehen? */
  required?: boolean;

  /** Cursor im Eingabefeld positionieren? */
  autoFocus?: boolean;

  /** Autovervollständig erlaubt? */
  autoCompleteOff?: boolean;

  /** Anzahl Zeilen. */
  rows?: number;

  /** Anzahl Spalten. */
  cols?: number;

  /** Zeilenumbruch. */
  wrap?: "soft" | "hard";

  /** Platzhaltertext für leere Eingabefelder. */
  placeholder?: string;

  /** Eingabewert (optional damit Storybook usw. Eingaben zulassen). */
  value?: any;

  /** Validierungsregel mit Konfiurationsdaten für das Eingabefeld. */
  validationRule?: ValidationRuleType;

  /** Fehlertext(e), wenn ein oder mehrere Fehler aufgetreten sind. */
  error?: string | string[] | null;

  /** Nur das Eingabefeld markieren oder auch Fehlertexte anzeigen? */
  errorMarkerOnly?: boolean;

  /** Hinweistext(e), wenn ein oder mehrere Hinweise angegeben sind. */
  hint?: string | string[] | null;

  /** Quellenangabe(n), wenn ein oder mehrere Quellen angegeben sind. */
  source?: string | string[] | null;

  /** Tooltip. */
  tooltip?: string;

  /** Ausrichtung des Tooltips. */
  tooltipPlacement?: Popper.Placement;

  /** Tooltip, wenn Eingabefeld disabled. */
  disabledTooltip?: string;

  /** Bearbeitungsmodus des Formulars (true = Neuanalage, false = Bearbeitung). Der Pflichtfeldstatus des Eingabefeldes kann vom Bearbeitungsmodus
   * abhängen.  */
  createMode?: boolean;

  /** Deaktivieren? */
  disabled?: boolean;

  /** Element ausgeben? */
  render?: boolean;

  /** Nur lesbar? */
  readOnly?: boolean;

  /** Label und Eingabefeld horizontal anordnen? */
  inline?: boolean;

  /** Max. Anzahl erlaubter Zeichen. */
  maxLength?: number;

  /** Funktion für onChange-Aufrufe. */
  onChange?: React.ChangeEventHandler<any>;

  /** Funktion für KeyUp-Aufrufe */
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;

  /** Funktion für KeyUp-Aufrufe */
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;

  /** Funktion für MouseDown-Aufrufe */
  onMouseDown?: React.MouseEventHandler<HTMLElement>;

  /** Funktion für MouseUp-Aufrufe */
  onMouseUp?: React.MouseEventHandler<HTMLElement>;

  /** Funktion für onFcus-Aufrufe. */
  onFocus?: React.FocusEventHandler<HTMLElement>;

  /** Funktion für onBlur-Aufrufe. */
  onBlur?: React.FocusEventHandler<any>;

  /** Definiert ein Formularfeld mit Name, Label, Wert, Validierungsregel und Fehlertext. Einzelparameter haben Priorität. */
  formField?: FormField<any>;

  /** optionales Funktionsicon */
  functionIcon?: IconType;

  /** The style in which the function icon will be displayed*/
  functionIconType?: "primary" | null;

  /** If the function icon should be disabled */
  functionIconDisabled?: (currentInputValue: string) => boolean;

  /** Tooltip to render under the function icon button when disabled */
  functionIconDisabledTooltip?: string;

  /** Funktion, falls das Funktionsicon klickbar sein soll */
  onFunctionClick?: React.MouseEventHandler<any>;

  /** innerReference to use in useRef for later focussing */
  innerRef?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
};

/** Text Input mit integriertem Label. */
export const DHLTextInput = observer(({
                                        name,
                                        label,
                                        labelClassName,
                                        className,
                                        type = "text",
                                        required,
                                        autoFocus,
                                        autoCompleteOff,
                                        rows,
                                        cols,
                                        wrap,
                                        createMode,
                                        placeholder,
                                        disabled,
                                        render = true,
                                        readOnly,
                                        value,
                                        maxLength,
                                        error,
                                        errorMarkerOnly,
                                        hint,
                                        source,
                                        tooltip,
                                        tooltipPlacement = "right",
                                        disabledTooltip,
                                        validationRule,
                                        onChange,
                                        onKeyDown,
                                        onKeyUp,
                                        onMouseDown,
                                        onMouseUp,
                                        onFocus,
                                        onBlur,
                                        formField,
                                        functionIcon,
                                        functionIconType,
                                        functionIconDisabled = () => false,
                                        functionIconDisabledTooltip,
                                        onFunctionClick,
                                        innerRef
                                      }: DHLTextInputProps
) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [functionIconTooltipOpen, setFunctionIconTooltipOpen] = useState(false);
  if (!render) {
    return null;
  }

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleFunctionIconTooltip = () => setFunctionIconTooltipOpen(!functionIconTooltipOpen);
  let usedTooltip = tooltip;

  if (disabled && disabledTooltip) {
    usedTooltip = disabledTooltip;
  }
  const calcName = calculateName(name, formField);

  if (!calcName) {
    logger.error("Components needs an explicit name or formField parameter");
    return null;
  }

  const calcLabelCompact = type !== "textarea" ? calculateLabel(label, formField) : null;
  const calcLabelClassic = type === "textarea" ? calculateLabel(label, formField) : null;
  const calcValidationRule = calculateValidationRule(validationRule, formField);
  const calcError = calculateError(error, formField);
  const calcHint = calculateHint(hint, formField);
  const calcSource = calculateSource(source, formField);
  const calcValue = calculateValue(value, formField);
  const calcOnChange = calculateOnChange(onChange, formField);
  const calcMaxLength = calculateMaxLength(maxLength, calcValidationRule);
  const calcRequired = calculateRequired(required, calcValidationRule, createMode);
  const calcErrorMarkerOnly = calculateErrorMarkerOnly(errorMarkerOnly, calcValidationRule, formField);
  const calcDisabled = calculateDisabled(disabled, calcValidationRule, createMode);
  const calcLabelActive = calcValue !== null && calcValue !== undefined && calcValue !== "";
  const calcRows = rows ? rows : 5;
  const calcCols = cols ? cols : undefined;
  const calcWrap = wrap ? wrap : "soft";
  const calcFunctionsIcon = functionIcon !== undefined
      ? <div
          className={classNames("input-function", {
            "function-empty": functionIcon === "close-thin",
            "not-interactive": onFunctionClick === undefined || calcDisabled || disabled || functionIconDisabled(formField?.value ?? ""),
            "disabled": calcDisabled || disabled || functionIconDisabled(formField?.value ?? "")
          }, functionIcon, functionIconType)}
          onClick={calcDisabled
              ? () => { /* intended use */ }
              : onFunctionClick}
      >
        <DHLIcon name={calcName + "-function"} icon={functionIcon} />
        {functionIconDisabled(formField?.value ?? "") && functionIconDisabledTooltip !== undefined &&
        <DHLTooltip testid={calcName + "-functionTooltip"} placement={"bottom"} tooltipOpen={functionIconTooltipOpen} target={calcName + "-function"}
                    toggle={toggleFunctionIconTooltip}>
          {functionIconDisabledTooltip}
        </DHLTooltip>}
      </div>
      : null;

  const noLabelInputField = type !== "textarea" && !calcLabelCompact;
  const output = (
      <UIDConsumer>
        {uid => (
            <div data-testid={calcName + "-inputContainer"} className={classNames("inputContainer", className)}>
              <div className={classNames("inputContainer-inner")}>
                {calcLabelClassic && <DHLLabel name={calcName + "-label"}
                                               htmlFor={uid}
                                               label={calcLabelClassic}
                                               tooltip={tooltip ? tooltip : undefined}
                                               required={calcRequired}
                                               className={classNames("labelClassic", labelClassName,
                                                   {labelActive: calcLabelActive}, {hasError: calcError})} />}
                <Input
                    id={uid}
                    name={calcName}
                    type={type}
                    data-testid={calcName}
                    placeholder={type === "textarea" ? placeholder : undefined}
                    value={calcValue}
                    maxLength={calcMaxLength}
                    autoComplete={autoCompleteOff ? "off" : undefined}
                    rows={type === "textarea" ? calcRows : undefined}
                    cols={type === "textarea" ? calcCols : undefined}
                    wrap={type === "textarea" ? calcWrap : undefined}
                    autoFocus={autoFocus}
                    disabled={calcDisabled}
                    readOnly={readOnly}
                    onChange={calcOnChange}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    invalid={calcError !== null && calcError !== undefined && calcError.length > 0}
                    className={
                      classNames(
                          className,
                          "dhlInput",
                          cols ? null : "full-width",
                          functionIcon ? "has-function" : null,
                          functionIconType ? ("has-" + functionIconType) : null,
                          noLabelInputField ? "no-label" : null
                      )}
                    innerRef={innerRef}

                />
                {calcLabelCompact && <DHLLabel name={calcName + "-label"} htmlFor={uid} label={calcLabelCompact} required={calcRequired}
                                               className={classNames("labelCompact", labelClassName,
                                                   {labelActive: calcLabelActive}, {hasError: calcError})} />}
                {usedTooltip && <div className={classNames("input-tooltip")}>
                  <DHLIcon name={calcName + "-tooltip"} icon={"alert-info"} />
                  <DHLTooltip testid={calcName + "-tooltip"} placement={tooltipPlacement} tooltipOpen={tooltipOpen} target={calcName + "-tooltip"}
                              toggle={toggle}>
                    {usedTooltip}
                  </DHLTooltip>
                </div>}
                {calcFunctionsIcon}
              </div>
              {!calcErrorMarkerOnly && calcError && <DHLFieldNote name={calcName + "-error"} type={"error"} notes={calcError} />}
              {calcHint && <DHLFieldNote name={calcName + "-hint"} type={"hint"} notes={calcHint} />}
              {calcSource && <DHLFieldNote name={calcName + "-source"} type={"source"} notes={calcSource} />}
            </div>
        )}
      </UIDConsumer>
  );

  return <>{output}</>;
});
