/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect } from "react";
import { BaseDataStore, ResourceDataStore, ValidationRuleDataStore } from "..";

function isBaseDataStore(baseDataStoreOrResourceDataSore: BaseDataStore | ResourceDataStore | ValidationRuleDataStore): baseDataStoreOrResourceDataSore is BaseDataStore {
  return baseDataStoreOrResourceDataSore.hasOwnProperty("resourceDataStore") && baseDataStoreOrResourceDataSore.hasOwnProperty("validationRuleDataStore");
}

/**
 * @deprecated use `useResourcesAndValidations(baseDataStore.resourceDataStore, baseDataStore.validationRuleDataStore)` instead.
 * @param {BaseDataStore} baseDataStore base data store
 */
export function useResourcesAndValidations(baseDataStore: BaseDataStore): void;
/**
 * Loads resources and validations.
 *
 * @param {ResourceDataStore} resourceDataStore resource data store
 * @param {ValidationRuleDataStore} validationRuleDataStore validation rule data store
 */
export function useResourcesAndValidations(resourceDataStore: ResourceDataStore, validationRuleDataStore: ValidationRuleDataStore): void;
/**
 * @deprecated use `useResourcesAndValidations(baseDataStore.resourceDataStore, baseDataStore.validationRuleDataStore)` instead.
 * @param {BaseDataStore | ResourceDataStore} baseDataStoreOrResourceDataSore base data store or resource data store
 * @param {ValidationRuleDataStore} validationRuleDataStore validation rule data store
 */
export function useResourcesAndValidations(
    baseDataStoreOrResourceDataSore: BaseDataStore | ResourceDataStore,
    validationRuleDataStore?: ValidationRuleDataStore
): void {
  const resourceDataStore: ResourceDataStore = isBaseDataStore(baseDataStoreOrResourceDataSore)
      ? baseDataStoreOrResourceDataSore.resourceDataStore
      : baseDataStoreOrResourceDataSore;
  validationRuleDataStore = isBaseDataStore(baseDataStoreOrResourceDataSore)
      ? baseDataStoreOrResourceDataSore.validationRuleDataStore
      : validationRuleDataStore;

  useResources(resourceDataStore);

  useEffect(() => {
    if (validationRuleDataStore !== undefined) {
      validationRuleDataStore.load();
    }
  }, [validationRuleDataStore]);
}

export function useResources(resourceDataStore: ResourceDataStore): void {
  useEffect(() => {
    void resourceDataStore.load();
  }, [resourceDataStore]);
}

/**
 * @deprecated use `useValidations(baseDataStore.validationRuleDataStore)` instead.
 * @param {BaseDataStore} baseDataStore base data store
 */
export function useValidations(baseDataStore: BaseDataStore): void;
/**
 * Loads validations.
 *
 * @param {ValidationRuleDataStore} validationRuleDataStore validation rule data store
 */
export function useValidations(validationRuleDataStore: ValidationRuleDataStore): void;
/**
 * @deprecated use `useValidations(baseDataStore.validationRuleDataStore)` instead.
 * @param {BaseDataStore | ValidationRuleDataStore} baseDataStoreOrValidationRuleDataStore base data store or validation rule data store
 */
export function useValidations(baseDataStoreOrValidationRuleDataStore: BaseDataStore | ValidationRuleDataStore): void {
  const validationRuleDataStore: ValidationRuleDataStore = isBaseDataStore(baseDataStoreOrValidationRuleDataStore)
      ? baseDataStoreOrValidationRuleDataStore.validationRuleDataStore
      : baseDataStoreOrValidationRuleDataStore;

  useEffect(() => {
    validationRuleDataStore.load();
  }, [validationRuleDataStore]);
}
