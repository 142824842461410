/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { LocalizationPack } from "../../../stores/LocalizationPack";
import { DHLSelectOne } from "../DHLSelectOne/DHLSelectOne";
import { fullTextFilterPredicate } from "../../../stores/DataFilterStore";
import { useDataFilterStore } from "../../../utils/useDataFilterStore";
import { KeyValueType } from "../../../types/DataTypes";
import { KeyResourceDataType } from "../../../types/ResourceDataStoreTypes";
import {useEffect} from "react";

export type DHLSelectionList = {
  values: (KeyValueType | KeyResourceDataType)[];
  title?: string;
}

export type DHLSelectOneFilterProps<T> = {
  name: string;
  selectionList: DHLSelectionList
  localizationPack: LocalizationPack;
  /**
   * @deprecated only implemented to keep backward compatibility. Just observe the dataStore to get notified about changes in filter criteria.
   */
  beforeChange?: React.ChangeEventHandler<HTMLSelectElement>;
  /**
   * @deprecated only implemented to keep backward compatibility. Just observe the dataStore to get notified about changes in filter criteria.
   */
  afterChange?: React.ChangeEventHandler<HTMLSelectElement>;
  idSymbol: string;
  predicate?: (values: Set<string>) => (data: T) => boolean;
  localize?: (value: string) => string;
}

export const DHLSelectOneFilter = <T extends {}>(
    {
      name,
      selectionList,
      localizationPack,
      idSymbol,
      predicate = fullTextFilterPredicate,
      localize,
      beforeChange,
      afterChange
    }: DHLSelectOneFilterProps<T>
) => {
  const dataFilterStore = useDataFilterStore<T>();

  useEffect(
      () => dataFilterStore.register(idSymbol, predicate, localize),
      [dataFilterStore, idSymbol, predicate, localize]
  );

  return <DHLSelectOne
      name={name}
      data={selectionList.values}
      localizationPack={localizationPack}
      placeholder={selectionList.title}
      forceNoEmptyOption={true}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        beforeChange?.(event);
        dataFilterStore.setFilterValues(idSymbol, [event.target.value]);
        afterChange?.(event);
      }}
  />;
};