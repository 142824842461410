

export type DHLSpacerProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name?: string;

  /** Abstand, gemäß Bootstrap size. */
  size: number;

  /** Element ausgeben? */
  render?: boolean;
};

/** Abstand ausgeben. */
export const DHLSpacer = ({name, size, render = true}: DHLSpacerProps) => {
  if (!render) {
    return null;
  }

  const className = "mt-" + size;

  return <div data-testid={name} className={className} />;
};
