import classNames from "classnames";
import { ReactElement } from "react";
import { Label } from "reactstrap";
import { createMessageWithSpecialTags } from "../../../utils/messagesUtils";
import { DHLTooltipIcon } from "../DHLTooltipIcon/DHLTooltipIcon";
import "./DHLLabel.scss";
import { observer } from "mobx-react-lite";

export type DHLLabelProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** HTML ID des zugeordneten Eingabefeldes. */
  htmlFor: string;

  /** Labeltext. */
  label?: string;

  /** Pflichtfeldmarkierung hinter dem Labeltext anzeigen. */
  required?: boolean;

  /** Label für eine Checkbox? */
  checkbox?: boolean;

  /** Tooltiptext. */
  tooltip?: string;

  /** Function calle if the label is clicked. */
  onClick?: React.MouseEventHandler<HTMLElement>;

  /** CSS-Klassen. */
  className?: string;

  /** Kind-Komponenten */
  children?: ReactElement[] | ReactElement;

  /** Flag, indicating, that the label text should be converted with the method interpreting special tags. */
  useSpecialTags?: boolean;
};

/** Label mit Required-Markierung. */
export const DHLLabel = observer(({
                                    name, htmlFor, label, required, checkbox, tooltip, onClick = () => { /* intended use */},
                                    className, children, useSpecialTags = false
                                  }: DHLLabelProps) => {
  const output = (
      <div className={classNames("dhlLabelContainer")} onClick={onClick}>
        <Label data-testid={name} htmlFor={htmlFor} check={checkbox} className={classNames("dhlLabel", className)}>
          {children}
          {useSpecialTags ? createMessageWithSpecialTags(label) : label}
          {required && (
              <span data-testid={name + "-req"} className="dhl-required">
            {"*"}
          </span>
          )}
        </Label>
        {tooltip && <DHLTooltipIcon name={name + "-tooltip"} tooltip={tooltip} />}
      </div>
  );
  return <>{output}</>;
});
