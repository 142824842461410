/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import "./DHLSuccessMessageContainer.scss";
import { DHLHeadline } from "../DHLHeadline/DHLHeadline";
import { DHLIcon } from "../DHLIcon/DHLIcon";


export type DHLSuccessMessageContainerProps = {
  /** Headline */
  title: string;

  /** messge in container */
  message: string;
};

/** Abstand ausgeben. */
export const DHLSuccessMessageContainer = ({title, message}: DHLSuccessMessageContainerProps) => {
  return <div className={"successMessageContainer"}>
    <DHLHeadline type={"h5"}>
      {title}
    </DHLHeadline>
    <DHLIcon name={"check"} icon={"ofi-form-checked"} />
    <p>{message}</p>
  </div>;
};