/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { UNSAFE_LocationContext, useSearchParams } from "react-router-dom";
import { Context, useContext } from "react";

// LocationContextObject is not exported from react-router(-dom). But we can use Typescript here :)
type ExtractGeneric<Type> = Type extends Context<infer X> ? X : never
type LocationContextObject = ExtractGeneric<typeof UNSAFE_LocationContext>

// to support multiple Routers on a site
let memorizedSearchParamMap: Map<LocationContextObject, ReturnType<typeof useSearchParams>> = new Map();

export function useSharedSearchParams(): ReturnType<typeof useSearchParams> {
  const locationContextObject = useContext(UNSAFE_LocationContext);
  // to comply with rules of hooks: not used in if
  const newSearchParams = useSearchParams();

  let memorizedSearchParams = memorizedSearchParamMap.get(locationContextObject)
  if(memorizedSearchParams === undefined) {
    memorizedSearchParamMap.set(locationContextObject, newSearchParams);
    memorizedSearchParams = newSearchParams;
  }
  return memorizedSearchParams;
}