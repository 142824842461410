/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {Component, ErrorInfo } from "react";
import { logger } from "../../../utils/logger";
import { DHLFatalErrorPage } from "../../atoms/DHLFatalErrorPage/DHLFatalErrorPage";

/** Catch errors in child components and display an error page. */
export class DHLErrorBoundary extends Component<{}, { hasError: boolean }> {

  constructor(props: {}) {
    super(props);

    this.state = {hasError: false};
  }

  static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    logger.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <DHLFatalErrorPage />;
    }

    return this.props.children;
  }
}
