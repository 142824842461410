import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { uid } from "react-uid";
import { createMessageWithSpecialTags, DHLAlert, DHLTextOutput, logger } from "../../..";
import { MessageDataStore } from "../../../stores/MessageDataStore";
import "./DHLMessages.scss";
import { NavigateFunction } from "react-router-dom";

export type DHLMessagesProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Lokalisierte Meldungen. */
  msgStore: MessageDataStore;

  /** Alert variant for displaying messages */
  variant?: "medium" | "flex";

  /** Alert variant for displaying messages */
  closable?: boolean;

  /** navigate function, only required for internal links**/
  navigateFunction?: NavigateFunction;
};

const LOG_MODULE = "[DHLMessages] ";

/** Ausgabe von Erfolgs- und Fehlermeldungen. */
export const DHLMessages = observer(({name, msgStore, variant = "flex", closable = false, navigateFunction}: DHLMessagesProps) => {
  logger.log(LOG_MODULE, "message count - success/error/neutral/warning ",
    msgStore.successMsgCount, msgStore.errorMsgCount, msgStore.neutralMsgCount, msgStore.warningMsgCount);

  function formatText(text: string, name?: string) {
    return <DHLTextOutput name={name} value={createMessageWithSpecialTags(text, navigateFunction)} />;
  }

  function createHeadlineIfSet(headline: string | undefined): JSX.Element | null {
    if (!headline) {
      return null;
    }
    return formatText(headline, `${name}-headline`);
  }

  function createMessageOutput(headline: string | undefined, messages: string[], nameSuffix: string): JSX.Element | null {
    const outputName = `${name}-${nameSuffix}`;
    if (!headline && messages.length <= 1) {
      if (messages.length === 0) {
        return null;
      }
      return formatText(messages[0], outputName);
    }
    return (
        <>
          {createHeadlineIfSet(headline)}
          <ul data-testid={outputName}>
            {messages.map(msg => <li key={uid(msg)}>{formatText(msg)}</li>)}
          </ul>
        </>
    );
  }

  const errorMsgs = msgStore.errorMsgs ? msgStore.errorMsgs : [];
  const successMsgs = msgStore.successMsgs ? msgStore.successMsgs : [];
  const neutralMsgs = msgStore.neutralMsgs ? msgStore.neutralMsgs : [];
  const warningMsgs = msgStore.warningMsgs ? msgStore.warningMsgs : [];

  const permanentErrorMsgs = msgStore.permanentErrorMsgs ? msgStore.permanentErrorMsgs : [];
  const permanentSuccessMsgs = msgStore.permanentSuccessMsgs ? msgStore.permanentSuccessMsgs : [];
  const permanentNeutralMsgs = msgStore.permanentNeutralMsgs ? msgStore.permanentNeutralMsgs : [];
  const permanentWarningMsgs = msgStore.permanentWarningMsgs ? msgStore.permanentWarningMsgs : [];

  const errorMessages = ([] as string[]).concat(errorMsgs, permanentErrorMsgs);
  const successMessages = ([] as string[]).concat(successMsgs, permanentSuccessMsgs);
  const neutralMessages = ([] as string[]).concat(neutralMsgs, permanentNeutralMsgs);
  const warningMessages = ([] as string[]).concat(warningMsgs, permanentWarningMsgs);

  const errorOutput = createMessageOutput(msgStore.errorHeadline, errorMessages, "error");
  const successOutput = createMessageOutput(msgStore.successHeadline, successMessages, "success");
  const neutralOutput = createMessageOutput(msgStore.neutralHeadline, neutralMessages, "neutral");
  const warningOutput = createMessageOutput(msgStore.warningHeadline, warningMessages, "warning");

  logger.log(LOG_MODULE, "changes detected - render success/error ", successOutput !== null, errorOutput !== null, successOutput);

  const closeNeutralAlerts = closable ? () => {
    msgStore.clearNeutralMessages();
  } : undefined;
  const closeErrorAlerts = closable ? () => {
    msgStore.clearErrorMessages();
  } : undefined;
  const closeSuccessAlerts = closable ? () => {
    msgStore.clearSuccessMessages();
  } : undefined;
  const closeWarningAlerts = closable ? () => {
    msgStore.clearWarningMessages();
  } : undefined;

  if (errorOutput === null && successOutput === null && neutralOutput === null && warningOutput === null) {
    return null;
  }
  return (
    <div data-testid={name} className={classNames("dhlMessagesContainer")}>
      {neutralOutput && <DHLAlert type={"default"} variant={variant} closable={closeNeutralAlerts}>{neutralOutput}</DHLAlert>}
      {errorOutput && <DHLAlert type={"error"} variant={variant} closable={closeErrorAlerts}>{errorOutput}</DHLAlert>}
      {successOutput && <DHLAlert type={"success"} variant={variant} closable={closeSuccessAlerts}>{successOutput}</DHLAlert>}
      {warningOutput && <DHLAlert type={"warning"} variant={variant} closable={closeWarningAlerts}>{warningOutput}</DHLAlert>}
    </div>
  );
});
