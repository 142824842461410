/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { ProgressBarState } from "./DHLProgressBar";

export type ProgressBarLabelProps = {
  labelText: string;
  labelState: ProgressBarState
}

export const ProgressBarLabel = ({labelText, labelState}: ProgressBarLabelProps) => {
  const className = "dhlProgressBar-label";
  const classNameWithState = `${className}-${labelState}`;
  return <span className={classNames(className, classNameWithState)}>{labelText}</span>;
};