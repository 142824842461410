/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import {useCallback, useState} from "react";
import {FormField} from "../../../stores/FormField";
import {logger} from "../../../utils/logger";
import {DHLIcon} from "../../atoms/DHLIcon/DHLIcon";
import {DHLTextInput} from "../DHLTextInput/DHLTextInput";
import "./DHLSearchBar.scss";
import {DHLTooltip} from "../DHLTooltip/DHLTooltip";

export type DHLSearchBarProps = {
  /** Name for the searchbar */
  name: string;
  /** Form field for the search bar */
  formField: FormField<string>;
  /** Invoked when the submit-button is clicked */
  onSubmit: () => void;
  /** Disables the entire search bar */
  disabled?: boolean;
  /** Disables the submit button only */
  buttonDisabled?: boolean;
  /** Tooltip to display when the search button is disabled */
  buttonDisabledTooltip?: string;
  /** Maximum length of input */
  maxLength?: number;
}

const LOG_MODULE = "DHLSearchBar";

export const DHLSearchBar = ({
                               name,
                               formField,
                               onSubmit,
                               disabled,
                               buttonDisabled,
                               buttonDisabledTooltip,
                               maxLength
                             }: DHLSearchBarProps) => {
  const baseName = formField.name !== undefined && formField.name.length > 0 ? formField.name : name;
  const inputName = `${baseName}-input`;
  const buttonName = `${baseName}-submitButton`;
  const [buttonTooltipOpen, setButtonTooltipOpen] = useState(false);
  const buttonTooltipToggle = () => setButtonTooltipOpen(!buttonTooltipOpen);

  const submitValue = useCallback(() => {
    if (!disabled && !buttonDisabled && formField.value.length > 0) {
      onSubmit();
      logger.log(LOG_MODULE, `Value submitted: ${formField.value}`);
    }
  }, [disabled, buttonDisabled, formField.value, onSubmit]);

  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      logger.log(LOG_MODULE, "Enter pressed");
      e.preventDefault();
      submitValue();
    }
  }, [submitValue]);

  const onButtonClicked = (e: React.MouseEvent) => {
    logger.log(LOG_MODULE, "Submit button clicked");
    e.preventDefault();
    submitValue();
  };

  const input = (
      <DHLTextInput
          name={inputName}
          maxLength={maxLength}
          formField={formField}
          onKeyUp={onKeyUp}
          disabled={disabled} />
  );

  const button = (
      <DHLIcon
          name={buttonName}
          icon={"search"}
          onClick={onButtonClicked} />
  );

  const buttonTooltip = (
      buttonDisabled && buttonDisabledTooltip &&
      <DHLTooltip
          target={buttonName}
          tooltipOpen={buttonTooltipOpen}
          toggle={buttonTooltipToggle}>
        {buttonDisabledTooltip}
      </DHLTooltip>
  );

  return (
      <div className={classNames("dhlSearchBar")}>
        <div className={classNames("dhlSearchBar-container")}>
          <div>
            {input}
          </div>
          <div className={classNames("dhlSearchBar-submitButton", disabled || buttonDisabled ? "disabled" : "")}>
            {button}
            {buttonTooltip}
          </div>
        </div>
      </div>
  );

};