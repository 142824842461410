/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ComponentPropsWithoutRef, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { NavLink, parsePath, Route, To, useNavigate } from "react-router-dom";
import "./DHLTabNavigationBar.scss";
import classNames from "classnames";
import { DHLIconProps } from "../../atoms/DHLIcon/DHLIcon";
import { DHLTooltip } from "../DHLTooltip/DHLTooltip";
import { useSharedSearchParams } from "../../../utils/useSharedSearchParams";

type DHLTabNavigationBarChild = ReactElement<DHLTabNavigationBarItemProps> | boolean | null | undefined

export type DHLTabNavigationBarProps = {
  /**
   * Look of tabs as defined in GKDS (with some adjustments like dual-line labels).
   * - `primary`: always displays the optional gray bar after the tabs.
   * - `secondary-dialog`: not implemented
   * - `secondary-free-gray`: use `secondary-free` and render gray surrounding box from outer component
   * - `secondary-free-white`: use `secondary-free`
   * - `scrollbar`: not implemented
   */
  variant: "primary" | "secondary-free";

  /**
   * Can be used to preserve search params during navigation.
   * - `false`or `undefined`: discard all search params
   * - `true`: preserve all search params
   * - function: filters current search params by search param name
   */
  preserveSearchParams?: boolean | ((searchParamName: string) => boolean);

  /** Children. Should be one or more `DHLTabNavigationBarItem`(s) */
  // type is adjusted from ReactNode
  children: DHLTabNavigationBarChild | DHLTabNavigationBarChild[];
};

/**
 * Renders tabs for navigation but is not directly responsible for displaying content.
 *
 * @example
 * ```tsx
 * <CallingComponent>
 *   <DHLTabNavigationBar variant={"primary"}>
 *     <DHLTabNavigationBarItem
 *         to={"/target/path/to/SomeComponent"}
 *         labelText={"Some label"}
 *         icon={<DHLIcon icon={"add"} />}
 *         tooltip={"Some tooltip"}
 *         disabled={disabled}
 *     />
 *     ...more DHLTabNavigationBarItems
 *   </DHLTabNavigationBar>
 *
 *   <Routes>
 *     {createNavigatingSplatRoute("path/to/navigate/to/on/index/or/unknown/route")}
 *     <Route path="/target/path/to/SomeComponent" element={<SomeComponent />}
 *       ...more Route elements for other DHLTabNavigationBarItems
 *     </Route>
 *   </Routes>
 * </CallingComponent>
 * ```
 */
export const DHLTabNavigationBar = ({variant, children, preserveSearchParams = false}: DHLTabNavigationBarProps) => {
  const [searchParams] = useSharedSearchParams();
  return (
      <div className={classNames("tab-navigation-bar", variant)}>
        <div className={"tab-navigation-bar-item-container"}>
          {
            (Array.isArray(children) ? children : [children])
                .filter(isDHLTabNavigationBarItem)
                .map((child, index) => {
                  const {to: toFromProps} = child.props;
                  let filteredParams: [string, string][] = [];
                  if (preserveSearchParams === true) {
                    filteredParams = [...searchParams];
                  } else if (typeof preserveSearchParams === "function") {
                    filteredParams = [...searchParams].filter(([searchParamName]) => preserveSearchParams(searchParamName));
                  }
                  const to: To = typeof toFromProps === "string" ? parsePath(toFromProps) : toFromProps;
                  const newSearch = new URLSearchParams(to.search);
                  filteredParams.forEach(([name, value]) => newSearch.append(name, value));
                  const newSearchAsString = newSearch.toString();
                  if (newSearchAsString !== "") {
                    to.search = "?" + newSearchAsString;
                  }
                  return <DHLTabNavigationBarItem {...child.props} to={to} key={child.key ?? index}/>;
                })
          }
        </div>
      </div>
  );
};

export type DHLTabNavigationBarItemProps = {
  to: To;
  labelText?: string | [string, string];
  icon?: ReactElement<DHLIconProps>,
  disabled?: boolean;
  className?: string;
  tooltip?: ReactNode;
};

export const DHLTabNavigationBarItem = ({labelText, icon, to, tooltip, disabled = false, className = ""}: DHLTabNavigationBarItemProps) => {
  const tooltipTargetRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const toggleIsTooltipOpen = useCallback(() => setIsTooltipOpen(prevState => !prevState), [setIsTooltipOpen]);

  const labelElem = labelText === undefined || labelText === ""
      ? undefined
      : <div className={"tab-navigation-item--label"}>
        {(Array.isArray(labelText) ? labelText : [labelText])
            .filter(labelLine => labelLine !== "")
            .map((labelLine, index) => <span key={index}>{labelLine}</span>)
        }
      </div>;

  return (<>
    <div ref={tooltipTargetRef}> {/* only used to disable NavLink pointer-events in CSS but have tooltip on hover over this div */}
      <NavLink to={to} className={classNames("tab-navigation-item", {disabled}, className)}>
        {labelElem}{icon}
      </NavLink>
    </div>
    {tooltip
        && <DHLTooltip placement={"top"} tooltipOpen={isTooltipOpen} target={tooltipTargetRef} toggle={toggleIsTooltipOpen}>
          {tooltip}
        </DHLTooltip>
    }
  </>);
};

// this has to be a function an NOT a functional component! `Route` does only accept other `Route`s as children
export function createNavigatingSplatRoute(to: To): ReactElement<ComponentPropsWithoutRef<typeof Route>> {
  return <Route path={"*"} element={<NavigatingRoute to={to} />} />;
}

function NavigatingRoute({to}: { to: To }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, {replace: true});
  });

  return null;
}

function isDHLTabNavigationBarItem(child: DHLTabNavigationBarChild): child is ReactElement<DHLTabNavigationBarItemProps> {
  return child !== undefined && child !== null && typeof child !== "boolean";
}