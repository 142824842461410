/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import {DHLTableCellCommonProps} from "./DHLTableCell";

export type DHLTableCellLinkProps = DHLTableCellCommonProps & {
  /** Column title. */
  title?: string;

  /** Name of the property to be displayed. */
  propertiesName: string;

  /** CSS-Classes. */
  className?: string;

  /** Function is called when you click on the link. */
  onClick: (event: React.MouseEvent, value: any) => void;

  /** Text (Link). */
  label?: string;

  /** Tooltip for Links. */
  tooltip?: string;

  /** Alignment of content for this cell */
  contentAlignment?: "left" | "center" | "right" | null,

  /** Prevent, that this column will have a min width. */
  disableMinWidth?: boolean;

  /** Disable cell based on this row property. */
  disabledPropertiesName?: string;
}

/** Tabellenspaltendefinition - Link. */
export const DHLTableCellLink = (props: DHLTableCellLinkProps) => {
  return null;
};

DHLTableCellLink.defaultProps = {
  type: "link",
};
