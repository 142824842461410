/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DHLMultiBox, DHLTextInput, FormField } from "../../..";
import { FilteredComboBoxStore } from "../../../stores/FilteredComboBoxStore";
import "./DHLFilteredComboBox.scss";
import { DHLIcon, IconType } from "../../atoms/DHLIcon/DHLIcon";
import { DHLMultiBoxAdditionalInfoAlignmentType } from "../../atoms/DHLMultiBox/DHLMultiBox";

export type DHLFilteredComboBoxDataType = {
  /** Data value that is to be displayed in the selection list.  */
  value: string,
  /** Additional information regarding to this data that is to be displayed in the selection list.  */
  additionalInformation?: string;
};

/** Props Definition for the DHLFilteredComboBox component. */
export type DHLFilteredComboBoxProps = {
  /** Name used as part of the unique test-id for testing the component. */
  name?: string;
  /** Flag, indicating if the component is disabled. */
  disabled?: boolean;
  /** Complete list of values that are available to be displayed in the selection list.  */
  data: DHLFilteredComboBoxDataType[];
  /** Alignment of the additional Informations (if present).
   * Note: alignment left is not GKDS-conform and should not be used */
  additionalInfoAlignment?: DHLMultiBoxAdditionalInfoAlignmentType;
  /** Default value, which is entered automatically, if no value is selected or entered manually i.e. instead of being empty. */
  defaultValue?: string,
  /** Alternativ text which is displayed if the selection list is empty. */
  emptyListText?: string;
  /** The maximum number of elements shown in the selection list, before there will be scroll bar. (implemented
   *  2-20, should not be more than 7 (GKDS) */
  maxShownElements?: number;
  /** Wrapper prop containing props like name, label, value, etc. Explicitly set props take precedence over props contained in the FormField prop. */
  formField: FormField<string>;
  /** Flag indicating if an entered value, that does not match a value from the data list, should be kept or dismissed. */
  keepValueIfNotInList?: boolean;
  /** List of forbidden characters. */
  forbiddenChars?: string[];
  /** Error message displayed if the entered value contains forbidden characters. */
  forbiddenCharsErrorMessage?: string
  /** Name des Icons . */
  icon?: IconType;
  /** Limits option box width to input field witdh */
  limitToInputFieldWidth?: boolean;
  /** Function for onChange calls. */
  onChange?: Function;
  /** Function for onChange calls. */
  clearOnFocus?: boolean;
  /** Flag to disable autocomplete. */
  autoCompleteOff?: boolean;
};

/** The component DHLFilteredComboBox. This component displays a selection list input component. A value from the selection list can be selected
 *  and will be entered in the input field as usual. The input field can be used to manually enter values which will automatically filter the
 *  available values in the selection list. It can be configured that new values may be entered or that only available values may be
 *  entered/selected. */
export const DHLFilteredComboBox = observer((
    {
      name,
      disabled,
      data,
      additionalInfoAlignment,
      defaultValue,
      emptyListText,
      maxShownElements = 7,
      formField,
      keepValueIfNotInList = false,
      forbiddenChars = [],
      forbiddenCharsErrorMessage = ".combobox.invalidchar",
      icon,
      limitToInputFieldWidth = true,
      onChange,
      clearOnFocus = false,
      autoCompleteOff = true

    }: DHLFilteredComboBoxProps) => {
  const [store] = useState(new FilteredComboBoxStore(formField));
  const [forbiddenCharsError, setForbiddenCharsError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const displayAreaRef = useRef<any>(null);
  const [defaultInitiallySet, setDefaultInitiallySet] = useState(false);
  if (defaultValue && !formField.value && !defaultInitiallySet) {
    formField.updateValue(defaultValue);
    setDefaultInitiallySet(true);
  }
  useEffect(() => {
    store.setAvailableEntries(data);
  }, [data]);
  const [isMultiBoxOpen, setIsMultiBoxOpen] = useState(false);
  useEffect(() => {
    if (isMultiBoxOpen) {
      inputRef!.current!.focus();
    }
  }, [isMultiBoxOpen]);
  const toggleMultiBoxIsOpen = () => {
    setIsMultiBoxOpen(!isMultiBoxOpen);
  };
  const onSelectValue = (key: string) => {
    formField.updateValue(key);
    if (onChange) {
      onChange(key);
    }
    setIsMultiBoxOpen(false);
  };
  const _onKeyPressed = useCallback((event) => {
    const CLOSING_KEYS = ["Escape", "Tab", "Alt"];
    const KEY_ENTER = "Enter";
    const IGNORE_KEYS = ["Control", "Alt", "AltGraph", "Tab", "Unidentified"];
    if (isMultiBoxOpen && CLOSING_KEYS.indexOf(event.key) !== -1) {
      setIsMultiBoxOpen(false);
    } else if (isMultiBoxOpen && event.key === KEY_ENTER) {
      if (store.inputMatchesExactly) {
        setIsMultiBoxOpen(false);
      }
    } else if (!isMultiBoxOpen && IGNORE_KEYS.indexOf(event.key) === -1) {
      setIsMultiBoxOpen(true);
    }
  }, [isMultiBoxOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", _onClickOutside);
    return () => {
      document.removeEventListener("mousedown", _onClickOutside);
    };
  }, [displayAreaRef]);

  const clickIsInsideThisComponent = (event: MouseEvent) => {
    return (displayAreaRef.current.contains(event.target));
  };
  const unfocusFilteredCombobox = () => {
    setIsMultiBoxOpen(false);
    if (!keepValueIfNotInList && !store.availableEntries.map((entry) => entry.value).includes(formField.value)) {
      formField.updateValue(defaultValue ? defaultValue : "");
    }
  };
  const _onClickOutside = (event: MouseEvent) => {
    if (!clickIsInsideThisComponent(event)) {
      unfocusFilteredCombobox();
    }
  };
  const _onFocus = (event: React.FocusEvent) => {
    if (clearOnFocus) {
      formField.updateValue("");
    }
    setIsMultiBoxOpen(true);
  };
  const onIconClick = () => {
    if (!isMultiBoxOpen && clearOnFocus) {
      formField.updateValue("");
    }
    setIsMultiBoxOpen(!isMultiBoxOpen);
  };
  const inputContainsForbiddenChars = useMemo(
      () => forbiddenChars.some(char => formField.value.indexOf(char) !== -1)
      , [formField.value]
  );
  useEffect(() => {
    if (inputContainsForbiddenChars) {
      setForbiddenCharsError(forbiddenCharsErrorMessage);
      setIsMultiBoxOpen(false);
    } else {
      setForbiddenCharsError("");
    }
  }, [inputContainsForbiddenChars]);

  useEffect(() => {
    if (disabled) {
      unfocusFilteredCombobox();
    }
  }, [disabled]);
  const isOpen = !disabled && isMultiBoxOpen && !(store.matchingEntriesMarked.length === 0 && !emptyListText);
  return (
      <div ref={displayAreaRef} className={classNames("filteredComboBox-container", icon ? "withIcon" : null)}>
        <DHLTextInput
            type="text"
            formField={formField}
            functionIcon={!disabled ? "arrow-down" : undefined}
            onFunctionClick={toggleMultiBoxIsOpen}
            onKeyDown={_onKeyPressed}
            onFocus={_onFocus}
            disabled={disabled}
            innerRef={inputRef}
            error={forbiddenCharsError ? forbiddenCharsError : undefined}
            autoCompleteOff={autoCompleteOff}
        />
        {icon &&
        <div className={classNames("filteredComboBoxIcon-container", disabled ? "disabled" : null)}>
          <DHLIcon name={"filteredComboBoxIcon"} icon={icon} onClick={onIconClick}/>
        </div>}
        <DHLMultiBox
            availableValues={store.matchingEntriesMarked}
            name={name}
            additionalInfoAlignment={additionalInfoAlignment}
            isOpen={isOpen}
            onSelectValue={!disabled ? onSelectValue : () => { /* intended use */ }}
            className={classNames(limitToInputFieldWidth ? "limitWidth" : null)}
            emptyListText={emptyListText}
            maxShownElements={maxShownElements} />
      </div>
  );

});