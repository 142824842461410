/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import { Button } from "reactstrap";

export type DHLCloseButtonProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Funktion für onClick-Aufrufe. */
  onClick: React.MouseEventHandler<HTMLElement>;

  /** Element ausgeben? */
  render?: boolean;
};

/** Close-Button für Dialoge. */
export const DHLCloseButton = ({name, onClick, render = true}: DHLCloseButtonProps) => {
  if (!render) {
    return null;
  }

  return (
      <Button data-testid={name + "-close"} className="close" onClick={onClick}>&times;</Button>
  );
};
