import {ReactElement, useEffect, useState} from "react";
import {TabItemType} from "../../atoms/DHLTabNavigationItem/DHLTabNavigationItem";
import {DHLTabNavigation} from "../DHLTabNavigation/DHLTabNavigation";

export type DHLTabContainerProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** ID des aktiven Tabs. */
  activeTabId: string;

  /** Wird beim Wechsel eines Tabs aufgerufen. */
  onTabChange?: (evt: React.MouseEvent, tabId: string) => void;

  /** Display type (primary | secondary) */
  displayType?: TabItemType;

  /** Kind-Komponenten */
  children: ReactElement[];
};

/** Tab-Container. */
export const DHLTabContainer = ({name, activeTabId, onTabChange, displayType, children}: DHLTabContainerProps) => {
  const [currentActiveTabId, setCurrentActiveTabId] = useState(activeTabId);

  useEffect(() => {
    setCurrentActiveTabId(activeTabId);
  }, [activeTabId]);

  const onClickTabItem = (evt: React.MouseEvent, tabId: string) => {
    setCurrentActiveTabId(tabId);

    if (onTabChange) {
      onTabChange(evt, tabId);
    }
  };

  return (
      <div data-testid={name} className="tab-content">
        <DHLTabNavigation name={name + "-tnc"} activeTabId={currentActiveTabId} navItems={children} displayType={displayType}
                          onClickTabItem={onClickTabItem} />
        <div data-testid={name + "-active-" + currentActiveTabId}>
          {children.map(child => { 
            if (child.props.tabId !== currentActiveTabId) {
              return undefined;
            }

            return child.props.children;
          })}
        </div>
      </div>
  );
};
