import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FormField, logger } from "../../..";
import { ValidationRuleType } from "../../../types/ValidationRuleTypes";
import { calculateName } from "../../../utils/calcPropertiesValue";
import { DHLTextInput } from "../DHLTextInput/DHLTextInput";

export type DHLPasswordInputProps = {
  /** Name des Eingabefeldes. Sollte mit dem Namen des Properties für den Eingabewert übereinstimmen, um einen einzigen Change-Handler verwenden zu
   * können. Wir dauch für die Generierung der Test-ID ververwendet. */
  name?: string;

  /** Labeltext */
  label?: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: React.ChangeEventHandler<any>;

  /** Function die bei Enter aufgerufen wird. */
  onEnter?: React.KeyboardEventHandler<any>;

  /** Label mit Pflichtfeldmarkierung versehen? */
  required?: boolean;

  /** Cursor im Eingabefeld positionieren? */
  autoFocus?: boolean;

  /** Max Länge des Passworts. */
  maxLength?: number;

  /** Platzhaltertext für leere Eingabefelder. */
  placeholder?: string;

  /** Deaktivieren? */
  disabled?: boolean;

  /** Element ausgeben? */
  render?: boolean;

  /** Passworteingabe. */
  value?: any;

  /** Validierungsregel. */
  validationRule?: ValidationRuleType;

  /** True wenn das Passwort im Klartext angezeigt werden soll, sonst false. */
  showVisibilityToggle?: boolean;

  /** Fehlertext(e), wenn ein oder mehrere Fehler aufgetreten sind. */
  error?: string | string[] | null;

  /** Flag determining if in case of an error no local error is displayed, but only the error style will be applied to the input field.  */
  errorMarkerOnly?: boolean;

  /** Definiert ein Formularfeld mit Name, Label, Wert, Validierungsregel und Fehlertext. Einzelparameter haben Priorität. */
  formField?: FormField<any>;
};

/** Passworteingabe mit integriertem Label und Icon zur Aktivierung der Klartextanzeige. */
export const DHLPasswordInput = observer(({
                                            value,
                                            label,
                                            error,
                                            errorMarkerOnly,
                                            onChange,
                                            onEnter,
                                            required,
                                            autoFocus,
                                            placeholder,
                                            disabled,
                                            render = true,
                                            maxLength,
                                            name,
                                            validationRule,
                                            showVisibilityToggle,
                                            formField
                                          }: DHLPasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  if (showPassword && (formField?.value === "" || value === "")) {
    setShowPassword(false);
  }

  const toggleShowPassword = (event: any): void => {
    setShowPassword(!showPassword);

    event.preventDefault();
  };

  if (!render) {
    return null;
  }

  const calcName = calculateName(name, formField);

  if (!calcName) {
    logger.error("Components needs an explicit name or formField parameter");
    return null;
  }

  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setShowPassword(false);
    }
    if (onChange) {
      onChange(event);
    }
  };

  const showPasswordIconEnabled = showVisibilityToggle && !disabled;
  const showPasswordState = showPassword ? "form-hide" : "form-show";
  const showPasswordIcon = showPasswordIconEnabled ? showPasswordState : undefined;
  return (
      <>
        <DHLTextInput
            name={name}
            label={label}
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            onChange={onChangeWrapper}
            onKeyUp={(event: React.KeyboardEvent<any>) => {
              if (event.key === "Enter" && onEnter) {
                onEnter(event);
              }
            }}
            value={value}
            maxLength={maxLength}
            error={error}
            errorMarkerOnly={errorMarkerOnly}
            required={required}
            disabled={disabled}
            autoFocus={autoFocus}
            autoCompleteOff={true}
            validationRule={validationRule}
            formField={formField}
            functionIcon={showPasswordIcon}
            onFunctionClick={showPasswordIconEnabled && value && value !== "" ? toggleShowPassword : undefined}
        />
      </>
  );
});
