/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as Popper from "popper.js";
import { MouseEventHandler, ReactNode, RefObject } from "react";
import { Tooltip } from "reactstrap";
import "./DHLTooltip.scss";
import { DHLTextOutput } from "../DHLTextOutput/DHLTextOutput";

/** IMPORTANT: Do not use extends, because Storybook cannot handle it and does not display the attributes of the parent interfaces/types. */
export type DHLTooltipProps = {

  /** Test id for test selectors */
  testid?: string;

  /** Placement of the tooltip relative to the target. */
  placement?: Popper.Placement;

  /** FLag to explicitly disable the max-width of the tooltip. */
  noMaxWidth?: boolean;

  /** Flag indicating of the tooltip is currently shown. */
  tooltipOpen?: boolean;

  /** Target element where the tooltip will be shown. */
  target: string | HTMLElement | RefObject<HTMLElement>;

  /** Function to toggle the display of the tooltip. */
  toggle?: MouseEventHandler<HTMLElement> | (() => void);

  /** CSS classes for the container div in the tooltip */
  className?: string;

  /**  */
  children: ReactNode;
};

/** Text Input with integrated label. */
export const DHLTooltip = observer(({
                                      testid,
                                      placement = "right",
                                      noMaxWidth,
                                      tooltipOpen,
                                      target,
                                      toggle,
                                      className,
                                      children
                                    }: DHLTooltipProps) => {

  const output = (
      <Tooltip data-testid={testid}
               className={classNames(className, "dhlTooltip", noMaxWidth ? "noMaxWidth" : null)}
               placement={placement}
               isOpen={tooltipOpen}
               target={target}
               toggle={toggle}>
        <div data-testid={testid != null ? (testid + "-dhlTooltipContainer") : undefined} className={classNames(className, "dhlTooltipContainer")}>
          {typeof children === "string" ? <DHLTextOutput value={children} /> : children}
        </div>
      </Tooltip>
  );

  return output;
});
