/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export const extractObjectData = (data: any, propertyName: string): any => {
  let value: any = data;
  if (propertyName !== "") {
  propertyName
      .split(".")
      .forEach(levelname => {value = value?.[levelname] ?? null;});
  }
  return value;
};
