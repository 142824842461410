/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { DatePickerDataStore, logger } from "../../..";
import { DayOfMonth } from "../../../types/DatePickerTypes";
import "./DHLDatePicker.scss";
import { Month } from "./Month";
import { WeekNavigationHeader } from "./WeekNavigationHeader";

export type DHLDatePickerProps = {
  /** Name, wird für die Generierung der Test-ID verwendet. */
  name: string;

  /** Steuert den DatePicker. */
  dataStore: DatePickerDataStore;

  /** Funktion für Übergabe des gewählten Datums. */
  onDateSelected?: (date: moment.Moment) => void;
};

/** Stellt einen DatePicker dar. */
export const DHLDatePicker = observer(({name, dataStore, onDateSelected}: DHLDatePickerProps) => {


  useEffect(() => {
    /* Zum Thema zur Verfügung stehende Jahre in der Auswahl:
     * Ein Maximum gibt es nicht. Die Jahre werden je nach Anwendungszweck über die Story vorgegeben.
     * Bspw. ist es bei der Beauftragung immer Zukunft und bei Reporting-Funktionen immer Vergangenheit.
     * Die Liste kann also mindestens ein Jahr und maximal vermutlich 10 Jahre enthalten.
     */
    if (!dataStore.startDate || !dataStore.endDate) {
      logger.warn("DHLDatePicker constructed with dataStore without start or end date!");
    }
  }, []);

  const _onDateSelected = (day: DayOfMonth) => {
    dataStore.onDaySelected(day);

    if (onDateSelected && dataStore.selectedYear !== undefined && dataStore.selectedMonth !== undefined && dataStore.selectedDay !== undefined) {
      const selectedDate: moment.Moment = moment([dataStore.selectedYear, dataStore.selectedMonth, dataStore.selectedDay]);

      if (selectedDate.isValid()) {
        onDateSelected(selectedDate);
      }
    }
  };

  return (
      <div className={"dhlDatePicker dhlDatePicker-expanded"}>
        <div className="dhlDatePicker-expanded-header">
          {<WeekNavigationHeader name={name + "-wh-nav"}
                                 isNextMonthAvailable={dataStore.isNextMonthAvailable()}
                                 isPreviousMonthAvailable={dataStore.isPreviousMonthAvailable()}
                                 monthsOfYear={dataStore.monthsOfYear}
                                 years={dataStore.years}
                                 displayedMonth={dataStore.displayedMonth} displayedYear={dataStore.displayedYear}
                                 onPreviousMonth={dataStore.onPreviousMonth}
                                 onNextMonth={dataStore.onNextMonth}
                                 onMonthSelected={dataStore.onMonthSelected}
                                 onYearSelected={dataStore.onYearSelected} />}
        </div>
        <div className="dhlDatePicker-expanded-dates">
          <Month name={name} daysOfMonthGroupedByWeek={dataStore.daysOfMonthGroupedByWeek} showWeekNumbers={true} onDateSelected={_onDateSelected} />
        </div>
      </div>
  );
});
