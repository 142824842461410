/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {observer} from "mobx-react-lite";
import {DHLCheckbox} from "../../..";
import {logger} from "../../../utils/logger";

export type DHLTableColBooleanProps = {
  /** Anzuzeigender Wert. */
  value?: any;

  /** ID der Zelle. */
  cellId: string;

  /** Funktion für onChange-Aufrufe. */
  onChange?: (value: boolean, cellId: string, idPropertyName: string, colPropertyName: string) => void;

  /** Name des Properties mit der ID des Datensatzes. */
  idPropertyName?: string;

  /** Name des Properties dessen Daten in dieser Spalte angezeigt werden. */
  colPropertyName?: string;

  /** Deaktiviertheitsstatus */
  disabled?: boolean;

};

/** Ausgabe einer Tabebellenzelle - boolscher Wert als Checkbox. */
export const DHLTableColBoolean = observer(({
                                              value,
                                              cellId,
                                              onChange,
                                              idPropertyName,
                                              colPropertyName,
                                              disabled
                                            }: DHLTableColBooleanProps) => {
  let output = value;

  if (onChange && !(idPropertyName && colPropertyName)) {
    logger.error("Missing parameters, need names of id and col properties when using onChange");
  }

  if (output !== undefined && output !== null) {
    if (onChange && idPropertyName && colPropertyName) {
      output = (
          <DHLCheckbox name={cellId}
                       value={value}
                       disabled={disabled}
                       onChange={() => {
                         onChange(!value, cellId, idPropertyName, colPropertyName);
                       }}
          />
      );
    } else {
      output =
          <DHLCheckbox name={cellId}
                       value={value}
                       disabled={true}
                       onChange={() => { /* intended use */}}
          />;
    }
  }

  return <>{output}</>;
});
