/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import "./DHLProgressBar.scss";
import { ProgressBarState } from "./DHLProgressBar";
import { ProgressBarBorder } from "./ProgressBarBorder";

export type ProgressBarIndicatorProps = {
  showBorderStart: boolean;
  showBorderEnd: boolean;
  index: number;
  indicatorState: ProgressBarState;
}

export const ProgressBarIndicator = ({showBorderStart, showBorderEnd, index, indicatorState}: ProgressBarIndicatorProps) => {
  const indicatorClass = "dhlProgressBar-indicator";
  const circleClass = `${indicatorClass}-circle`;
  const numberClass = `${indicatorClass}-number`;
  const numberContainerClassWithState = `${circleClass}-${indicatorState}`;
  const numberClassWithState = `${numberClass}-${indicatorState}`;

  return (
      <div className={indicatorClass}>
        <ProgressBarBorder borderState={showBorderStart ? indicatorState : "invisible"}/>
        <div className={classNames(circleClass, numberContainerClassWithState)}>
          <p className={classNames(numberClass, numberClassWithState)}>
            {index}
          </p>
        </div>
        <ProgressBarBorder borderState={showBorderEnd ? indicatorState : "invisible"}/>
      </div>
  );

};