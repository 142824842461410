/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import "./DHLFoldOut.scss";
import { DHLCheckbox } from "../DHLCheckbox/DHLCheckbox";
import { FormField } from "../../../stores/FormField";

export type DHLFoldOutProps = {
  /** Name of the accordion has to be unique on each site with more than one accordion field.
   * Also used for generating the Test ID. */
  name: string;

  /** icon slot */
  icon?: React.ReactNode;

  /** Label text. */
  checkboxFormField: FormField<boolean>;

  /** Content text. */
  children: string | React.ReactNode;
};

/** Checkbox with integrated Label. */
export const DHLFoldOut = observer(
    ({
       name,
       icon,
       checkboxFormField,
       children
     }: DHLFoldOutProps
    ) => {

      return (
          <div className="foldOut">
            <input id={name} type="checkbox" readOnly checked={checkboxFormField.value} />
            <div className={"foldOutHeader"}>
              {icon}
              <DHLCheckbox name={name} formField={checkboxFormField} />
            </div>
            <div className="foldOutContent">
              <div className="foldOutContentInner">
                {children}
              </div>
            </div>
          </div>
      );
    }
);
