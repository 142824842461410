/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import "./DHLAccordion.scss";

export type DHLAccordionProps = {
  /** Name of the accordion has to be unique on each site with more than one accordion field.
   * Also used for generating the Test ID. */
  name: string;

  /** Label text. */
  label: string | React.ReactNode;

  /** Content text. */
  content: string | React.ReactNode;

  /** CSS-classes for input field. */
  className?: string;

  /** render element? */
  render?: boolean;

};

/** Checkbox with integrated Label. */
export const DHLAccordion = observer(
    ({
       name,
       label,
       content,
       className,
       render = true,
     }: DHLAccordionProps) => {

      if (!render) {
        return null;
      }

      const output = (
          <div className={className}>
            <div className="accordion">
              <input id={name} type="checkbox" />
              <label className="accordionLabelText" htmlFor={name}>{label}</label>
              <div className="accordionContent">{content}</div>
            </div>
          </div>
      );

      return <>{output}</>;
    }
);
