/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import "./DHLActionTextLink.scss";
import * as Popper from "popper.js";
import { useRef, useState } from "react";
import { DHLTooltip } from "../../molecules/DHLTooltip/DHLTooltip";
import { DHLIcon, IconType } from "../DHLIcon/DHLIcon";

export type DHLActionTextLinkProps = {
  /** Link text. */
  text: string;
  /** Optional Icon */
  icon?: IconType;
  /** Function für onClick-calls. */
  action: React.MouseEventHandler<HTMLElement>;
  /** CSS-classes. */
  className?: string;
  /** Tooltip text. */
  tooltip?: string;
  /** Placement of tooltip. */
  tooltipPlacement?: Popper.Placement;
  /** Tooltip, if link disabled. */
  disabledTooltip?: string;
  /** Flag to disable the link. */
  disabled?: boolean;
}

export const DHLActionTextLink = ({
                                    text,
                                    icon,
                                    action,
                                    className,
                                    tooltip,
                                    tooltipPlacement = "bottom",
                                    disabledTooltip,
                                    disabled
                                  }: DHLActionTextLinkProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  let usedTooltip: string | undefined = tooltip;

  if (disabled && disabledTooltip) {
    usedTooltip = disabledTooltip;
  }

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const link =
      <span className={classNames("dhlActionTextLink", className, disabled ? "disabled" : "")} onClick={!disabled ? action : undefined}>
        {icon && <DHLIcon name={"dhlActionTextLinkIcon"} icon={icon} />}
        {text}
      </span>;
  return <>
    <span ref={tooltipRef}>
      {link}
    </span>
    {usedTooltip &&
        <DHLTooltip
            placement={tooltipPlacement}
            tooltipOpen={tooltipOpen}
            target={tooltipRef}
            toggle={toggle}>
          {usedTooltip}
        </DHLTooltip>}
  </>;
};