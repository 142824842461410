/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { makeObservable, observable } from "mobx";
import { ParameterizedMessageType } from "../types/ResourceDataStoreTypes";
import { FetchAdapter } from "../utils/fetch/FetchAdapter";
import { LocalizationPack } from "./LocalizationPack";
import { ResourceDataStore } from "./ResourceDataStore";
import { TFunction } from "i18next";
import { transformParams } from "../utils/i18next";

/** Data store for the localization of a module. */
export class ResourceDataStoreTAdapter extends ResourceDataStore {

  /**
   * Constructor.
   * @param fetchAdapter the FetchAdapter to use
   * @param baseUrl base url of the backend
   * @param module text for the modul loading
   * @param t tfunction
   */
  constructor(
      fetchAdapter: FetchAdapter,
      baseUrl: string,
      module: string,
      readonly t: TFunction
      // private readonly i18next: i18n
  ) {
    super(fetchAdapter, baseUrl, "de", module);
    makeObservable<ResourceDataStoreTAdapter, "t">(this, {
      t: observable
    });
  }

  /** Mapping Keys on text. */
  get messageMap(): Map<string, string> {
    // const resultMap: Map<string, string> = new Map();
    //
    // Object.values(this.i18next.getDataByLanguage(this.language) ?? {})
    //     .forEach((data) =>
    //         Object.entries(data).forEach(([key, value]) => resultMap.set(key, value))
    //     );
    //
    // return resultMap;
    throw new Error("Not implemented");
  }

  /** Gibt an, wie viele Dinge gerade noch geladen werden (müssen). */
  get loading(): number {
    const howMuchIsLoaded = 1 + (this.loadedSelectionLists ? 1 : 0);
    return this.initialLoading - howMuchIsLoaded;
  }

  /** Loaded locations? */
  get loadedLocalizations(): boolean {
    return true;
  }

  /** Set of keys for which no plaintext was found. */
  get warnings(): Set<string> {
    throw new Error("Not implemented");
  }

  /**
   * Das LocalizationPack dieses ResourceDataStores.
   * @returns {LocalizationPack} LocalizationPack dieses ResourceDataStores
   */
  get localizationPack(): LocalizationPack {
    throw new Error("Not implemented");
  }

  /**
   * Texte und Auswahllisten vom Server laden.
   */
  async load(): Promise<void> {
    await Promise.all([this.loadSelectionLists()]);
  }

  getScopedMessage(key: string, params?: string[]) {
    return this.getMessage(key, params);
  }

  /**
   * Msg-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getMsg(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

  /**
   * Label-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getLabel(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

  getSelectionListOptionLabel(key: string): string {
    return this.getMessage(key, []);
  }

  /**
   * Tooltip-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getTooltip(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

  /**
   * Placeholder-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getPlaceholder(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

  /**
   * Button-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getButton(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

  /**
   * Error-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getError(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

  /**
   * Button-Lokalisierung für das konfigurierte Modul laden.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getSuccess(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

  /**
   * Ermittelt den lokalisierten Text zum Schlüssel.
   * @param key Schlüssel
   * @param params Parameter
   * @return Text oder der nicht ersetzte Schlüssel
   */
  getMessage(key: string, params?: string[] | null): string {
    return this.t(key, transformParams(params));
  }

  /**
   * Ermittelt alle lokalisierten Texte.
   * @param msgs Array mit Objekten im Format {messageKey: "", params: ""}
   * @returns Array mit den Meldungen und ersetzten Platzhaltern
   */
  getMessages(msgs: ParameterizedMessageType[]) {
    return msgs.map(msg => this.getMessage(msg.messageKey, msg.params ?? []));
  }

  getHelp(key: string, params?: string[]): string {
    return this.getMessage(key, params);
  }

}
