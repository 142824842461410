/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { reaction } from "mobx";
import { useEffect, useState } from "react";
import { SelectionListManager } from "../stores/SelectionListManager";
import { SelectionListPack } from "../stores/SelectionListPack";

export const useSelectionLists= (selectionListManager: SelectionListManager) => {
  const [selectionLists, setSelectionLists] = useState<SelectionListPack>(new SelectionListPack(selectionListManager.selectionLists));

  useEffect(() => {
    selectionListManager.loadSelectionLists();
    return reaction(
        () => selectionListManager.selectionLists,
        () => setSelectionLists(new SelectionListPack(selectionListManager.selectionLists))
    );
  }, []);

  return selectionLists;
};