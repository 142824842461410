/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { DHLOptionTreeSubTreeEntry } from "../../../stores/DHLOptionTreeStore";
import { createMessageWithSpecialTags } from "../../../utils/messagesUtils";
import { DHLIcon } from "../../atoms/DHLIcon/DHLIcon";
import "./DHLOptionTree.scss";
import {DHLTextOutput} from "../DHLTextOutput/DHLTextOutput";

export type DHLOptionSubTreeProps = {
  entry: DHLOptionTreeSubTreeEntry
  onClick: (id: string) => void
  children: JSX.Element[]
}

export const DHLOptionSubTree = observer(({
                                   entry,
                                   onClick,
                                   children
                                 }: DHLOptionSubTreeProps) => {
  const createIcon = (id: string) => {
    const name = `${id}-icon`;
    return (
        <>
        {entry.expanded ? <DHLIcon name={name} icon={"minus"} /> : <DHLIcon name={name} icon={"plus"} />}
        </>
    );
  };
  const createLabel = (id: string) => {
    const name = `${id}-label`;
    const text = createMessageWithSpecialTags(`[b|${entry.label}]`);
    return (
      <div className={"label"}>
        <DHLTextOutput name={name} value={text} />
      </div>
    );
  };

  return (
    <>
      <div className={classNames("headline")} onClick={() => onClick(entry.id)}>
        {createIcon(entry.id)}
        {createLabel(entry.id)}
      </div>
      {entry.expanded && children}
    </>
  );

});