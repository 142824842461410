/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useRef, useState } from "react";
import classNames from "classnames";
import { DHLButton, DHLIcon } from "../../..";
import { DHLTooltip } from "../../molecules/DHLTooltip/DHLTooltip";
import "./DHLHelpButton.scss";
import { useUID } from "react-uid";
import { useTranslation } from "react-i18next";

export type DHLHelpButtonProps = {
  testId?: string,
  url: string,
  tooltipText?: string,
  type: "pagetemplate" | "pagetemplateWithHeaderbutton" | "dialog",
  className?: string,
}

export const DHLHelpButton = ({type, url, tooltipText, className, testId}: DHLHelpButtonProps) => {
  const [t] = useTranslation();
  const iconRef = useRef<HTMLDivElement | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const uid = useUID();
  tooltipText = tooltipText || t("helpButton.tooltip");

  if (type === "dialog") {// not clear if it should also switch to a button in a dialog
    return <>
      <DHLIcon innerRef={iconRef} name={testId || ""} icon="question" className={classNames("dhlHelpButton", "dhlHelpButton-type-" + type, className)}
               onClick={() => {
                 window.open(url, "_blank");
               }
               } />
      <DHLTooltip testid={testId ? (testId + "-tooltip") : ""} tooltipOpen={tooltipOpen} target={iconRef} toggle={toggle}>
        {tooltipText}
      </DHLTooltip>
    </>;
  }

  const id = testId || ("hb" + uid); // button requires name/id
  return <DHLButton
      onClick={() => window.open(url, "_blank")}
      tooltip={tooltipText}
      className={classNames("dhlHelpButton", "dhlHelpButton-type-" + type, className)}
      type={"default"}
      size={"sm"}
      icon="question"
      iconPosition={"icon-button"}
      name={id}
  />;
};