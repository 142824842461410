/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect, useState } from "react";
import { FormField } from "../../../stores/FormField";
import { useDataFilterStore } from "../../../utils/useDataFilterStore";
import { ResourceDataStore } from "../../../stores/ResourceDataStore";
import { ValidationRuleDataStore } from "../../../stores/ValidationRuleDataStore";
import { DHLCheckbox } from "../DHLCheckbox/DHLCheckbox";
import classNames from "classnames";
import { DHLFormGroup } from "../../atoms/DHLFormGroup/DHLFormGroup";
import { DHLToggle } from "../DHLToggle/DHLToggle";

export type DHLToggleFilterProps<T> = {

  /** Name to identify element */
  name: string;

  /** ID symbol */
  idSymbol?: string;
  // language switching within FormFields requires a resourceDataStore (not localizationPack)!

  /** ResourceDataStore */
  resourceDataStore: ResourceDataStore;

  /** ValidationRuleDataStore */
  validationRuleDataStore: ValidationRuleDataStore;

  /** Disable toggle */
  disabled?: boolean;

  /** Initial value */
  initialValue?: boolean;

  /** FormField to use for toggle */
  formField?: FormField<boolean>;

  /** Filter predicate */
  predicate: (data: T) => boolean;

  /** Style */
  style?: "checkbox" | "toggle"
}

export const DHLToggleFilter = <T extends unknown>(
    {
      name,
      idSymbol = name,
      resourceDataStore,
      validationRuleDataStore,
      disabled = false,
      initialValue = false,
      formField,
      predicate,
      style = "toggle"
    }: DHLToggleFilterProps<T>
) => {
  const dataFilterStore = useDataFilterStore<T>();

  const [inputField] = useState(() => formField ??
      new FormField<boolean>(
          `dataFilter.${name}`,
          initialValue,
          resourceDataStore,
          validationRuleDataStore
      )
  );

  useEffect(
      () => dataFilterStore.register(
          idSymbol,
          (_: Set<string>) => (data: T) => !inputField.value || predicate(data)
      ),
      [dataFilterStore, inputField, predicate]
  );

  return (
      <div className={classNames("dhlDataFilterToggle")}>
        <DHLFormGroup checkboxGroup>
          {style === "checkbox"
              ? <DHLCheckbox
                  name={"datafilter-toggle-checkbox"}
                  formField={inputField}
                  disabled={disabled}
              />
              : <DHLToggle
                  name={"datafilter-toggle-toggle"}
                  size={"sm"}
                  formField={inputField}
                  disabled={disabled}
              />
          }
        </DHLFormGroup>
      </div>
  );
};