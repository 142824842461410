/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import classNames from "classnames";
import "./DHLUploadedFile.scss";
import { DHLIcon, DHLIconProps } from "../DHLIcon/DHLIcon";

export type DHLUploadedFileProps = {
  fileTypeIcon: DHLIconProps,
  filename: string,
  successMessage: string,
  errorMessage: string,
  progressMessage: string,
  status: "Uploading" | "UploadComplete" | "UploadError",
  onRemove: React.MouseEventHandler<HTMLDivElement>;
}

export const DHLUploadedFile = ({successMessage, errorMessage, progressMessage, filename, fileTypeIcon, status, onRemove}: DHLUploadedFileProps) => {
  const statusClass = "dhlUploadedFile-" + status;
  const optimizeFileName = <span className="filename">{filename}</span>;

  const fileIcon = <DHLIcon {...fileTypeIcon} className={classNames("uploadIcon")} />;
  const text = (function () {
    switch (status) {
      case "Uploading":
        return <div className="fileNameContainer">{optimizeFileName}{progressMessage}</div>;
      case "UploadComplete":
        return <div className="fileNameContainer">{optimizeFileName}{successMessage}</div>;
      case "UploadError":
        return <div className="fileNameContainer">{optimizeFileName}{errorMessage}</div>;
      default:
        return null;
    }
  })();

  const resultIcon = (function () {
    switch (status) {
      case "Uploading":
        return <DHLIcon icon="preloader-no-bg" name="uploading" />;
      case "UploadComplete":
        return <DHLIcon icon="check" name="uploadComplete" />;
      case "UploadError":
        return <DHLIcon icon="alert-warning" name="uploadFailed" />;
      default:
        return <></>;
    }
  })();

  const actionIcon = (function () {
    switch (status) {
      case "UploadComplete":
        return <DHLIcon icon="delete" name="delete" onClick={onRemove} />;
      case "UploadError":
        return <DHLIcon icon="close-thin" name="close" onClick={onRemove} />;
      default:
        return <></>;
    }
  })();

  return <div className={classNames("dhlUploadedFile")}>
    <div className={classNames("dhlUploadedFile-inner", statusClass)}>
      {fileIcon}
      {text}
      {resultIcon}
    </div>
    {actionIcon}
  </div>;

};
